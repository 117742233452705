/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FileUploadIcon from "../../images/fileupload.svg";
import fileUploadStyle from "./FileUpload.module.css";
import Button from "../custom/button/Button";
import uploadIcon from "../../images/Vector.svg";
import crossIcon from "../../images/crossicon.svg";
// import AddDocStyles from "./AddDoc.module.css";
import addIcon from "../../images/add-plus.svg";
import Fill from "../../images/Fill.svg";
import useAxiosUpload from "../../useAxiosUpload";
import { fileSizeBYkb } from "../../utilities/FileSize";
import DeleteConfirmation from "../custom/delete-confirmation/DeleteConfirmation"
import { useSelector } from "react-redux";
import useTranslation from "../customHooks/translations";



// import { useSearchParams } from "react-router-dom";

function FileUpload() {
    const translation = useTranslation();
    const navigate = useNavigate();
    let akToken = localStorage.getItem("AkTOKENI");
    // const [searchParams] = useSearchParams();
    // let name = searchParams.get("name");
    // let age = searchParams.get("age");
    // let org = searchParams.get("org");
    const [files, setFiles] = useState([]);
    const [resfiles, setresFiles] = useState([]);
    const axiosInstanceUpload = useAxiosUpload();
    // const axiosInstance = useAxios();
    const [show, setShow] = useState(false);
    const login = useSelector((state) => state.loginPatient.arr[0]);
    //console.log("Hello test", login)
    const [deleteId, setdeleteId] = useState("");
    const [deleteIdUpload, setdeleteIdUpload] = useState("");
    const [deleteResId, setdeleteResId] = useState("");
    const [procTime, setprocTime] = useState(0);

    const chunkSize = 1024 * 1024 * 5; //its 5MB, increase the number measure in mbfunction App() {
    const [showProgress, setShowProgress] = useState(false);
    const [counter, setCounter] = useState(1);
    const [fileToBeUpload, setFileToBeUpload] = useState({});
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
    const [progress, setProgress] = useState(0);
    // const [fileGuid, setFileGuid] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [uploadFiles, setUploadFiles] = useState(false);
    const [uploadNumber, setuploadNumber] = useState(0);
    const [chunkCount, setChunkCount] = useState(0);
    const [uploadid, setUploadid] = useState("");
    const [sessionId, setsessionId] = useState("");
    const [dataPacks, setDataPacks] = useState([]);
    const [theArray, setTheArray] = useState([]);
    const [s3Key, sets3Key] = useState("");
    const [abc, setAbc] = useState(false);

    useEffect(() => {
        // setFileSize(0);
        setShow(files.length > 0);
        // setProgress(0);
        // setChunkCount(0);
        // setCounter(1);
        // let i =uploadNumber
        if (files) {
          let totalFile = files.length - 1;
          if (uploadNumber <= totalFile) {
            // console.log(uploadNumber, "hh");
            // getFileContext(files[uploadNumber]);
            let _file = files[uploadNumber];
            const _totalCount =
              _file.size % chunkSize === 0
                ? _file.size / chunkSize
                : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
            setChunkCount(_totalCount);
            setFileToBeUpload(_file);
            setFileSize(_file.size);
            initUpload(_file.name);
          }
        }
      }, [files, uploadNumber]);
    // console.log(abc ,'jjjjj')
    // useEffect(() => {
    //     //     // console.log("filesi", fileSize);
    //     if (fileSize > 0) {
    //         //       // console.log("filesize", fileSize);
    //         // setShowProgress(true);
    //         fileUpload(counter);
    //     }
    // }, [abc, progress]);


    const initUpload = async (q) => {

        setBeginingOfTheChunk(0);
        setEndOfTheChunk(chunkSize);
        setUploadid("");
        setsessionId("");
        sets3Key("");
        setCounter(1);
        setTheArray([]);
        try {
            // Call the Init API
            const response = await axiosInstanceUpload.current.post(
                `extglobal/files/initiateUpload?check_size=1`,
                {
                    ak_ou: '',
                    ak_pt: akToken,
                    ak_dc: '',
                    type: 38,
                    filename: q,
                },
                {
                    headers: {
                        "content-type": "application/json",
                    },
                }
            );
            setUploadid(response.data.data.uploadId);
            setsessionId(response.data.data.sessionId);
            sets3Key(response.data.data.s3Key);
            setAbc(!abc);
        } catch (error) {
            console.log("error", error.response.statusText);
        }
    };
    // console.log(fileSize, "size");
    // console.log(uploadid, "uploadid");
    // console.log(uploadid, "uploadid");
    // console.log(sessionId, "sessionId");
    // console.log(uploadid, "uploadid");
    // console.log(s3Key, "s3Key");
    // console.log(counter, "counter");

const uploadChunk = async (chunk) => {
    const formData = new FormData();
    formData.append("sessionId", sessionId);
    formData.append("uploadId", uploadid);
    formData.append("key", s3Key);
    formData.append("partNum", counter);
    formData.append(`size`, chunk.size);
    formData.append(`chunk`, chunk);
    // console.log(chunk);
    try {
        // let body = {chunk};

        const response = await axiosInstanceUpload.current.post(
            // `http://localhost:5000/continue?fileName=${fileGuid}&uploadId=${uploadid}&chunkNumber=${counter}`,
            "extglobal/files/uploadPart",
            formData
        );
        let data = response.data.data;

        setTheArray([...theArray, data]);
        //let arr=dataPacks.push(data)
        // setDataPacks([data])

        if (data.PartNumber !== 0) {
            setBeginingOfTheChunk(endOfTheChunk);
            setEndOfTheChunk(endOfTheChunk + chunkSize);
            if (counter === chunkCount) {
                // console.log(data);
                // console.log("Process is complete, counter", counter);

                // await uploadCompleted();
            } else {
                var percentage = (counter / chunkCount) * 100;
                setProgress(percentage);
            }
        } else {
           // console.log("Error Occurred:", data.errorMessage);
        }
    } catch (error) {
        console.log("error", error);
    }
};
    const fileUpload = () => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            let chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            if(s3Key){
            uploadChunk(chunk);
            }
            // setChunk_data(chunk)
        }
    };

    useEffect(() => {
        if (s3Key) {
            fileUpload();
        }
    }, [s3Key]);
    // useEffect(() => {
    //     //     // console.log("filesi", fileSize);
    //     if (fileSize > 0) {
    //         //       // console.log("filesize", fileSize);
    //         // setShowProgress(true);
    //         fileUpload(counter);
    //     }
    // }, [abc, progress]);
   // console.log(sessionId,uploadid,'uploadidUUUMri',s3Key);
    
  
// useEffect(()=>{
//     if(chunk_data && sessionId){
//         uploadChunk(chunk_data)
//     }
// },[sessionId,chunk_data])

    useEffect(() => {
        if (progress === 100) {
            const timer = setTimeout(() => {
                setProgress(0);
            }, 1000); 
    
            return () => clearTimeout(timer); 
        }
    }, [progress]);
    
    const saveData = async () => {
        // console.log("hhhhhhh");
        let per = {
            ak_pt: akToken,
            ak_dc: '',
            ak_ou: '',
            type: 38,
            filename: fileToBeUpload.name,
            key: s3Key,
        };

        
        const response = await axiosInstanceUpload.current.post(
            `extglobal/files/saveFileInResources`,
            per
        );
        if (response) {
            setuploadNumber(uploadNumber + 1);
            setresFiles([...resfiles, response.data.data]);
        }
    };

    
    const uploadCompleted = async () => {
        const params = {
            Key: s3Key,
            UploadId: uploadid,
            MultipartUpload: {
                Parts: theArray,
            },
        };

        const response = await axiosInstanceUpload.current.post(
            `extglobal/files/completeUpload`,
            params
        );


        const data1 = await response.data;
        if (data1) {
            // data2.setVideoload(false);
            saveData();
            setProgress(100);

            // document.getElementById("vid_disable").disabled = true;
        }
    };

    useEffect(() => {
        if (chunkCount !== 0 && s3Key) {
                   
            if (chunkCount === theArray.length) {
                
                uploadCompleted();
            }
        }
    }, [theArray]);
    //   // chunk upload  end




    const [errorMsg, setErrorMsg] = useState("");

    const handleDrop = (event) => {
        event.preventDefault();
        // let droppedFiles = event.dataTransfer.files;

        const selectedFiles = event.dataTransfer.files;
        let errorMsg = "";

        for (const file of selectedFiles) {
            // console.log("testing",file)
          //  console.log(file.name.substring(file.name.lastIndexOf(".") + 1));
            if (file.size > 200000000) {
                errorMsg = "File size exceeds 200MB limit";
                break;
            }
            let typeName = file.name.substring(file.name.lastIndexOf(".") + 1)
            // if (file.type === "") {
            //   if (file.name.substring(file.name.indexOf(".") + 1) === "hevc") {

            //   }
            // }
            if (
                ![
                    "image/png",
                    "image/jpeg",
                    "application/pdf",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "image/jpg",
                    "image/heif",
                    "image/heic",
                    "image/heif-sequence",
                    "image/heic-sequence",
                    // "video/hevc",
                    // "video/mp4",
                    "text/plain",
                    "application/zip",
                    "application/x-zip-compressed",
                    "image/heic", // added HEIC MIME type
                    "application/msword", // doc MIME type
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx MIME type
                    // "video/quicktime",
                ].includes(file.type) && typeName !== "hevc"
            ) {
                errorMsg =
                    `${translation.Invalid_file_type_only} PNG, JPEG, JPG, XLSX, TXT,ZIP,HEIF, HEVC, HEIC , MOV ${translation.and_} PDF ${translation.files_are_allowed}`;
                break;
            }
        }

        if (errorMsg) {
            event.target.value = null; // clear the selected files
            setErrorMsg(errorMsg);
        } else {
            // setFiles(selectedFiles);
            setFiles([...files, ...selectedFiles]);
            setErrorMsg("");
        }
        setprocTime("0%");
    };




    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        let errorMsg = "";
    
        for (const file of selectedFiles) {
            const fileType = file.type;
            const fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
    
            if (file.size > 200000000) {
                errorMsg = translation.File_size_exceeds_200MB_limit;
                break;
            }
    
            const validTypes = [
                "image/png",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "image/jpg",
                "image/heif",
                "image/heic",
                "image/heif-sequence",
                "image/heic-sequence",
                // "video/hevc",
                // "video/mp4",
                "text/plain",
                "application/zip",
                "application/x-zip-compressed",
                "image/heic",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                // "video/quicktime"
            ];
    
            if (!validTypes.includes(fileType) && fileExtension !== "hevc") {
                errorMsg = `${translation.Invalid_file_type_only} PNG, JPEG, JPG, XLSX, TXT, ZIP, HEIF, HEVC, HEIC, MOV, ${translation.and_} PDF ${translation.files_are_allowed}`;
                break;
            }
        }
    
        if (errorMsg) {
            e.target.value = null; // clear the selected files
            setErrorMsg(errorMsg);
        } else {
            setFiles([...files, ...selectedFiles]);
            setErrorMsg("");
        }
        setprocTime("0%");
    };

    
    
  
    //     const selectedFile = e.target.files[0]; // Get the first selected file

    //     if (selectedFile) {
    //         let errorMsg = "";

    //         if (selectedFile.size > 200000000) {
    //             errorMsg = translation.File_size_exceeds_200MB_limit;
    //         } else {
    //             let typeName = selectedFile.name.substring(selectedFile.name.lastIndexOf(".") + 1);

    //             if (
    //                 ![
    //                     "image/png",
    //                     "image/jpeg",
    //                     "application/pdf",
    //                     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //                     "image/jpg",
    //                     "image/heif",
    //                     "image/heic",
    //                     "image/heif-sequence",
    //                     "image/heic-sequence",
    //                     "video/hevc",
    //                     "video/mp4",
    //                     "text/plain",
    //                     "application/zip",
    //                     "application/x-zip-compressed",
    //                     "image/heic",
    //                     "application/msword",
    //                     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //                     "video/quicktime",
    //                 ].includes(selectedFile.type) && typeName !== "hevc"
    //             ) {
    //                 errorMsg =
    //                     `${translation.Invalid_file_type_only} PNG, JPEG, JPG, XLSX, TXT,ZIP ,HEIF, HEVC, HEIC ,MP4 , MOV ${translation.and_} PDF ${translation.files_are_allowed}`;
    //             }
    //         }

    //         if (errorMsg) {
    //             e.target.value = null; // Clear the selected file
    //             setErrorMsg(errorMsg);
    //         } else {
    //             setFiles([selectedFile]); // Set the selected file
    //             setErrorMsg("");
    //         }
    //         setprocTime("0%");
    //     }
    // };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const deleteArr = (e, index, res_id) => {
        setdeleteId(e);
        setdeleteIdUpload(index);
        setdeleteResId(res_id);
        setDisplayConfirmationModalDelete(true);
    };

    const deleteForS3 = (e) => {
        axiosInstanceUpload.current
            .delete(`ext_global/delete_file?key=${e}&resource_id=${deleteResId}`)
            .then((res) => {
              //  console.log(res);
            });
    };
    // console.log(deleteIdUpload);
    const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
        useState(false);

    const submitDelete = (type, e) => {
        if (files.length !== 0) {
            let x = files.filter((el, i) => e !== i);
            setFiles(x);
        }

        if (resfiles.length !== 0) {
            //   deleteForS3(deleteId);
            let x = resfiles.filter((el, i) => deleteIdUpload !== i);
            setresFiles(x);
            setuploadNumber(x.length);

            if (files.length !== 0) {
                let x = files.filter((el, i) => deleteIdUpload !== i);
                setFiles(x);
                setuploadNumber(x.length);
            }
        }
        setDisplayConfirmationModalDelete(false);
    };

    const hideConfirmationModal = () => {
        setDisplayConfirmationModalDelete(false);
    };

    
    function countTo100WithProgressBar() {
        let i = 1;
        let interval = setInterval(() => {
            let progress = (i / 100) * 100;
            setprocTime(progress.toString() + "%");
            i++;
            if (i > 100) {
                setprocTime("100%");

                clearInterval(interval);
            }
        }, 100);
    }
    // console.log(procTime);
    const handleCancel = () => {
        setFiles([]);
        setresFiles([]);
        setdeleteId("");
        setdeleteIdUpload("");
        setdeleteResId("");
        // setprocTime(0);
    };


    return (
        <>
            <div className={`file_upload`}>
                <div
                    className={`${fileUploadStyle.drop_zone}`}
                    id="dragandrophandler"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <div>

                        <img src={FileUploadIcon} alt="" />
                        <h4 className="mt-2 f_600">{translation.drap_and_drop}</h4>
                        <p className={`${fileUploadStyle.small}`}>
                            {translation.or_browser_files}
                        </p>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(e)}
                            name="ufile"
                            multiple
                            id="upload"
                            style={{ display: "none" }}
                            accept=".png,.jpeg,.pdf,.jpg,.xlsx,.txt,.zip,.heif,.heic,image/heif,image/heic,image/heif-sequence,image/heic-sequence,image/hevc,,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" //.mp4, video/quicktime, .hevc
                            //required
                        />
                        <label
                            htmlFor="upload"
                            className={`${fileUploadStyle.browserfilebtn}`}
                        >
                            {translation.browse_files}
                        </label>
                    </div>
                </div>
                {errorMsg && <p className="text-danger">{errorMsg}</p>}
                <br />
                {files.length === 0 ? (
                    <p></p>
                ) : (
                    <>
                        {files.map((file, indexs) => (
                            <div
                                className={`row upload_details ${indexs + 1 <= resfiles.length ? "d-none" : ""
                                    }`}
                                key={indexs}
                            >
                                <div className="col-md-8 col-sm-10">
                                    <img className="file_icon" src={uploadIcon} alt="icon" />
                                    <div className="file_name">
                                        <span>{file.name}</span>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 text-end pt_6">
                                    {show && indexs === uploadNumber && (
                                        <div class="progress">
                                            <div
                                                class="progress-bar"
                                                style={{ width: progress.toString() + "%" }}
                                            ></div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-2 col-sm-2 text-end pt_6">
                                    <span>{fileSizeBYkb(file.size)} </span>
                                    {/* <img
                                        className="ml_15 c_pointer"
                                        onClick={() => deleteArr(indexs, indexs)}
                                        src={crossIcon}
                                        alt="icon"
                                    /> */}
                                </div>
                            </div>
                        ))}
                    </>
                )}
                {resfiles.length === 0 ? (
                    <p></p>
                ) : (
                    <>
                        {resfiles.map((file, index) => (
                            <div className="row upload_details">
                                <div className="col-md-8 col-sm-8">
                                    <img className="file_icon" src={uploadIcon} alt="icon" />
                                    <div className="file_name">
                                        <span>{file.file_name}</span>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 text-end pt_6">
                                    <div class="">
                                        <div class="progress_bar">
                                            <img src={Fill} className="me-2" />
                                            {/* <span className="green-text">upload complete</span> */}
                                            {/* upload complete */}
                                            <span style={{ color: '#1DCE00' }}>{translation.Upload_complete}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 text-end pt_6">
                                    <span>{fileSizeBYkb(file.size)} </span>
                                    {/* <img
                                        className="ml_15 c_pointer"
                                        onClick={() => deleteArr(file.key, index, file.resource_id)}
                                        src={crossIcon}
                                        alt="icon"
                                    /> */}
                                </div>
                            </div>
                        ))}
                    </>
                )}

                <DeleteConfirmation
                    showModal={displayConfirmationModalDelete}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    message={""}
                    type="task_id"
                    id={deleteId}
                    openmodel="filedelete"
                />
                {/* <div className="p_25">
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-12">
                            <Button
                                onClick={handleCancel}
                                value="Cancel"
                                width="200px"
                                height="40px"
                                buttonStyle=" btn_outline  mr_20"
                            />
                            {show ? (
                                <button
                                    className="btn_fill"
                                    style={{ width: "200px", height: "40px" }}
                                    onClick={() => handleClick()}
                                >
                                    Upload
                                </button>
                            ) : (
                                <Button
                                    onClick={handleCancel}
                                    style={{ width: "200px", height: "40px" }}
                                    value="Upload"
                                    buttonStyle="btn_fill"
                                />
                            )}
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default FileUpload;
