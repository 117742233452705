import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import visitsStyles from "./MyVisits.module.css";
import Button from "../custom/button/Button";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import useTranslation from "../customHooks/translations";
import Modal from "react-bootstrap/Modal";
import ResultCircleProgress from "../custom/result-circle-progress/ResultCircleProgress";
import ScoreWidget from "./score-widget/ScoreWidget";
import { DateFormate, UserDateFormat } from "../../utilities/DateFormate";
import add from "../../images/add-plus.svg";
import minus from "../../images/Vector-m.svg";
import { ChangeLocalization } from "../../utilities/LocalazationChange";
import InfiniteScroll from "react-infinite-scroll-component";
// import useAxios from "../axiosinstance";
import Followupcomponent from "./Followup";

function MyVisits() {
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const [profile, setProfile] = useState("");
  const login = useSelector((state) => state.loginPatient.arr[0]);
  // console.log(ChangeLocalization("Feeling of instability"))
  useEffect(() => {
    if (login) {
      setProfile(login.patient);
    }
  }, [login]);
  const [isLoading, setIsLoading] = useState(true);
  const [initShow, setinitShow] = useState(true);
  const [show, setShow] = useState(false);
  const [initial, setInitial] = useState([]);
  const [Followup, setFollowup] = useState([]);
  const [offset, setOffset] = useState(15);
  const queryParams = new URLSearchParams(window.location.search);
  const q = queryParams.get("search");
  const search = queryParams.get("q");
  const [searchData, setSearch] = useState();
  const [check, setcheck] = useState();
  const [loop, setLoop] = useState(false);

  const [id, setId] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  // console.log(Followup.length);




  useEffect(() => {
    const getInitialData = async () => {
      try {
        const result = await axiosInstance.current.get(
          // `extapp/patients/patientVists/${profile.ak_id}?offset=${offset}&limit=10`
          `/extapp/patients/patientVists/${profile.ak_id}?offset=${0}&limit=15`
        );
        if (result) {
          setInitial(result.data.data);
          setinitShow(false);
        }
      } catch (err) {
        
        setinitShow(false);
      } finally {
       // console.log("api response finally");
      }
    };
    if (profile.ak_id) {
      getInitialData();
    }
  }, [profile]);

  const getFollowupData = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/followups/${profile.ak_id}?resource_id=${id}`
      );
      if (result.status === 200) {
        setFollowup(result.data.data);
        setShow(true);
      }
    } catch (err) {
     
      // setMessageType("error");
    } finally {
     // console.log("api response finally");
    }
  };

  useEffect(() => {
    if(profile.ak_id && id){
      getFollowupData();
    }
  }, [id, profile]);

  const View = (id) => {
    if (id) {
      setId(id);
      setShow(!show);
      // console.log(id);
    }
  };
  const [data, setDatas] = useState([]);
  const [load, setLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const getData = async (id) => {
    const response = await axiosInstance.current.get(
      `/extapp/patients/scoringLists/${profile.ak_id}/${id}`
    );
    setDatas(response.data.data);
    setLoad(true);
  };

  const handleShow = (id) => {
    setLoad(false);
    setShowModal(true);
    getData(id);
  };
  const [selectedItem, setSelectedItem] = useState([]);
  const handleShowMore = (item) => {
    setSelectedItem([]);
    if (selectedItem.includes(item)) {
      setSelectedItem(selectedItem.filter((i) => i !== item));
    } else {
      setSelectedItem([item]);
    }
  };

  const handleChk = (doctor, org, scoringForm, pending, resource_ids) => {
    // alert(doctor , org ,scoringForm)

    // alert( scoringForm)
    if (!pending) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "personal-data" +
        `/${window.btoa(profile.ak_id)}` +
        `/${window.btoa(doctor)}` +
        `/${window.btoa(org)}` +
        `/${window.btoa(scoringForm)}`,
        "_blank"
      );
    } else {
      if (scoringForm === 5) {
        window.open(
          process.env.REACT_APP_FORM_URL +
          "adult-knee-pain" +
          `/${resource_ids}`,
          "_blank"
        );
      }
      if (scoringForm === 4) {
        window.open(
          process.env.REACT_APP_FORM_URL + "pain" + `/${resource_ids}`,
          "_blank"
        );
      }
      if (scoringForm === 20) {
        window.open(
          process.env.REACT_APP_FORM_URL +
          "pedia-knee-pain" +
          `/${resource_ids}`,
          "_blank"
        );
      }
      if (scoringForm === 6) {
        window.open(
          process.env.REACT_APP_FORM_URL + "hip-pain" + `/${resource_ids}`,
          "_blank"
        );
      }
      if (scoringForm === 21) {
        window.open(
          process.env.REACT_APP_FORM_URL +
          "foot-ankle-pain" +
          `/${resource_ids}`,
          "_blank"
        );
      }
      if (scoringForm === 22) {
        window.open(
          process.env.REACT_APP_FORM_URL + "elbow-pain" + `/${resource_ids}`,
          "_blank"
        );
      }
      if (scoringForm === 23) {
        window.open(
          process.env.REACT_APP_FORM_URL +
          "hand-and-wrist-pain" +
          `/${resource_ids}`,
          "_blank"
        );
      }
      if (scoringForm === 33) {
        // alert(1)
        window.open(
          process.env.REACT_APP_FORM_URL +
          "general-history" +
          `/${resource_ids}`,
          "_blank"
        );
      }
    }
  };
  let lang = sessionStorage.getItem("lang_")

  //  const fetchNextUsers =async () => {
  //   setOffset(offset + 10);
  //   try{
  //   if (loop === false) {
  //   await  axiosinstance.current
  //       // .post(`globalsearch?offset=${offset}&limit=16`, { keyword: search })
  // .get(
  //   `/extapp/patients/patientVists/${profile.ak_id}?offset=${offset}&limit=10`

  // )
  //       .then((response) => {
  //         let res = response.data;
  //         if (res.length === 0) {
  //           setLoop(true);
  //           console.log("no data=========");
  //         } else {

  //           setSearch(searchData.concat(response.data));
  //         }
  //       });
  //   }
  // }catch(err){

  // }
  // };

  // const fetchNextUsers = async () => {
  //   // alert("5")
  //   console.log("fetchNextUsers called");
  //   setOffset(offset + 10);
  //   try {
  //     if (loop === false) {
  //       await axiosInstance.current.get(
  //         `/extapp/patients/patientVists/${profile.ak_id}?offset=${offset}&limit=10`
  //       ).then((res)=>{
  //         setInitial(...initial,res.data.data);
  //       })


  //     }
  //   } catch (err) {
  //     console.error("Fetch Next API Error:", err);
  //     // Handle the error as needed
  //   }
  // };

  const fetchNextUsers = async () => {
    
    setOffset(offset + 15);
    try {
      if (!loop) {
        const response = await axiosInstance.current.get(
          `/extapp/patients/patientVists/${profile.ak_id}?offset=${offset}&limit=15`
        );
        if (response && response.data) {
          const newData = response.data.data;
          if (newData.length === 0) {
            setLoop(true);
           
          } else {
            // Concatenate the new data with the existing data
            setInitial((prevData) => [...prevData, ...newData]);
          }
        }
      }
    } catch (err) {
      
      // Handle the error as needed
    }
  };

  // useEffect(() => {
  //   if (profile.ak_id) {
  //     getInitialData();
  //   }
  // }, [profile]);


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="my_visit_screen mb_100">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4 className="ph_title">{translation.myVisit}</h4>
            </div>
          </div>
          <div className="panel">


            <div id="accordion">
              <div className="panel-body p_25" >

                {initShow ? (
                  <LoadingSpinner />
                ) :


                  (


                    <InfiniteScroll
                      dataLength={initial.length}
                      next={fetchNextUsers}
                      hasMore={!loop}
                    >


                      {initial.length > 0 ? (
                        initial.map((item) => (
                          <div className={`${visitsStyles.visits_main}`}>
                            <div className={`${visitsStyles.visits_initial}`}>
                              <div className={`${visitsStyles.visits_inner}`}>
                                <div
                                  className={`${visitsStyles.visits_left_content}`}
                                >
                                  <p className={`${visitsStyles.visits_assdet}`}>
                                    <span>
                                      {item.created_at}
                                      {/* {new Date(
                                    item.created_at
                                  ).toLocaleDateString()} */}
                                    </span>
                                    <span>
                                      {" "}
                                      {item.evaluted === "Both"
                                        ? translation.Both
                                        : item.evaluted === "Right"
                                          ? translation.Right
                                          : item.evaluted === "Left"
                                            ? translation.Left
                                            : ""}
                                      -
                                    </span>
                                    <span>
                                      {item.case_type === "Shoulder"
                                        ? translation.shoulder
                                        : item.case_type === "Adult Knee"
                                          ? translation.adult_knee
                                          : item.case_type === "Hip"
                                            ? translation.hip_form
                                            : item.case_type === "Foot And Ankle"
                                              ? translation.Foot_And_Ankle
                                              : item.case_type === "Elbow"
                                                ? translation.Elbow
                                                : item.case_type === "Hand And Wrist"
                                                  ? translation.Hand_And_Wrist
                                                  : item.case_type === "Paediatric Knee"
                                                    ? translation.paediatric_knee :
                                                    item.case_type === "Adolescent Knee"
                                                      ? translation.adolescent_knee
                                                      : translation.General}
                                    </span>
                                  </p>
                                  <p className={`${visitsStyles.visits_dctrdet}`}>
                                    <span className="f_400">
                                      {item.doctor_name}
                                    </span>
                                    ,<span className="f_400"> {item.org_name}</span>
                                  </p>
                                </div>

                                {item.status === true ? (
                                  <>
                                    {item.case_type !== "General" && (
                                      <div
                                        className="btn_view"
                                        onClick={() => handleShow(item.resource_id)}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title={item.scoreDetails}
                                      >
                                        {translation.View_score}
                                      </div>
                                    )}
                                  </>

                                ) : (
                                  <div
                                    className="btn_view"
                                    onClick={() =>
                                      handleChk(
                                        item.ak_dc,
                                        item.ak_ou,
                                        item.type,
                                        item.personal_info,
                                        item.resource_id
                                      )
                                    }
                                  // data-bs-toggle="tooltip"
                                  // data-bs-placement="top"
                                  // title={item.scoreDetails}
                                  >
                                    {/* {translation.View_score} */}
                                    {translation.PendingForm}
                                  </div>
                                )}
                                {/* <div
                              className="btn_view"
                              onClick={() => handleShow(item.resource_id)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={item.scoreDetails}
                            >
                              {translation.View_score}
                            </div> */}

                                <div className={`${visitsStyles.visits_right_btn}`}>
                                  <a
                                    className="btn_nill f_600 f_25 me-3"
                                    onClick={() => View(item.resource_id)}
                                    data-bs-toggle="collapse"
                                    href={`#${item.resource_id}`}
                                  >
                                    <span
                                      onClick={() =>
                                        handleShowMore(item.resource_id)
                                      }
                                    >
                                      {selectedItem[0] === item.resource_id ? (
                                       <img src={add} />
                                      ) : (
                                        
                                        <img src={minus} />
                                      )}
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <>
                            <Followupcomponent id={item.resource_id} selectedItem={selectedItem[0]}/>
                            </>
                          </div>
                        ))
                      ) : (
                        <div className="no_data">{translation.error_past_visits}</div>
                      )}

                    </InfiniteScroll>
                  )}
              </div>
            </div>


            {/*  */}
            {/* )} */}
          </div>
          <Modal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            dialogClassName="modal-md patient_notes_popup model_width modal-dialog-centered"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                {translation.View_score}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <div className="result_screen mb-4">
                <div className={lang === "ara" ? "panelarb" : "panel"}>
                  <div className="panel-body p_25">
                    <div className="row justify-content-center">
                      {!load ? (
                        <LoadingSpinner />
                      ) : (
                        <>
                          {data.length === 0 ? (
                            <div className="panel">
                              <div className="panel-body p_25">
                                <div className="no_data">
                                  {translation.No_data_found}
                                  {/* No Score Found */}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {data.map((row) => (
                                <>

                                  <div className={lang==="en"?`col-xxl-4 col-lg-6 col-md-6 col-sm-6`:"col-xxl-6 col-lg-6 col-md-6 col-sm-6"}>
                                    <div className="box_d">
                                      <ResultCircleProgress
                                        total={row.total_score}
                                        title={row.title}
                                        type=""
                                        arm={row.evaluted_value}
                                        // text={
                                        //   row.title === "VAS"
                                        //     ? `${row.score * 10}`
                                        //     : `${row.score * 1}`
                                        // }
                                        // value={
                                        //   row.title === "VAS"
                                        //     ? `${row.score * 10}`
                                        //     : `${row.score * 1}`
                                        // }
                                        value={Number(row.score)}
                                        text={Number(row.score)}
                                        // value={row.score}
                                        date={row.date}
                                        color={row.path_color}
                                        reverse={row.reversed}
                                        min_score={row.min_score}
                                      />
                                    </div>
                                  </div>
                                </>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>
              <Button
                onClick={handleClose}
                value={translation.cancel}
                buttonStyle="btn_nill mr_20 ms-auto"
              />
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}

export default MyVisits;
