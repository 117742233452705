import React, { useState, useEffect, useRef } from "react";
import InputField from "../custom/inputfield/InputField";
import Dropdownno from "../custom/dropdown/Dropdownno";
import Button from "../custom/button/Button";
import useAxios from "../../axiosinstance";
// import { Validators } from "../../utilities/Validator";
import useTranslation from "../customHooks/translations";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// import PatientListTable from "./PatientListTable";
import ReminderTable from "./ReminderTable";
import editIcon from "../../images/edit-icon.svg";
import { useSelector } from "react-redux";
const Reminder = () => {
  const [drop, setDrop] = useState("");
  const [profile, setProfile] = useState("");
  const [checked, setChecked] = useState();
  const [note, setNote] = useState("");
  const condition = checked === 1 ? 1 : checked === 2 ? -1 : "";

  const [note1, setNote1] = useState("");
  const [reset, setReset] = useState(true);
  const [msgData, setmsgData] = useState([]);
  const [doneData, setDoneData] = useState([]);
  const [timezoneD, setTimeZone] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  let akToken = localStorage.getItem("AkTOKENI");
  const [offset, setOffset] = useState(10);
  const [limit, setLimit] = useState(10);
  const axiosInstance = useAxios();
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const translation = useTranslation();
  const [active, setActive] = useState(1);
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const [key, setKey] = useState("allcases");
  const [keyID, setKeyID] = useState(1);
  const [show1, setshow1] = useState(true);

  const handleNote = (e, err) => {
    setNote(e);
  };
  const handleNote1 = (e, err) => {
    setNote1(e);
  };
  const handleReset = (e) => {
    setReset(false);
    setNote("");
    setNote1("");
  };
  useEffect(() => {
    if (login) {
      setProfile(login.patient);
      setTimeZone(login.patient.timezone);
      setTimeFormat(login.patient.timeformat);
    }
  }, [login, active]);
  const json1 = JSON.stringify({
    title: note,
    description: note1,
  });
  const postdata = async (e) => {
    let res = await axiosInstance.current.post(
      `extapp/patients/addPatientNote/${login.patient.ak_id}`,
      json1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status === 200) {
      setRefresh(!refresh);
    }
  };

  const getdata = async (e) => {
   
    const result = await axiosInstance.current.get(
      `extapp/patients/getPatientNotes/${e}?offset=0&limit=${limit}&status=0&sort=${
        checked === 1 ? 1 : checked === 2 ? -1 : ""
      }&alphaSort=${checked === 3 ? 1 : checked === 4 ? -1 : ""}`
    );

    if (result) {
      setmsgData(result.data.data);
    }
  };

  const getDoneData = async (e) => {
    const result = await axiosInstance.current.get(
      `extapp/patients/getPatientNotes/${
        profile.ak_id
      }?offset=0&limit=${limit}&status=1&sort=${
        checked === 1 ? 1 : checked === 2 ? -1 : ""
      }&alphaSort=${checked === 3 ? 1 : checked === 4 ? -1 : ""}`
    );
    if (result) {
      setDoneData(result.data.data);
    }
  };

  useEffect(() => {
    if (profile.ak_id !== undefined) {
      getdata(profile.ak_id);
      getDoneData();
    }
  }, [refresh, active, login, profile, checked]);

  const [errshow, seterrshow] = useState(false);
  const handleSubmit = (e) => {
    if (note === "" || note1 === "") {
      seterrshow(true);
    } else {
      postdata();
      setNote("");
      setNote1("");
      seterrshow(false);
    }
  };

  const handleActive = (e) => {
    setActive(e);
    setOffset(10);
  };

  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/patients/getPatientNotes/${akToken}?offset=${offset}&limit=${limit}&status=1&sort=${
        checked === 1 ? 1 : checked === 2 ? -1 : ""
      }&alphaSort=${checked === 3 ? 1 : checked === 4 ? -1 : ""}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setDoneData(doneData.concat(resdata));
    }, 500);
  };
  const fetchMoreData = () => {
    addMoreData();
  };
  const addMoreData1 = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/patients/getPatientNotes/${akToken}?offset=${offset}&limit=${limit}&status=0&sort=${
        checked === 1 ? 1 : checked === 2 ? -1 : ""
      }&alphaSort=${checked === 3 ? 1 : checked === 4 ? -1 : ""}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setmsgData(msgData.concat(resdata));
    }, 500);
  };
  const fetchMoreData1 = () => {
    addMoreData1();
  };

  const handleSelect = (key) => {
    if (key === "allcases") {
      setshow1(false);
      setKey(key);
      setOffset(10);
      setKeyID(1);
    } else if (key === "submitted") {
      setshow1(false);
      setKey(key);
      setOffset(10);
      setKeyID(2);
    }
  };

  return (
    <>
      <div class="row page_header">
        <div class="col-sm-6 col-md-6">
          <h4 class="ph_title">{translation.reminder}</h4>
        </div>
      </div>

      <div className="whitebox">
        {show && (
          <span className="text-danger ml-9"> {translation.pls_ensure}</span>
        )}

        <div className="row mb_15">
          <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div>
              <InputField
                label={translation.title+'*'}
                value={note}
                type="text"
                placeholder={translation.enter_your_title_here}
                onChange={handleNote}
                rows={drop === "text" ? 10 : 5}
                styleClass="mw-100"
                mic={true}
              />
              {!note && (
                <>
                  {errshow && (
                    <span className="text-danger ">
                      {" "}
                      {translation.This_field_is_required}
                    </span>
                  )}
                </>
              )}
            </div>
            {/* {drop === "text" && ( */}
            <>
              <div className="col-sm-12 col-md-12">
                <InputField
                  label={translation.description+'*'}
                  value={note1}
                  type="textarea"
                  placeholder={translation.enter_your_description_here}
                  onChange={handleNote1}
                  rows={drop === "text" ? 10 : 5}
                  styleClass="mw-100 scrollbar_vert reminder_box"
                  mic={true}
                />
                {!note1 && (
                  <>
                    {errshow && (
                      <span className="text-danger ">
                        {translation.This_field_is_required}
                      </span>
                    )}
                  </>
                )}
              </div>
            </>
            <div className="col-sm-12 col-md-12 pt_30">
              <div className="text-end">
                <Button
                  onClick={() => handleReset()}
                  value={translation.reset}
                  buttonStyle="btn_nill mr_20"
                />

                <Button
                  onClick={() => handleSubmit()}
                  value={translation.Save}
                  buttonStyle="btn_fill"
                />
              </div>
            </div>
          </div>
          <div
            className="col-xxl-7 col-lg-7 col-md-7 col-sm-6 col-12"
            style={{ marginTop: "31px" }}
          >
            <>
              <div className="my_visit_screen org_tab mb_100">
                <Tabs
                  id="case-list-tabs"
                  activeKey={key}
                  onSelect={handleSelect}
                  // onSelect={(k) => setKey(k)}
                  className="border-0 pe-0"
                >
                  <Tab
                    eventKey="allcases"
                    title={translation.progress}
                    tabClassName="w-90 pe-0"
                  >
                    {msgData.length > 0 ? (
                      <ReminderTable
                        data={msgData}
                        updatePagination={fetchMoreData1}
                        timezoneD={timezoneD}
                        active={1}
                        timeFormat={timeFormat}
                        reminder="true"
                        setRefresh={setRefresh}
                        refresh={refresh}
                        setChecked={setChecked}
                      />
                    ) : (
                      <div className="no_data">{translation.No_data_found}</div>
                    )}
                  </Tab>
                  <Tab eventKey="submitted" title={translation.done}>
                    {keyID === 2 &&
                      (doneData.length > 0 ? (
                        <ReminderTable
                          data={doneData}
                          updatePagination={fetchMoreData}
                          timezoneD={timezoneD}
                          active={2}
                          timeFormat={timeFormat}
                          reminder="true"
                          hideicon="true"
                          setRefresh={setRefresh}
                          refresh={refresh}
                          setChecked={setChecked}
                        />
                      ) : (
                        <div className="no_data">
                          {translation.No_data_found}
                        </div>
                      ))}
                  </Tab>
                </Tabs>
              </div>

              {/* {active === 1 && (
                                msgData.length !== 0 ? (

                                    <div className="my_visit_screen mb_100">
                                        <ReminderTable data={msgData} updatePagination={fetchMoreData1} timezoneD={timezoneD} active={1} timeFormat={timeFormat} reminder="true" setRefresh={setRefresh} refresh={refresh} />
                                    </div>
                                ) : (
                                    <div className="panel">
                                        <div className="panel-body p_25">
                                            <div className="no_data">{translation.No_data_found}</div>
                                        </div>
                                    </div>
                                )
                            )}
                            {active === 2 && (
                                doneData.length !== 0 ? (

                                    <div className="my_visit_screen mb_100">
                                        <ReminderTable data={doneData} updatePagination={fetchMoreData} timezoneD={timezoneD} active={2} timeFormat={timeFormat} reminder="true" hideicon="true" setRefresh={setRefresh} refresh={refresh} />
                                    </div>
                                ) : (
                                    <div className="panel">
                                        <div className="panel-body p_25">
                                            <div className="no_data">{translation.No_data_found}</div>
                                        </div>
                                    </div>
                                )
                            )} */}
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default Reminder;