import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import LockImage from "../../images/lock.svg";
import dashboardStyles from "../my-journey/Dashboard.module.css";
import UserIconImage from "../../images/user-icon.svg";
import SearchIconImage from "../../images/search-icon.svg";
import Dropdown from "../custom/dropdown/Dropdown";
import searchGreyIcon from "../../images/search-grey.svg";
import searchBlueIcon from "../../images/search-blue.svg";
import searchCloseIcon from "../../images/search-close.svg";
import useAxios from "../../axiosinstance";
import TextField from "@mui/material/TextField";
import useTranslation from "../customHooks/translations";
import Autocomplete from "@mui/material/Autocomplete";
import { Modal } from "react-bootstrap";
import useAxiosUpload from "../../useAxiosUpload";
import Button from "../custom/button/Button";
import searchCloseIconGrey from "../../images/search-grey.svg";
import { useNavigate } from "react-router-dom";
import Message from "../custom/toster/Message";
import { useLanguageContext } from "../contexts/LanguageContext";

const DoctorCode = ({ numInputs = 7 }) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { language } = useLanguageContext();
  const [doctor, setDoctor] = useState("");
  const [doctorname, setDoctorname] = useState("");
  const [orgErr, setOrgErr] = useState(false);
  const [otp, setOtp] = useState(Array(numInputs).fill(""));
  const inputRefs = useRef([]);
  const [modelShow1, setModelShow1] = useState(false);
  const [modelShow2, setModelShow2] = useState(false);
  const [modelShow3, setModelShow3] = useState(false);
  const [scoringForm, setScoringForm] = useState("");

  const login = useSelector((state) => state.loginPatient.arr[0]);
  const loginData = useSelector((state) => state.loginPatient.login);
  const [name, setName] = useState("");
  const [orgname, setOrgname] = useState("");
  const [orgname2, setOrgname2] = useState("");
  const [lastName, setlastName] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [noteSearch, setNoteSearch] = useState("");
  const [recentPatient, setRecentPatient] = useState([]);
  const [recentPatient2, setRecentPatient2] = useState("");
  const [data, setData] = useState("");
  const [data2, setData2] = useState("");
  const [datas, setDatas] = useState([]);
  const [datas1, setDatas1] = useState(true);
  const [consent, setConsent] = useState("");
  const [org, setOrg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const axiosInstance = useAxios();
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [lastTypingTime, setLastTypingTime] = useState(null);
  // const [noteSearch, setNoteSearch] = useState('');
  // const [searchActive, setSearchActive] = useState(false);
  const [items, setItems] = useState([]);
  let akToken = localStorage.getItem("AkTOKENI");
  const queryParams = new URLSearchParams(window.location.search);
  let datafilter = recentPatient.filter(
    (item) => item.value === Number(recentPatient2)
  );

  const type = queryParams.get("type");
  const rId = queryParams.get("rId");

  useEffect(() => {
    if (login !== undefined) {
      setName(login.patient.firstname);
      setlastName(login.patient.lastname);
    }
    if (data2.status === "true") {
      //   setModelShow1(true);
    }
  }, [login, data2.status]);

  const handelClick = () => {
    // navigate("/patient-list");
  };
  const searchClose = () => {
    setNoteSearch("");
  };

  const handleCloseShow1 = () => {
    setModelShow1(false);
    setOtp(Array(numInputs).fill(""));
  };
  const handleCloseShow2 = () => {
    setModelShow2(false);
    setRecentPatient2("");
  };

  const handleCloseShow3 = () => {
    setModelShow3(false);
    setOrg("");
    setOrgname("");
    setItems([]);
  };

  // const handleInputChange = (e, index) => {
  //   const value = e.target.value;

  //   if (isNaN(value)) {
  //     return;
  //   }

  //   const newOtp = [...otp];
  //   newOtp[index] = value;

  //   if (value && !newOtp[0]) {
  //     inputRefs.current[0].focus(); 
  //   } else {
  //     setOtp(newOtp); 
  
  //     if (value && index < otp.length - 1) {
  //       inputRefs.current[index + 1].focus();
  //     }
  //   }

  //   if (index === numInputs - 1 && newOtp.every((digit) => digit !== "")) {
  //     if (login !== undefined) {
  //       getData(newOtp);
  //     }
  //   }
  // };

  // const handleKeyDown = (e, index) => {
  //   if (e.key === "Backspace" && index > 0 && otp[index] === "") {
  //     inputRefs.current[index - 1].focus();
  //   }
  // };

  const handleInputChange = (e, index) => {
    let value = e.target.value.replace(/\D/, ""); // Remove non-numeric input
    
    const newOtp = [...otp];
    newOtp[index] = value;
  
    setOtp(newOtp); 
  
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus(); // Move to next input
    }
  
    // If last digit is entered, trigger API call
    if (index === numInputs - 1 && newOtp.every((digit) => digit !== "")) {
      if (login !== undefined) {
        getData(newOtp);
      }
    }
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const newOtp = [...otp];
  
      if (otp[index] !== "") {
        newOtp[index] = ""; // Clear current input
      } else if (index > 0) {
        newOtp[index - 1] = ""; // Clear previous input
        inputRefs.current[index - 1].focus(); // Move cursor back
      }
  
      setOtp(newOtp);
    }
  };
  

  // const handlePaste = (e) => {
  //   e.preventDefault();
  //   const pastedData = e.clipboardData.getData("Text").slice(0, numInputs);
  //   const newOtp = pastedData
  //     .split("")
  //     .map((digit) => (isNaN(digit) ? "" : digit));

  //   setOtp(newOtp);

  //   // Check if the pasted data has exactly numInputs digits
  //   if (newOtp.length === numInputs) {
  //     // Assuming you want to trigger the API call only when the pasted data has the required number of digits
  //     if (login !== undefined) {

  //       getData(newOtp);
  //     }
  //   }

  //   // Set focus on the first input
  //   if (newOtp.length > 0) {
  //     inputRefs.current[0].focus();
  //   }
  // };

  // const handleDropdownChange = (e) => {
  //     setRecentPatient(e);
  //   };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").replace(/\D/g, "");
    const newOtp = [...otp];

    for (let i = 0; i < pastedData.length && i < 7; i++) {
      newOtp[i] = pastedData[i];
    }

    while (newOtp.length < 7) newOtp.push("");

    setOtp(newOtp);

    if (newOtp.join("").length === 7 && login !== undefined) {
      getData(newOtp);
    }

    const nextIndex = newOtp.findIndex((val) => val === "");
    if (nextIndex !== -1) {
      inputRefs.current[nextIndex]?.focus();
    }
  };

  const divStyle = {
    display: "flex",
    width: "auto",
    height: "85px",
    flexShrink: 0,
    margin: "auto",
    justifyContent: "center",
  };

  const inputStyle = {
    marginRight: "0px", // Adjust as needed
    width: "85px",
    borderRadius: "3px",
    border: "1px solid var(--Primary-Purple, #3F2783)",
    fontSize: "20px",
    textAlign: "center",
    // borderRightStyle: "none"
  };

  const lineStyle = {
    borderRight: "1px solid black",
    height: "25%",
    margin: "30px 20px",
    transform: "rotate(90deg)", // Adjust as needed
  };

  // const newotp=otp.replace(/,/g, '')

  // console.log(newotp,"newotpnewotp");{}
  const getData = async (id) => {
    const otpString = id.join("");
    // console.log(otpString, "otp");
    try {
      const response = await axiosInstance.current.get(
        `/extapp/patients/verify_doctor_code?ak_id=${login.patient.ak_id}&code=${otpString}&type=${type}`
        // `/extapp/patients/verify_doctor_code?ak_id=${login.patient.ak_id}&code=${otpString}&type=4`
      );
      if (response.status === 200) {
        setData(response.data.data);
        setData2(response.data.data);
        // checkspeciality(response.data.data.ak_id,response.data.data.akou_id,type);
        setConsent(response.data.data.isConsent, "consent value");
        setModelShow1(true);
      }
    } catch (error) {
      if (error.response.data.status === false) {
        setMessageType("error");
        setMessage(translation.No_Record_Found);
        setTimeout(() => {
          setMessage(false);
        }, 4000);
      }
    }
  };

  const handleContinue1 = (e) => {
    axiosInstance.current
      .post(
        `extapp/forms/share_forms?ak_id=${login.patient.ak_id}&ak_dc=${data.ak_id}&akou_id=${data.akou_id}&type=${type}&resource_id=${rId}&status=1`
      )
      .then((response) => {
        if (response) {
          setMessageType("success");
          // setMessage("Form shared successfully");
          setMessage(translation.Form_shared_successfully);
          setTimeout(() => {
            navigate("/home");
            setMessage(false);
          }, 4000);
        }
      })
      .catch((err) => {
        // if (err.response.data.status === false) {
        setMessageType("error");
        // setMessage(err.response.data.message);
        // setMessage(translation.You_have_already_shared_with_same_doctor_and_organization);
        if (
          err.response.data.message ===
          "You have already shared with same doctor and organization"
        ) {
          setMessage(
            translation.You_have_already_shared_with_same_doctor_and_organization
          );
        } else {
          setMessage(err.response.data.message);
        }
        setTimeout(() => {
          setMessage(false);
        }, 4000);
        // }

        // setMessage(err.response.data.message);
        // setMessageType("error");
      });
  };

  const handleFirst3 = () => {
    if (!datas1) {
      setMessageType("error");
      setMessage(
        "This doctor does not have approval for this specialty. Please contact your clinician."
      );
      setTimeout(() => {
        setMessage(false);
      }, 4000);
    } else if (consent) {
      handleContinue2();
    } else {
      window.open(
        process.env.REACT_APP_FORM_URL +
          `consent-form-share/${window.btoa(login.patient.ak_id)}/${window.btoa(
            datafilter[0].ak_id
          )}/${window.btoa(datafilter[0].akou_id)}/${window.btoa(
            type
          )}/${window.btoa(rId)}`
      );
      setTimeout(() => navigate("/home"), 2000);
    }
  };

  const handleContinue2 = (e) => {
    axiosInstance.current
      .post(
        `extapp/forms/share_forms?ak_id=${login.patient.ak_id}&ak_dc=${datafilter[0].ak_id}&akou_id=${datafilter[0].akou_id}&type=${type}&resource_id=${rId}&status=1`
      )
      .then((response) => {
        if (response) {
          setMessageType("success");
          // setMessage("Form shared successfully");
          setMessage(translation.Form_shared_successfully);
          setTimeout(() => {
            navigate("/home");
            setMessage(false);
          }, 4000);
        }
      })
      .catch((err) => {
        // if (err.response.data.status === false) {
        setMessageType("error");
        // setMessage(err.response.data.message);
        if (
          err.response.data.message ===
          "You have already shared with same doctor and organization"
        ) {
          setMessage(
            translation.You_have_already_shared_with_same_doctor_and_organization
          );
        } else {
          setMessage(err.response.data.message);
        }
        setTimeout(() => {
          setMessage(false);
        }, 4000);
        // }

        // setMessage(err.response.data.message);
        // setMessageType("error");
      });
  };

  const handleContinue3 = (e) => {
    axiosInstance.current
      .post(
        `extapp/forms/share_forms?ak_id=${login.patient.ak_id}&ak_dc=${doctor}&akou_id=${org}&type=${type}&resource_id=${rId}&status=1`
      )
      .then((response) => {
        if (response) {
          setMessageType("success");
          // setMessage("Form shared successfully");
          setMessage(translation.Form_shared_successfully);
          setTimeout(() => {
            navigate("/home");
            setMessage(false);
          }, 4000);
        }
      })
      .catch((err) => {
        // if (err.response.data.status === false) {
        setMessageType("error");
        // setMessage(err.response.data.message);
        if (
          err.response.data.message ===
          "You have already shared with same doctor and organization"
        ) {
          setMessage(
            translation.You_have_already_shared_with_same_doctor_and_organization
          );
        } else {
          setMessage(err.response.data.message);
        }
        setTimeout(() => {
          setMessage(false);
        }, 4000);
        // }

        // setMessage(err.response.data.message);
        // setMessageType("error");
      });
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.current.get(
        `/extapp/patients/recent_visit?ak_id=${login.patient.ak_id}&type=${type}`
      );
      if (response) {
        const dropdownData = response.data.data.map((item) => ({
          value: `${item.ak_id}`,
          label: `${item.doctor_name} - ${item.org_name}`,
          akou_id: item.akou_id,
          ak_id: item.ak_id,
          doctor_name: item.doctor_name,
          org_name: item.org_name,
        }));
        const uniqueData = Array.from(
          new Set(dropdownData.map(JSON.stringify))
        ).map(JSON.parse);
        let i = 1;
        let da = uniqueData.map((item) => {
          return {
            value: i++,
            label: item.label,
            id: item.value,
            akou_id: item.akou_id,
            ak_id: item.ak_id,
            doctor_name: item.doctor_name,
            org_name: item.org_name,
          };
        });

        setRecentPatient(da);
      }
      setDatas(response.data.data);
      // console.log(datas, "responseresponse");
    } catch (error) {}
  };

  useEffect(() => {
    if (login !== undefined) {
      fetchData();
    }
  }, [login]);

  const handleDropdownChange = (selectedValue) => {
    setRecentPatient2(selectedValue);

    if (selectedValue) {
      setModelShow2(true);
    }
    try {
      checkspeciality(datafilter[0].ak_id, datafilter[0].akou_id, type);
      axiosInstance.current
        .get(
          `extapp/patients/constent_form?org_id=${datafilter[0].akou_id}&ak_id=${datafilter[0].ak_id}&type=14&akpt_id=${akToken}&formType=${type}`
        )
        .then((response) => {
          setConsent(response.data.status);

          //   // navigate("/home");
          //   // setOrgname("")
          //   // console.log(response.data.status , "response.data.status")
        });
    } catch (error) {}
  };

  const checkspeciality = async (akDc, akOu, type) => {
    try {
      const response = await axiosInstance.current.get(
        `/extapp/doctors/check_specility?ak_id=${akDc}&akou_id=${akOu}&type=${type}`
      );
      if (response) {
        setDatas1(response.data.status);
      }
    } catch (error) {
      // alert(2)
      setDatas1(false);
    }
  };
  // console.log(datas1, "responseresponse");

  const handleChange = (event, newValue) => {
    try {
      if (newValue) {
        setOrg(newValue.aKou || "");
        setOrgname(newValue.label || "");
        setDoctor(newValue.akDc_id || "");
        setDoctorname(newValue.doctor_name || "");
        setOrgname2(newValue.org_name || "");
        // Check if orgname2 is not empty before setting ModelShow3
        if (newValue.org_name !== "") {
        }

        if (newValue.org_name !== "") {
          axiosInstance.current
            .get(
              `extapp/patients/constent_form?org_id=${newValue.aKou}&ak_id=${newValue.akDc_id}&type=14&akpt_id=${akToken}&formType=${type}`
            )
            .then((response) => {
              setConsent(response.data.status);
              checkspeciality(newValue.akDc_id, newValue.aKou, type);
              setModelShow3(true);
              //   // navigate("/home");
              //   // setOrgname("")
              //   // console.log(response.data.status , "response.data.status")
            });
        }
      }
    } catch (error) {
      setModelShow3(true);
    }
  };

  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault(); // Prevent the default behavior of the Enter key
  //   }
  // };

  const handleKeyPress = (e) => {
    if (inputValue.length <= 2) {
      setItems([]);
    }
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of the Enter key
      if (inputValue.length > 2) {
        // Check if a selection has been made
        if (orgname2 === "") {
          // If no selection has been made, you can display a message or handle it in another way
        }
      }
    }
  };

  const handleOnSearch = (value, results) => {
    setLastTypingTime(new Date().getTime());
    setInputValue(value);
  };
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        if (inputValue.length > 2) {
          getAllOrg(inputValue);
        } else {
          setItems([]);
        }
      }, 1000);

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when lastTypingTime changes
    }
  }, [lastTypingTime, inputValue]);

  const getAllOrg = async (noteSearch) => {
    // Use noteSearch directly instead of e.target.value
    const value = noteSearch;
    setNoteSearch(value);

    // Check if the length of the entered value is at least 3 characters

    try {
      const response = await axiosInstance.current.get(
        `/extapp/patients/searchByDocOrOrg/${akToken}?keyword=${value}`
      );

      const options = response.data.data.map((item) => ({
        value: item.id,
        label: item.label,
        akDc_id: item.akdc_id,
        aKou: item.akou,
        doctor_name: item.doctor_name,
        org_name: item.org_name,
      }));

      setItems(options);
    } catch (error) {
      setItems([]);
    }
  };

  //   let datafilter2=items.filter((item)=>item.akou_id===orgname)

  const handleFirst = () => {
    // if(!datas1){
    //   setMessageType("error");
    //   setMessage('Doctor is not part of this speciality!');
    //   setTimeout(() => {
    //     setMessage(false);
    //   }, 4000);
    // }
    if (consent) {
      // alert(1)
      // return
      handleContinue1(); //consent-form-share/:akPt/:akDc/:akOu/:formId/:rId
    } else {
      window.open(
        process.env.REACT_APP_FORM_URL +
          `consent-form-share/${window.btoa(login.patient.ak_id)}/${window.btoa(
            data.ak_id
          )}/${window.btoa(data.akou_id)}/${window.btoa(type)}/${window.btoa(
            rId
          )}`
      );

      setTimeout(() => navigate("/home"), 2000);
    }
  };

  const handleFirst2 = () => {
    if (!datas1) {
      setMessageType("error");
      setMessage(
        "This doctor does not have approval for this specialty. Please contact your clinician."
      );
      setTimeout(() => {
        setMessage(false);
      }, 4000);
    } else if (consent) {
      handleContinue3();
    } else {
      window.open(
        process.env.REACT_APP_FORM_URL +
          `consent-form-share/${window.btoa(login.patient.ak_id)}/${window.btoa(
            doctor
          )}/${window.btoa(org)}/${window.btoa(type)}/${window.btoa(rId)}`
      );
      setTimeout(() => navigate("/home"), 2000);
    }
  };

  // console.log(rId, "orgnameorgname");

  // const formattedDate = getFormattedDate();
  const [formattedDate, setFormattedDate] = useState("");

  // function getFormattedDate() {
  //   const options = { day: "numeric", month: "long", year: "numeric" };
  //   const today = new Date();
  //   return today.toLocaleDateString(undefined, options);
  // }

  const getAssesmentDate = () => {
    axiosInstance.current
      .get(
        `extapp/patients/getassesmentdate?resource_id=${rId}&ak_id=${akToken}`
      )
      .then((response) => {
        const options = { day: "numeric", month: "long", year: "numeric" };
        const today = new Date(response.data.assessment_date);
        //  console.log(today.toLocaleDateString(undefined, options),"formattedDatetoday====");
        setFormattedDate(today.toLocaleDateString(undefined, options));
      });
  };
  useEffect(() => {
    getAssesmentDate();
  }, [akToken]);

  const lang_ = sessionStorage.getItem("lang_");
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [lang_]);

  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <section className="mt-2 mb_100">
        <div className="welcome-section-doc">
          <h5 className="f_700 f_600">
            {translation.Welcome_to_akunah}, {`${name} ${lastName}`}
          </h5>
          <h4 className="f_18 f_600">
            {translation.Share_to_your_Doctor_using_one_of_the_3_options_below}
          </h4>
        </div>
        <div className="row dt_individual_top_sec justify-content-center">
          <div className="col-xxl-10 col-lg-10 col-md-10 col-sm-10">
            <section className="mt-2">
              <div
                className="whitebox  Dashboard_whitebox__3eZ74 radio_box_fixed p-0"
                style={{ height: "400px" }}
              >
                <div class="row page_header pb-0">
                  <div class="col-sm-12 col-md-12 p-4 pt-0">
                    <div className="white_img">
                      <img src={LockImage} alt="logo" />
                    </div>
                    <h4 className="f_doc_code text-center">
                      {translation.Doctor_Code}
                    </h4>
                    <div className="doc_code_card">
                      {
                        translation.Enter_the_code_that_has_been_provided_to_you_by_your
                      }{" "}
                      <br />
                      {translation.doctor_in_your_email_or_in_the_clinic} <br />
                      {translation.recent_visits_or_use_the_scratch}
                    </div>
                  </div>
                </div>
                <div className="box-content card_heigh  pb-3">
                  <div
                    style={divStyle}
                    className="doctorCode"
                    onPaste={(e) => handlePaste(e)}
                    // Prevent pasting into individual fields
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                  >
                    {otp.map((digit, index) => (
                      <React.Fragment key={index}>
                        <input
                           type="tel"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleInputChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                          style={inputStyle}
                          className=""
                        />
                        {index === 2 && <div style={lineStyle}></div>}{" "}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </section>
            <div className={dashboardStyles.dashboard_page}>
              <div className={`row ${dashboardStyles.card_individual_sec}`}>
                <div className={`col-sm-6`}>
                  <div
                    className={`whitebox text-center ${dashboardStyles.whitebox} journey-box`}
                  >
                    <div className="journey-content">
                      <div className="journey-baground-image">
                        <img src={UserIconImage} alt="user-logo" />
                      </div>
                      <h4 className="">{translation.Recent_Visits}</h4>
                      <p className="f_search_doc_code">
                        {translation.Select_your_recent_doctor_or} <br />
                        {translation.organisation_here}
                      </p>

                      <div
                        className="col-sm-12 col-md-12 mb_20"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <Dropdown
                          data={recentPatient}
                          styleClass="br_10"
                          value={recentPatient2}
                          placeholder={translation.Select}
                          onChange={handleDropdownChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-sm-6`}>
                  <div
                    className={`whitebox text-center ${dashboardStyles.whitebox} journey-box`}
                  >
                    <div className="journey-content">
                      <div className="journey-baground-image">
                        <img src={SearchIconImage} alt="user-logo" />
                      </div>
                      <h4 className="">{translation.Search}</h4>
                      <p className="f_search_doc_code">
                        {translation.Search_for_your_doctor_or} <br />
                        {translation.organisation_here}
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-12  mb_20">
                      <div className="dropdown_main_1">
                        <span className="white_shade"></span>
                        <Autocomplete
                          freeSolo
                          id={
                            language === "ara"
                              ? "free-solo-2-demo1"
                              : "free-solo-2-demo"
                          }
                          // disableClearable={true}
                          filterOptions={(options) => options}
                          options={items}
                          value={orgname}
                          className=" w-100"
                          onChange={handleChange}
                          // disableClearable={disable}
                          renderInput={(params) => (
                            <TextField
                              placeholder={translation.Select}
                              className=""
                              style={
                                {
                                  // height: "34px",
                                  // // border: "2px solid rgba(63, 39, 131, 0.7)",
                                  // borderRadius: "10px",
                                  // color: "#3F2783",
                                  // // padding: "0 15px",
                                  // height: "40px",
                                  // marginBottom: "12px",
                                  // width: "100%",
                                  // boxShadow: "none",
                                  // zIndex: 1,
                                }
                              }
                              {...params}
                              // onChange={(e) => handleOnSearch(e.target.value)}
                              onKeyUp={(e) => {
                                handleOnSearch(e.target.value);
                                handleKeyPress(e); // Call the handleKeyPress function on keyup
                              }}
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                            />
                          )}
                        />
                      </div>
                      <span
                        className="searc_font fst-italic fw-light"
                        style={{ color: "#3F2783" }}
                      >
                        {" "}
                        {translation.Search_with_minimum_three_characters}
                      </span>
                      <br></br>
                      {/* {orgErr && (
                                                <span className="text-danger mt-3">
                                                    {translation.This_field_is_required}
                                                </span>
                                            )} */}
                      {/* <ReactSearchAutocomplete
                    items={items}
                    fuseOptions={{
                      shouldSort: true,
                      threshold: 0.6,
                      location: 0,
                      distance: 100,
                      maxPatternLength: 32,
                      minMatchCharLength: 3,
                      keys: ["label", "value"],
                    }} // Search on both fields
                    resultStringKeyName="label" // String to display in the results
                    onSearch={handleOnSearch}
                    // onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    // onFocus={handleOnFocus}
                    placeholder={{translation.Select}}
                    onClear={handleOnClear}
                    showIcon={false}
                    styling={{
                      height: "34px",
                      border: "2px solid rgba(63, 39, 131, 0.7)",
                      borderRadius: "10px",
                      color: "#3f2783",
                      padding: "0 15px",
                      height: "40px",
                      marginBottom: "12px",
                      width: "100%",
                      boxShadow: "none",
                      zIndex: 1,
                      marginTop:"20px",
                    }}
                  />
                  {orgErr && (
                    <span className="text-danger mt-3">
                      This field is required
                    </span>
                  )} */}
                    </div>

                    <Modal
                      show={modelShow1}
                      onHide={handleCloseShow1}
                      dialogClassName="modal-md patient_notes_popup"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title className="text-center">
                          {
                            translation.Please_confirm_that_the_following_details
                          }
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="">
                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex">
                              <p
                                className="fw-bold model_head"
                                style={{ width: "48%" }}
                              >
                                {translation.Doctor_Name}:
                              </p>
                              <p
                                className="model_text"
                                style={{ width: "auto" }}
                              >
                                {data.doctor_name}
                              </p>
                            </div>

                            <div className="d-flex">
                              <p
                                className="fw-bold model_head"
                                style={{ width: "48%" }}
                              >
                                {translation.Organisation}:
                              </p>
                              <p
                                className="model_text"
                                style={{ width: "auto" }}
                              >
                                {data.org_name}
                              </p>
                            </div>

                            <div className="d-flex">
                              <p
                                className="fw-bold mb-2 model_head"
                                style={{ width: "48%" }}
                              >
                                {translation.PROMs_Specialty_Date}:
                              </p>{" "}
                              <p
                                className="model_text"
                                style={{ width: "auto" }}
                              >
                                {type == 4
                                  ? translation.shoulder
                                  : type == 6
                                  ? translation.hip_form
                                  : type == 21
                                  ? translation.Foot_And_Ankle
                                  : type == 22
                                  ? translation.Elbow
                                  : type == 23
                                  ? translation.Hand_And_Wrist
                                  : type == 5
                                  ? translation.Knee
                                  : type == 20
                                  ? translation.Knee
                                  : translation.General}
                                , {formattedDate}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer style={{ justifyContent: "flex-end" }}>
                        <Button
                          show={modelShow1}
                          onClick={handleFirst}
                          // disabled={button1Disabled}
                          value={
                            // {translation.Continue}
                            translation.yes
                          }
                          buttonStyle="btn_success"
                        />
                        <Button
                          // show={modelShow}
                          onClick={handleCloseShow1}
                          // disabled={button2Disabled}
                          value={translation.no}
                          buttonStyle="btn_fill"
                        />
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={modelShow2}
                      onHide={handleCloseShow2}
                      dialogClassName="modal-md patient_notes_popup"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title className="">
                          {
                            translation.Please_confirm_that_the_following_details
                          }
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="">
                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex">
                              <p
                                className="fw-bold model_head"
                                style={{ width: "48%" }}
                              >
                                {translation.Doctor_Name}:
                              </p>
                              <p
                                className="model_text"
                                style={{ width: "auto" }}
                              >
                                {datafilter.length !== 0 &&
                                  datafilter[0].doctor_name}
                              </p>
                            </div>

                            <div className="d-flex">
                              <p
                                className="fw-bold model_head"
                                style={{ width: "48%" }}
                              >
                                {translation.Organisation}:
                              </p>
                              <p
                                className="model_text"
                                style={{ width: "auto" }}
                              >
                                {datafilter.length !== 0 &&
                                  datafilter[0].org_name}
                              </p>
                            </div>

                            <div className="d-flex">
                              <p
                                className="fw-bold mb-2 model_head"
                                style={{ width: "48%" }}
                              >
                                {translation.PROMs_Specialty_Date}:
                              </p>{" "}
                              <p
                                className="model_text"
                                style={{ width: "auto" }}
                              >
                                {type == 4
                                  ? translation.shoulder
                                  : type == 6
                                  ? translation.hip_form
                                  : type == 21
                                  ? translation.Foot_And_Ankle
                                  : type == 22
                                  ? translation.Elbow
                                  : type == 23
                                  ? translation.Hand_And_Wrist
                                  : type == 5
                                  ? translation.Knee
                                  : type == 20
                                  ? translation.Knee
                                  : translation.General}
                                , {formattedDate}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer style={{ justifyContent: "flex-end" }}>
                        <Button
                          show={modelShow2}
                          onClick={handleFirst3}
                          // disabled={button1Disabled}
                          value={
                            // {translation.Continue}
                            translation.yes
                          }
                          buttonStyle="btn_success"
                        />
                        <Button
                          // show={modelShow}
                          onClick={handleCloseShow2}
                          // disabled={button2Disabled}
                          value={translation.no}
                          buttonStyle="btn_fill"
                        />
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={modelShow3}
                      onHide={handleCloseShow3}
                      dialogClassName="modal-md patient_notes_popup"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title className="text-center">
                          {
                            translation.Please_confirm_that_the_following_details
                          }
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="">
                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex">
                              <p
                                className="fw-bold model_head"
                                style={{ width: "48%" }}
                              >
                                {translation.Doctor_Name}:
                              </p>
                              <p
                                className="model_text"
                                style={{ width: "auto" }}
                              >
                                {doctorname}
                              </p>
                            </div>

                            <div className="d-flex">
                              <p
                                className="fw-bold model_head"
                                style={{ width: "48%" }}
                              >
                                {translation.Organisation}:
                              </p>
                              <p
                                className="model_text"
                                style={{ width: "auto" }}
                              >
                                {orgname2}
                              </p>
                            </div>

                            <div className="d-flex">
                              <p
                                className="fw-bold mb-2 model_head"
                                style={{ width: "48%" }}
                              >
                                {translation.PROMs_Specialty_Date}:
                              </p>{" "}
                              <p
                                className="model_text"
                                style={{ width: "auto" }}
                              >
                                {type == 4
                                  ? translation.shoulder
                                  : type == 6
                                  ? translation.hip_form
                                  : type == 21
                                  ? translation.Foot_And_Ankle
                                  : type == 22
                                  ? translation.Elbow
                                  : type == 23
                                  ? translation.Hand_And_Wrist
                                  : type == 5
                                  ? translation.Knee
                                  : type == 20
                                  ? translation.Knee
                                  : translation.General}
                                , {formattedDate}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer style={{ justifyContent: "flex-end" }}>
                        <Button
                          show={modelShow2}
                          onClick={handleFirst2}
                          // disabled={button1Disabled}
                          value={translation.yes}
                          buttonStyle="btn_success"
                        />
                        <Button
                          // show={modelShow}
                          onClick={handleCloseShow3}
                          // disabled={button2Disabled}
                          value={translation.no}
                          buttonStyle="btn_fill"
                        />
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorCode;
