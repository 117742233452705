import React, { useState, useEffect, useRef } from "react";

import { useTable } from "react-table";
import useTranslation from "../customHooks/translations";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteConfirmation from "../custom/delete-confirmation/DeleteConfirmation";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from "react-bootstrap/Modal";
import { s3UrlLinkFile, s3UrlLink } from "../../utilities/S3Url";
import RadioSwitch from "../custom/radio-switch/RadioSwitch";
import close from "../../images/close.png";
import { Validators } from "../../utilities/Validator";
import InputField from "../custom/inputfield/InputField";
import SignaturePad from "react-signature-pad-wrapper";
import {
  Hours_Minutes_Format,
  UserDateFormat,
  UserDateFormatTimeZone,
} from "../../utilities/DateFormate";
import useAxios from "../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import Message from "../custom/toster/Message";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import download from "downloadjs";
import useAxiosUpload from "../../useAxiosUpload";
import MyDocView from "./MyDocView";
import { setRefRedux } from "../../redux/actions";
const ConsentTable = ({
  data,
  updatePagination,
  timezoneD,
  timeFormat,
  setReff,
  reff,
}) => {
  const axiosInstance = useAxios();
  const axiosInstanceUpload = useAxiosUpload();
  const dispatch = useDispatch();

  const login = useSelector((state) => state.loginPatient.arr[0]);
  // const dataRefe = useSelector((state) => state.mainReducer.refRedux.arr);
  const queryParams = new URLSearchParams(window.location.search);
  const consent_id = queryParams.get("consent_id");

  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
  const [resource_id, setresource_id] = useState("");
  const handleReject = async (resource_id) => {
    setresource_id(resource_id);
    setDisplayConfirmationModalDelete(true);
  };

  const [acceptdata, setacceptdata] = useState(false);
  const handleChange = (event) => {
    const { checked } = event.target;
    setacceptdata(checked);
  };
  const [show, setShow] = useState(false);
  const translation = useTranslation();
  const [type, setType] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileLink, setfileLink] = useState("");
  const [signText, setsignText] = useState(null);
  const signaturePadRef = useRef({});
  const signTypeRef = useRef();
  const signTextRef = useRef();
  const [signType, setsignType] = useState("0");
  const [image1, setImage1] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [autoId, setAutoId] = useState("");

  const chooseSign = (e) => {
    setsignType(e);
  };

  const handleSignText = (e) => {
    setsignText(e);
  };
  const [viewConscent, setViewConsecent] = useState(true);
  const handleShow = (link, name, type, resource_id, auto_id) => {
    setacceptdata(false);
    setsignType("0");
    setsignText(null);
    setfileLink(link);
    setFileName(name);
    setViewConsecent(true);
    setType(type);
    setresource_id(resource_id);
    setAutoId(auto_id);
    setShow(true);
  };

  const handleView = (link, name, type, resource_id, sign_type, sign) => {
    if (link === "undefined" || link === undefined) {
      return;
    }
    setfileLink(link);
    setFileName(name);
    setType(type);
    setresource_id(resource_id);
    setShow(true);
    setViewConsecent(false);
    setacceptdata(true);
    setsignType(sign_type);
    setImageUrl(sign);
  };
  const submitDelete = async (type, e) => {
    try {
      const result = await axiosInstance.current.put(
        `extapp/patients/rejectConsent?ak_id=${login.patient.ak_id}&resource_id=${resource_id}`
      );
      if (result) {
        setDisplayConfirmationModalDelete(false);
      }
    } catch (err) {
      console.log(err.message || "Unexpected Error!");
    } finally {
     // console.log("api response finally");
      dispatch(setRefRedux(new Date().getMilliseconds()));
      setReff(new Date().getMilliseconds());
    }
  };

  const handleHome = () => {
    // handleSubmit()
    if (!signType) {
      signTypeRef.current.errUpdate();
    }
    if (signType == "0") {
      if (!signText) {
        signTextRef.current.errUpdate();
      } else {
        handleSubmit();
      }
    } else if (signType == "1") {
      if (signaturePadRef.current.isEmpty()) {
        setImage1(true);
      } else {
        handleSubmit();
      }
    }
  };

  const [err, setErr] = useState(false);
  const handleSubmit = async () => {
    setMessage(false);
    setMessageType("");
    let image = "";
    if (signType === "1") {
      image = signaturePadRef.current.toDataURL();
    }
    const json = JSON.stringify({
      sign: signType === "1" ? image : signText,
      sign_type: signType === "0" ? "1" : "2",
    });
    if (!acceptdata) {
      setErr(true);
    } else {
      try {
        const result = await axiosInstance.current.put(
          `extapp/patients/updateConsent?ak_id=${login.patient.ak_id}&resource_id=${autoId}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result) {
          modifyPdf(image, signType);
          setMessage("Updated Successfully");
          setMessageType("success");
          setShow(false);
          dispatch(setRefRedux(new Date().getMilliseconds()));
          setReff(new Date().getMilliseconds());
        }
      } catch (err) {
        setMessage("Something is wrong");
        setMessageType("error");
        console.log(err.message || "Unexpected Error!");
      } finally {
       // console.log("api response finally");
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: translation.Document_Name,
        accessor: "name",
      },
      {
        Header: translation.Date,
        accessor: "created_at",
        Cell: (row) => (
          <>
            {/* {UserDateFormat(row.row.original.created_at, timeFormat)}{" "} */}
            {UserDateFormatTimeZone(
              row.row.original.created_at,
              login?.patient.dateFormat,
              login?.patient.timezone
            )}{" "}
            {/* {Hours_Minutes_Format(
              row.row.original.created_at,
              timeFormat === "1" ? true : false,
              timezoneD
            )} */}
          </>
        ),
      },
      {
        Header: translation.Requested_by,
        accessor: "org_name",
      },

      {
        Header: translation.Action,
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action text-center",

        Cell: (row) => (
          <span className="d-flex">
            {row.row.original.status === -1 ? (
              <button className="btn_disabled border-0">
                {translation.rejected}
              </button>
            ) : row.row.original.status === 1 ? (
              <>
                <button
                  className="btn_fill rounded-pill"
                  onClick={() =>
                    handleView(
                      row.row.original.key,
                      row.row.original.name,
                      row.row.original.file_extension?.replace(".", ""),
                      row.row.original.resource_id,
                      row.row.original.sign_type,
                      row.row.original.sign,
                      row.row.original._id
                    )
                  }
                >
                  {translation.View}
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn_green me-4"
                  onClick={() =>
                    handleShow(
                      row.row.original.s3_file_path,
                      row.row.original.name,
                      row.row.original.file_extension?.replace(".", ""),
                      row.row.original.resource_id,
                      row.row.original._id
                    )
                  }
                >
                  {translation.grant}
                </button>
                <button
                  className="btn_danger"
                  onClick={() => handleReject(row.row.original._id)}
                >
                  {translation.reject}
                </button>
              </>
            )}
          </span>
        ),
      },
    ],
    [reff]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const handleCloseShow = () => {
    setShow(false);
  };

  const [loading, setLoading] = useState(false);
  const modifyPdf = async (img, signType) => {
    setLoading(true);

    // Fetch an existing PDF document
    const url = s3UrlLinkFile(fileLink, fileName);
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Get the number of pages in the document
    const pageCount = pdfDoc.getPageCount();

    // Loop through each page
    for (let i = 0; i < pageCount; i++) {
      // Get the current page
      const currentPage = pdfDoc.getPage(i);

      // Get the width and height of the current page
      const { width, height } = currentPage.getSize();

      // Draw a rectangle with a white fill color and a black border color
      currentPage.drawRectangle({
        x: 2,
        y: 2,
        width: 200,
        height: 50,
        color: rgb(1, 1, 1), // White color
        borderColor: rgb(0, 0, 0), // Black border color
        borderWidth: 1,
      });

      // Draw text aligned with the left side
      currentPage.drawText("Signature:", {
        x: 10,
        y: 37,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });

      // Draw the image or text based on the signType
      if (signType === "1") {
        // Embed the image
        const image = await pdfDoc.embedPng(img);

        // Draw the image on the page
        currentPage.drawImage(image, {
          x: 10,
          y: 10,
          width: 150,
          height: 25,
        });
      } else {
        // Draw text on the page
        currentPage.drawText(signText, {
          x: 10,
          y: 20,
          size: 17,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        });
      }
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Create a blob from the PDF bytes
    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    // Create a FormData object and append the blob
    const formData = new FormData();
    formData.append("file", blob, fileName);

    // Upload the PDF document
    const res = await axiosInstanceUpload.current.post(
      `extglobal/only_file_upload?_id=${autoId}&ak_id=${login.patient.ak_id}`,
      formData
    );
    if (res) {
      setReff(new Date().getMilliseconds());
    }
    setLoading(false);
    // Update state or perform other actions based on the response

    setLoading(false);
  };

  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                const rowStyle =
                  row.original._id == consent_id
                    ? { backgroundColor: "#80808038" }
                    : {};
                return (
                  <tr {...row.getRowProps()} style={rowStyle}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModalDelete}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        message={""}
        rejected={true}
      />

      <Modal
        show={show}
        onHide={handleCloseShow}
        dialogClassName="modal-lg patient_notes_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{translation.View}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          {/* {videoLink} */}
          {/* <div className="msg-box">
            <span className=""> {message}</span>
          </div> */}
          {/* {type === "mp4" ? (
                        <VideoPlayer url={s3UrlLinkFile(videoLink, fileName)} />
                    ) : ( */}
          <MyDocView
            url={[{ uri: s3UrlLinkFile(fileLink, fileName), fileType: type }]}
          />
          {/* <DocViewer
            pluginRenderers={DocViewerRenderers}
            prefetchMethod="GET"
            documents={[
              { uri: s3UrlLinkFile(fileLink, fileName), fileType: type },
            ]}
            config={{
              header: {
                disableHeader: false,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          /> */}
          {/* )} */}
          {viewConscent && (
            <>
              <div className="mt-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={acceptdata}
                    onChange={handleChange}
                    id="flexCheckDefault"
                    defaultChecked={acceptdata}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {translation.i_accept_consent}
                  </label>
                </div>
                {err && (
                  <>
                    {!acceptdata && (
                      <span className="text-danger">
                        {" "}
                        {translation.This_field_is_required}
                      </span>
                    )}
                  </>
                )}
              </div>
              {acceptdata && (
                <div className="mt-3">
                  <div className="input-row sign-type signup__sign_type">
                    <div className=" mt-20">
                      <RadioSwitch
                        labelLeft={translation.Type}
                        labelRight={translation.Draw}
                        idLeft="feelUnstableNo"
                        idRight="feelUnstableYes"
                        name="feelUnstable"
                        title={<>{translation.Choose_Sign_Type}</>}
                        styleClass=""
                        value={signType}
                        ref={signTypeRef}
                        onChange={chooseSign}
                        placeholder="select"
                        validators={[
                          {
                            check: Validators.required,
                            message: translation.This_field_is_required,
                          },
                        ]}
                      />
                    </div>
                  </div>
                  {signType == "0" && (
                    <div className="input-row" style={{ display: "" }}>
                      <InputField
                        label={translation.Signed_by_Keyboard}
                        value={signText}
                        ref={signTextRef}
                        type="textarea"
                        placeholder={translation.patient_signature}
                        onChange={handleSignText}
                        styleClass="h_150"
                        formGroupClass="label_back"
                        validators={[
                          {
                            check: Validators.required,
                            message: translation.This_field_is_required,
                          },
                        ]}
                      />
                    </div>
                  )}
                  {signType == "1" && (
                    <>
                      <label>{translation.Signed_by_Mouse}</label>
                      <div
                        className="SignaturePad "
                        style={{ marginBottom: "15px" }}
                      >
                        <a
                          href="javaScript:void(0)"
                          className="clearlink"
                          onClick={() => {
                            signaturePadRef.current.clear();
                          }}
                        >
                          <img alt="" src={close} />
                          {translation.Clear}
                        </a>
                        <SignaturePad
                          height={140}
                          ref={signaturePadRef}
                          options={{
                            minWidth: 2,
                            maxWidth: 4,
                            penColor: "rgb(000, 000, 000 ,1)",
                          }}
                        />
                      </div>
                      {image1 && (
                        <span className="text-danger">
                          {" "}
                          {translation.This_field_is_required}
                        </span>
                      )}
                    </>
                  )}

                  <div
                    className="col-md-12 col-sm-12 col-12 text-end"
                    onClick={() => handleHome()}
                  >
                    <button className="next_btn btn_fill rounded-pill">
                      {translation.submit}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          {/* {!viewConscent && (
            <>
              <div className="mt-3">
                <div
                  style={{
                    border: "1px solid",
                    width: "50%",
                    padding: "10px",
                    marginLeft: "50px",
                  }}
                >
                  {signType === "2" && (
                    <img className="w-75" alt="signature" src={imageUrl}></img>
                  )}
                  {signType === "1" && <span>{imageUrl}</span>}
                </div>
              </div>
            </>
          )} */}
        </Modal.Body>
        {/* <Modal.Footer style={{ justifyContent: "flex-start" }}>
                <Button
                  onClick={handleContinue}
                  value="Continue"
                  buttonStyle="btn_fill mr_20 "
                />
              </Modal.Footer> */}
      </Modal>
    </>
  );
};
export default ConsentTable;
