import React, { useEffect } from "react";
import { useState } from "react";
import check from "../../images/check.svg";
import document from "../../images/DocumentUpload.svg";
import pending from "../../images/pending.svg";
import incomplete from "../../images/incomplete.png";
import expired from "../../images/expired.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import actionStyles from "../custom/table-action-items/TableActionItems.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import toggleIcon from "../../images/dots-blue.svg";
import viewIcon from "../../images/eye-icon.svg";
import active_icon from "../../images/Active.png";
import edit_icon from "../../images/edit-icon.svg";
import upload_icon from "../../images/Icon/upload_blue.svg";
import document_icon from "../../images/Icon/document_icon.svg";
import deleteIcon from "../../images/delete-icon.svg";
import useAxios from "../../axiosinstance";
import useTranslation from "../customHooks/translations";
import DeleteConfirmation from "../custom/delete-confirmation/DeleteConfirmation";
import Message from "../custom/toster/Message";
import { WordCutter } from "../../utilities/DocumentType";
import {
  DateFormate,
  UserDateFormat,
  DateFormatWithTimeZone,
  UserDateFormatTimeZone,
} from "../../utilities/DateFormate";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  TooltipExpiration,
  calculateTimeDifference,
  checkExpiration,
} from "../../utilities/ExpiryCheck";
import { Modal, ModalHeader } from "react-bootstrap";
export default function SummaryTable({
  data,
  status,
  updatePagination,
  data1,
  setRefs,
  refs,
}) {
  const login = useSelector((state) => state.loginPatient.arr[0]);

 
  const dispatch = useDispatch();
  const translation = useTranslation();
  const [showModals, setShowModals] = useState(false);
  // const [hideConfirmationModal, setHideConfirmationModal] = useState(false);
  const [org_id, setOrgId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [res_id, setResId] = useState("");

  const [showModalStop, setShowModalStop] = useState(false);
  const [stopIconClicked, setStopIconClicked] = useState(true);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const axiosinstance = useAxios();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const text = "Share to doctor";
  const modifiedText = text.charAt(0).toUpperCase() + text.slice(1);
  let akToken = localStorage.getItem("AkTOKENI");
  const handleShowModals = () => {
    // handleGetData();
    setShowModals(true);
    setDisplayConfirmationModal(true);
  };

  const handleForm = (resource_id, doctor_id, type) => {
    if (type === 4) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "shoulder-view-treatment-form" +
          `/${resource_id}?doctor=${doctor_id}&patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 6) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "hip-view-treatment-form" +
          `/${resource_id}?doctor=${doctor_id}&patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 21) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "foot-ankle-view-treatment-form" +
          `/${resource_id}?doctor=${doctor_id}&patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 22) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "elbow-view-treatment-form" +
          `/${resource_id}?doctor=${doctor_id}&patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 23) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "hand-wrist-view-treatment-form" +
          `/${resource_id}?doctor=${doctor_id}&patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 20) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "pediaknee-view-treatment-form" +
          `/${resource_id}?doctor=${doctor_id}&patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 5) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "adultknee-view-treatment-form" +
          `/${resource_id}?doctor=${doctor_id}&patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 24) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "adultknee-view-treatment-form" +
          `/${resource_id}?doctor=${doctor_id}&patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
    if (type === 33) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "general-treatment-view" +
          `/${resource_id}?doctor=${doctor_id}&patient=${window.btoa(
            login.patient.ak_id
          )}&form=true&action=1&patientEdit=true`,
        "_blank"
      );
    }
  };
  const continueForm = (resource_id, doctor_id, type) => {
    if (type) {
      if (type === 5) {
        window.open(
          process.env.REACT_APP_FORM_URL +
            "adult-knee-pain" +
            `/${resource_id}?is_doctor_form=null&edit=true`,
          "_blank"
        );
      }
      if (type === 24) {
        window.open(
          process.env.REACT_APP_FORM_URL +
            "adult-knee-pain" +
            `/${resource_id}?is_doctor_form=null&edit=true`,
          "_blank"
        );
      }
      if (type === 4) {
        window.open(
          process.env.REACT_APP_FORM_URL +
            "pain" +
            `/${resource_id}?is_doctor_form=null&edit=true`,
          "_blank"
        );
      }
      if (type === 20) {
        window.open(
          process.env.REACT_APP_FORM_URL +
            "pedia-knee-pain" +
            `/${resource_id}?is_doctor_form=null&edit=true`,
          "_blank"
        );
      }
      if (type === 6) {
        window.open(
          process.env.REACT_APP_FORM_URL +
            "hip-pain" +
            `/${resource_id}?is_doctor_form=null&edit=true`,
          "_blank"
        );
      }
      if (type === 21) {
        window.open(
          process.env.REACT_APP_FORM_URL +
            "foot-ankle-pain" +
            `/${resource_id}?is_doctor_form=null&edit=true`,
          "_blank"
        );
      }
      if (type === 22) {
        window.open(
          process.env.REACT_APP_FORM_URL +
            "elbow-pain" +
            `/${resource_id}?is_doctor_form=null&edit=true`,
          "_blank"
        );
      }
      if (type === 23) {
        window.open(
          process.env.REACT_APP_FORM_URL +
            "hand-and-wrist-pain" +
            `/${resource_id}?is_doctor_form=null&edit=true`,
          "_blank"
        );
      }
      if (type === 33) {
        window.open(
          process.env.REACT_APP_FORM_URL +
            "general-history" +
            `/${resource_id}?is_doctor_form=null&edit=true`,
          "_blank"
        );
      }
    }
  };
  const PendingContinueForm = (resource_id, doctor_id, type) => {
    if (type === 33) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "general-history" +
          `/${resource_id}?is_doctor_form=null&edit=true&status=3`,
        "_blank"
      );
    } else {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "patient-personal-data" +
          `/${window.btoa(akToken)}` +
          `/${window.btoa(type)}?r=${window.btoa(resource_id)}`,
        "_blank"
      );
    }
  };
  const handleShowModaledit = async (resource_id, doctor_id, type) => {
    // setShowModal(true);
    continueForm(resource_id, doctor_id, type);
  };
  const handleShowModal = async (resource_id, doctor_id, type) => {
    // setShowModal(true);
    // setDisplayConfirmationModal(true);
    // setResId(resource_id)
    handleForm(resource_id, doctor_id, type);
  };
  const handleShowModal1 = async (resource_id, doctor_id, type) => {
    // setShowModal(true);
    setDisplayConfirmationModal(true);
    setResId(resource_id);
    // handleForm(resource_id, doctor_id, type);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const handleShowStop = () => {
    if (stopIconClicked) {
      // handleGetData();
      setShowModalStop(true);
    }
  };
  const handleCloseStop = () => {
    setShowModalStop(false);
  };
  const handleShare = (r_id, ak_id, type) => {
    //  alert(r_id)
    //  alert(ak_id)
    //  alert(type);
    navigate(`/doctor-code?type=${type}&rId=${r_id}`);
  };

  const handleDel = async () => {
    try {
      const result = await axiosinstance.current.delete(
        `extapp/forms/delete_froms?ak_id=${login.patient.ak_id}&res_id=${res_id}`
      );
      if (result.status === 200) {
        // dispatch(noteRefresh(!dataRefe));
        setMessageType("success");
        setMessage("Form Deleted Successfully");
        setRefs(new Date().getMilliseconds());
        setTimeout(() => {
          setMessage(false);
        }, 2000);
      }
    } catch (err) {
    
    } finally {
     
    }
  };
  const submitDelete = () => {
    handleDel();

    setDisplayConfirmationModal(false);
  };

  const handleIncomplete = () => {
    
  };

 
  const [showModalall, setshowModalall] = useState(false);
  const [alldoc, setAlldoc] = useState([]);
  const handleOpenModel = (doc) => {
    setAlldoc(doc);
    setshowModalall(true);
  };
  const handleOpenModelOff = () => {
    setshowModalall(false);
  };
  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div className="tableContainer" id={`rpScrollableDivSummary${status} `}>
        <div className="infinite-scroll-component__outerdiv">
          <InfiniteScroll
            dataLength={data.length}
            // next={updatePagination}
            hasMore={true}
            scrollableTarget={`rpScrollableDivSummary${status} `}
            loader={""}
          >
            <table className="custom-tbl width_op">
              <thead>
                <tr role="row">
                  <th colspan="1" role="columnheader" className="col-img"></th>

                  <th colSpan={1} role="columnheader">
                    {" "}
                    {translation.Date}
                  </th>
                  <th colSpan={1} role="columnheader">
                    {translation.Specialty}
                  </th>
                  <th colSpan={1} role="columnheader">
                    {" "}
                    {translation.Organization}
                  </th>
                  <th
                    colSpan={1}
                    role="columnheader"
                    style={{ paddingLeft: "13px" }}
                  >
                    {" "}
                    {translation.Status}
                  </th>
                  {status !== -1 && (
                    <th
                      colSpan={1}
                      role="columnheader"
                      className="col-action"
                      style={{ paddingLeft: 20 }}
                    >
                      {translation.Action}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <>
                    <tr
                      role="row"
                      key={index}
                      className={
                        index === data.length - 1 ? "last-row-class" : ""
                      }
                    >
                      <td>
                        <span className="circle"></span>
                      </td>
                      <td role="cell" className="col-action">
                        {" "}
                        {UserDateFormatTimeZone(
                          item.created_at,
                          login?.patient.dateFormat,
                          login?.patient.timezone
                        )}
                      </td>
                      <td role="cell" className="col-action">
                        {/* {item.speciality} */}


                        {item.speciality == "Shoulder"
                                  ? translation.shoulder
                                  : item.speciality == "Hip"
                                  ? translation.hip_form
                                  : item.speciality == "Foot And Ankle"
                                  ? translation.Foot_And_Ankle
                                  : item.speciality == "Elbow"
                                  ? translation.Elbow
                                  : item.speciality == "Hand And Wrist"
                                  ? translation.Hand_And_Wrist
                                  : item.speciality == "Adult Knee"
                                  ? translation.adult_knee
                                  : item.speciality == "Paediatric Knee"
                                  ? translation.paediatric_knee
                                  : item.speciality == "Padratic Knee"
                                  ? translation.paediatric_knee
                                  : item.speciality == "Adolescent Knee"
                                  ? translation.adolescent_knee
                                  : item.speciality == "Adolesent Knee"
                                  ? translation.adolescent_knee
                                  : translation.General}
                      </td>
                      <td
                        role="cell"
                        className="col-action"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "fit-content",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={item?.more_doctor[0]}
                      >
                        {" "}
                        {item.doctor_names === ""  || 
                        item.doctor_names === " - "
                          ? "NA"
                          : ` ${item?.more_doctor[0]}`}
                        {item?.more_doctor.length > 1 && (
                          <div
                            className="more_"
                            onClick={() => handleOpenModel(item.more_doctor)}
                          >
                            + {item?.more_doctor.length - 1} more
                          </div>
                        )}
                      </td>
                      <td role="cell" style={{ padding: "15px 10px" }}>
                        {" "}
                        <span>
                          {item.status == "2" ? (
                            <div
                              className="f_600  icon_border share c_pointer"
                              onClick={() =>
                                handleShare(
                                  item.resource_id,
                                  item.doctor_id,
                                  item.type
                                )
                              }
                            >
                              <img
                                className="me-2"
                                src={upload_icon}
                                alt=""
                                width={16}
                              ></img>
                              {translation.Share}
                            </div>
                          ) : item.status == "-1" ? (
                            <div className="f_600  expired">
                              <img className="mx-2" src={expired} alt=""></img>
                              {translation.Expired}
                            </div>
                          ) : item.status == "0" ? (
                            <div
                              className="f_600 icon_border incomplete c_pointer"
                              onClick={() =>
                                handleShowModaledit(
                                  item.resource_id,
                                  item.doctor_id,
                                  item.type
                                )
                              }
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={calculateTimeDifference(
                                item.created_at,
                                translation
                              )}
                            >
                              <img
                                className=""
                                src={incomplete}
                                alt=""
                                style={{
                                  cursor: "pointer",
                                  textTransform: "none",
                                }}
                              ></img>
                              {translation.Incomplete}
                            </div>
                          ) : item.status == "3" ? (
                            <div
                              className="f_600 icon_border pending c_pointer"
                              onClick={() =>
                                PendingContinueForm(
                                  item.resource_id,
                                  item.doctor_id,
                                  item.type
                                )
                              }
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={TooltipExpiration(
                                item.created_at,
                                translation
                              )}
                            >
                              <img className="" src={pending} alt=""></img>
                              {checkExpiration(item.created_at, translation)}
                              {/* {translation.progress} */}
                            </div>
                          ) : item.status == "1" ? (
                            <div className="f_600 d-flex">
                              <span>
                                <img
                                  className="check mx-2"
                                  src={check}
                                  alt=""
                                ></img>
                              </span>
                              <span>{translation.shared}</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </span>
                      </td>
                      {item.status === "-1" && (<td></td>)}
                      {item.status !== "-1" && (
                        <td role="cell" className="col-action">
                          <div
                            className={`action_small_dev text-end custom_sort_dropdown ${actionStyles.action_small_dev}`}
                          >
                            <Dropdown
                              className={`custom_dropdown ${actionStyles.drop_down} text-start`}
                            >
                              <Dropdown.Toggle
                                className={actionStyles.dropdown_button}
                                variant="default"
                                id="dropdown-basic"
                              >
                                {" "}
                                <span>
                                  <img
                                    className={`toggle_dots_img`}
                                    src={toggleIcon}
                                    alt="toggle"
                                  />
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {item.status === "1" && (
                                  <>
                                    <Dropdown.Item>
                                      <div
                                        onClick={() =>
                                          handleShowModal(
                                            item.resource_id,
                                            item.doctor_id,
                                            item.type
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          textTransform: "none",
                                        }}
                                      >
                                        <img
                                          className={`action_icons`}
                                          src={viewIcon}
                                          alt="Icon"
                                        />
                                        {translation.View_form}
                                      </div>
                                    </Dropdown.Item>

                                    <Dropdown.Item>
                                      <div
                                        onClick={() =>
                                          handleShare(
                                            item.resource_id,
                                            item.doctor_id,
                                            item.type
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          textTransform: "none",
                                        }}
                                      >
                                        <img
                                          className={`action_icons`}
                                          src={document_icon}
                                          alt="Icon"
                                        />
                                        {translation.Share_to_another_doctor}
                                      </div>
                                    </Dropdown.Item>

                                    {item.edit != false && (
                                      <>
                                        <Dropdown.Item>
                                          <div
                                            onClick={() =>
                                              handleShowModaledit(
                                                item.resource_id,
                                                item.doctor_id,
                                                item.type
                                              )
                                            }
                                            style={{
                                              cursor: "pointer",
                                              textTransform: "none",
                                            }}
                                          >
                                            <img
                                              className={`action_icons`}
                                              src={edit_icon}
                                              alt="Icon"
                                            />
                                            {translation.Edit_form}
                                          </div>
                                        </Dropdown.Item>
                                      </>
                                    )}
                                  </>
                                )}
                                {item.status === "3" && (
                                  <>
                                    {/* {item.edit != false && ( */}
                                    <>
                                      <Dropdown.Item>
                                        <div
                                          onClick={() =>
                                            PendingContinueForm(
                                              item.resource_id,
                                              item.doctor_id,
                                              item.type
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            textTransform: "none",
                                          }}
                                        >
                                          <img
                                            className={`action_icons`}
                                            src={edit_icon}
                                            alt="Icon"
                                          />
                                          {translation.Answer_the_form}
                                        </div>
                                      </Dropdown.Item>
                                    </>
                                    {/* )} */}
                                  </>
                                )}
                                {item.status === "0" && (
                                  <>
                                    {item.edit != false && (
                                      <>
                                        {
                                          <Dropdown.Item>
                                            <div
                                              onClick={() =>
                                                handleShowModaledit(
                                                  item.resource_id,
                                                  item.doctor_id,
                                                  item.type
                                                )
                                              }
                                              style={{
                                                cursor: "pointer",
                                                textTransform: "none",
                                              }}
                                            >
                                              <img
                                                className={`action_icons`}
                                                src={edit_icon}
                                                alt="Icon"
                                              />
                                              {translation.Complete_the_form}
                                            </div>
                                          </Dropdown.Item>
                                        }
                                      </>
                                    )}

                                    <Dropdown.Item>
                                      <div
                                        onClick={() =>
                                          handleShowModal1(
                                            item.resource_id,
                                            item.doctor_id,
                                            item.type
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          textTransform: "none",
                                        }}
                                      >
                                        <img
                                          className={`action_icons`}
                                          src={deleteIcon}
                                          alt="Icon"
                                        />
                                        {translation.Delete_the_form}
                                      </div>
                                    </Dropdown.Item>
                                  </>
                                )}
                                {item.status === "-1" && ""}
                                {item.status === "2" && (
                                  <>
                                    <Dropdown.Item>
                                      <div
                                        onClick={() =>
                                          handleShowModal(
                                            item.resource_id,
                                            item.doctor_id,
                                            item.type
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          textTransform: "none",
                                        }}
                                      >
                                        <img
                                          className={`action_icons`}
                                          src={viewIcon}
                                          alt="Icon"
                                        />
                                        {translation.View_form}
                                      </div>
                                    </Dropdown.Item>

                                    <Dropdown.Item>
                                      <div
                                        onClick={() =>
                                          handleShare(
                                            item.resource_id,
                                            item.doctor_id,
                                            item.type
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          textTransform: "none",
                                        }}
                                      >
                                        <img
                                          className={`action_icons`}
                                          src={document_icon}
                                          alt="Icon"
                                        />
                                        {translation.Share_to_doctor}
                                      </div>
                                    </Dropdown.Item>
                                    {item.edit != false && (
                                      <>
                                        <Dropdown.Item>
                                          <div
                                            onClick={() =>
                                              handleShowModaledit(
                                                item.resource_id,
                                                item.doctor_id,
                                                item.type
                                              )
                                            }
                                            style={{
                                              cursor: "pointer",
                                              textTransform: "none",
                                            }}
                                          >
                                            <img
                                              className={`action_icons`}
                                              src={edit_icon}
                                              alt="Icon"
                                            />
                                            {translation.Edit_form}
                                          </div>
                                        </Dropdown.Item>
                                      </>
                                    )}
                                  </>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      )}
                    </tr>
                  </>
                ))}
                {/* <tr role="row">
                  <td>
                    <span className="circle"></span>
                  </td>
                  <td role="cell">12/12/2023</td>
                  <td role="cell">Elbow</td>
                  <td role="cell"></td>
                  <td role="cell">
                    <div className="f_600 icon_border">
                      <img className=" me-2" src={document} alt=""></img>
                      Share
                    </div>
                  </td>

                  <td role="cell" className="col-action "></td>
                </tr> */}
                {/* <tr role="row">
                  <td>
                    <span className="circle"></span>
                  </td>
                  <td role="cell">25/12/2023</td>
                  <td role="cell">Knee</td> 
                  <td role="cell">Dr. Nidhi , QOC</td>
                  <td role="cell">
                    <div className="f_600 icon_border pending">
                      <img className=" me-2" src={pending} alt=""></img>
                      Pending
                    </div>
                  </td>

                  <td role="cell" className="col-action "></td>
                </tr>
                <tr role="row">
                  <td>
                    <span className="circle"></span>
                  </td>
                  <td role="cell">25/12/2023</td>
                  <td role="cell">Knee</td>
                  <td role="cell">Dr. Nidhi , QOC</td>
                  <td role="cell">
                    <div className="f_600 icon_border incomplete">
                      <img className=" me-2" src={incomplete} alt=""></img>
                      Incomplete
                    </div>
                  </td>

                  <td role="cell" className="col-action "></td>
                </tr>
                <tr role="row">
                  <td>
                    <span className="circle"></span>
                  </td>
                  <td role="cell" className="expired_dis">
                    25/12/2023
                  </td>
                  <td role="cell" className="expired_dis">
                    Hip
                  </td>
                  <td role="cell"></td>
                  <td role="cell">
                    <div className="f_600  expired">
                      <img className=" me-2" src={expired} alt=""></img>
                      Expired
                    </div>
                  </td>

                  <td role="cell" className="col-action "></td>
                </tr> */}
              </tbody>
            </table>
            <DeleteConfirmation
              showModal={displayConfirmationModal}
              confirmModal={submitDelete}
              hideModal={hideConfirmationModal}
              // message={notetitle}
              // type={rowType}
            />
          </InfiniteScroll>
        </div>
      </div>
      {/* <Modal
        show={showModalall}
        onHide={handleOpenModelOff}
        className={`delete_modal_main`}
        centered
      > */}
      <Modal
        show={showModalall}
        onHide={handleOpenModelOff}
        // backdrop="static"
        // keyboard={false}
        dialogClassName="height_model"
        className="settings_ppup_main"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{translation.doctorOrganization}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            className=""
            style={{
              listStyle: "auto",
              fontWeight: "600",
              overflow: "auto",
              maxHeight: "330px",
            }}
          >
            {alldoc.map((item) => (
              <>
                <li className="">{item} </li> <hr className="mb-3" />
              </>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
