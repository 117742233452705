import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import MessageTable from "./DocumentTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DocumentTable1 from "./DocumentTable1";
import useTranslation from "../customHooks/translations";
import { Modal } from "react-bootstrap";
import Button from "../custom/button/Button";
import FileUpload from "./FileUpload";
import { useSearchParams } from "react-router-dom";
import "../../css/style.css"
function Document() {
  let lang = sessionStorage.getItem("lang_");
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [msgData, setmsgData] = useState([]);
  const [profile, setProfile] = useState("");
  const login = useSelector((state) => state.loginPatient.arr[0]);
  let akToken = localStorage.getItem("AkTOKENI");
  let resouceId = searchParams.get("id");

  const [offset, setOffset] = useState(10);
  const [limit, setLimit] = useState(10);
  const [timezoneD, setTimeZone] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [modelShow, setModelShow] = useState(false);

  const [msgDataDoc1, setmsgDataDoc1] = useState([]);
  const [offsetDoc1, setOffsetDoc1] = useState(10);
  const [key, setKey] = useState("allcases");
  const [keyID, setKeyID] = useState(1);
  const [active, setActive] = useState(1);
  const [show1, setshow1] = useState(true);
  const handleActive = (e) => {
    setActive(e);
  };
  // console.log(login,"dfvhsdfgbsajg")
  // console.log(login.patient.ak_id)

  // const getPatData = async () => {
  //   try {
  //     const result = await axiosInstance.current.get(
  //       `/extapp/doctors/getUserSpecificDocuments/${login.patient.ak_id}?offset=0&limit=5`
  //     );
  //     if (result) {
  //       setmsgData(result.data.data);
  //     }
  //   } catch (err) {
  //     console.log(err.message || "Unexpected Error!");
  //   } finally {
  //     console.log("api response finally");
  //   }
  // };

  const getMsgData = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getPatientDocuments/${akToken}?offset=0&limit=${limit}&sDate=&eDate=&filename=&org_id=&doc_type&t`
      );
      if (result) {
        setmsgData(result.data.data);
      }
    } catch (err) {
      console.log(err.message || "Unexpected Error!");
    } finally {
      //console.log("api response finally");
    }
  };
  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/patients/getPatientDocuments/${akToken}?offset=${msgData.length}&limit=${limit}&sDate=&eDate=&filename=&org_id=&doc_type&t`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setmsgData(msgData.concat(resdata));
    }, 500);
  };
  const fetchMoreData = () => {
    addMoreData();
  };

  const [Refress, setRefress] = useState(false);

  const getMsgDataDoc1 = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/doctors/getUserSpecificDocuments/${login.patient.ak_id
        }?offset=${0}&limit=${limit}`
      );
      if (result) {
        setmsgDataDoc1(result.data.data);
      }
    } catch (err) {
      console.log(err.message || "Unexpected Error!");
    } finally {
     // console.log("api response finally");
    }
  };
  useEffect(() => {
    getMsgDataDoc1();
    setOffsetDoc1(10);
  }, [Refress, modelShow, key]);
  const addMoreDataDoc1 = async () => {
    setOffsetDoc1(offsetDoc1 + 10);
    const response = await axiosInstance.current.get(
      `extapp/doctors/getUserSpecificDocuments/${login.patient.ak_id}?offset=${offsetDoc1}&limit=${limit}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setmsgDataDoc1(msgDataDoc1.concat(resdata));
    }, 500);
  };
  const fetchMoreDataDoc1 = () => {
    addMoreDataDoc1();
  };

  // useEffect(() => {
  //   getPatData();
  // }, [])

  useEffect(() => {
    getMsgData();
    if (login) {
      setProfile(login.patient);
      setTimeZone(login.patient.timezone);
      setTimeFormat(login.patient.timeformat);
    }
  }, [login]);

  // useEffect(() => {
  //   getPatData();
  //   if (login) {
  //     setProfile(login.patient);
  //     setTimeZone(login.patient.timezone);
  //     setTimeFormat(login.patient.timeformat)
  //   }
  // }, [login]);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleCloseShow = () => {
    setModelShow(false);
  };
  const handleSelect = (key) => {
    if (key === "allcases") {
      setshow1(false);
      setKey(key);
      setOffset(10);
      setKeyID(1);
    } else if (key === "submitted") {
      setshow1(false);
      setKey(key);
      setOffset(10);
      setKeyID(2);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="my_visit_screen">
            <div className="row page_header">
              <div className="col-sm-6 col-md-6">
                <h4 className="ph_title">{translation.document}</h4>
              </div>

              <div
                className={
                  lang === "ara" ? "text-start" : "col-sm-6 col-md-6 text-end"
                }
                style={{ cursor: "pointer" }}
              >
                <span
                  onClick={() => setModelShow(true)}
                  className={`btn_outline p-2 px-4`}
                >
                  {/* <img className={`action_icons`} src={downloadIcon} alt="" /> */}
                  <span className="me-2">+</span>
                  {translation.add_document}
                </span>
              </div>
            </div>
          </div>

          <div className="my_visit_screen org_tab mb_100 p-0">
            <Tabs
              id="case-list-tabs"
              activeKey={key}
              onSelect={handleSelect}
              // onSelect={(k) => setKey(k)}
              className="border-0 pe-0"
            >
              <Tab eventKey="allcases" title={translation.shared}>
                {msgData.length > 0 ? (
                  <MessageTable
                    data={msgData}
                    updatePagination={fetchMoreData}
                    timezoneD={timezoneD}
                    timeFormat={timeFormat}
                    resouceId={resouceId}
                  />
                ) : (
                  <div className="no_data">{translation.error_documents}</div>
                )}
              </Tab>
              <Tab eventKey="submitted" title={translation.private}>
                {keyID === 2 &&
                  (msgDataDoc1.length > 0 ? (
                    <DocumentTable1
                      data={msgDataDoc1}
                      updatePagination={fetchMoreDataDoc1}
                      timezoneD={timezoneD}
                      timeFormat={timeFormat}
                      setRefress={setRefress}
                    />
                  ) : (
                    <div className="no_data">{translation.error_documents}</div>
                  ))}
              </Tab>
            </Tabs>
          </div>
          {/* {msgData.length !== 0 ? (
            <>
          
            </>
          ) : (
            <div className="panel">
              <div className="panel-body p_25">
                <div className="no_data">{translation.No_data_found}</div>
              </div>
            </div>
          )} */}

          <Modal
            show={modelShow}
            onHide={handleCloseShow}
            dialogClassName="modal-xl patient_notes_popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                {translation.add_document}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <FileUpload />
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>

              <Button
                show={modelShow}
                onClick={handleCloseShow}
                value={translation.Continue}
                buttonStyle="btn_fill mr_20 ms-auto"
              />

            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default Document;
