export default{
  "patient_shoulder_pain": "통증",
  "patient_shoulder_pain_q1": "어깨에 통증이 있나요?",
  "patient_shoulder_pain_q1_a1": "아니오",
  "patient_shoulder_pain_q1_a2": "예",
  "patient_shoulder_pain_q2": "지난 24시간 동안 일상적인 활동 중 어깨에 느껴지는 통증의 최고 수준은 어느 정도였습니까? (0은 통증 없음, 15는 참을 수 없는 통증을 의미합니다)",
  "patient_shoulder_pain_q2_a1": "견딜 수 없는 통증",
  "patient_shoulder_pain_q2_a2": "통증 없음",
  "patient_shoulder_pain_q3": "어깨 때문에 수면에 지장을 받고 있습니까?",
  "patient_shoulder_pain_q3_a1": "수면에 지장이 없다",
  "patient_shoulder_pain_q3_a2": "가끔 장애가 있음",
  "patient_shoulder_pain_q3_a3": "매일 밤 장애가 있음",
  "patient_shoulder_pain_q4": "오늘 당신의 통증은 얼마나 심한가요?",
  "patient_shoulder_pain_q5": "현재 흡연자이신가요?",
  "patient_shoulder_pain_q5_a1": "아니오",
  "patient_shoulder_pain_q5_a2": "예",
  "patient_shoulder_pain_q5_a3": "전 흡연자",
  "patient_shoulder_pain_q5_a2_q1": "하루에 몇 개의 담배를 피우십니까?",
  "patient_shoulder_pain_q5_a3_q1": "얼마나 오래 전에 그만두셨나요? (몇 년 전에요?)",
  "patient_shoulder_pain_q5_a3_q2": "몇 년 동안 담배를 피우셨나요?",
  "patient_shoulder_pain_q6": "통증 완화 약을 복용하십니까? (예: 파라세타몰 등)",
  "patient_shoulder_pain_q6_a1": "아니오",
  "patient_shoulder_pain_q6_a2": "예",
  "patient_shoulder_pain_q6_a2_q1": "강한 진통제 (예 : 코데인, 트라마돌, 모르핀)를 복용하십니까?",
  "patient_shoulder_pain_q6_a2_q1_a1": "아니오",
  "patient_shoulder_pain_q6_a2_q1_a2": "예",
  "patient_shoulder_pain_q6_a2_q2": "평균적으로 하루에 몇 알의 약을 복용하십니까?",
  "patient_shoulder_pain_q7": "다음 중 당신의 통증을 가장 잘 설명하는 것은 무엇입니까?",
  "patient_shoulder_pain_q7_a1": "없음",
  "patient_shoulder_pain_q7_a2": "가끔씩 약간",
  "patient_shoulder_pain_q7_a3": "과격한 활동이나 특정한 활동 중에 통증이 있으며, 아스피린은 때때로 사용된다.",
  "patient_shoulder_pain_q7_a4": "가벼운 활동 중에는 통증이 있고, 휴식 중에는 거의 또는 전혀 없으며, 아스피린을 자주 사용한다",
  "patient_shoulder_pain_q7_a5": "통증이 항상 존재하고 견딜 수 있으며 때때로 강력한 약물이 필요하다.",
  "patient_shoulder_pain_q7_a6": "통증이 항상 존재하고 견딜 수 없으며 자주 강력한 약물이 필요하다.",
  "patient_shoulder_pain_q8": "팔에 핀과 바늘 또는 따끔거림이 느껴지나요?",
  "patient_shoulder_pain_q8_a1": "아니오",
  "patient_shoulder_pain_q8_a2": "예",
  "patient_shoulder_pain_q9": "통증이 있는 곳을 표시하세요",
  "patient_shoulder_pain_q9_a1": "전면",
  "patient_shoulder_pain_q9_a2": "후면",
  "patient_shoulder_function": "기능",
  "patient_shoulder_function_q1": "다음 중 영향을받는 어깨의 기능을 가장 잘 설명하는 것은 무엇입니까?",
  "patient_shoulder_function_q1_a1": "정상",
  "patient_shoulder_function_q1_a2": "어깨 높이 이상에서 작업할 수 있지만 약간의 제한이 있다.",
  "patient_shoulder_function_q1_a3": "대부분의 집안일, 쇼핑, 운전, 머리 손질, 옷 입기/벗기 가능",
  "patient_shoulder_function_q1_a4": "가벼운 집안일과 대부분의 일상 생활 활동 가능",
  "patient_shoulder_function_q1_a5": "가벼운 활동만 가능",
  "patient_shoulder_function_q1_a6": "사지를 사용할 수 없다",
  "patient_shoulder_function_instruction": "다음 활동을 수행할 수 있는 능력을 나타내는 숫자를 선택하기.",
  "patient_shoulder_function_instruction_0": "불가능",
  "patient_shoulder_function_instruction_1": "매우 어렵다",
  "patient_shoulder_function_instruction_2": "다소 어렵다",
  "patient_shoulder_function_instruction_3": "어렵지 않다",
  "patient_shoulder_function_note": "주 : 어깨 기능과 관련된 다음 질문에 답하십시오.",
  "patient_shoulder_function_q2": "코트/셔츠를 입기.",
  "patient_shoulder_function_q2_a0": "불가능",
  "patient_shoulder_function_q2_a1": "매우 어렵다",
  "patient_shoulder_function_q2_a2": "다소 어렵다",
  "patient_shoulder_function_q2_a3": "어렵지 않다",
  "patient_shoulder_function_q3": "아픈 쪽이나 영향을 받은 쪽으로 자기.",
  "patient_shoulder_function_q3_a0": "불가능",
  "patient_shoulder_function_q3_a1": "매우 어렵다",
  "patient_shoulder_function_q3_a2": "다소 어렵다",
  "patient_shoulder_function_q3_a3": "어렵지 않다",
  "patient_shoulder_function_q4": "등을 씻으세요/브래지어를 착용하세요",
  "patient_shoulder_function_q4_a0": "불가능｜",
  "patient_shoulder_function_q4_a1": "매우 어렵다",
  "patient_shoulder_function_q4_a2": "다소 어렵다",
  "patient_shoulder_function_q4_a3": "어렵지 않다",
  "patient_shoulder_function_q5": "화장실에서 엉덩이 닦기",
  "patient_shoulder_function_q5_a0": "불가능",
  "patient_shoulder_function_q5_a1": "매우 어렵다",
  "patient_shoulder_function_q5_a2": "다소 어렵다",
  "patient_shoulder_function_q5_a3": "어렵지 않다",
  "patient_shoulder_function_q6": "머리 빗기",
  "patient_shoulder_function_q6_a0": "불가능",
  "patient_shoulder_function_q6_a1": "매우 어렵다",
  "patient_shoulder_function_q6_a2": "다소 어렵다",
  "patient_shoulder_function_q6_a3": "어렵지 않다",
  "patient_shoulder_function_q7": "면도하기/화장하기",
  "patient_shoulder_function_q7_a0": "불가능",
  "patient_shoulder_function_q7_a1": "매우 어렵다",
  "patient_shoulder_function_q7_a2": "다소 어렵다",
  "patient_shoulder_function_q7_a3": "어렵지 않다",
  "patient_shoulder_function_q8": "이 닦기",
  "patient_shoulder_function_q8_a0": "불가능",
  "patient_shoulder_function_q8_a1": "매우 어렵다",
  "patient_shoulder_function_q8_a2": "다소 어렵다",
  "patient_shoulder_function_q8_a3": "어렵지 않다",
  "patient_shoulder_function_q9": "가득 찬 병으로 컵 채우기",
  "patient_shoulder_function_q9_a0": "불가능",
  "patient_shoulder_function_q9_a1": "매우 어렵다",
  "patient_shoulder_function_q9_a2": "다소 어렵다",
  "patient_shoulder_function_q9_a3": "어렵지 않다",
  "patient_shoulder_function_q10": "마시기（가득찬 컵을 입으로 가져온다）",
  "patient_shoulder_function_q10_a0": "불가능",
  "patient_shoulder_function_q10_a1": "매우 어렵다",
  "patient_shoulder_function_q10_a2": "다소 어렵다",
  "patient_shoulder_function_q10_a3": "어렵지 않다",
  "patient_shoulder_function_q11": "국물을 먹기(국물 한 숟가락을 입에 가져온다 )",
  "patient_shoulder_function_q11_a0": "불가능",
  "patient_shoulder_function_q11_a1": "매우 어렵다",
  "patient_shoulder_function_q11_a2": "다소 어렵다",
  "patient_shoulder_function_q11_a3": "어렵지 않다",
  "patient_shoulder_function_q12": "누군가의 손을 흔들기 / 문을 열기",
  "patient_shoulder_function_q12_a0": "불가능",
  "patient_shoulder_function_q12_a1": "매우 어렵다",
  "patient_shoulder_function_q12_a2": "다소 어렵다",
  "patient_shoulder_function_q12_a3": "어렵지 않다",
  "patient_shoulder_function_q13": "전화 사용(귀 높이)",
  "patient_shoulder_function_q13_a0": "불가능",
  "patient_shoulder_function_q13_a1": "매우 어렵다",
  "patient_shoulder_function_q13_a2": "다소 어렵다",
  "patient_shoulder_function_q13_a3": "어렵지 않다",
  "patient_shoulder_function_q14": "편지를 쓰다 / 종이에 서명하기 / 키보드를 사용하기",
  "patient_shoulder_function_q14_a0": "불가능",
  "patient_shoulder_function_q14_a1": "매우 어렵다",
  "patient_shoulder_function_q14_a2": "다소 어렵다",
  "patient_shoulder_function_q14_a3": "어렵지 않다",
  "patient_shoulder_function_q15": "손이 높은 선반(어깨 위)에 닿을 수 있음",
  "patient_shoulder_function_q15_a0": "불가능",
  "patient_shoulder_function_q15_a1": "매우 어렵다",
  "patient_shoulder_function_q15_a2": "다소 어렵다",
  "patient_shoulder_function_q15_a3": "어렵지 않다",
  "patient_shoulder_function_q16": "어깨 위로 0.5kg (1 lbs) 들어올리기",
  "patient_shoulder_function_q16_a0": "불가능",
  "patient_shoulder_function_q16_a1": "매우 어렵다",
  "patient_shoulder_function_q16_a2": "다소 어렵다",
  "patient_shoulder_function_q16_a3": "어렵지 않다",
  "patient_shoulder_function_q17": "어깨 위로 4.5kg (10 lbs) 들어올리기",
  "patient_shoulder_function_q17_a0": "불가능",
  "patient_shoulder_function_q17_a1": "매우 어렵다",
  "patient_shoulder_function_q17_a2": "다소 어렵다",
  "patient_shoulder_function_q17_a3": "어렵지 않다",
  "patient_shoulder_function_q18": "영향 받은 어깨로 10kg (22 lbs)를 옮김",
  "patient_shoulder_function_q18_a0": "불가능",
  "patient_shoulder_function_q18_a1": "매우 어렵다",
  "patient_shoulder_function_q18_a2": "다소 어렵다",
  "patient_shoulder_function_q18_a3": "어렵지 않다",
  "patient_shoulder_function_q19": "어깨보다 낮은 동작으로 공을 던진다 (최소 3미터 이상)",
  "patient_shoulder_function_q19_a0": "불가능",
  "patient_shoulder_function_q19_a1": "매우 어렵다",
  "patient_shoulder_function_q19_a2": "다소 어렵다",
  "patient_shoulder_function_q19_a3": "어렵지 않다",
  "patient_shoulder_function_q20": "어깨보다 높은 동작으로 공을 던진다 (최소 6미터 이상).",
  "patient_shoulder_function_q20_a0": "불가능",
  "patient_shoulder_function_q20_a1": "매우 어렵다",
  "patient_shoulder_function_q20_a2": "다소 어렵다",
  "patient_shoulder_function_q20_a3": "어렵지 않다",
  "patient_shoulder_function_q21": "보통처럼 완전한 일을 하기",
  "patient_shoulder_function_q21_a0": "불가능",
  "patient_shoulder_function_q21_a1": "매우 어렵다",
  "patient_shoulder_function_q21_a2": "다소 어렵다",
  "patient_shoulder_function_q21_a3": "어렵지 않다",
  "patient_shoulder_function_q22": "어깨로 평소 업무를 어느 정도 수행할 수 있나요?",
  "patient_shoulder_function_q22_a0": "전혀",
  "patient_shoulder_function_q22_a1": "모두",
  "patient_shoulder_function_q23": "평소처럼 취미나 스포츠를 전부 하기",
  "patient_shoulder_function_q23_a0": "불가능",
  "patient_shoulder_function_q23_a1": "매우 어렵다",
  "patient_shoulder_function_q23_a2": "다소 어렵다",
  "patient_shoulder_function_q23_a3": "어렵지 않다",
  "patient_shoulder_function_q24": "어깨는 어느 정도까지 정상적인 레크리에이션 활동을 할 수 있나요?",
  "patient_shoulder_function_q24_a0": "전혀",
  "patient_shoulder_function_q24_a1": "모두",
  "patient_shoulder_function_q25": "작업 활동을 나열해주세요.",
  "patient_shoulder_function_q26": "취미 / 스포츠 활동을 나열해주세요.",
  "patient_shoulder_feeling_instability": "불안정한 느낌",
  "patient_shoulder_feeling_instability_q1": "어깨가 튀어나올 것처럼 불안정하거나 느슨하게 느껴지나요?아니면 어깨 탈구 또는 불안정한 어깨로 진단을 받거나 치료를 받은 적이 있나요?",
  "patient_shoulder_feeling_instability_q1_a1": "아니오",
  "patient_shoulder_feeling_instability_q1_a2": "예",
  "patient_shoulder_feeling_instability_q1_a2_q1": "어깨가 얼마나 불안정한지 표시해주세요.",
  "patient_shoulder_wosi": "웨스턴 온타리오 어깨 불안정 지수",
  "patient_shoulder_wosi_instruction": "이 설문지의 이 부분에서는 문제가 있는 어깨와 관련하여 지난 주 동안 경험한 증상의 정도를 나타내도록 요청됩니다. 슬라이더를 이동하여 증상과 정확하게 일치하도록 하십시오.",
  "patient_shoulder_wosi_note": "슬라이더를 오른쪽 이동할수록 해당 증상을 더 많이 경험한다는 것을 나타냅니다. 슬라이더를 왼쪽 이동할수록 해당 증상을 덜 경험한다는 것을 나타냅니다. 특정 질문의 의도에 대해 궁금한 점이 있으면 언제든지 질문해주세요.",
  "patient_shoulder_wosi_sectionA": "섹션 A: 신체 증상",
  "patient_shoulder_wosi_q1": "머리 위에서 하는 활동 중 어깨에 얼마나 아픔을 느끼십니까?",
  "patient_shoulder_wosi_q1_a1": "아픔 없음",
  "patient_shoulder_wosi_q1_a2": "극심한 통증",
  "patient_shoulder_wosi_q2": "어깨에서 얼마나 아픔이나 욱신거리는 통증을 느끼십니까?",
  "patient_shoulder_wosi_q2_a1": "아프지 않음 / 두근거림 없음",
  "patient_shoulder_wosi_q2_a2": "극심한 통증 / 두근거림",
  "patient_shoulder_wosi_q3": "어깨에서 얼마나 약함이나 힘의 부족을 느끼십니까?",
  "patient_shoulder_wosi_q3_a1": "약점 없음",
  "patient_shoulder_wosi_q3_a2": "극도의 약함",
  "patient_shoulder_wosi_q4": "어깨에서 얼마나 피로감이나 힘 부족을 느끼십니까?",
  "patient_shoulder_wosi_q4_a1": "피로하지 않음",
  "patient_shoulder_wosi_q4_a2": "극도의 피로",
  "patient_shoulder_wosi_q5": "어깨에서 얼마나 많은 클릭, 갈라짐 또는 딸깍하는 소리를 경험하십니까?",
  "patient_shoulder_wosi_q5_a1": "클릭 소리가 없다.",
  "patient_shoulder_wosi_q5_a2": "클릭이 극도로 심하다.",
  "patient_shoulder_wosi_q6": "어깨에 얼마나 뻣뻣함을 느끼십니까?",
  "patient_shoulder_wosi_q6_a1": "뻣뻣함 없음",
  "patient_shoulder_wosi_q6_a2": "극도의 강성",
  "patient_shoulder_wosi_q7": "어깨로 인해 목 근육에서 얼마나 불편함을 느끼십니까?",
  "patient_shoulder_wosi_q7_a1": "불편함 없음",
  "patient_shoulder_wosi_q7_a2": "극도의 불편함",
  "patient_shoulder_wosi_q8": "어깨에서 얼마나 불안정하거나 느슨한 느낌을 경험하십니까?",
  "patient_shoulder_wosi_q8_a1": "불안정성 없음",
  "patient_shoulder_wosi_q8_a2": "극도의 불안정성",
  "patient_shoulder_wosi_q9": "어깨를 다른 근육으로 얼마나 보상하시나요?",
  "patient_shoulder_wosi_q9_a1": "전혀 없다",
  "patient_shoulder_wosi_q9_a2": "극단적인",
  "patient_shoulder_wosi_q10": "어깨의 움직임 범위가 얼마나 제한되었나요?",
  "patient_shoulder_wosi_q10_a1": "손실 없음",
  "patient_shoulder_wosi_q10_a2": "극심한 손실",
  "patient_shoulder_wosi_sectionB": "섹션 B: 스포츠 / 여가 / 일",
  "patient_shoulder_wosi_q11": "어깨 문제로 인해 스포츠나 여가 활동에 참여할 수 있는 정도가 얼마나 제한되었나요?",
  "patient_shoulder_wosi_q11_a1": "제한 없음",
  "patient_shoulder_wosi_q11_a2": "매우 제한적입니다",
  "patient_shoulder_wosi_q12": "당신의 어깨가 당신의 스포츠나 일에 필요한 특정 기술을 수행하는 능력에 얼마나 영향을 미쳤나요?",
  "patient_shoulder_wosi_q12_note": "어깨가 스포츠와 업무에 모두 영향을 미친다면 가장 영향을 받는 부위를 고려해보세요.",
  "patient_shoulder_wosi_q12_a1": "영향을 받지 않음",
  "patient_shoulder_wosi_q12_a2": "매우 영향을 받은",
  "patient_shoulder_wosi_q13": "활동 중 팔을 보호해야 할 필요성을 얼마나 느끼십니까?",
  "patient_shoulder_wosi_q13_a1": "전혀 없다",
  "patient_shoulder_wosi_q13_a2": "극단적인",
  "patient_shoulder_wosi_q14": "어깨 수준 아래에서 무거운 물건을 들 때 얼마나 어려움을 겪나요?",
  "patient_shoulder_wosi_q14_a1": "어려움 없음",
  "patient_shoulder_wosi_q14_a2": "극도의 어려움",
  "patient_shoulder_wosi_sectionC": "섹션 C: 라이프스타일",
  "patient_shoulder_wosi_q15": "어깨에로 떨어지는 것에 대해 얼마나 두려움을 느끼나요?",
  "patient_shoulder_wosi_q15_a1": "두려움 없음",
  "patient_shoulder_wosi_q15_a2": "극도의 공포",
  "patient_shoulder_wosi_q16": "원하는 체력 수준을 유지하는 데 얼마나 어려움을 겪고 계십니까?",
  "patient_shoulder_wosi_q16_a1": "어려움 없음",
  "patient_shoulder_wosi_q16_a2": "극도의 어려움",
  "patient_shoulder_wosi_q17": "가족이나 친구와 함께 싸움이나 놀이를 할 때 얼마나 어려움을 겪고 있나요?",
  "patient_shoulder_wosi_q17_a1": "어려움 없음",
  "patient_shoulder_wosi_q17_a2": "극도의 어려움",
  "patient_shoulder_wosi_q18": "어깨 때문에 잠을 자는데 얼마나 어려움을 겪고 있나요?",
  "patient_shoulder_wosi_q18_a1": "어려움 없음",
  "patient_shoulder_wosi_q18_a2": "극도의 어려움",
  "patient_shoulder_wosi_sectionD": "섹션 D: 감정",
  "patient_shoulder_wosi_q19": "당신은 어깨를 얼마나 의식하나요?",
  "patient_shoulder_wosi_q19_a1": "의식이 없음",
  "patient_shoulder_wosi_q19_a2": "매우 의식적인",
  "patient_shoulder_wosi_q20": "어깨가 더 악화되는 것에 대해 얼마나 걱정하고 계십니까?",
  "patient_shoulder_wosi_q20_a1": "걱정 없음",
  "patient_shoulder_wosi_q20_a2": "매우 걱정",
  "patient_shoulder_wosi_q21": "어깨 때문에 얼마나 좌절을 느끼고 계세요?",
  "patient_shoulder_wosi_q21_a1": "번거로움 없음",
  "patient_shoulder_wosi_q21_a2": "매우 좌절",
  "patient_shoulder_ac_joint": "AC 관절",
  "patient_shoulder_ac_joint_q1": "AC 관절이 불안정합니까?",
  "patient_shoulder_ac_joint_q1_note": "참고: AC관절은 쇄골과 견갑골의 앞쪽 끝을 연결하는 어깨 위쪽의 관절입니다.",
  "patient_shoulder_ac_joint_q1_a1": "아니오",
  "patient_shoulder_ac_joint_q1_a2": "예",
  "patient_shoulder_sacs": "특정 AC 점수 (SAC)",
  "patient_shoulder_sacs_explanation": "각 질문마다 통증 정도 또는 다른 작업 수행의 어려움 정도를 평가하셔야 합니다. 평가 척도는 0은 아무런 통증이나 어려움이 없음을 나타내고, 10은 가장 심한 통증이나 어려움을 나타냅니다.",
  "patient_shoulder_sacs_note": "2점은 최소한의 통증 수준을 나타냅니다. 특정 질문의 의도에 대해 궁금한 점이 있으시면 언제든지 질문해주세요.",
  "patient_shoulder_sacs_sectionA": "섹션 A: 통증",
  "patient_shoulder_sacs_a1": "어깨가 가장 심할 때 어깨 통증의 수준을 평가해주세요 - 가장 심한 수준.",
  "patient_shoulder_sacs_a1_a1": "아픔 없음",
  "patient_shoulder_sacs_a1_a2": "가장 심한 통증",
  "patient_shoulder_sacs_a2": "어깨가 최상태일 때 통증의 정도를 평가해주세요.",
  "patient_shoulder_sacs_a2_a1": "아픔 없음",
  "patient_shoulder_sacs_a2_a2": "가장 심한 통증",
  "patient_shoulder_sacs_a3": "어깨 윗부분을 누르거나 만지거나 압력을 가할 때 느끼는 통증의 정도를 평가해주세요.",
  "patient_shoulder_sacs_a3_note": "예. 노트북이나 핸드백 스트랩",
  "patient_shoulder_sacs_a3_a1": "아픔 없음",
  "patient_shoulder_sacs_a3_a2": "가장 심한 통증",
  "patient_shoulder_sacs_a4": "목과 영향을 받는 어깨 뼈 구역에서 느끼는 통증, 긴장 또는 당기는 감각의 정도를 평가해주세요.",
  "patient_shoulder_sacs_a4_a1": "아픔 없음",
  "patient_shoulder_sacs_a4_a2": "가장 심한 통증",
  "patient_shoulder_sacs_sectionB": "섹션 B: 기능",
  "patient_shoulder_sacs_b1": "어깨에서 느끼는 클릭 또는 갈기 또는 덜거덕거림의 정도를 평가해주세요.",
  "patient_shoulder_sacs_b1_a1": "클릭 소리가 없다.",
  "patient_shoulder_sacs_b1_a2": "클릭이 극도로 심하다.",
  "patient_shoulder_sacs_b2": "걷는 동안 팔이 지지가 필요한지 여부를 나타내십시오.",
  "patient_shoulder_sacs_b2_note": "예시: 주머니에 팔을 올려놓거나 슬링을 사용하는 것",
  "patient_shoulder_sacs_b2_a1": "보장구가 필요 없음",
  "patient_shoulder_sacs_b2_a2": "절대적으로 보장구가 필요함",
  "patient_shoulder_sacs_b3": "팔을 통해 체중을 지거나 기울이는 난이도 수준을 평가해주세요.",
  "patient_shoulder_sacs_b3_note": "예시. 푸시 업 사이클링 요가",
  "patient_shoulder_sacs_b3_a1": "어려움 없음",
  "patient_shoulder_sacs_b3_a2": "극도로 어려움이 있거나 수행할 수 없음",
  "patient_shoulder_sacs_b4": "5분 이상 옆에 무거운 물건을 들고 다니는 난이도를 평가해주세요.",
  "patient_shoulder_sacs_b4_note": "예시. 쇼핑백",
  "patient_shoulder_sacs_b4_a1": "어려움 없음",
  "patient_shoulder_sacs_b4_a2": "극도로 어려움이 있거나 수행할 수 없음",
  "patient_shoulder_sacs_b5": "당신의 영향 받은 팔로 몸통을 가로지르는 난이도 수준을 평가해주세요.",
  "patient_shoulder_sacs_b5_note": "예시: 건강한 어깨를 건드리기 위해 손을 뻗어서 닿다.",
  "patient_shoulder_sacs_b5_a1": "어려움 없음",
  "patient_shoulder_sacs_b5_a2": "극도로 어려움이 있거나 수행할 수 없음",
  "patient_shoulder_sacs_b6": "무거운 물체나 무게를 머리 위로 밀어 올리는 난이도 수준을 평가해주세요.",
  "patient_shoulder_sacs_b6_note": "예: 다섯 킬로그램 이상",
  "patient_shoulder_sacs_b6_a1": "어려움 없음",
  "patient_shoulder_sacs_b6_a2": "극도로 어려움이 있거나 수행할 수 없음",
  "patient_shoulder_sacs_b7": "당신의 영향 받은 팔을 오랜 기간 동안 앞으로 내밀어 두는 난이도 수준을 평가하십시오.",
  "patient_shoulder_sacs_b7_note": "예시: 운전 중에 핸들을 잡고 있기",
  "patient_shoulder_sacs_b7_a1": "어려움 없음",
  "patient_shoulder_sacs_b7_a2": "극도로 어려움이 있거나 수행할 수 없음",
  "patient_shoulder_sacs_b8": "일상적인 직업 수행에서 경험하는 난이도 수준을 평가하십시오.",
  "patient_shoulder_sacs_b8_a1": "어려움 없음",
  "patient_shoulder_sacs_b8_a2": "극도로 어려움이 있거나 수행할 수 없음",
  "patient_shoulder_sacs_b8_a3": "해당 없음/적용되지 않음",
  "patient_shoulder_sacs_b9": "어깨 문제를 보상하기 위해 일반적인 직업을 얼마나 많이 변경/수정해야 했는지 평가하십시오.",
  "patient_shoulder_sacs_b9_a1": "어려움 없음",
  "patient_shoulder_sacs_b9_a2": "극도로 어려움이 있거나 수행할 수 없음",
  "patient_shoulder_sacs_b9_a3": "해당 없음/적용되지 않음",
  "patient_shoulder_sacs_b10": "당신이 주요 스포츠 활동을 수행하는 데 어려움을 겪는 난이도를 평가해주세요.",
  "patient_shoulder_sacs_b10_a1": "어려움 없음",
  "patient_shoulder_sacs_b10_a2": "극도로 어려움이 있거나 수행할 수 없음",
  "patient_shoulder_sacs_b10_a3": "해당 없음/적용되지 않음",
  "patient_shoulder_sacs_b11": "어깨 문제를 보상하기 위해 주요 운동 활동을 얼마나 수정해야 했는지 평가해주세요.",
  "patient_shoulder_sacs_b11_a1": "어려움 없음",
  "patient_shoulder_sacs_b11_a2": "극도로 어려움이 있거나 수행할 수 없음",
  "patient_shoulder_sacs_b11_a3": "해당 없음/적용되지 않음",
  "patient_shoulder_sacs_sectionC": "섹션 C: 삶의 질과 만족도",
  "patient_shoulder_sacs_c1": "일반 어깨와 비교하여 어깨 꼭대기에 있는 뼈의 외모 차이 수준을 평가하십시오.",
  "patient_shoulder_sacs_c1_note": "덩어리의 존재를 포함하여",
  "patient_shoulder_sacs_c1_a1": "별반 다르지 않아",
  "patient_shoulder_sacs_c1_a2": "매우 다른",
  "patient_shoulder_sacs_c2": "어깨 문제로 인해 느껴지는 불안 또는 스트레스의 전반적인 수준을 평가해주세요.",
  "patient_shoulder_sacs_c2_a1": "불안 / 스트레스 없음",
  "patient_shoulder_sacs_c2_a2": "심각한 정도의 불안 / 스트레스",
  "patient_shoulder_sacs_c3": "당신은 어깨 문제에 대해 얼마나 자주 인식하나요?",
  "patient_shoulder_sacs_c3_a1": "전혀",
  "patient_shoulder_sacs_c3_a2": "끊임없이",
  "patient_shoulder_sacs_c4": "어깨 문제를 피하기 위해 삶의 방식을 변경하셨나요?",
  "patient_shoulder_sacs_c4_a1": "전혀 없다",
  "patient_shoulder_sacs_c4_a2": "완전히",
  "patient_shoulder_sacs_c5": "어깨에 대해 얼마나 불만족하시나요?",
  "patient_shoulder_sacs_c5_a1": "매우 행복합니다",
  "patient_shoulder_sacs_c5_a2": "매우 불행합니다",
  "patient_shoulder_sacs_c6": "정상' 어깨에 대한 기대치와 비교하면 자신의 어깨 상태를 전반적으로 백분율로 어떻게 평가하시겠습니까?(0%는 좋지 않음, 100%는 매우 좋음)",
  "patient_shoulder_satisfaction": "만족도",
  "patient_shoulder_satisfaction_q1": "영향을받는 어깨와 관련하여, 현재 귀하는:",
  "patient_shoulder_satisfaction_q1_a1": "불만족 및/또는 더 좋음",
  "patient_shoulder_satisfaction_q1_a2": "불만족 및/또는 더 나쁨",
  "patient_shoulder_complete": "설문 끝",
  "patient_shoulder_complete_text1": "시간을 내어 이 설문 조사를 작성해 주셔서 감사합니다.",
  "patient_shoulder_complete_text2": "제출을 클릭하여 완료하고 점수를 확인하십시오.",
  "patient_shoulder_complete_text3": "귀하의 답은 기밀로 취급되며 모든 보고서는 신원 식별되지 않은 정보만 사용합니다.",
  "patient_shoulder_results": "결과",
  "patient_shoulder_results_text1": "다음은 설문에서 계산되는 통증 및 어깨 기능 점수입니다.",
  "patient_shoulder_results_pain": "통증",
  "patient_shoulder_results_function": "기능",
  "patient_shoulder_results_instability": "불안정성",
  "patient_shoulder_results_selfevaluation": "어깨 점수 자체 평가",
  "patient_shoulder_results_text2": "곧 진료실로 호명되실 것입니다.",
  "patient_hip_pain": "통증",
  "patient_hip_pain_q1": "전반적으로, 지난 한 달 동안 평소 엉덩이 통증에 대해 어떻게 설명하시겠습니까?",
  "patient_hip_pain_q1_a1": "없음",
  "patient_hip_pain_q1_a2": "매우 가벼움",
  "patient_hip_pain_q1_a3": "약간",
  "patient_hip_pain_q1_a4": "보통",
  "patient_hip_pain_q1_a5": "심각",
  "patient_hip_pain_q2": "당신의 힙에 현재 얼마나 심한 통증을 느끼고 있나요? (0은 아무런 통증이 없음을 의미하고 10은 상상할 수 있는 가장 심한 통증을 의미합니다)",
  "patient_hip_pain_q2_a1": "아픔 없음",
  "patient_hip_pain_q2_a2": "가장 심한 통증",
  "patient_hip_pain_q3": "다음 중 통증을 가장 잘 설명하는 것은 무엇입니까?",
  "patient_hip_pain_q3_a1": "없음/무시",
  "patient_hip_pain_q3_a2": "약간, 가끔씩, 활동에 영향이 없음",
  "patient_hip_pain_q3_a3": "경미한 통증, 일반 활동에 영향 없음, 일상적인 활동 후 통증, 아스피린 사용",
  "patient_hip_pain_q3_a4": "보통한 통증, 견딜 수 있고, 활동 제한 있고, 가끔 코데인을 복용",
  "patient_hip_pain_q3_a5": "현저한 통증, 심각한 제한",
  "patient_hip_pain_q3_a6": "완전히 활동불능",
  "patient_hip_pain_q4": "엉덩이 통증 얼마나 자주 아픈가요?",
  "patient_hip_pain_q4_a1": "전혀",
  "patient_hip_pain_q4_a2": "매월",
  "patient_hip_pain_q4_a3": "매주",
  "patient_hip_pain_q4_a4": "매일",
  "patient_hip_pain_q4_a5": "항상",
  "patient_hip_pain_q5": "당신의 힙 통증이 일상 업무(가사 일 포함)에 얼마나 방해가 되었습니까?",
  "patient_hip_pain_q5_a1": "전혀 없다",
  "patient_hip_pain_q5_a2": "조금",
  "patient_hip_pain_q5_a3": "보통",
  "patient_hip_pain_q5_a4": "매우",
  "patient_hip_pain_q5_a5": "완전히",
  "patient_hip_pain_instruction": "다음 활동 중에 엉덩이 통증을 어느 정도 경험하셨나요?",
  "patient_hip_pain_instruction_0": "없음",
  "patient_hip_pain_instruction_1": "약간",
  "patient_hip_pain_instruction_2": "보통",
  "patient_hip_pain_instruction_3": "심각",
  "patient_hip_pain_instruction_4": "극단적인",
  "patient_hip_pain_note": "당신의 힙 활동과 관련된 다음 질문에 답해주세요.",
  "patient_hip_pain_q6": "힙을 완전히 펴기",
  "patient_hip_pain_q6_a1": "없음",
  "patient_hip_pain_q6_a2": "약간",
  "patient_hip_pain_q6_a3": "보통",
  "patient_hip_pain_q6_a4": "심각",
  "patient_hip_pain_q6_a5": "극단적인",
  "patient_hip_pain_q7": "힙을 완전히 굽히기",
  "patient_hip_pain_q7_a1": "없음",
  "patient_hip_pain_q7_a2": "약간",
  "patient_hip_pain_q7_a3": "보통",
  "patient_hip_pain_q7_a4": "심각",
  "patient_hip_pain_q7_a5": "극단적인",
  "patient_hip_pain_q8": "평면 위를 걷기",
  "patient_hip_pain_q8_a1": "없음",
  "patient_hip_pain_q8_a2": "약간",
  "patient_hip_pain_q8_a3": "보통",
  "patient_hip_pain_q8_a4": "심각",
  "patient_hip_pain_q8_a5": "극단적인",
  "patient_hip_pain_q9": "힙 통증이 심해지기 전에 얼마나 오래 걸을 수 있나요? (지팡이를 사용하든 안 하든)",
  "patient_hip_pain_q9_a1": "통증 없음 / 30분 이상 / 시간 제한 없음",
  "patient_hip_pain_q9_a2": "16-30 분/6 블록",
  "patient_hip_pain_q9_a3": "5-15 분/2-3 블록",
  "patient_hip_pain_q9_a4": "집 주변/실내만",
  "patient_hip_pain_q9_a5": "전혀 걸을 수 없음/걸을 때 통증이 심함/침대와 의자만 있음",
  "patient_hip_pain_q10": "경사로를 걷는 것 (아스팔트 콘크리트 등)",
  "patient_hip_pain_q10_a1": "없음",
  "patient_hip_pain_q10_a2": "약간",
  "patient_hip_pain_q10_a3": "보통",
  "patient_hip_pain_q10_a4": "심각",
  "patient_hip_pain_q10_a5": "극단적인",
  "patient_hip_pain_q11": "불규칙한 표면을 걷는 것",
  "patient_hip_pain_q11_a1": "없음",
  "patient_hip_pain_q11_a2": "약간",
  "patient_hip_pain_q11_a3": "보통",
  "patient_hip_pain_q11_a4": "심각",
  "patient_hip_pain_q11_a5": "극단적인",
  "patient_hip_pain_q12": "계단을 오르거나 내려가는 것",
  "patient_hip_pain_q12_a1": "없음",
  "patient_hip_pain_q12_a2": "약간",
  "patient_hip_pain_q12_a3": "보통",
  "patient_hip_pain_q12_a4": "심각",
  "patient_hip_pain_q12_a5": "극단적인",
  "patient_hip_pain_q13": "밤에 침대에서",
  "patient_hip_pain_q13_a1": "없음",
  "patient_hip_pain_q13_a2": "약간",
  "patient_hip_pain_q13_a3": "보통",
  "patient_hip_pain_q13_a4": "심각",
  "patient_hip_pain_q13_a5": "극단적인",
  "patient_hip_pain_q14": "밤에 침대에서 힙 통증으로 통증받은 적이 얼마나 자주 있었나요?",
  "patient_hip_pain_q14_a1": "없음",
  "patient_hip_pain_q14_a2": "한두밤",
  "patient_hip_pain_q14_a3": "몇몇 밤들",
  "patient_hip_pain_q14_a4": "대부분의 밤들",
  "patient_hip_pain_q14_a5": "매일 밤",
  "patient_hip_pain_q15": "앉거나 누워있을 때",
  "patient_hip_pain_q15_a1": "없음",
  "patient_hip_pain_q15_a2": "약간",
  "patient_hip_pain_q15_a3": "보통",
  "patient_hip_pain_q15_a4": "심각",
  "patient_hip_pain_q15_a5": "극단적인",
  "patient_hip_pain_q16": "기립 상태",
  "patient_hip_pain_q16_a1": "없음",
  "patient_hip_pain_q16_a2": "약간",
  "patient_hip_pain_q16_a3": "보통",
  "patient_hip_pain_q16_a4": "심각",
  "patient_hip_pain_q16_a5": "극단적인",
  "patient_hip_pain_q17": "의자에서 일어날 때",
  "patient_hip_pain_q17_a1": "없음",
  "patient_hip_pain_q17_a2": "약간",
  "patient_hip_pain_q17_a3": "보통",
  "patient_hip_pain_q17_a4": "심각",
  "patient_hip_pain_q17_a5": "극단적인",
  "patient_hip_pain_q18": "활동 후",
  "patient_hip_pain_q18_a1": "없음",
  "patient_hip_pain_q18_a2": "약간",
  "patient_hip_pain_q18_a3": "보통",
  "patient_hip_pain_q18_a4": "심각",
  "patient_hip_pain_q18_a5": "극단적인",
  "patient_hip_other_symptoms": "다른 증상들",
  "patient_hip_other_symptoms_instruction1": "고관절 증상에 가장 맞는 답을 선택하십시오.",
  "patient_hip_other_symptoms_instruction2": "질문에 어떻게 답해야 할지 잘 모르겠다면 가능한 최선의 답을 제공해 주세요.",
  "patient_hip_other_symptoms_instruction3": "다음을 가지고 있습니까?",
  "patient_hip_other_symptoms_instruction_0": "없음",
  "patient_hip_other_symptoms_instruction_1": "약간",
  "patient_hip_other_symptoms_instruction_2": "보통",
  "patient_hip_other_symptoms_instruction_3": "심각",
  "patient_hip_other_symptoms_instruction_4": "극단적인",
  "patient_hip_other_symptoms_q1": "엉덩이에서 삐걱거리는 소리, 딸깍거리는 소리 또는 기타 유형의 소음",
  "patient_hip_other_symptoms_q1_a1": "전혀",
  "patient_hip_other_symptoms_q1_a2": "드물게",
  "patient_hip_other_symptoms_q1_a3": "가끔",
  "patient_hip_other_symptoms_q1_a4": "자주",
  "patient_hip_other_symptoms_q1_a5": "항상",
  "patient_hip_other_symptoms_q2": "엉덩이가 걸리거나 움직일 수 없는 느낌",
  "patient_hip_other_symptoms_q2_a1": "없음",
  "patient_hip_other_symptoms_q2_a2": "약간",
  "patient_hip_other_symptoms_q2_a3": "보통",
  "patient_hip_other_symptoms_q2_a4": "심각",
  "patient_hip_other_symptoms_q2_a5": "극단적인",
  "patient_hip_other_symptoms_q3": "아침에 처음 일어난 후의 뻣뻣함",
  "patient_hip_other_symptoms_q3_a1": "없음",
  "patient_hip_other_symptoms_q3_a2": "약간",
  "patient_hip_other_symptoms_q3_a3": "보통",
  "patient_hip_other_symptoms_q3_a4": "심각",
  "patient_hip_other_symptoms_q3_a5": "극단적인",
  "patient_hip_other_symptoms_q4": "하루가 저물고 앉거나 누워서 쉰 후에 뻣뻣함",
  "patient_hip_other_symptoms_q4_a1": "없음",
  "patient_hip_other_symptoms_q4_a2": "약간",
  "patient_hip_other_symptoms_q4_a3": "보통",
  "patient_hip_other_symptoms_q4_a4": "심각",
  "patient_hip_other_symptoms_q4_a5": "극단적인",
  "patient_hip_other_symptoms_q5": "절뚝거리기",
  "patient_hip_other_symptoms_q5_a1": "드물게/ 전혀 없다",
  "patient_hip_other_symptoms_q5_a2": "약간, 가끔 또는 처음에는",
  "patient_hip_other_symptoms_q5_a3": "처음뿐만 아니라 중간정도, 자주",
  "patient_hip_other_symptoms_q5_a4": "심각, 대부분의 시간",
  "patient_hip_other_symptoms_q5_a5": "항상 또는 걸을 수 없",
  "patient_hip_other_symptoms_q6": "갑작스럽고 심한 통증-엉덩이에서 '쏘는', '찌르는' 또는 '경련하는'것이 발생합니다.",
  "patient_hip_other_symptoms_q6_a1": "없다",
  "patient_hip_other_symptoms_q6_a2": "단 하루 또는 이틀만",
  "patient_hip_other_symptoms_q6_a3": "가끔",
  "patient_hip_other_symptoms_q6_a4": "대부분의 날들",
  "patient_hip_other_symptoms_q6_a5": "매일",
  "patient_hip_other_symptoms_q7": "힙의 힘이 빠지는 기분",
  "patient_hip_other_symptoms_q7_a1": "없음",
  "patient_hip_other_symptoms_q7_a2": "약간",
  "patient_hip_other_symptoms_q7_a3": "보통",
  "patient_hip_other_symptoms_q7_a4": "심각",
  "patient_hip_other_symptoms_q7_a5": "극단적인",
  "patient_hip_other_symptoms_q8": "움직임의 감소",
  "patient_hip_other_symptoms_q8_a1": "없음",
  "patient_hip_other_symptoms_q8_a2": "약간",
  "patient_hip_other_symptoms_q8_a3": "보통",
  "patient_hip_other_symptoms_q8_a4": "심각",
  "patient_hip_other_symptoms_q8_a5": "극단적인",
  "patient_hip_other_symptoms_q9": "걷기를 할 때 어떤 보장구를 사용하시나요?",
  "patient_hip_other_symptoms_q9_a1": "없음",
  "patient_hip_other_symptoms_q9_a2": "장거리 산책을 위한 지팡이",
  "patient_hip_other_symptoms_q9_a3": "대부분의 시간에 지팡이 사용",
  "patient_hip_other_symptoms_q9_a4": "하나의 목발",
  "patient_hip_other_symptoms_q9_a5": "두 개의 지팡이",
  "patient_hip_other_symptoms_q9_a6": "두 개의 목발이거나 걷지 못함",
  "patient_hip_function": "기능",
  "patient_hip_function_instruction": "지난 주에 힙으로 인해 경험한 어려움의 정도를 나타내십시오.",
  "patient_hip_function_instruction_0": "없음",
  "patient_hip_function_instruction_1": "약간",
  "patient_hip_function_instruction_2": "보통",
  "patient_hip_function_instruction_3": "심각",
  "patient_hip_function_instruction_4": "극도로/할 수 없다",
  "patient_hip_function_note": "만약 특정 활동을 하지 않는다면, 시도해봐야 하는 상황에서 당신의 힙의 어떻게 느껴질지 상상해보세요.",
  "patient_hip_function_q1": "계단 내려가기",
  "patient_hip_function_q1_a1": "없음",
  "patient_hip_function_q1_a2": "약간",
  "patient_hip_function_q1_a3": "보통",
  "patient_hip_function_q1_a4": "심각",
  "patient_hip_function_q1_a5": "극도로/할 수 없다",
  "patient_hip_function_q2": "계단 오르기",
  "patient_hip_function_q2_a1": "없음",
  "patient_hip_function_q2_a2": "약간",
  "patient_hip_function_q2_a3": "보통",
  "patient_hip_function_q2_a4": "심각",
  "patient_hip_function_q2_a5": "극도로/할 수 없다",
  "patient_hip_function_q3": "앉아 있기",
  "patient_hip_function_q3_a1": "없음",
  "patient_hip_function_q3_a2": "약간",
  "patient_hip_function_q3_a3": "보통",
  "patient_hip_function_q3_a4": "심각",
  "patient_hip_function_q3_a5": "극도로/할 수 없다",
  "patient_hip_function_q4": "앉아서 일어나기",
  "patient_hip_function_q4_a1": "없음",
  "patient_hip_function_q4_a2": "약간",
  "patient_hip_function_q4_a3": "보통",
  "patient_hip_function_q4_a4": "심각",
  "patient_hip_function_q4_a5": "극도로/할 수 없다",
  "patient_hip_function_q5": "바닥에서 일어나고 앉기",
  "patient_hip_function_q5_a1": "없음",
  "patient_hip_function_q5_a2": "약간",
  "patient_hip_function_q5_a3": "보통",
  "patient_hip_function_q5_a4": "심각",
  "patient_hip_function_q5_a5": "극도로/할 수 없다",
  "patient_hip_function_q6": "서 있기",
  "patient_hip_function_q6_a1": "없음",
  "patient_hip_function_q6_a2": "약간",
  "patient_hip_function_q6_a3": "보통",
  "patient_hip_function_q6_a4": "심각",
  "patient_hip_function_q6_a5": "극도로/할 수 없다",
  "patient_hip_function_q7": "15분 동안 서 있기",
  "patient_hip_function_q7_a1": "없음",
  "patient_hip_function_q7_a2": "약간",
  "patient_hip_function_q7_a3": "보통",
  "patient_hip_function_q7_a4": "심각",
  "patient_hip_function_q7_a5": "극도로/할 수 없다",
  "patient_hip_function_q8": "다리를 넓게 벌리기",
  "patient_hip_function_q8_a1": "없음",
  "patient_hip_function_q8_a2": "약간",
  "patient_hip_function_q8_a3": "보통",
  "patient_hip_function_q8_a4": "심각",
  "patient_hip_function_q8_a5": "극도로/할 수 없다",
  "patient_hip_function_q9": "걷는 동안 한 발씩 큰 보폭으로 걷기",
  "patient_hip_function_q9_a1": "없음",
  "patient_hip_function_q9_a2": "약간",
  "patient_hip_function_q9_a3": "보통",
  "patient_hip_function_q9_a4": "심각",
  "patient_hip_function_q9_a5": "극도로/할 수 없다",
  "patient_hip_function_q10": "바닥으로 숙이기/물건을 집기",
  "patient_hip_function_q10_a1": "없음",
  "patient_hip_function_q10_a2": "약간",
  "patient_hip_function_q10_a3": "보통",
  "patient_hip_function_q10_a4": "심각",
  "patient_hip_function_q10_a5": "극도로/할 수 없다",
  "patient_hip_function_q11": "평면 위를 걷기",
  "patient_hip_function_q11_a1": "없음",
  "patient_hip_function_q11_a2": "약간",
  "patient_hip_function_q11_a3": "보통",
  "patient_hip_function_q11_a4": "심각",
  "patient_hip_function_q11_a5": "극도로/할 수 없다",
  "patient_hip_function_q12": "고르지 않은 표면을 걷기",
  "patient_hip_function_q12_a1": "없음",
  "patient_hip_function_q12_a2": "약간",
  "patient_hip_function_q12_a3": "보통",
  "patient_hip_function_q12_a4": "심각",
  "patient_hip_function_q12_a5": "극도로/할 수 없다",
  "patient_hip_function_q13": "긴 거리를 걷기",
  "patient_hip_function_q13_a1": "없음",
  "patient_hip_function_q13_a2": "약간",
  "patient_hip_function_q13_a3": "보통",
  "patient_hip_function_q13_a4": "심각",
  "patient_hip_function_q13_a5": "극도로/할 수 없다",
  "patient_hip_function_q14": "운동을 위한 걷기",
  "patient_hip_function_q14_a1": "없음",
  "patient_hip_function_q14_a2": "약간",
  "patient_hip_function_q14_a3": "보통",
  "patient_hip_function_q14_a4": "심각",
  "patient_hip_function_q14_a5": "극도로/할 수 없다",
  "patient_hip_function_q15": "가파른 언덕을 오르기",
  "patient_hip_function_q15_a1": "없음",
  "patient_hip_function_q15_a2": "약간",
  "patient_hip_function_q15_a3": "보통",
  "patient_hip_function_q15_a4": "심각",
  "patient_hip_function_q15_a5": "극도로/할 수 없다",
  "patient_hip_function_q16": "가파른 언덕을 걷는 것",
  "patient_hip_function_q16_a1": "없음",
  "patient_hip_function_q16_a2": "약간",
  "patient_hip_function_q16_a3": "보통",
  "patient_hip_function_q16_a4": "심각",
  "patient_hip_function_q16_a5": "극도로/할 수 없다",
  "patient_hip_function_q17": "보행 초기",
  "patient_hip_function_q17_a1": "없음",
  "patient_hip_function_q17_a2": "약간",
  "patient_hip_function_q17_a3": "보통",
  "patient_hip_function_q17_a4": "심각",
  "patient_hip_function_q17_a5": "극도로/할 수 없다",
  "patient_hip_function_q18": "약 10분 도보",
  "patient_hip_function_q18_a1": "없음",
  "patient_hip_function_q18_a2": "약간",
  "patient_hip_function_q18_a3": "보통",
  "patient_hip_function_q18_a4": "심각",
  "patient_hip_function_q18_a5": "극도로/할 수 없다",
  "patient_hip_function_q19": "15분 이상 걷기",
  "patient_hip_function_q19_a1": "없음",
  "patient_hip_function_q19_a2": "약간",
  "patient_hip_function_q19_a3": "보통",
  "patient_hip_function_q19_a4": "심각",
  "patient_hip_function_q19_a5": "극도로/할 수 없다",
  "patient_hip_function_q20": "경사로를 오르내리기",
  "patient_hip_function_q20_a1": "없음",
  "patient_hip_function_q20_a2": "약간",
  "patient_hip_function_q20_a3": "보통",
  "patient_hip_function_q20_a4": "심각",
  "patient_hip_function_q20_a5": "극도로/할 수 없다",
  "patient_hip_function_q21": "차나 대중교통(버스)을 이용할 때 내리거나 타는 것, 어느 쪽이든 사용하는 것에 따라",
  "patient_hip_function_q21_a1": "없음",
  "patient_hip_function_q21_a2": "약간",
  "patient_hip_function_q21_a3": "보통",
  "patient_hip_function_q21_a4": "심각",
  "patient_hip_function_q21_a5": "극도로/할 수 없다",
  "patient_hip_function_q22": "쇼핑하기",
  "patient_hip_function_q22_a1": "없음",
  "patient_hip_function_q22_a2": "약간",
  "patient_hip_function_q22_a3": "보통",
  "patient_hip_function_q22_a4": "심각",
  "patient_hip_function_q22_a5": "극도로/할 수 없다",
  "patient_hip_function_q23": "양말/스타킹을 착용",
  "patient_hip_function_q23_a1": "없음",
  "patient_hip_function_q23_a2": "약간",
  "patient_hip_function_q23_a3": "보통",
  "patient_hip_function_q23_a4": "심각",
  "patient_hip_function_q23_a5": "극도로/할 수 없다",
  "patient_hip_function_q24": "양말/스타킹을 벗는다",
  "patient_hip_function_q24_a1": "없음",
  "patient_hip_function_q24_a2": "약간",
  "patient_hip_function_q24_a3": "보통",
  "patient_hip_function_q24_a4": "심각",
  "patient_hip_function_q24_a5": "극도로/할 수 없다",
  "patient_hip_function_q25": "침대에 누워(뒤집기, 엉덩이 자세 유지)",
  "patient_hip_function_q25_a1": "없음",
  "patient_hip_function_q25_a2": "약간",
  "patient_hip_function_q25_a3": "보통",
  "patient_hip_function_q25_a4": "심각",
  "patient_hip_function_q25_a5": "극도로/할 수 없다",
  "patient_hip_function_q26": "침대에서 돌아눕기",
  "patient_hip_function_q26_a1": "없음",
  "patient_hip_function_q26_a2": "약간",
  "patient_hip_function_q26_a3": "보통",
  "patient_hip_function_q26_a4": "심각",
  "patient_hip_function_q26_a5": "극도로/할 수 없다",
  "patient_hip_function_q27": "침대에서 일어나기",
  "patient_hip_function_q27_a1": "없음",
  "patient_hip_function_q27_a2": "약간",
  "patient_hip_function_q27_a3": "보통",
  "patient_hip_function_q27_a4": "심각",
  "patient_hip_function_q27_a5": "극도로/할 수 없다",
  "patient_hip_function_q28": "욕조에 들어가고 나오기",
  "patient_hip_function_q28_a1": "없음",
  "patient_hip_function_q28_a2": "약간",
  "patient_hip_function_q28_a3": "보통",
  "patient_hip_function_q28_a4": "심각",
  "patient_hip_function_q28_a5": "극도로/할 수 없다",
  "patient_hip_function_q29": "변기 타기 및 내리기",
  "patient_hip_function_q29_a1": "없음",
  "patient_hip_function_q29_a2": "약간",
  "patient_hip_function_q29_a3": "보통",
  "patient_hip_function_q29_a4": "심각",
  "patient_hip_function_q29_a5": "극도로/할 수 없다",
  "patient_hip_function_q30": "몸 전체를 씻고 말리기",
  "patient_hip_function_q30_a1": "없음",
  "patient_hip_function_q30_a2": "약간",
  "patient_hip_function_q30_a3": "보통",
  "patient_hip_function_q30_a4": "심각",
  "patient_hip_function_q30_a5": "극도로/할 수 없다",
  "patient_hip_function_q31": "가벼운~중간 정도의 작업 (서 있기, 걷기)",
  "patient_hip_function_q31_a1": "없음",
  "patient_hip_function_q31_a2": "약간",
  "patient_hip_function_q31_a3": "보통",
  "patient_hip_function_q31_a4": "심각",
  "patient_hip_function_q31_a5": "극도로/할 수 없다",
  "patient_hip_function_q32": "무거운 가사 (무거운 상자 옮기기, 바닥 닦기 등)",
  "patient_hip_function_q32_a1": "없음",
  "patient_hip_function_q32_a2": "약간",
  "patient_hip_function_q32_a3": "보통",
  "patient_hip_function_q32_a4": "심각",
  "patient_hip_function_q32_a5": "극도로/할 수 없다",
  "patient_hip_function_q33": "가벼운 집안일(요리, 청소 등)",
  "patient_hip_function_q33_a1": "없음",
  "patient_hip_function_q33_a2": "약간",
  "patient_hip_function_q33_a3": "보통",
  "patient_hip_function_q33_a4": "심각",
  "patient_hip_function_q33_a5": "극도로/할 수 없다",
  "patient_hip_function_q34": "아이를 들거나 옮기기",
  "patient_hip_function_q34_a1": "없음",
  "patient_hip_function_q34_a2": "약간",
  "patient_hip_function_q34_a3": "보통",
  "patient_hip_function_q34_a4": "심각",
  "patient_hip_function_q34_a5": "극도로/할 수 없다",
  "patient_hip_function_q35": "스쿼팅",
  "patient_hip_function_q35_a1": "없음",
  "patient_hip_function_q35_a2": "약간",
  "patient_hip_function_q35_a3": "보통",
  "patient_hip_function_q35_a4": "심각",
  "patient_hip_function_q35_a5": "극도로/할 수 없다",
  "patient_hip_function_q36": "운동을 위한 조깅",
  "patient_hip_function_q36_a1": "없음",
  "patient_hip_function_q36_a2": "약간",
  "patient_hip_function_q36_a3": "보통",
  "patient_hip_function_q36_a4": "심각",
  "patient_hip_function_q36_a5": "극도로/할 수 없다",
  "patient_hip_function_q37": "달리기",
  "patient_hip_function_q37_a1": "없음",
  "patient_hip_function_q37_a2": "약간",
  "patient_hip_function_q37_a3": "보통",
  "patient_hip_function_q37_a4": "심각",
  "patient_hip_function_q37_a5": "극도로/할 수 없다",
  "patient_hip_function_q38": "부하가 실린 다리로 비틀거리기/회전하기",
  "patient_hip_function_q38_a1": "없음",
  "patient_hip_function_q38_a2": "약간",
  "patient_hip_function_q38_a3": "보통",
  "patient_hip_function_q38_a4": "심각",
  "patient_hip_function_q38_a5": "극도로/할 수 없다",
  "patient_hip_function_q39": "성적 활동",
  "patient_hip_function_q39_a1": "없음",
  "patient_hip_function_q39_a2": "약간",
  "patient_hip_function_q39_a3": "보통",
  "patient_hip_function_q39_a4": "심각",
  "patient_hip_function_q39_a5": "극도로/할 수 없다",
  "patient_hip_function_q39_not_relevant": "이것은 나에게 관련이 없습니다.",
  "patient_hip_function_q40": "추격이나 커팅이 포함된 인기 있는 스포츠(예: 축구, 농구, 테니스, 유산소 운동)",
  "patient_hip_function_q40_a1": "없음",
  "patient_hip_function_q40_a2": "약간",
  "patient_hip_function_q40_a3": "보통",
  "patient_hip_function_q40_a4": "심각",
  "patient_hip_function_q40_a5": "극도로/할 수 없다",
  "patient_hip_function_q41": "저부하 스포츠 (예: 골프, 볼링)",
  "patient_hip_function_q41_a1": "없음",
  "patient_hip_function_q41_a2": "약간",
  "patient_hip_function_q41_a3": "보통",
  "patient_hip_function_q41_a4": "심각",
  "patient_hip_function_q41_a5": "극도로/할 수 없다",
  "patient_hip_function_q42": "여가 활동",
  "patient_hip_function_q42_a1": "없음",
  "patient_hip_function_q42_a2": "약간",
  "patient_hip_function_q42_a3": "보통",
  "patient_hip_function_q42_a4": "심각",
  "patient_hip_function_q42_a5": "극도로/할 수 없다",
  "patient_hip_function_q43": "오늘 당신의 힙을 정상의 백분율로 평가하면 어떻게 됩니까? (0%에서 100%까지, 100%가 정상입니다)",
  "patient_hip_function_q44": "참여할 수 있는 최고 수준의 활동을 선택하세요",
  "patient_hip_function_q44_q1": "엉덩이 부상/엉덩이 문제 이전",
  "patient_hip_function_q44_q1_a1": "경쟁성 있는 활동",
  "patient_hip_function_q44_q1_a2": "레크리에이션, 신체 단련",
  "patient_hip_function_q44_q1_a3": "스포츠하지 않고 일만 한다",
  "patient_hip_function_q44_q1_a4": "일상생활 활동 만",
  "patient_hip_function_q44_q1_a5": "건강 문제로 병가 또는 장애 연금",
  "patient_hip_function_q44_q1_a1_o1": "경쟁 스포츠, 세계/올림픽 수준 또는 프로급",
  "patient_hip_function_q44_q1_a1_o2": "육상 및 필드, 라켓/공 스포츠, 체조, 조정, 스키 또는 무술과 같은 경쟁 스포츠, 국가나 국제급",
  "patient_hip_function_q44_q1_a1_o3": "수상 스포츠, 사이클링, 하키, 컬링 또는 승마 타기/로데오와 같은 경쟁 스포츠, 국가나 국제급",
  "patient_hip_function_q44_q1_a2_o1": "달리기, 공/라켓 스포츠, 웨이트 트레이닝, 컬링, 조정, 골프, 요가 또는 체조와 같은 레크리에이션 스포츠, 주 5회 이상",
  "patient_hip_function_q44_q1_a2_o2": "수영, 수상 스포츠, 스키, 하키, 롤러 블레이드, 자전거 타기, 승마 또는 등산과 같은 레크리에이션 스포츠, 주 5회 이상",
  "patient_hip_function_q44_q1_a2_o3": "레크리에이션 스포츠, 적어도 매주 2 회",
  "patient_hip_function_q44_q1_a2_o4": "레크리에이션 스포츠 주 1 회 또는 그 이하",
  "patient_hip_function_q44_q1_a2_o5": "레크리에이션 스포츠, 월 1 회 이하",
  "patient_hip_function_q44_q1_a3_o1": "건축과 같은 무거운 노동",
  "patient_hip_function_q44_q1_a3_o2": "트럭 운전과 같은 중간 정도의 중노동",
  "patient_hip_function_q44_q1_a3_o3": "간호와 같은 가벼운 노동",
  "patient_hip_function_q44_q1_a3_o4": "비서 또는 컴퓨터 작업과 같은 앉아있는 직업",
  "patient_hip_function_q44_q1_a4_o1": "정원 가꾸기, 여러 층의 계단 오르기, 짐 나르기, 짐 밀기/당기기, 지각 시 달리기 등의 일상 활동",
  "patient_hip_function_q44_q1_a4_o2": "집안 청소, 계단 1~2층 오르기, 가벼운 짐을 들고 걷기 등의 일상 활동",
  "patient_hip_function_q44_q1_a4_o3": "일상 활동 제한(예: 계단 이용 불가, 짐 운반 불가)",
  "patient_hip_function_q44_q2": "현재 상태",
  "patient_hip_function_q44_q2_a1": "경쟁성 있는 활동",
  "patient_hip_function_q44_q2_a2": "레크리에이션적 신체 단련",
  "patient_hip_function_q44_q2_a3": "스포츠하지 않고 일만 한다",
  "patient_hip_function_q44_q2_a4": "일상생활 활동 만",
  "patient_hip_function_q44_q2_a5": "건강 문제로 병가 또는 장애 연금",
  "patient_hip_function_q44_q2_a1_o1": "경쟁 스포츠, 세계/올림픽 수준 또는 프로급",
  "patient_hip_function_q44_q2_a1_o2": "육상 및 필드, 라켓/공 스포츠, 체조, 조정, 스키 또는 무술과 같은 경쟁 스포츠, 국가나 국제급",
  "patient_hip_function_q44_q2_a1_o3": "수상 스포츠, 사이클링, 하키, 컬링 또는 승마 타기/로데오와 같은 경쟁 스포츠, 국가나 국제급",
  "patient_hip_function_q44_q2_a2_o1": "달리기, 공/라켓 스포츠, 웨이트 트레이닝, 컬링, 조정, 골프, 요가 또는 체조와 같은 레크리에이션 스포츠, 주 5회 이상",
  "patient_hip_function_q44_q2_a2_o2": "수영, 수상 스포츠, 스키, 하키, 롤러 블레이드, 자전거 타기, 승마 또는 등산과 같은 레크리에이션 스포츠, 주 5회 이상",
  "patient_hip_function_q44_q2_a2_o3": "레크리에이션 스포츠, 적어도 매주 2 회",
  "patient_hip_function_q44_q2_a2_o4": "레크리에이션 스포츠 주 1 회 또는 그 이하",
  "patient_hip_function_q44_q2_a2_o5": "레크리에이션 스포츠, 월 1 회 이하",
  "patient_hip_function_q44_q2_a3_o1": "건축과 같은 무거운 노동",
  "patient_hip_function_q44_q2_a3_o2": "트럭 운전과 같은 중간 정도의 중노동",
  "patient_hip_function_q44_q2_a3_o3": "간호와 같은 가벼운 노동",
  "patient_hip_function_q44_q2_a3_o4": "비서 또는 컴퓨터 작업과 같은 앉아있는 직업",
  "patient_hip_function_q44_q2_a4_o1": "정원 가꾸기, 여러 층의 계단 오르기, 짐 나르기, 짐 밀기/당기기, 지각 시 달리기 등의 일상 활동",
  "patient_hip_function_q44_q2_a4_o2": "집안 청소, 계단 1~2층 오르기, 가벼운 짐을 들고 걷기 등의 일상 활동",
  "patient_hip_function_q44_q2_a4_o3": "일상 활동 제한(예: 계단 이용 불가, 짐 운반 불가)",
  "patient_hip_quality_of_life": "삶의 질",
  "patient_hip_quality_of_life_instruction": "다음은 엉덩이 문제와 관련하여 느낄 수 있는 사회적, 정서적, 생활 습관적 문제에 대한 질문입니다. 무릎과 관련하여 평소 어떤 느낌을 받았는지 생각해 보세요.",
  "patient_hip_quality_of_life_q1": "당신은 자신의 힙 문제를 얼마나 자주 인식하나요?",
  "patient_hip_quality_of_life_q1_a1": "전혀",
  "patient_hip_quality_of_life_q1_a2": "매월",
  "patient_hip_quality_of_life_q1_a3": "매주",
  "patient_hip_quality_of_life_q1_a4": "매일",
  "patient_hip_quality_of_life_q1_a5": "끊임없이",
  "patient_hip_quality_of_life_q2": "당신은 힙에 잠재적으로 손상을 줄 수 있는 활동을 피하기 위해 생활 방식을 수정했습니까?",
  "patient_hip_quality_of_life_q2_a1": "전혀 없다",
  "patient_hip_quality_of_life_q2_a2": "약간",
  "patient_hip_quality_of_life_q2_a3": "보통",
  "patient_hip_quality_of_life_q2_a4": "심하게",
  "patient_hip_quality_of_life_q2_a5": "완전히",
  "patient_hip_quality_of_life_q3": "당신이 원하는 체력 수준을 유지하는 능력에 대해 얼마나 우려하고 있습니까?",
  "patient_hip_quality_of_life_q3_a1": "전혀 걱정하지 않음",
  "patient_hip_quality_of_life_q3_a2": "매우 걱정",
  "patient_hip_quality_of_life_q4": "당신의 힙에 대한 자신감 부족으로 얼마나 괴로움을 느끼고 있나요?",
  "patient_hip_quality_of_life_q4_a1": "전혀 없다",
  "patient_hip_quality_of_life_q4_a2": "약간",
  "patient_hip_quality_of_life_q4_a3": "보통",
  "patient_hip_quality_of_life_q4_a4": "심하게",
  "patient_hip_quality_of_life_q4_a5": "극도로",
  "patient_hip_quality_of_life_q5": "당신의 힙 문제는 얼마나 방해가 됩니까?",
  "patient_hip_quality_of_life_q5_a1": "전혀 방해되지 않음",
  "patient_hip_quality_of_life_q5_a2": "극도의 산만함",
  "patient_hip_quality_of_life_q6": "일반적으로 엉덩이에 어느 정도 어려움이 있나요?",
  "patient_hip_quality_of_life_q6_a1": "없음",
  "patient_hip_quality_of_life_q6_a2": "약간",
  "patient_hip_quality_of_life_q6_a3": "보통",
  "patient_hip_quality_of_life_q6_a4": "심각",
  "patient_hip_quality_of_life_q6_a5": "극단적인",
  "patient_hip_quality_of_life_q7": "오늘 얼만큼 불안/우울 하십니까?",
  "patient_hip_quality_of_life_q7_a1": "전혀 없다",
  "patient_hip_quality_of_life_q7_a2": "약간",
  "patient_hip_quality_of_life_q7_a3": "보통",
  "patient_hip_quality_of_life_q7_a4": "심하게",
  "patient_hip_quality_of_life_q7_a5": "극도로",
  "patient_hip_quality_of_life_q8": "오늘의 건강은 얼마나 좋은가요?/나쁜가요? (100은 최고의 건강, 0은 상상할 수있는 최악의 건강을 의미합니다)",
  "patient_hip_quality_of_life_q8_a1": "최악의 건강",
  "patient_hip_quality_of_life_q8_a2": "최고의 건강",
  "patient_hip_satisfaction": "만족도",
  "patient_hip_satisfaction_instructions": "엉덩이 수술/치료 결과에 얼마나 만족하십니까?",
  "patient_hip_satisfaction_instructions_1": "매우 불만족한다",
  "patient_hip_satisfaction_instructions_2": "어느정도 불만족한다",
  "patient_hip_satisfaction_instructions_3": "어느정도 만족한다",
  "patient_hip_satisfaction_instructions_4": "매우 만족한다",
  "patient_hip_satisfaction_q1": "종합",
  "patient_hip_satisfaction_q1_not_applicable": "해당 사항 없음",
  "patient_hip_satisfaction_q1_a1": "매우 불만족한다",
  "patient_hip_satisfaction_q1_a2": "어느정도 불만족한다",
  "patient_hip_satisfaction_q1_a3": "어느정도 만족한다",
  "patient_hip_satisfaction_q1_a4": "매우 만족한다",
  "patient_hip_satisfaction_q2": "통증을 호전시키기 위해? (우측)",
  "patient_hip_satisfaction_q2_not_applicable": "해당 사항 없음",
  "patient_hip_satisfaction_q2_a1": "매우 불만족한다",
  "patient_hip_satisfaction_q2_a2": "어느정도 불만족한다",
  "patient_hip_satisfaction_q2_a3": "어느정도 만족한다",
  "patient_hip_satisfaction_q2_a4": "매우 만족한다",
  "patient_hip_satisfaction_q3": "집안일/마당일을 할 수 있도록?",
  "patient_hip_satisfaction_q3_not_applicable": "해당 사항 없음",
  "patient_hip_satisfaction_q3_a1": "매우 불만족한다",
  "patient_hip_satisfaction_q3_a2": "어느정도 불만족한다",
  "patient_hip_satisfaction_q3_a3": "어느정도 만족한다",
  "patient_hip_satisfaction_q3_a4": "매우 만족한다",
  "patient_hip_satisfaction_q4": "여가 활동 능력 향상을 위해?",
  "patient_hip_satisfaction_q4_not_applicable": "해당 사항 없음",
  "patient_hip_satisfaction_q4_a1": "매우 불만족한다",
  "patient_hip_satisfaction_q4_a2": "어느정도 불만족한다",
  "patient_hip_satisfaction_q4_a3": "어느정도 만족한다",
  "patient_hip_satisfaction_q4_a4": "매우 만족한다",
  "patient_hip_complete": "설문 끝",
  "patient_hip_complete_text1": "시간을 내어 이 설문 조사를 작성해 주셔서 감사합니다.",
  "patient_hip_complete_text2": "제출을 클릭하여 완료하고 점수를 확인하십시오.",
  "patient_hip_complete_text3": "귀하의 답은 기밀로 취급되며 모든 보고서는 신원 식별되지 않은 정보만 사용합니다.",
  "patient_hip_results": "결과",
  "patient_hip_results_text1": "다음은 설문대로 계산되는 통증과 엉덩이 기능 점수입니다.",
  "patient_hip_results_pain": "통증",
  "patient_hip_results_function": "기능",
  "patient_hip_results_health_perception": "전반적인 건강 인식",
  "patient_hip_results_self_evaluation": "엉덩이 점수 자체 평가",
  "patient_hip_results_text2": "곧 진료실로 호명되실 것입니다.",
  "": "",
  "patient_adult_knee_pain": "통증",
  "patient_adult_knee_pain_q1": "무릎 통증이 심하지 않으면 할 수 있는 최고 수준의 활동은 어느 정도인가요?",
  "patient_adult_knee_pain_q1_a1": "농구나 축구에서 점프나 회전과 같은 매우 격렬한 활동",
  "patient_adult_knee_pain_q1_a2": "무거운 신체 작업, 스키 또는 테니스와 같은 격렬한 활동",
  "patient_adult_knee_pain_q1_a3": "중간 강도의 신체 작업, 달리기 또는 조깅과 같은 적당한 활동",
  "patient_adult_knee_pain_q1_a4": "걷기, 집안일 또는 마당 작업과 같은 가벼운 활동",
  "patient_adult_knee_pain_q1_a5": "무릎 통증으로 인해 위의 활동을 수행 할 수 없다.",
  "patient_adult_knee_pain_q2": "얼마나 자주 통증을 느꼈습니까? (좌측)",
  "patient_adult_knee_pain_q2_a1": "전혀",
  "patient_adult_knee_pain_q2_a2": "매월",
  "patient_adult_knee_pain_q2_a3": "매주",
  "patient_adult_knee_pain_q2_a4": "매일",
  "patient_adult_knee_pain_q2_a5": "끊임없다",
  "patient_adult_knee_pain_q3": "다음 중 당신의 통증을 가장 잘 설명하는 것은 무엇입니까?",
  "patient_adult_knee_pain_q3_a1": "통증 없음, 정상 무릎, 100% 기능 발휘",
  "patient_adult_knee_pain_q3_a2": "격렬한 운동과 과중한 작업으로 인한 가끔 통증, 무릎이 완전히 정상이 아닌 경우, 약간의 제한이 있지만 경미하고 견딜 수 있다.",
  "patient_adult_knee_pain_q3_a3": "일반적으로 스포츠, 가벼운 레크리에이션 활동 또는 중간 정도의 작업으로 인해 통증이 발생합니다. 2km 이상 걷거나 서 있거나 가벼운 작업을 할 때 가끔 발생합니다.",
  "patient_adult_knee_pain_q3_a4": "가벼운 레크리에이션 스포츠 또는 적당한 업무 활동, 달리기 또는 중노동, 격렬한 스포츠를 할 때 가끔씩 통증이 발생한다.",
  "patient_adult_knee_pain_q3_a5": "통증은 2km 미만의 걷기와 같은 간단한 활동에서 심각한 문제이며, 휴식을 취하면 완화되며, 스포츠를 할 수 없다.",
  "patient_adult_knee_pain_q3_a6": "끊임없다",
  "patient_adult_knee_pain_q4": "통증이 있다면 얼마나 심한가요?",
  "patient_adult_knee_pain_q4_a1": "없음",
  "patient_adult_knee_pain_q4_a2": "매우 가벼움",
  "patient_adult_knee_pain_q4_a3": "약간",
  "patient_adult_knee_pain_q4_a4": "보통",
  "patient_adult_knee_pain_q4_a5": "심각",
  "patient_adult_knee_pain_q5": "(집안일을 포함하여) 무릎 통증이 일상적인 활동에 얼마나 지장을 주었습니까?",
  "patient_adult_knee_pain_q5_a1": "전혀 없다",
  "patient_adult_knee_pain_q5_a2": "조금",
  "patient_adult_knee_pain_q5_a3": "보통",
  "patient_adult_knee_pain_q5_a4": "매우",
  "patient_adult_knee_pain_q5_a5": "완전히",
  "patient_adult_knee_pain_instruction": "다음의 동작들을 수행할 때 무릎 통증이 얼마나 심했습니까?",
  "patient_adult_knee_pain_instruction_0": "없음",
  "patient_adult_knee_pain_instruction_1": "약간",
  "patient_adult_knee_pain_instruction_2": "보통",
  "patient_adult_knee_pain_instruction_3": "심각",
  "patient_adult_knee_pain_instruction_4": "극단적인",
  "patient_adult_knee_pain_q6": "무릎의 회전/축이동 (좌측)",
  "patient_adult_knee_pain_q6_a1": "없음",
  "patient_adult_knee_pain_q6_a2": "약간",
  "patient_adult_knee_pain_q6_a3": "보통",
  "patient_adult_knee_pain_q6_a4": "심각",
  "patient_adult_knee_pain_q6_a5": "극단적인",
  "patient_adult_knee_pain_q7": "무릎을 완전히 펴기",
  "patient_adult_knee_pain_q7_a1": "없음",
  "patient_adult_knee_pain_q7_a2": "약간",
  "patient_adult_knee_pain_q7_a3": "보통",
  "patient_adult_knee_pain_q7_a4": "심각",
  "patient_adult_knee_pain_q7_a5": "극단적인",
  "patient_adult_knee_pain_q8": "무릎을 완전히 굽히기",
  "patient_adult_knee_pain_q8_a1": "없음",
  "patient_adult_knee_pain_q8_a2": "약간",
  "patient_adult_knee_pain_q8_a3": "보통",
  "patient_adult_knee_pain_q8_a4": "심각",
  "patient_adult_knee_pain_q8_a5": "극단적인",
  "patient_adult_knee_pain_q9": "평면 위를 걷기",
  "patient_adult_knee_pain_q9_a1": "없음",
  "patient_adult_knee_pain_q9_a2": "약간",
  "patient_adult_knee_pain_q9_a3": "보통",
  "patient_adult_knee_pain_q9_a4": "심각",
  "patient_adult_knee_pain_q9_a5": "극단적인",
  "patient_adult_knee_pain_q10": "무릎 통증이 심해지기 전까지 얼마나 걸을 수 있습니까? (지팡이 유무에 관계 없이)",
  "patient_adult_knee_pain_q10_a1": "30 분 이상",
  "patient_adult_knee_pain_q10_a2": "16-30 분",
  "patient_adult_knee_pain_q10_a3": "5-15 minutes",
  "patient_adult_knee_pain_q10_a4": "집 주변에서만",
  "patient_adult_knee_pain_q10_a5": "전혀 걸을 수 없음/걸을 때 통증이 심함",
  "patient_adult_knee_pain_q11": "계단 오르내리기",
  "patient_adult_knee_pain_q11_a1": "없음",
  "patient_adult_knee_pain_q11_a2": "약간",
  "patient_adult_knee_pain_q11_a3": "보통",
  "patient_adult_knee_pain_q11_a4": "심각",
  "patient_adult_knee_pain_q11_a5": "극단적인",
  "patient_adult_knee_pain_q12": "저녁에 침상에서",
  "patient_adult_knee_pain_q12_a1": "없음",
  "patient_adult_knee_pain_q12_a2": "약간",
  "patient_adult_knee_pain_q12_a3": "보통",
  "patient_adult_knee_pain_q12_a4": "심각",
  "patient_adult_knee_pain_q12_a5": "극단적인",
  "patient_adult_knee_pain_q13": "무릎 통증으로 얼마나 자주 잠을 설쳤습니까?",
  "patient_adult_knee_pain_q13_a1": "없음",
  "patient_adult_knee_pain_q13_a2": "한두밤",
  "patient_adult_knee_pain_q13_a3": "몇몇 밤들",
  "patient_adult_knee_pain_q13_a4": "대부분의 밤들",
  "patient_adult_knee_pain_q13_a5": "매일 밤",
  "patient_adult_knee_pain_q14": "앉거나 누워있을 때",
  "patient_adult_knee_pain_q14_a1": "없음",
  "patient_adult_knee_pain_q14_a2": "약간",
  "patient_adult_knee_pain_q14_a3": "보통",
  "patient_adult_knee_pain_q14_a4": "심각",
  "patient_adult_knee_pain_q14_a5": "극단적인",
  "patient_adult_knee_pain_q15": "기립 상태",
  "patient_adult_knee_pain_q15_a1": "없음",
  "patient_adult_knee_pain_q15_a2": "약간",
  "patient_adult_knee_pain_q15_a3": "보통",
  "patient_adult_knee_pain_q15_a4": "심각",
  "patient_adult_knee_pain_q15_a5": "극단적인",
  "patient_adult_knee_pain_q16": "의자에서 일어날 때",
  "patient_adult_knee_pain_q16_a1": "없음",
  "patient_adult_knee_pain_q16_a2": "약간",
  "patient_adult_knee_pain_q16_a3": "보통",
  "patient_adult_knee_pain_q16_a4": "심각",
  "patient_adult_knee_pain_q16_a5": "극단적인",
  "patient_adult_knee_other_symptoms": "기타 증상",
  "patient_adult_knee_other_symptoms_instruction1": "무릎 증상에 가장 잘 맞는 답을 선택하십시오.",
  "patient_adult_knee_other_symptoms_instruction2": "질문에 어떻게 답해야 할지 잘 모르겠다면 가능한 최선의 답을 제공해 주세요.",
  "patient_adult_knee_other_symptoms_instruction3": "다음을 가지고 있습니까?",
  "patient_adult_knee_other_symptoms_instruction_0": "없음",
  "patient_adult_knee_other_symptoms_instruction_1": "약간",
  "patient_adult_knee_other_symptoms_instruction_2": "보통",
  "patient_adult_knee_other_symptoms_instruction_3": "심각",
  "patient_adult_knee_other_symptoms_instruction_4": "극단적인",
  "patient_adult_knee_other_symptoms_q1": "붓기",
  "patient_adult_knee_other_symptoms_q1_a1": "전혀",
  "patient_adult_knee_other_symptoms_q1_a2": "드물게",
  "patient_adult_knee_other_symptoms_q1_a3": "가끔",
  "patient_adult_knee_other_symptoms_q1_a4": "자주",
  "patient_adult_knee_other_symptoms_q1_a5": "항상",
  "patient_adult_knee_other_symptoms_q2": "무릎을 움직일 때 발생하는 갈리거나 걸리는 소리, 혹은 기타 다른 소리",
  "patient_adult_knee_other_symptoms_q2_a1": "전혀",
  "patient_adult_knee_other_symptoms_q2_a2": "드물게",
  "patient_adult_knee_other_symptoms_q2_a3": "가끔",
  "patient_adult_knee_other_symptoms_q2_a4": "자주",
  "patient_adult_knee_other_symptoms_q2_a5": "항상",
  "patient_adult_knee_other_symptoms_q3": "잠기는 증상",
  "patient_adult_knee_other_symptoms_q3_a1": "전혀",
  "patient_adult_knee_other_symptoms_q3_a2": "드물게",
  "patient_adult_knee_other_symptoms_q3_a3": "가끔",
  "patient_adult_knee_other_symptoms_q3_a4": "자주",
  "patient_adult_knee_other_symptoms_q3_a5": "항상",
  "patient_adult_knee_other_symptoms_q4": "절뚝거리기",
  "patient_adult_knee_other_symptoms_q4_a1": "드물게/전혀 없음",
  "patient_adult_knee_other_symptoms_q4_a2": "때때로, 또는 처음에는",
  "patient_adult_knee_other_symptoms_q4_a3": "처음에뿐만 아니라 자주",
  "patient_adult_knee_other_symptoms_q4_a4": "대부분의 경우",
  "patient_adult_knee_other_symptoms_q4_a5": "항상",
  "patient_adult_knee_other_symptoms_q5": "지난 4주 동안, 혹은 무릎을 다친 후, 무릎이 얼마나 굳거나 부어있었습니까?",
  "patient_adult_knee_other_symptoms_q5_a1": "전혀 없다",
  "patient_adult_knee_other_symptoms_q5_a2": "약간",
  "patient_adult_knee_other_symptoms_q5_a3": "보통",
  "patient_adult_knee_other_symptoms_q5_a4": "매우",
  "patient_adult_knee_other_symptoms_q5_a5": "극도로",
  "patient_adult_knee_other_symptoms_q6": "무릎이 심하게 붓지 않으면 수행할 수 있는 최고 수준의 활동은 어느 정도인가요?",
  "patient_adult_knee_other_symptoms_q6_a1": "농구나 축구에서 점프나 회전과 같은 매우 격렬한 활동",
  "patient_adult_knee_other_symptoms_q6_a2": "무거운 신체 작업, 스키 또는 테니스와 같은 격렬한 활동",
  "patient_adult_knee_other_symptoms_q6_a3": "중간 강도의 신체 작업, 달리기 또는 조깅과 같은 적당한 활동",
  "patient_adult_knee_other_symptoms_q6_a4": "걷기, 집안일 또는 마당 작업과 같은 가벼운 활동",
  "patient_adult_knee_other_symptoms_q6_a5": "무릎 부종으로 인해 위의 활동을 수행할 수 없다",
  "patient_adult_knee_other_symptoms_q7": "무릎이 얼마나 굳어있습니까?",
  "patient_adult_knee_other_symptoms_q7_0": "없음",
  "patient_adult_knee_other_symptoms_q7_1": "약간",
  "patient_adult_knee_other_symptoms_q7_2": "보통",
  "patient_adult_knee_other_symptoms_q7_3": "심각",
  "patient_adult_knee_other_symptoms_q7_4": "극단적인",
  "patient_adult_knee_other_symptoms_q7_q1": "아침에 일어난 후 바로",
  "patient_adult_knee_other_symptoms_q7_q1_a1": "없음",
  "patient_adult_knee_other_symptoms_q7_q1_a2": "약간",
  "patient_adult_knee_other_symptoms_q7_q1_a3": "보통",
  "patient_adult_knee_other_symptoms_q7_q1_a4": "심각",
  "patient_adult_knee_other_symptoms_q7_q1_a5": "극단적인",
  "patient_adult_knee_other_symptoms_q7_q2": "오후/저녁에 앉거나 눕거나 휴식 시",
  "patient_adult_knee_other_symptoms_q7_q2_a1": "없음",
  "patient_adult_knee_other_symptoms_q7_q2_a2": "약간",
  "patient_adult_knee_other_symptoms_q7_q2_a3": "보통",
  "patient_adult_knee_other_symptoms_q7_q2_a4": "심각",
  "patient_adult_knee_other_symptoms_q7_q2_a5": "극단적인",
  "patient_adult_knee_other_symptoms_q8": "무릎이 빠지는 느낌없이 수행할 수 있는 최대치의 활동",
  "patient_adult_knee_other_symptoms_q8_a1": "무릎 좌굴 없음",
  "patient_adult_knee_other_symptoms_q8_a2": "격렬한 운동이나 과중한 작업 시 가끔씩 좌굴할 수 있으며, 모든 스포츠에 참여할 수 있지만 일부 보호 또는 제한 사항이 존재합니다. 육상 또는 기타 격렬한 운동중에는 드물게",
  "patient_adult_knee_other_symptoms_q8_a3": "육상 또는 기타 격렬한 운동중에는 자주 발생. 가벼운 운동이나 중간 강도의 작업 시 가끔 좌굴할 수 있음. 보상은 가능하지만 격렬한 활동, 스포츠 또는 무거운 작업은 제한되며 갑자기 자르거나 비틀 수 없다.",
  "patient_adult_knee_other_symptoms_q8_a4": "좌굴하면 스포츠 또는 중간 정도의 작업이 제한되며, 걷기 또는 가벼운 작업에서 드물게 발생합니다(연간 약 3회). 일상 활동 중 가끔 발생",
  "patient_adult_knee_other_symptoms_q8_a5": "간단한 걷기 활동과 가벼운 작업으로 좌굴. 한 달에 한 번 발생하며 보호가 필요. 일상 활동에서 자주 발생",
  "patient_adult_knee_other_symptoms_q8_a6": "간단한 걷기 활동에도 심각한 문제. 모든 걸음에서 좌굴. 좌굴없이 걷는 동안 돌리거나 비틀 수 없음",
  "patient_adult_knee_other_symptoms_q9": "걷기를 할 때 어떤 보장구를 사용하시나요?",
  "patient_adult_knee_other_symptoms_q9_a1": "없음",
  "patient_adult_knee_other_symptoms_q9_a2": "스틱 또는 목발",
  "patient_adult_knee_other_symptoms_q9_a3": "체중을 지탱하는 것은 불가능",
  "patient_adult_knee_function": "기능",
  "patient_adult_knee_function_instruction": "지난 한 주 동안 무릎으로 인해 다음 활동에서 경험한 어려움의 정도를 나타내십시오.",
  "patient_adult_knee_function_instruction_0": "어려움 없음",
  "patient_adult_knee_function_instruction_1": "어려움이 조금 있다",
  "patient_adult_knee_function_instruction_2": "어려움이 어느정도 있다",
  "patient_adult_knee_function_instruction_3": "극도의 어려움",
  "patient_adult_knee_function_instruction_4": "할 수 없다",
  "patient_adult_knee_function_note": "참고: 특정 활동을 하지 않는다면, 그 활동을 해야 할 때 무릎이 어떻게 느껴질지 상상해 보세요.",
  "patient_adult_knee_function_q1": "계단을 올라간다",
  "patient_adult_knee_function_q1_a1": "어려움 없음",
  "patient_adult_knee_function_q1_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q1_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q1_a4": "극도의 어려움",
  "patient_adult_knee_function_q1_a5": "할 수 없다",
  "patient_adult_knee_function_q2": "계단 내려가기",
  "patient_adult_knee_function_q2_a1": "어려움 없음",
  "patient_adult_knee_function_q2_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q2_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q2_a4": "극도의 어려움",
  "patient_adult_knee_function_q2_a5": "할 수 없다",
  "patient_adult_knee_function_q3": "무릎 앞쪽으로 무릎 꿇기",
  "patient_adult_knee_function_q3_a1": "어려움 없음",
  "patient_adult_knee_function_q3_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q3_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q3_a4": "극도의 어려움",
  "patient_adult_knee_function_q3_a5": "할 수 없다",
  "patient_adult_knee_function_q4": "무릎 펴기",
  "patient_adult_knee_function_q4_a1": "어려움 없음",
  "patient_adult_knee_function_q4_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q4_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q4_a4": "극도의 어려움",
  "patient_adult_knee_function_q4_a5": "할 수 없다",
  "patient_adult_knee_function_q5": "무릎 굽히기",
  "patient_adult_knee_function_q5_a1": "어려움 없음",
  "patient_adult_knee_function_q5_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q5_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q5_a4": "극도의 어려움",
  "patient_adult_knee_function_q5_a5": "할 수 없다",
  "patient_adult_knee_function_q6": "스쿼팅",
  "patient_adult_knee_function_q6_a1": "어려움 없음",
  "patient_adult_knee_function_q6_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q6_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q6_a4": "극도의 어려움",
  "patient_adult_knee_function_q6_a5": "할 수 없다",
  "patient_adult_knee_function_q7": "무릎을 굽힌 채 앉기",
  "patient_adult_knee_function_q7_a1": "어려움 없음",
  "patient_adult_knee_function_q7_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q7_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q7_a4": "극도의 어려움",
  "patient_adult_knee_function_q7_a5": "할 수 없다",
  "patient_adult_knee_function_q8": "의자에서 일어나기",
  "patient_adult_knee_function_q8_a1": "어려움 없음",
  "patient_adult_knee_function_q8_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q8_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q8_a4": "극도의 어려움",
  "patient_adult_knee_function_q8_a5": "할 수 없다",
  "patient_adult_knee_function_q9": "침대에서 일어나기",
  "patient_adult_knee_function_q9_a1": "어려움 없음",
  "patient_adult_knee_function_q9_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q9_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q9_a4": "극도의 어려움",
  "patient_adult_knee_function_q9_a5": "할 수 없다",
  "patient_adult_knee_function_q10": "침대에 눕기 (무릎 자세를 유지한 채 돌아 눕기)",
  "patient_adult_knee_function_q10_a1": "어려움 없음",
  "patient_adult_knee_function_q10_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q10_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q10_a4": "극도의 어려움",
  "patient_adult_knee_function_q10_a5": "할 수 없다",
  "patient_adult_knee_function_q11": "바닥 위 물건을 짚기 위해 몸을 앞으로 구부리기",
  "patient_adult_knee_function_q11_a1": "어려움 없음",
  "patient_adult_knee_function_q11_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q11_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q11_a4": "극도의 어려움",
  "patient_adult_knee_function_q11_a5": "할 수 없다",
  "patient_adult_knee_function_q12": "양말/스타킹 신기",
  "patient_adult_knee_function_q12_a1": "어려움 없음",
  "patient_adult_knee_function_q12_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q12_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q12_a4": "극도의 어려움",
  "patient_adult_knee_function_q12_a5": "할 수 없다",
  "patient_adult_knee_function_q13": "양말/스타킹 벗기",
  "patient_adult_knee_function_q13_a1": "어려움 없음",
  "patient_adult_knee_function_q13_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q13_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q13_a4": "극도의 어려움",
  "patient_adult_knee_function_q13_a5": "할 수 없다",
  "patient_adult_knee_function_q14": "서 있기",
  "patient_adult_knee_function_q14_a1": "어려움 없음",
  "patient_adult_knee_function_q14_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q14_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q14_a4": "극도의 어려움",
  "patient_adult_knee_function_q14_a5": "할 수 없다",
  "patient_adult_knee_function_q15": "걷기",
  "patient_adult_knee_function_q15_a1": "어려움 없음",
  "patient_adult_knee_function_q15_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q15_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q15_a4": "극도의 어려움",
  "patient_adult_knee_function_q15_a5": "할 수 없다",
  "patient_adult_knee_function_q16": "앞서 똑바로 달리기",
  "patient_adult_knee_function_q16_a1": "어려움 없음",
  "patient_adult_knee_function_q16_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q16_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q16_a4": "극도의 어려움",
  "patient_adult_knee_function_q16_a5": "할 수 없다",
  "patient_adult_knee_function_q17": "영향을 받지 않은 다리로 점프하고 착지하기",
  "patient_adult_knee_function_q17_a1": "어려움 없음",
  "patient_adult_knee_function_q17_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q17_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q17_a4": "극도의 어려움",
  "patient_adult_knee_function_q17_a5": "할 수 없다",
  "patient_adult_knee_function_q18": "신속한 정지 및 시작",
  "patient_adult_knee_function_q18_a1": "어려움 없음",
  "patient_adult_knee_function_q18_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q18_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q18_a4": "극도의 어려움",
  "patient_adult_knee_function_q18_a5": "할 수 없다",
  "patient_adult_knee_function_q19": "다친 다리로 방향 전환/축이동하기",
  "patient_adult_knee_function_q19_a1": "어려움 없음",
  "patient_adult_knee_function_q19_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q19_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q19_a4": "극도의 어려움",
  "patient_adult_knee_function_q19_a5": "할 수 없다",
  "patient_adult_knee_function_q20": "대중 교통 타고 내리기",
  "patient_adult_knee_function_q20_a1": "어려움 없음",
  "patient_adult_knee_function_q20_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q20_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q20_a4": "극도의 어려움",
  "patient_adult_knee_function_q20_a5": "할 수 없다",
  "patient_adult_knee_function_q21": "침대에 오르고 내리기",
  "patient_adult_knee_function_q21_a1": "어려움 없음",
  "patient_adult_knee_function_q21_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q21_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q21_a4": "극도의 어려움",
  "patient_adult_knee_function_q21_a5": "할 수 없다",
  "patient_adult_knee_function_q22": "변기에 올라타기/내리기",
  "patient_adult_knee_function_q22_a1": "어려움 없음",
  "patient_adult_knee_function_q22_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q22_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q22_a4": "극도의 어려움",
  "patient_adult_knee_function_q22_a5": "할 수 없다",
  "patient_adult_knee_function_q23": "샤워하고 물기 말리기",
  "patient_adult_knee_function_q23_a1": "어려움 없음",
  "patient_adult_knee_function_q23_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q23_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q23_a4": "극도의 어려움",
  "patient_adult_knee_function_q23_a5": "할 수 없다",
  "patient_adult_knee_function_q24": "집안 용품 쇼핑하기",
  "patient_adult_knee_function_q24_a1": "어려움 없음",
  "patient_adult_knee_function_q24_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q24_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q24_a4": "극도의 어려움",
  "patient_adult_knee_function_q24_a5": "할 수 없다",
  "patient_adult_knee_function_q25": "무거운 집안일(무거운 상자 이동, 바닥 닦기 등)",
  "patient_adult_knee_function_q25_a1": "어려움 없음",
  "patient_adult_knee_function_q25_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q25_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q25_a4": "극도의 어려움",
  "patient_adult_knee_function_q25_a5": "할 수 없다",
  "patient_adult_knee_function_q26": "가벼운 집안일(요리, 청소 등)",
  "patient_adult_knee_function_q26_a1": "어려움 없음",
  "patient_adult_knee_function_q26_a2": "어려움이 조금 있다",
  "patient_adult_knee_function_q26_a3": "어려움이 어느정도 있다",
  "patient_adult_knee_function_q26_a4": "극도의 어려움",
  "patient_adult_knee_function_q26_a5": "할 수 없다",
  "patient_adult_knee_function_q27": "현재 무릎의 기능은 몇 점입니까? (0부터 10점까지)",
  "patient_adult_knee_function_q28": "스포츠를 포함한 일상적인 활동을 수행한다(10은 정상, 0은 불가능).",
  "patient_adult_knee_function_q28_a1": "최악",
  "patient_adult_knee_function_q28_a2": "최고",
  "patient_adult_knee_function_q29": "정기적으로 참여할 수있는 최고 수준의 활동은 무엇입니까?",
  "patient_adult_knee_function_q29_a1": "농구나 축구에서 점프나 회전과 같은 매우 격렬한 활동",
  "patient_adult_knee_function_q29_a2": "무거운 신체 작업, 스키 또는 테니스와 같은 격렬한 활동",
  "patient_adult_knee_function_q29_a3": "중간 강도의 신체 작업, 달리기 또는 조깅과 같은 적당한 활동",
  "patient_adult_knee_function_q29_a4": "걷기, 집안일 또는 마당 작업과 같은 가벼운 활동",
  "patient_adult_knee_function_q29_a5": "무릎때문에 상기의 활동을 수행 할 수 없다.",
  "patient_adult_knee_function_q30": "참여할 수 있는 최고 활동 수준을 지정해주세요.",
  "patient_adult_knee_function_q30_q1": "무릎 수상/문제 전",
  "patient_adult_knee_function_q30_q1_a1": "경쟁성 있는 활동",
  "patient_adult_knee_function_q30_q1_a2": "레크리에이션적 신체 단련",
  "patient_adult_knee_function_q30_q1_a3": "스포츠하지 않고 일만 한다",
  "patient_adult_knee_function_q30_q1_a4": "일상생활 활동 만",
  "patient_adult_knee_function_q30_q1_a5": "건강 문제로 병가 또는 장애 연금",
  "patient_adult_knee_function_q30_q1_a1_o1": "경쟁 스포츠, 세계/올림픽 수준 또는 프로급",
  "patient_adult_knee_function_q30_q1_a1_o2": "육상 및 필드, 라켓/공 스포츠, 체조, 조정, 스키 또는 무술과 같은 경쟁 스포츠, 국가나 국제급",
  "patient_adult_knee_function_q30_q1_a1_o3": "수상 스포츠, 사이클링, 하키, 컬링 또는 승마 타기/로데오와 같은 경쟁 스포츠, 국가나 국제급",
  "patient_adult_knee_function_q30_q1_a2_o1": "달리기, 공/라켓 스포츠, 웨이트 트레이닝, 컬링, 조정, 골프, 요가 또는 체조와 같은 레크리에이션 스포츠, 주 5회 이상",
  "patient_adult_knee_function_q30_q1_a2_o2": "수영, 수상 스포츠, 스키, 하키, 롤러 블레이드, 자전거 타기, 승마 또는 등산과 같은 레크리에이션 스포츠, 주 5회 이상",
  "patient_adult_knee_function_q30_q1_a2_o3": "레크리에이션 스포츠, 적어도 매주 2 회",
  "patient_adult_knee_function_q30_q1_a2_o4": "레크리에이션 스포츠 주 1 회 또는 그 이하",
  "patient_adult_knee_function_q30_q1_a2_o5": "레크리에이션 스포츠, 월 1 회 이하",
  "patient_adult_knee_function_q30_q1_a3_o1": "건축과 같은 무거운 노동",
  "patient_adult_knee_function_q30_q1_a3_o2": "트럭 운전과 같은 중간 정도의 중노동",
  "patient_adult_knee_function_q30_q1_a3_o3": "간호와 같은 가벼운 노동",
  "patient_adult_knee_function_q30_q1_a3_o4": "비서 또는 컴퓨터 작업과 같은 앉아있는 직업",
  "patient_adult_knee_function_q30_q1_a4_o1": "정원 가꾸기, 여러 층의 계단 오르기, 짐 나르기, 짐 밀기/당기기, 지각 시 달리기 등의 일상 활동",
  "patient_adult_knee_function_q30_q1_a4_o2": "집안 청소, 계단 1~2층 오르기, 가벼운 짐을 들고 걷기 등의 일상 활동",
  "patient_adult_knee_function_q30_q1_a4_o3": "일상 활동 제한(예: 계단 이용 불가, 짐 운반 불가)",
  "patient_adult_knee_function_q30_q2": "현재 상태",
  "patient_adult_knee_function_q30_q2_a1": "경쟁성 있는 활동",
  "patient_adult_knee_function_q30_q2_a2": "레크리에이션적 신체 단련",
  "patient_adult_knee_function_q30_q2_a3": "스포츠하지 않고 일만 한다",
  "patient_adult_knee_function_q30_q2_a4": "일상생활 활동 만",
  "patient_adult_knee_function_q30_q2_a5": "건강 문제로 병가 또는 장애 연금",
  "patient_adult_knee_function_q30_q2_a1_o1": "경쟁 스포츠, 세계/올림픽 수준 또는 프로급",
  "patient_adult_knee_function_q30_q2_a1_o2": "육상 및 필드, 라켓/공 스포츠, 체조, 조정, 스키 또는 무술과 같은 경쟁 스포츠, 국가나 국제급",
  "patient_adult_knee_function_q30_q2_a1_o3": "수상 스포츠, 사이클링, 하키, 컬링 또는 승마 타기/로데오와 같은 경쟁 스포츠, 국가나 국제급",
  "patient_adult_knee_function_q30_q2_a2_o1": "달리기, 공/라켓 스포츠, 웨이트 트레이닝, 컬링, 조정, 골프, 요가 또는 체조와 같은 레크리에이션 스포츠, 주 5회 이상",
  "patient_adult_knee_function_q30_q2_a2_o2": "수영, 수상 스포츠, 스키, 하키, 롤러 블레이드, 자전거 타기, 승마 또는 등산과 같은 레크리에이션 스포츠, 주 5회 이상",
  "patient_adult_knee_function_q30_q2_a2_o3": "레크리에이션 스포츠, 적어도 매주 2 회",
  "patient_adult_knee_function_q30_q2_a2_o4": "레크리에이션 스포츠 주 1 회 또는 그 이하",
  "patient_adult_knee_function_q30_q2_a2_o5": "레크리에이션 스포츠, 월 1 회 이하",
  "patient_adult_knee_function_q30_q2_a3_o1": "건축과 같은 무거운 노동",
  "patient_adult_knee_function_q30_q2_a3_o2": "트럭 운전과 같은 중간 정도의 중노동",
  "patient_adult_knee_function_q30_q2_a3_o3": "간호와 같은 가벼운 노동",
  "patient_adult_knee_function_q30_q2_a3_o4": "비서 또는 컴퓨터 작업과 같은 앉아있는 직업",
  "patient_adult_knee_function_q30_q2_a4_o1": "정원 가꾸기, 여러 층의 계단 오르기, 짐 나르기, 짐 밀기/당기기, 지각 시 달리기 등의 일상 활동",
  "patient_adult_knee_function_q30_q2_a4_o2": "집안 청소, 계단 1~2층 오르기, 가벼운 짐을 들고 걷기 등의 일상 활동",
  "patient_adult_knee_function_q30_q2_a4_o3": "일상 활동 제한(예: 계단 이용 불가, 짐 운반 불가)",
  "patient_adult_knee_function_q31": "당신의 무릎 무릎판이 어긋나거나 어긋나는 느낌이 드나요?",
  "patient_adult_knee_function_q31_a1": "아니오",
  "patient_adult_knee_function_q31_a2": "예",
  "patient_adult_knee_patellofemoral_instability": "슬개대퇴 불안정성",
  "patient_adult_knee_patellofemoral_instability_instruction": "다음 각 활동을 할 때 무릎뼈가 관절에서 \"튀어나올\" 것 같은 느낌 또는 불안정한 느낌을 얼마나 자주 느끼는지 가장 잘 설명하는 것을 선택해 주십시오.",
  "patient_adult_knee_patellofemoral_instability_instruction_0": "전혀",
  "patient_adult_knee_patellofemoral_instability_instruction_1": "드물게",
  "patient_adult_knee_patellofemoral_instability_instruction_2": "가끔",
  "patient_adult_knee_patellofemoral_instability_instruction_3": "자주",
  "patient_adult_knee_patellofemoral_instability_instruction_4": "항상",
  "patient_adult_knee_patellofemoral_instability_q1": "스포츠/게임 중에 무릎을 비틀기/방향 전환하기",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q1_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q1_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q1_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q1_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q1_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q2": "달리는 중 방향 전환하기",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q2_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q2_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q2_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q2_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q2_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q3": "불규칙한 지면에서 직선으로 뛰기",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q3_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q3_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q3_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q3_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q3_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q4": "미끄러운 지면 위에서 걷기",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q4_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q4_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q4_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q4_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q4_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q5": "옆으로 뛰기",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q5_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q5_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q5_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q5_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q5_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q6": "깡총 뛰기",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q6_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q6_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q6_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q6_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q6_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q7": "점프하기",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q7_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q7_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q7_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q7_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q7_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q8": "고른 지면에서 직선으로 뛰기",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q8_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q8_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q8_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q8_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q8_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q9": "계단 내려가기",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q9_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q9_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q9_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q9_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q9_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q10": "스쿼팅",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q10_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q10_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q10_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q10_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q10_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q11": "무릎 꿇기",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q11_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q11_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q11_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q11_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q11_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q12": "고르지 않은 표면에서 일직선으로 걷기",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q12_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q12_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q12_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q12_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q12_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q13": "계단 오르기",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q13_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q13_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q13_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q13_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q13_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q14": "높은 단계 위로 또는 넘어서기",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q14_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q14_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q14_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q14_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q14_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q15": "다리 꼬고 앉기",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q15_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q15_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q15_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q15_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q15_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q16": "고른 지면에서 직선으로 걷기",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q16_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q16_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q16_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q16_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q16_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q17": "차에 타고 내리기",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q17_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q17_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q17_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q17_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q17_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q18": "슈퍼에서 무거운 카트를 방향 전환하기",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q18_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q18_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q18_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q18_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q18_a5": "항상",
  "patient_adult_knee_patellofemoral_instability_q19": "고개를 돌려 어깨 뒤를 쳐다보기",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do": "하지 않다",
  "patient_adult_knee_patellofemoral_instability_q19_a1": "전혀",
  "patient_adult_knee_patellofemoral_instability_q19_a2": "드물게",
  "patient_adult_knee_patellofemoral_instability_q19_a3": "가끔",
  "patient_adult_knee_patellofemoral_instability_q19_a4": "자주",
  "patient_adult_knee_patellofemoral_instability_q19_a5": "항상",
  "patient_adult_knee_quality_of_life": "삶의 질",
  "patient_adult_knee_quality_of_life_instruction": "다음은 무릎 문제와 관련하여 느낄 수 있는 사회적, 정서적, 생활 습관적 문제에 대한 질문입니다. 무릎과 관련하여 평소 어떤 느낌을 받았는지 생각해 보세요.",
  "patient_adult_knee_quality_of_life_q1": "얼마나 자주 무릎에 문제가 있다고 느끼십니까?",
  "patient_adult_knee_quality_of_life_q1_a1": "전혀",
  "patient_adult_knee_quality_of_life_q1_a2": "매월",
  "patient_adult_knee_quality_of_life_q1_a3": "매주",
  "patient_adult_knee_quality_of_life_q1_a4": "매일",
  "patient_adult_knee_quality_of_life_q1_a5": "끊임없이",
  "patient_adult_knee_quality_of_life_q2": "무릎에 무리를 주지 않기 위해 생활 습관을 교정하셨습니까?",
  "patient_adult_knee_quality_of_life_q2_a1": "전혀 없다",
  "patient_adult_knee_quality_of_life_q2_a2": "약간",
  "patient_adult_knee_quality_of_life_q2_a3": "보통",
  "patient_adult_knee_quality_of_life_q2_a4": "심하게",
  "patient_adult_knee_quality_of_life_q2_a5": "완전히",
  "patient_adult_knee_quality_of_life_q3": "무릎에 대한 자신감 부족으로 얼마나 고민하고 계십니까?",
  "patient_adult_knee_quality_of_life_q3_a1": "전혀 없다",
  "patient_adult_knee_quality_of_life_q3_a2": "약간",
  "patient_adult_knee_quality_of_life_q3_a3": "보통",
  "patient_adult_knee_quality_of_life_q3_a4": "심하게",
  "patient_adult_knee_quality_of_life_q3_a5": "극도로",
  "patient_adult_knee_quality_of_life_q4": "일반적으로 무릎에 어느 정도 어려움이 있나요?",
  "patient_adult_knee_quality_of_life_q4_a1": "없음",
  "patient_adult_knee_quality_of_life_q4_a2": "약간",
  "patient_adult_knee_quality_of_life_q4_a3": "보통",
  "patient_adult_knee_quality_of_life_q4_a4": "심각",
  "patient_adult_knee_quality_of_life_q4_a5": "극단적인",
  "patient_adult_knee_quality_of_life_q5": "오늘 얼만큼 불안/우울 하십니까?",
  "patient_adult_knee_quality_of_life_q5_a1": "불안/우울하지 않다",
  "patient_adult_knee_quality_of_life_q5_a2": "약간",
  "patient_adult_knee_quality_of_life_q5_a3": "보통",
  "patient_adult_knee_quality_of_life_q5_a4": "심하게",
  "patient_adult_knee_quality_of_life_q5_a5": "극도로",
  "patient_adult_knee_quality_of_life_q6": "오늘의 건강은 얼마나 좋은가요?/나쁜가요?",
  "patient_adult_knee_quality_of_life_q6_instruction": "100은 최고의 건강을 의미하며 0은 상상할 수있는 최악의 건강을 의미합니다.",
  "patient_adult_knee_quality_of_life_q6_instruction_a1": "최악",
  "patient_adult_knee_quality_of_life_q6_instruction_a2": "최고",
  "patient_adult_knee_satisfaction": "만족도",
  "patient_adult_knee_satisfaction_instructions": "무릎 수술/치료 결과에 얼마나 만족하십니까?",
  "patient_adult_knee_satisfaction_instructions_1": "매우 불만족한다",
  "patient_adult_knee_satisfaction_instructions_2": "어느정도 불만족한다",
  "patient_adult_knee_satisfaction_instructions_3": "어느정도 만족한다",
  "patient_adult_knee_satisfaction_instructions_4": "매우 만족한다",
  "patient_adult_knee_satisfaction_q1": "종합",
  "patient_adult_knee_satisfaction_q1_not_applicable": "해당 사항 없음",
  "patient_adult_knee_satisfaction_q1_a1": "매우 불만족한다",
  "patient_adult_knee_satisfaction_q1_a2": "어느정도 불만족한다",
  "patient_adult_knee_satisfaction_q1_a3": "어느정도 만족한다",
  "patient_adult_knee_satisfaction_q1_a4": "매우 만족한다",
  "patient_adult_knee_satisfaction_q2": "통증을 개선하기 위해?",
  "patient_adult_knee_satisfaction_q2_not_applicable": "해당 사항 없음",
  "patient_adult_knee_satisfaction_q2_a1": "매우 불만족한다",
  "patient_adult_knee_satisfaction_q2_a2": "어느정도 불만족한다",
  "patient_adult_knee_satisfaction_q2_a3": "어느정도 만족한다",
  "patient_adult_knee_satisfaction_q2_a4": "매우 만족한다",
  "patient_adult_knee_satisfaction_q3": "집안일/마당일을 할 수 있도록?",
  "patient_adult_knee_satisfaction_q3_not_applicable": "해당 사항 없음",
  "patient_adult_knee_satisfaction_q3_a1": "매우 불만족한다",
  "patient_adult_knee_satisfaction_q3_a2": "어느정도 불만족한다",
  "patient_adult_knee_satisfaction_q3_a3": "어느정도 만족한다",
  "patient_adult_knee_satisfaction_q3_a4": "매우 만족한다",
  "patient_adult_knee_satisfaction_q4": "여가 활동 능력 향상을 위해?",
  "patient_adult_knee_satisfaction_q4_not_applicable": "해당 사항 없음",
  "patient_adult_knee_satisfaction_q4_a1": "매우 불만족한다",
  "patient_adult_knee_satisfaction_q4_a2": "어느정도 불만족한다",
  "patient_adult_knee_satisfaction_q4_a3": "어느정도 만족한다",
  "patient_adult_knee_satisfaction_q4_a4": "매우 만족한다",
  "patient_adult_knee_complete": "설문 끝",
  "patient_adult_knee_complete_text1": "시간을 내어 이 설문 조사를 작성해 주셔서 감사합니다.",
  "patient_adult_knee_complete_text2": "제출을 클릭하여 완료하고 점수를 확인하십시오.",
  "patient_adult_knee_complete_text3": "귀하의 답은 기밀로 취급되며 모든 보고서는 신원 식별되지 않은 정보만 사용합니다.",
  "patient_adult_knee_results": "결과",
  "patient_adult_knee_results_text1": "아래는 설문지를 통해 계산된 통증 및 무릎 기능 점수입니다",
  "patient_adult_knee_results_pain": "통증",
  "patient_adult_knee_results_function": "기능",
  "patient_adult_knee_results_health_perception": "전반적인 건강 인식",
  "patient_adult_knee_results_self_evaluation": "무릎 점수 자체 평가",
  "patient_adult_knee_results_text2": "당신의 의사가 곧 도착할 것입니다.",
  "patient_adolescent_knee_pain": "통증",
  "patient_adolescent_knee_function_q23": "쇼핑하기",
  "patient_adolescent_knee_function_q23_a1": "어려움 없음",
  "patient_adolescent_knee_function_q23_a2": "어려움이 조금 있다",
  "patient_adolescent_knee_function_q23_a3": "어려움이 어느정도 있다",
  "patient_adolescent_knee_function_q23_a4": "극도의 어려움",
  "patient_adolescent_knee_function_q23_a5": "할 수 없다",
  "patient_pedia_knee_pain": "통증",
  "patient_pedia_knee_pain_q1": "지난 4 주 동안 또는 부상 이후 부상당한 무릎이 얼마나 아파요?",
  "patient_pedia_knee_pain_q1_a1": "아픈적이 없다",
  "patient_pedia_knee_pain_q1_a2": "드물게",
  "patient_pedia_knee_pain_q1_a3": "가끔",
  "patient_pedia_knee_pain_q1_a4": "자주",
  "patient_pedia_knee_pain_q1_a5": "끊임없다",
  "patient_pedia_knee_pain_q2": "오늘 다친 무릎이 얼마나 아프세요?",
  "patient_pedia_knee_pain_q2_a1": "전혀 아프지 않다",
  "patient_pedia_knee_pain_q2_a2": "너무 아파서 참을 수 없다",
  "patient_pedia_knee_pain_instructions": "지난 7일 동안 다음 활동 중 얼마나 무릎 통증을 경험했습니까?",
  "patient_pedia_knee_pain_instructions_0": "통증 없다",
  "patient_pedia_knee_pain_instructions_1": "어느 정도의 통증",
  "patient_pedia_knee_pain_instructions_2": "어느 정도의 통증",
  "patient_pedia_knee_pain_instructions_3": "많은 통증",
  "patient_pedia_knee_pain_instructions_4": "극심한 통증",
  "patient_pedia_knee_pain_note": "주 : 무릎 활동과 관련된 다음 질문에 대답하십시오.",
  "patient_pedia_knee_pain_q3": "걷거나 서거나 달릴 때 무릎을 비틀거나 돌리다",
  "patient_pedia_knee_pain_q3_a1": "통증 없다",
  "patient_pedia_knee_pain_q3_a2": "어느 정도의 통증",
  "patient_pedia_knee_pain_q3_a3": "어느 정도의 통증",
  "patient_pedia_knee_pain_q3_a4": "많은 통증",
  "patient_pedia_knee_pain_q3_a5": "극심한 통증",
  "patient_pedia_knee_pain_q4": "무릎을 완전히 펴는 것",
  "patient_pedia_knee_pain_q4_a1": "통증 없다",
  "patient_pedia_knee_pain_q4_a2": "어느 정도의 통증",
  "patient_pedia_knee_pain_q4_a3": "어느 정도의 통증",
  "patient_pedia_knee_pain_q4_a4": "많은 통증",
  "patient_pedia_knee_pain_q4_a5": "극심한 통증",
  "patient_pedia_knee_pain_q5": "무릎을 완전히 굽히기",
  "patient_pedia_knee_pain_q5_a1": "통증 없다",
  "patient_pedia_knee_pain_q5_a2": "어느 정도의 통증",
  "patient_pedia_knee_pain_q5_a3": "어느 정도의 통증",
  "patient_pedia_knee_pain_q5_a4": "많은 통증",
  "patient_pedia_knee_pain_q5_a5": "극심한 통증",
  "patient_pedia_knee_pain_q6": "계단을 오르기",
  "patient_pedia_knee_pain_q6_a1": "통증 없다",
  "patient_pedia_knee_pain_q6_a2": "어느 정도의 통증",
  "patient_pedia_knee_pain_q6_a3": "어느 정도의 통증",
  "patient_pedia_knee_pain_q6_a4": "많은 통증",
  "patient_pedia_knee_pain_q6_a5": "극심한 통증",
  "patient_pedia_knee_pain_q7": "계단을 내려가기",
  "patient_pedia_knee_pain_q7_a1": "통증 없다",
  "patient_pedia_knee_pain_q7_a2": "어느 정도의 통증",
  "patient_pedia_knee_pain_q7_a3": "어느 정도의 통증",
  "patient_pedia_knee_pain_q7_a4": "많은 통증",
  "patient_pedia_knee_pain_q7_a5": "극심한 통증",
  "patient_pedia_knee_pain_q8": "무릎을 굽힌 채 앉기",
  "patient_pedia_knee_pain_q8_a1": "통증 없다",
  "patient_pedia_knee_pain_q8_a2": "어느 정도의 통증",
  "patient_pedia_knee_pain_q8_a3": "어느 정도의 통증",
  "patient_pedia_knee_pain_q8_a4": "많은 통증",
  "patient_pedia_knee_pain_q8_a5": "극심한 통증",
  "patient_pedia_knee_pain_q9": "양쪽 다리를 똑바로 세우고 일정 시간 동안 서 있기",
  "patient_pedia_knee_pain_q9_a1": "통증 없다",
  "patient_pedia_knee_pain_q9_a2": "어느 정도의 통증",
  "patient_pedia_knee_pain_q9_a3": "어느 정도의 통증",
  "patient_pedia_knee_pain_q9_a4": "많은 통증",
  "patient_pedia_knee_pain_q9_a5": "극심한 통증",
  "patient_pedia_knee_other_symptoms": "다른 증상",
  "patient_pedia_knee_other_symptoms_instruction1": "다음과 같은 일이 얼마나 자주 있었습니까?",
  "patient_pedia_knee_other_symptoms_instruction1_0": "전혀",
  "patient_pedia_knee_other_symptoms_instruction1_1": "드물게",
  "patient_pedia_knee_other_symptoms_instruction1_2": "가끔",
  "patient_pedia_knee_other_symptoms_instruction1_3": "자주",
  "patient_pedia_knee_other_symptoms_instruction1_4": "항상",
  "patient_pedia_knee_other_symptoms_q1": "무릎에서 소리가 난다",
  "patient_pedia_knee_other_symptoms_q1_a1": "전혀",
  "patient_pedia_knee_other_symptoms_q1_a2": "드물게",
  "patient_pedia_knee_other_symptoms_q1_a3": "가끔",
  "patient_pedia_knee_other_symptoms_q1_a4": "자주",
  "patient_pedia_knee_other_symptoms_q1_a5": "항상",
  "patient_pedia_knee_other_symptoms_q2": "무릎이 멈추거나 고정되는 현상",
  "patient_pedia_knee_other_symptoms_q2_a1": "전혀",
  "patient_pedia_knee_other_symptoms_q2_a2": "드물게",
  "patient_pedia_knee_other_symptoms_q2_a3": "가끔",
  "patient_pedia_knee_other_symptoms_q2_a4": "자주",
  "patient_pedia_knee_other_symptoms_q2_a5": "항상",
  "patient_pedia_knee_other_symptoms_q3": "무릎이 멈추는 느낌이 들지만 움직일 수 있다.",
  "patient_pedia_knee_other_symptoms_q3_a1": "전혀",
  "patient_pedia_knee_other_symptoms_q3_a2": "드물게",
  "patient_pedia_knee_other_symptoms_q3_a3": "가끔",
  "patient_pedia_knee_other_symptoms_q3_a4": "자주",
  "patient_pedia_knee_other_symptoms_q3_a5": "항상",
  "patient_pedia_knee_other_symptoms_q4": "부어오른 무릎",
  "patient_pedia_knee_other_symptoms_q4_a1": "전혀",
  "patient_pedia_knee_other_symptoms_q4_a2": "드물게",
  "patient_pedia_knee_other_symptoms_q4_a3": "가끔",
  "patient_pedia_knee_other_symptoms_q4_a4": "자주",
  "patient_pedia_knee_other_symptoms_q4_a5": "항상",
  "patient_pedia_knee_other_symptoms_q5": "무릎이 얼마나 부었나요?",
  "patient_pedia_knee_other_symptoms_q5_a1": "전혀 붓지 않았다",
  "patient_pedia_knee_other_symptoms_q5_a2": "조금 부었다",
  "patient_pedia_knee_other_symptoms_q5_a3": "어느 정도 부었다",
  "patient_pedia_knee_other_symptoms_q5_a4": "매우 부었다",
  "patient_pedia_knee_other_symptoms_q5_a5": "극도로 부었다",
  "patient_pedia_knee_other_symptoms_instruction2": "만약 아래 활동들을 하라고 요청받는다면, 최대로 할 수 있는 것은 무엇인가요?",
  "patient_pedia_knee_other_symptoms_instruction2_0": "농구나 축구와 같이 방향을 바꾸기 위해 빠르게 점프하거나 회전하는 것과 같이 매우 어려운 활동",
  "patient_pedia_knee_other_symptoms_instruction2_1": "무거운 물건을 들거나 스키 또는 테니스와 같은 힘든 활동",
  "patient_pedia_knee_other_symptoms_instruction2_2": "빠르게 걷거나 조깅과 같은 어려운 활동들",
  "patient_pedia_knee_other_symptoms_instruction2_3": "보통 속도로 걷기와 같은 가벼운 활동",
  "patient_pedia_knee_other_symptoms_instruction2_4": "위에 나열된 활동들을 어떤 것도 할 수 없어요.",
  "patient_pedia_knee_other_symptoms_q6": "무릎이 많이 안아플 정도로",
  "patient_pedia_knee_other_symptoms_q6_a1": "매우 어려운 활동들",
  "patient_pedia_knee_other_symptoms_q6_a2": "어려운 활동들",
  "patient_pedia_knee_other_symptoms_q6_a3": "어느정도 어려운 활동들",
  "patient_pedia_knee_other_symptoms_q6_a4": "가벼운 활동",
  "patient_pedia_knee_other_symptoms_q6_a5": "나는 위에 언급된 어떤 것도 할 수 없어요.",
  "patient_pedia_knee_other_symptoms_q7": "무릎이 붓지 않을 정도로",
  "patient_pedia_knee_other_symptoms_q7_a1": "매우 어려운 활동들",
  "patient_pedia_knee_other_symptoms_q7_a2": "어려운 활동들",
  "patient_pedia_knee_other_symptoms_q7_a3": "어느정도 어려운 활동들",
  "patient_pedia_knee_other_symptoms_q7_a4": "가벼운 활동",
  "patient_pedia_knee_other_symptoms_q7_a5": "나는 위에 언급된 어떤 것도 할 수 없어요.",
  "patient_pedia_knee_other_symptoms_q8": "무릎이 지탱할 수 없을 느낌이 안들 정도로",
  "patient_pedia_knee_other_symptoms_q8_a1": "매우 어려운 활동들",
  "patient_pedia_knee_other_symptoms_q8_a2": "어려운 활동들",
  "patient_pedia_knee_other_symptoms_q8_a3": "어느정도 어려운 활동들",
  "patient_pedia_knee_other_symptoms_q8_a5": "나는 위에 언급된 어떤 것도 할 수 없어요.",
  "patient_pedia_knee_other_symptoms_q9": "대부분의 시간",
  "patient_pedia_knee_other_symptoms_q9_a1": "매우 어려운 활동들",
  "patient_pedia_knee_other_symptoms_q9_a2": "어려운 활동들",
  "patient_pedia_knee_other_symptoms_q9_a3": "어느정도 어려운 활동들",
  "patient_pedia_knee_other_symptoms_q9_a4": "가벼운 활동",
  "patient_pedia_knee_other_symptoms_q9_a5": "나는 위에 언급된 어떤 것도 할 수 없어요.",
  "patient_pedia_knee_function": "기능",
  "patient_pedia_knee_function_instruction": "무릎 때문에 다음 동작을 하는 데 얼마나 어려움을 겪으셨나요?",
  "patient_pedia_knee_function_instruction_0": "어려움 없음",
  "patient_pedia_knee_function_instruction_1": "조금",
  "patient_pedia_knee_function_instruction_2": "일부",
  "patient_pedia_knee_function_instruction_3": "많이",
  "patient_pedia_knee_function_instruction_4": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_note": "참고 : 특정 활동을 하시지 않으면 무릎이 어떻게 느끼는지 상상해보십시오.",
  "patient_pedia_knee_function_q1": "계단 오르기",
  "patient_pedia_knee_function_q1_a1": "어려움 없음",
  "patient_pedia_knee_function_q1_a2": "조금",
  "patient_pedia_knee_function_q1_a3": "일부",
  "patient_pedia_knee_function_q1_a4": "많이",
  "patient_pedia_knee_function_q1_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q2": "계단 내려가기",
  "patient_pedia_knee_function_q2_a1": "어려움 없음",
  "patient_pedia_knee_function_q2_a2": "조금",
  "patient_pedia_knee_function_q2_a3": "일부",
  "patient_pedia_knee_function_q2_a4": "많이",
  "patient_pedia_knee_function_q2_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q3": "무릎 앞쪽으로 무릎 꿇기",
  "patient_pedia_knee_function_q3_a1": "어려움 없음",
  "patient_pedia_knee_function_q3_a2": "조금",
  "patient_pedia_knee_function_q3_a3": "일부",
  "patient_pedia_knee_function_q3_a4": "많이",
  "patient_pedia_knee_function_q3_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q4": "무릎을 완전히 펴는 것",
  "patient_pedia_knee_function_q4_a1": "어려움 없음",
  "patient_pedia_knee_function_q4_a2": "조금",
  "patient_pedia_knee_function_q4_a3": "일부",
  "patient_pedia_knee_function_q4_a4": "많이",
  "patient_pedia_knee_function_q4_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q5": "무릎을 완전히 굽히기",
  "patient_pedia_knee_function_q5_a1": "어려움 없음",
  "patient_pedia_knee_function_q5_a2": "조금",
  "patient_pedia_knee_function_q5_a3": "일부",
  "patient_pedia_knee_function_q5_a4": "많이",
  "patient_pedia_knee_function_q5_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q6": "아침에 일어나자마자 무릎을 움직이기.",
  "patient_pedia_knee_function_q6_a1": "어려움 없음",
  "patient_pedia_knee_function_q6_a2": "조금",
  "patient_pedia_knee_function_q6_a3": "일부",
  "patient_pedia_knee_function_q6_a4": "많이",
  "patient_pedia_knee_function_q6_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q7": "잠시동안 가만히 있은 후에 저녁에 무릎을 움직이기.",
  "patient_pedia_knee_function_q7_a1": "어려움 없음",
  "patient_pedia_knee_function_q7_a2": "조금",
  "patient_pedia_knee_function_q7_a3": "일부",
  "patient_pedia_knee_function_q7_a4": "많이",
  "patient_pedia_knee_function_q7_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q8": "야구 포수처럼 앉기",
  "patient_pedia_knee_function_q8_a1": "어려움 없음",
  "patient_pedia_knee_function_q8_a2": "조금",
  "patient_pedia_knee_function_q8_a3": "일부",
  "patient_pedia_knee_function_q8_a4": "많이",
  "patient_pedia_knee_function_q8_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q9": "의자에 앉아 무릎을 굽히고 발을 바닥에 평평하게 놓기",
  "patient_pedia_knee_function_q9_a1": "어려움 없음",
  "patient_pedia_knee_function_q9_a2": "조금",
  "patient_pedia_knee_function_q9_a3": "일부",
  "patient_pedia_knee_function_q9_a4": "많이",
  "patient_pedia_knee_function_q9_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q10": "의자에서 일어나기",
  "patient_pedia_knee_function_q10_a1": "어려움 없음",
  "patient_pedia_knee_function_q10_a2": "조금",
  "patient_pedia_knee_function_q10_a3": "일부",
  "patient_pedia_knee_function_q10_a4": "많이",
  "patient_pedia_knee_function_q10_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q11": "무릎을 굽히고 바닥에서 물건을 집어 올리세요.",
  "patient_pedia_knee_function_q11_a1": "어려움 없음",
  "patient_pedia_knee_function_q11_a2": "조금",
  "patient_pedia_knee_function_q11_a3": "일부",
  "patient_pedia_knee_function_q11_a4": "많이",
  "patient_pedia_knee_function_q11_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q12": "놀이나 스포츠 활동 중에 하기",
  "patient_pedia_knee_function_q12_a1": "어려움 없음",
  "patient_pedia_knee_function_q12_a2": "조금",
  "patient_pedia_knee_function_q12_a3": "일부",
  "patient_pedia_knee_function_q12_a4": "많이",
  "patient_pedia_knee_function_q12_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q13": "플레이나 스포츠 활동 중에 점프하기",
  "patient_pedia_knee_function_q13_a1": "어려움 없음",
  "patient_pedia_knee_function_q13_a2": "조금",
  "patient_pedia_knee_function_q13_a3": "일부",
  "patient_pedia_knee_function_q13_a4": "많이",
  "patient_pedia_knee_function_q13_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q14": "빠르게 움직이기 시작하고 멈추기",
  "patient_pedia_knee_function_q14_a1": "어려움 없음",
  "patient_pedia_knee_function_q14_a2": "조금",
  "patient_pedia_knee_function_q14_a3": "일부",
  "patient_pedia_knee_function_q14_a4": "많이",
  "patient_pedia_knee_function_q14_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q15": "플레이나 스포츠 활동 중에 돌거나 피벗하기",
  "patient_pedia_knee_function_q15_a1": "어려움 없음",
  "patient_pedia_knee_function_q15_a2": "조금",
  "patient_pedia_knee_function_q15_a3": "일부",
  "patient_pedia_knee_function_q15_a4": "많이",
  "patient_pedia_knee_function_q15_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q16": "고르지 않은 지면에서 걷거나 달릴 때 균형을 유지하기",
  "patient_pedia_knee_function_q16_a1": "어려움 없음",
  "patient_pedia_knee_function_q16_a2": "조금",
  "patient_pedia_knee_function_q16_a3": "일부",
  "patient_pedia_knee_function_q16_a4": "많이",
  "patient_pedia_knee_function_q16_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q17": "스포츠 하기",
  "patient_pedia_knee_function_q17_a1": "어려움 없음",
  "patient_pedia_knee_function_q17_a2": "조금",
  "patient_pedia_knee_function_q17_a3": "일부",
  "patient_pedia_knee_function_q17_a4": "많이",
  "patient_pedia_knee_function_q17_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q18": "차에 타기/차에서 내리기",
  "patient_pedia_knee_function_q18_a1": "어려움 없음",
  "patient_pedia_knee_function_q18_a2": "조금",
  "patient_pedia_knee_function_q18_a3": "일부",
  "patient_pedia_knee_function_q18_a4": "많이",
  "patient_pedia_knee_function_q18_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q19": "침대에서 일어나기",
  "patient_pedia_knee_function_q19_a1": "어려움 없음",
  "patient_pedia_knee_function_q19_a2": "조금",
  "patient_pedia_knee_function_q19_a3": "일부",
  "patient_pedia_knee_function_q19_a4": "많이",
  "patient_pedia_knee_function_q19_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q20": "침대에 누워 있을 때 무릎 위치를 변경하기.",
  "patient_pedia_knee_function_q20_a1": "어려움 없음",
  "patient_pedia_knee_function_q20_a2": "조금",
  "patient_pedia_knee_function_q20_a3": "일부",
  "patient_pedia_knee_function_q20_a4": "많이",
  "patient_pedia_knee_function_q20_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q21": "욕조/샤워실에 들어가기/나오기",
  "patient_pedia_knee_function_q21_a1": "어려움 없음",
  "patient_pedia_knee_function_q21_a2": "조금",
  "patient_pedia_knee_function_q21_a3": "일부",
  "patient_pedia_knee_function_q21_a4": "많이",
  "patient_pedia_knee_function_q21_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q22": "무거운 가방/백팩 등을 들기",
  "patient_pedia_knee_function_q22_a1": "어려움 없음",
  "patient_pedia_knee_function_q22_a2": "조금",
  "patient_pedia_knee_function_q22_a3": "일부",
  "patient_pedia_knee_function_q22_a4": "많이",
  "patient_pedia_knee_function_q22_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q23": "방 청소, 식기 세척/비우기, 침대 정리 등과 같은 가벼운 집안일을 하기.",
  "patient_pedia_knee_function_q23_a1": "어려움 없음",
  "patient_pedia_knee_function_q23_a2": "조금",
  "patient_pedia_knee_function_q23_a3": "일부",
  "patient_pedia_knee_function_q23_a4": "많이",
  "patient_pedia_knee_function_q23_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q24": "등교 또는 학교 내 이동(계단 오르기, 문 열기, 책 들고 다니기, 쉬는 시간 참여)",
  "patient_pedia_knee_function_q24_a1": "어려움 없음",
  "patient_pedia_knee_function_q24_a2": "조금",
  "patient_pedia_knee_function_q24_a3": "일부",
  "patient_pedia_knee_function_q24_a4": "많이",
  "patient_pedia_knee_function_q24_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q25": "친구들과 함께 일을 하기",
  "patient_pedia_knee_function_q25_a1": "어려움 없음",
  "patient_pedia_knee_function_q25_a2": "조금",
  "patient_pedia_knee_function_q25_a3": "일부",
  "patient_pedia_knee_function_q25_a4": "많이",
  "patient_pedia_knee_function_q25_a5": "극심한 어려움/할 수 없다",
  "patient_pedia_knee_function_q26": "무릎이 부상전에 얼마나 잘 작동 했습니까?",
  "patient_pedia_knee_function_q26_instruction": "10은 정상, 0은 스포츠를 포함한 일상적인 활동을 전혀 수행할 수 없음을 의미합니다.",
  "patient_pedia_knee_function_q26_instruction_a1": "아무것도 할 수 없었다",
  "patient_pedia_knee_function_q26_instruction_a2": "원하는 모든 것을 할 수있었다",
  "patient_pedia_knee_function_q27": "지금 무릎이 얼마나 잘 작동합니까?",
  "patient_pedia_knee_function_q27_instruction": "10은 정상, 0은 스포츠를 포함한 일상적인 활동을 전혀 수행할 수 없음을 의미합니다.",
  "patient_pedia_knee_function_q27_instruction_a1": "나는 아무것도 할 수 없다",
  "patient_pedia_knee_function_q27_instruction_a2": "원하는 모든 것을 할 수있다",
  "patient_pedia_knee_function_q28": "무릎뼈 (슬개골)이 제위치에서 벗어나거나 벗어나려고 하는 느낌을 받습니까?",
  "patient_pedia_knee_function_q28_a1": "아니오",
  "patient_pedia_knee_function_q28_a2": "예",
  "patient_pedia_knee_instability": "슬개대퇴 불안정성",
  "patient_pedia_knee_instability_instruction": "다음 각 활동을 할 때 무릎뼈가 관절에서 \"튀어나올\" 것 같은 느낌이 들거나 불안정한 느낌을 받는 빈도를 가장 잘 설명하는 것은 무엇입니까?",
  "patient_pedia_knee_instability_instruction_0": "전혀",
  "patient_pedia_knee_instability_instruction_1": "드물게",
  "patient_pedia_knee_instability_instruction_2": "가끔",
  "patient_pedia_knee_instability_instruction_3": "자주",
  "patient_pedia_knee_instability_instruction_4": "항상",
  "patient_pedia_knee_instability_note": "참고 : 무릎 활동과 관련된 다음 질문에 대답하십시오.",
  "patient_pedia_knee_instability_q1": "스포츠/게임 중에 무릎을 비틀기/방향 전환하기",
  "patient_pedia_knee_instability_q1_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q1_a1": "전혀",
  "patient_pedia_knee_instability_q1_a2": "드물게",
  "patient_pedia_knee_instability_q1_a3": "가끔",
  "patient_pedia_knee_instability_q1_a4": "자주",
  "patient_pedia_knee_instability_q1_a5": "항상",
  "patient_pedia_knee_instability_q2": "달리는 중 방향 전환하기",
  "patient_pedia_knee_instability_q2_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q2_a1": "전혀",
  "patient_pedia_knee_instability_q2_a2": "드물게",
  "patient_pedia_knee_instability_q2_a3": "가끔",
  "patient_pedia_knee_instability_q2_a4": "자주",
  "patient_pedia_knee_instability_q2_a5": "항상",
  "patient_pedia_knee_instability_q3": "불규칙한 지면에서 직선으로 뛰기",
  "patient_pedia_knee_instability_q3_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q3_a1": "전혀",
  "patient_pedia_knee_instability_q3_a2": "드물게",
  "patient_pedia_knee_instability_q3_a3": "가끔",
  "patient_pedia_knee_instability_q3_a4": "자주",
  "patient_pedia_knee_instability_q3_a5": "항상",
  "patient_pedia_knee_instability_q4": "미끄럽거나 젖어 있거나 결빙된 표면 위를 걷기",
  "patient_pedia_knee_instability_q4_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q4_a1": "전혀",
  "patient_pedia_knee_instability_q4_a2": "드물게",
  "patient_pedia_knee_instability_q4_a3": "가끔",
  "patient_pedia_knee_instability_q4_a4": "자주",
  "patient_pedia_knee_instability_q4_a5": "항상",
  "patient_pedia_knee_instability_q5": "옆으로 뛰기",
  "patient_pedia_knee_instability_q5_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q5_a1": "전혀",
  "patient_pedia_knee_instability_q5_a2": "드물게",
  "patient_pedia_knee_instability_q5_a3": "가끔",
  "patient_pedia_knee_instability_q5_a4": "자주",
  "patient_pedia_knee_instability_q5_a5": "항상",
  "patient_pedia_knee_instability_q6": "깡총 뛰기",
  "patient_pedia_knee_instability_q6_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q6_a1": "전혀",
  "patient_pedia_knee_instability_q6_a2": "드물게",
  "patient_pedia_knee_instability_q6_a3": "가끔",
  "patient_pedia_knee_instability_q6_a4": "자주",
  "patient_pedia_knee_instability_q6_a5": "항상",
  "patient_pedia_knee_instability_q7": "점프하기",
  "patient_pedia_knee_instability_q7_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q7_a1": "전혀",
  "patient_pedia_knee_instability_q7_a2": "드물게",
  "patient_pedia_knee_instability_q7_a3": "가끔",
  "patient_pedia_knee_instability_q7_a4": "자주",
  "patient_pedia_knee_instability_q7_a5": "항상",
  "patient_pedia_knee_instability_q8": "고른 표면에서 일직선으로 달리기",
  "patient_pedia_knee_instability_q8_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q8_a1": "전혀",
  "patient_pedia_knee_instability_q8_a2": "드물게",
  "patient_pedia_knee_instability_q8_a3": "가끔",
  "patient_pedia_knee_instability_q8_a4": "자주",
  "patient_pedia_knee_instability_q8_a5": "항상",
  "patient_pedia_knee_instability_q9": "계단 내려가기",
  "patient_pedia_knee_instability_q9_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q9_a1": "전혀",
  "patient_pedia_knee_instability_q9_a2": "드물게",
  "patient_pedia_knee_instability_q9_a3": "가끔",
  "patient_pedia_knee_instability_q9_a4": "자주",
  "patient_pedia_knee_instability_q9_a5": "항상",
  "patient_pedia_knee_instability_q10": "스쾃(기마)자세 취하기",
  "patient_pedia_knee_instability_q10_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q10_a1": "전혀",
  "patient_pedia_knee_instability_q10_a2": "드물게",
  "patient_pedia_knee_instability_q10_a3": "가끔",
  "patient_pedia_knee_instability_q10_a4": "자주",
  "patient_pedia_knee_instability_q10_a5": "항상",
  "patient_pedia_knee_instability_q11": "무릎 꿇기",
  "patient_pedia_knee_instability_q11_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q11_a1": "전혀",
  "patient_pedia_knee_instability_q11_a2": "드물게",
  "patient_pedia_knee_instability_q11_a3": "가끔",
  "patient_pedia_knee_instability_q11_a4": "자주",
  "patient_pedia_knee_instability_q11_a5": "항상",
  "patient_pedia_knee_instability_q12": "고르지 않은 표면에서 일직선으로 걷기",
  "patient_pedia_knee_instability_q12_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q12_a1": "전혀",
  "patient_pedia_knee_instability_q12_a2": "드물게",
  "patient_pedia_knee_instability_q12_a3": "가끔",
  "patient_pedia_knee_instability_q12_a4": "자주",
  "patient_pedia_knee_instability_q12_a5": "항상",
  "patient_pedia_knee_instability_q13": "계단 오르기",
  "patient_pedia_knee_instability_q13_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q13_a1": "전혀",
  "patient_pedia_knee_instability_q13_a2": "드물게",
  "patient_pedia_knee_instability_q13_a3": "가끔",
  "patient_pedia_knee_instability_q13_a4": "자주",
  "patient_pedia_knee_instability_q13_a5": "항상",
  "patient_pedia_knee_instability_q14": "계단 높은 층으로 발 내딛기",
  "patient_pedia_knee_instability_q14_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q14_a1": "전혀",
  "patient_pedia_knee_instability_q14_a2": "드물게",
  "patient_pedia_knee_instability_q14_a3": "가끔",
  "patient_pedia_knee_instability_q14_a4": "자주",
  "patient_pedia_knee_instability_q14_a5": "항상",
  "patient_pedia_knee_instability_q15": "다리 꼬고 앉기",
  "patient_pedia_knee_instability_q15_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q15_a1": "전혀",
  "patient_pedia_knee_instability_q15_a2": "드물게",
  "patient_pedia_knee_instability_q15_a3": "가끔",
  "patient_pedia_knee_instability_q15_a4": "자주",
  "patient_pedia_knee_instability_q15_a5": "항상",
  "patient_pedia_knee_instability_q16": "고른 지면에서 직선으로 걷기",
  "patient_pedia_knee_instability_q16_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q16_a1": "전혀",
  "patient_pedia_knee_instability_q16_a2": "드물게",
  "patient_pedia_knee_instability_q16_a3": "가끔",
  "patient_pedia_knee_instability_q16_a4": "자주",
  "patient_pedia_knee_instability_q16_a5": "항상",
  "patient_pedia_knee_instability_q17": "차에 타고 내리기",
  "patient_pedia_knee_instability_q17_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q17_a1": "전혀",
  "patient_pedia_knee_instability_q17_a2": "드물게",
  "patient_pedia_knee_instability_q17_a3": "가끔",
  "patient_pedia_knee_instability_q17_a4": "자주",
  "patient_pedia_knee_instability_q17_a5": "항상",
  "patient_pedia_knee_instability_q18": "슈퍼에서 무거운 카트를 방향 전환하기",
  "patient_pedia_knee_instability_q18_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q18_a1": "전혀",
  "patient_pedia_knee_instability_q18_a2": "드물게",
  "patient_pedia_knee_instability_q18_a3": "가끔",
  "patient_pedia_knee_instability_q18_a4": "자주",
  "patient_pedia_knee_instability_q18_a5": "항상",
  "patient_pedia_knee_instability_q19": "고개를 돌려 어깨 뒤를 쳐다보기",
  "patient_pedia_knee_instability_q19_do_not_do": "하지 않다",
  "patient_pedia_knee_instability_q19_a1": "전혀",
  "patient_pedia_knee_instability_q19_a2": "드물게",
  "patient_pedia_knee_instability_q19_a3": "가끔",
  "patient_pedia_knee_instability_q19_a4": "자주",
  "patient_pedia_knee_instability_q19_a5": "항상",
  "patient_pedia_knee_quality_of_life": "삶의 질",
  "patient_pedia_knee_quality_of_life_instruction": "부상이 생활에 어떤 영향을 미쳤나요?",
  "patient_pedia_knee_quality_of_life_q1": "자신의 무릎 문제에 대해 얼마나 자주 생각하나요?",
  "patient_pedia_knee_quality_of_life_q1_a1": "전혀",
  "patient_pedia_knee_quality_of_life_q1_a2": "드물게",
  "patient_pedia_knee_quality_of_life_q1_a3": "가끔",
  "patient_pedia_knee_quality_of_life_q1_a4": "자주",
  "patient_pedia_knee_quality_of_life_q1_a5": "항상",
  "patient_pedia_knee_problem_q2": "부상당한 무릎 때문에 얼마나 라이프스타일을 바꾸셨나요?",
  "patient_pedia_knee_problem_q2_a1": "전혀 없다",
  "patient_pedia_knee_problem_q2_a2": "조금",
  "patient_pedia_knee_problem_q2_a3": "일부",
  "patient_pedia_knee_problem_q2_a4": "많이",
  "patient_pedia_knee_problem_q2_a5": "매우 많이",
  "patient_pedia_knee_problem_q3": "다친 무릎에 얼마나 신뢰를 가지고 있나요?",
  "patient_pedia_knee_problem_q3_a1": "완전히",
  "patient_pedia_knee_problem_q3_a2": "많이",
  "patient_pedia_knee_problem_q3_a3": "일부",
  "patient_pedia_knee_problem_q3_a4": "조금",
  "patient_pedia_knee_problem_q3_a5": "전혀 없다",
  "patient_pedia_knee_problem_q4": "부상당한 무릎으로 인해 전반적으로 얼마나 어려움을 겪고 있나요?",
  "patient_pedia_knee_problem_q4_a1": "어려움 없음",
  "patient_pedia_knee_problem_q4_a2": "조금",
  "patient_pedia_knee_problem_q4_a3": "일부",
  "patient_pedia_knee_problem_q4_a4": "많이",
  "patient_pedia_knee_problem_q4_a5": "극도의 어려움",
  "patient_pedia_knee_satisfaction": "만족도",
  "patient_pedia_knee_satisfaction_instruction": "무릎 수술/치료 결과에 얼마나 만족하십나요?",
  "patient_pedia_knee_satisfaction_instruction_a1": "매우 불만족한다",
  "patient_pedia_knee_satisfaction_instruction_a2": "어느정도 불만족한다",
  "patient_pedia_knee_satisfaction_instruction_a3": "어느정도 만족한다",
  "patient_pedia_knee_satisfaction_instruction_a4": "매우 만족한다",
  "patient_pedia_knee_satisfaction_q1": "종합",
  "patient_pedia_knee_satisfaction_q1_NA": "해당 사항 없음",
  "patient_pedia_knee_satisfaction_q1_a1": "매우 불만족한다",
  "patient_pedia_knee_satisfaction_q1_a2": "어느정도 불만족한다",
  "patient_pedia_knee_satisfaction_q1_a3": "어느정도 만족한다",
  "patient_pedia_knee_satisfaction_q1_a4": "매우 만족한다",
  "patient_pedia_knee_satisfaction_q2": "통증을 호전시키기 위해? (우측)",
  "patient_pedia_knee_satisfaction_q2_NA": "해당 사항 없음",
  "patient_pedia_knee_satisfaction_q2_a1": "매우 불만족한다",
  "patient_pedia_knee_satisfaction_q2_a2": "어느정도 불만족한다",
  "patient_pedia_knee_satisfaction_q2_a3": "어느정도 만족한다",
  "patient_pedia_knee_satisfaction_q2_a4": "매우 만족한다",
  "patient_pedia_knee_satisfaction_q3": "집안일/마당일을 할 수 있도록?",
  "patient_pedia_knee_satisfaction_q3_NA": "해당 사항 없음",
  "patient_pedia_knee_satisfaction_q3_a1": "매우 불만족한다",
  "patient_pedia_knee_satisfaction_q3_a2": "어느정도 불만족한다",
  "patient_pedia_knee_satisfaction_q3_a3": "어느정도 만족한다",
  "patient_pedia_knee_satisfaction_q3_a4": "매우 만족한다",
  "patient_pedia_knee_satisfaction_q4": "여가 활동을 즐길 수 있도록?",
  "patient_pedia_knee_satisfaction_q4_NA": "해당 사항 없음",
  "patient_pedia_knee_satisfaction_q4_a1": "매우 불만족한다",
  "patient_pedia_knee_satisfaction_q4_a2": "어느정도 불만족한다",
  "patient_pedia_knee_satisfaction_q4_a3": "어느정도 만족한다",
  "patient_pedia_knee_satisfaction_q4_a4": "매우 만족한다",
  "patient_pedia_knee_complete": "설문 끝",
  "patient_pedia_knee_complete_text1": "시간을 내어 이 설문 조사를 작성해 주셔서 감사합니다.",
  "patient_pedia_knee_complete_text2": "제출을 클릭하여 완료하고 점수를 확인하십시오.",
  "patient_pedia_knee_complete_text3": "귀하의 답은 기밀로 취급되며 모든 보고서는 신원 식별되지 않은 정보만 사용합니다.",
  "patient_pedia_knee_results": "결과",
  "patient_pedia_knee_results_text1": "다음은 설문지에서 계산되는 통증 및 무릎 기능 점수입니다.",
  "patient_pedia_knee_results_pain": "통증",
  "patient_pedia_knee_results_function": "기능",
  "patient_pedia_knee_results_self_evaluation": "무릎 점수 자체 평가",
  "patient_pedia_knee_results_text2": "곧 진료실로 호명되실 것입니다.",
  "patient_foot_ankle_pain": "통증",
  "patient_foot_ankle_pain_q1": "당신의 발/발목에서 현재 통증 수준은 어떻게 되나요?",
  "patient_foot_ankle_pain_q1_0": "없음",
  "patient_foot_ankle_pain_q1_1": "상상할 수있는 최악의 통증",
  "patient_foot_ankle_pain_q2": "발/발목의 통증에 대해 가장 잘 설명할 수 있는 것은 무엇인가요?",
  "patient_foot_ankle_pain_q2_a1": "없음",
  "patient_foot_ankle_pain_q2_a2": "가벼운, 가끔",
  "patient_foot_ankle_pain_q2_a3": "보통, 매일",
  "patient_foot_ankle_pain_q2_a4": "심각, 거의 항상 존재한다",
  "patient_foot_ankle_pain_q3": "얼마나 자주 발/발목 통증을 경험하십니까?",
  "patient_foot_ankle_pain_q3_a1": "전혀",
  "patient_foot_ankle_pain_q3_a2": "매월",
  "patient_foot_ankle_pain_q3_a3": "매주",
  "patient_foot_ankle_pain_q3_a4": "매일",
  "patient_foot_ankle_pain_q3_a5": "항상",
  "patient_foot_ankle_pain_instruction": "다음 활동 중에 발/발목 통증을 얼마나 경험하셨나요?",
  "patient_foot_ankle_pain_instruction_0": "없음",
  "patient_foot_ankle_pain_instruction_1": "약간",
  "patient_foot_ankle_pain_instruction_2": "보통",
  "patient_foot_ankle_pain_instruction_3": "심각",
  "patient_foot_ankle_pain_instruction_4": "극단적인",
  "patient_foot_ankle_pain_q4": "일반적인 통증 수준",
  "patient_foot_ankle_pain_q4_a1": "없음",
  "patient_foot_ankle_pain_q4_a2": "약간",
  "patient_foot_ankle_pain_q4_a3": "보통",
  "patient_foot_ankle_pain_q4_a4": "심각",
  "patient_foot_ankle_pain_q4_a5": "극단적인",
  "patient_foot_ankle_pain_q5": "휴식 중",
  "patient_foot_ankle_pain_q5_a1": "없음",
  "patient_foot_ankle_pain_q5_a2": "약간",
  "patient_foot_ankle_pain_q5_a3": "보통",
  "patient_foot_ankle_pain_q5_a4": "심각",
  "patient_foot_ankle_pain_q5_a5": "극단적인",
  "patient_foot_ankle_pain_q6": "일상 활동 중",
  "patient_foot_ankle_pain_q6_a1": "없음",
  "patient_foot_ankle_pain_q6_a2": "약간",
  "patient_foot_ankle_pain_q6_a3": "보통",
  "patient_foot_ankle_pain_q6_a4": "심각",
  "patient_foot_ankle_pain_q6_a5": "극단적인",
  "patient_foot_ankle_pain_q7": "아침에 처음으로 하는 일",
  "patient_foot_ankle_pain_q7_a1": "없음",
  "patient_foot_ankle_pain_q7_a2": "약간",
  "patient_foot_ankle_pain_q7_a3": "보통",
  "patient_foot_ankle_pain_q7_a4": "심각",
  "patient_foot_ankle_pain_q7_a5": "극단적인",
  "patient_foot_ankle_pain_q8": "발/발목을 중심으로 돌리기/피벗하기",
  "patient_foot_ankle_pain_q8_a1": "없음",
  "patient_foot_ankle_pain_q8_a2": "약간",
  "patient_foot_ankle_pain_q8_a3": "보통",
  "patient_foot_ankle_pain_q8_a4": "심각",
  "patient_foot_ankle_pain_q8_a5": "극단적인",
  "patient_foot_ankle_pain_q9": "발/발목을 완전히 펴는 것",
  "patient_foot_ankle_pain_q9_a1": "없음",
  "patient_foot_ankle_pain_q9_a2": "약간",
  "patient_foot_ankle_pain_q9_a3": "보통",
  "patient_foot_ankle_pain_q9_a4": "심각",
  "patient_foot_ankle_pain_q9_a5": "극단적인",
  "patient_foot_ankle_pain_q10": "발/발목을 완전히 굽히기",
  "patient_foot_ankle_pain_q10_a1": "없음",
  "patient_foot_ankle_pain_q10_a2": "약간",
  "patient_foot_ankle_pain_q10_a3": "보통",
  "patient_foot_ankle_pain_q10_a4": "심각",
  "patient_foot_ankle_pain_q10_a5": "극단적인",
  "patient_foot_ankle_pain_q11": "평면 위를 걷기",
  "patient_foot_ankle_pain_q11_a1": "없음",
  "patient_foot_ankle_pain_q11_a2": "약간",
  "patient_foot_ankle_pain_q11_a3": "보통",
  "patient_foot_ankle_pain_q11_a4": "심각",
  "patient_foot_ankle_pain_q11_a5": "극단적인",
  "patient_foot_ankle_pain_q12": "계단을 오르거나 내려가는 것",
  "patient_foot_ankle_pain_q12_a1": "없음",
  "patient_foot_ankle_pain_q12_a2": "약간",
  "patient_foot_ankle_pain_q12_a3": "보통",
  "patient_foot_ankle_pain_q12_a4": "심각",
  "patient_foot_ankle_pain_q12_a5": "극단적인",
  "patient_foot_ankle_pain_q13": "저녁에 침상에서",
  "patient_foot_ankle_pain_q13_a1": "없음",
  "patient_foot_ankle_pain_q13_a2": "약간",
  "patient_foot_ankle_pain_q13_a3": "보통",
  "patient_foot_ankle_pain_q13_a4": "심각",
  "patient_foot_ankle_pain_q13_a5": "극단적인",
  "patient_foot_ankle_pain_q14": "앉거나 누워있을 때",
  "patient_foot_ankle_pain_q14_a1": "없음",
  "patient_foot_ankle_pain_q14_a2": "약간",
  "patient_foot_ankle_pain_q14_a3": "보통",
  "patient_foot_ankle_pain_q14_a4": "심각",
  "patient_foot_ankle_pain_q14_a5": "극단적인",
  "patient_foot_ankle_pain_q15": "직립하기",
  "patient_foot_ankle_pain_q15_a1": "없음",
  "patient_foot_ankle_pain_q15_a2": "약간",
  "patient_foot_ankle_pain_q15_a3": "보통",
  "patient_foot_ankle_pain_q15_a4": "심각",
  "patient_foot_ankle_pain_q15_a5": "극단적인",
  "patient_foot_ankle_instability": "불안정성",
  "patient_foot_ankle_instability_q1": "반복적으로 발목을 삐끗하거나 발목이 접질리거나 접질릴 것 같은 느낌이 드십니까?",
  "patient_foot_ankle_instability_q1_a1": "아니오",
  "patient_foot_ankle_instability_q1_a2": "예",
  "patient_foot_ankle_instability_q2": "발목 불안정성/재발 염좌로 진단/치료를받은 적이 있습니까?",
  "patient_foot_ankle_instability_q2_a1": "아니오",
  "patient_foot_ankle_instability_q2_a2": "예",
  "patient_foot_ankle_instability_q3": "발목 불안정성/재발 염좌로 진단/치료를받은 적이 있습니까?",
  "patient_foot_ankle_instability_q3_a1": "아니오",
  "patient_foot_ankle_instability_q3_a2": "예",
  "patient_foot_ankle_cumberland": "컴버랜드 발목 불안정도 도구",
  "patient_foot_ankle_cumberland_instruction": "각 질문에서 발목을 가장 잘 설명하는 진술을 선택하십시오.",
  "patient_foot_ankle_cumberland_q1": "나는 발목에 통증이 있어요.",
  "patient_foot_ankle_cumberland_q1_a1": "전혀",
  "patient_foot_ankle_cumberland_q1_a2": "스포츠 중",
  "patient_foot_ankle_cumberland_q1_a3": "고르지 않은 표면 위 달리기",
  "patient_foot_ankle_cumberland_q1_a4": "평평한 표면 위 달리기",
  "patient_foot_ankle_cumberland_q1_a5": "고르지 않은 표면 위 걷기",
  "patient_foot_ankle_cumberland_q1_a6": "평평한 표면 위 걷기",
  "patient_foot_ankle_cumberland_q2": "발목은 불안정하다고 느낀다",
  "patient_foot_ankle_cumberland_q2_a1": "전혀",
  "patient_foot_ankle_cumberland_q2_a2": "때때로 스포츠 중에 (매번 아님)",
  "patient_foot_ankle_cumberland_q2_a3": "스포츠 중에 자주 (매번)",
  "patient_foot_ankle_cumberland_q2_a4": "일상 활동 중 가끔",
  "patient_foot_ankle_cumberland_q2_a5": "일상 활동 중 자주",
  "patient_foot_ankle_cumberland_q3": "날카로운 회전을 할 때 발목이 불안정하다고 느낀다.",
  "patient_foot_ankle_cumberland_q3_a1": "전혀",
  "patient_foot_ankle_cumberland_q3_a2": "달릴 때 가끔",
  "patient_foot_ankle_cumberland_q3_a3": "달릴 때 자주",
  "patient_foot_ankle_cumberland_q3_a4": "걸을 때",
  "patient_foot_ankle_cumberland_q4": "계단을 내려갈 때 발목이 불안정하게 느껴진다.",
  "patient_foot_ankle_cumberland_q4_a1": "전혀",
  "patient_foot_ankle_cumberland_q4_a2": "빨리 가면",
  "patient_foot_ankle_cumberland_q4_a3": "가끔",
  "patient_foot_ankle_cumberland_q4_a4": "항상",
  "patient_foot_ankle_cumberland_q5": "한 다리로 서 있을 때 발목이 불안정해요.",
  "patient_foot_ankle_cumberland_q5_a1": "전혀",
  "patient_foot_ankle_cumberland_q5_a2": "발바닥에",
  "patient_foot_ankle_cumberland_q5_a3": "발이 평평한 상태에서",
  "patient_foot_ankle_cumberland_q6": "발목이 불안정하다고 느낄 때는 언제인가요?",
  "patient_foot_ankle_cumberland_q6_a1": "전혀",
  "patient_foot_ankle_cumberland_q6_a2": "좌우로 뛰어다니기",
  "patient_foot_ankle_cumberland_q6_a3": "그 자리에서 바로 뛰어내리기",
  "patient_foot_ankle_cumberland_q6_a4": "점프한다",
  "patient_foot_ankle_cumberland_q7": "발목이 불안정하다고 느낄 때는 언제인가요?",
  "patient_foot_ankle_cumberland_q7_a1": "전혀",
  "patient_foot_ankle_cumberland_q7_a2": "고르지 않은 표면에서 달리기",
  "patient_foot_ankle_cumberland_q7_a3": "고르지 않은 표면에서 조깅",
  "patient_foot_ankle_cumberland_q7_a4": "고르지 않은 표면 위를 걷기",
  "patient_foot_ankle_cumberland_q7_a5": "평평한 표면을 걷기",
  "patient_foot_ankle_cumberland_q8": "일반적으로 발목이 구르기(또는 비틀어지기） 시작하면 멈출 수 있다",
  "patient_foot_ankle_cumberland_q8_a1": "즉시",
  "patient_foot_ankle_cumberland_q8_a2": "자주",
  "patient_foot_ankle_cumberland_q8_a3": "가끔",
  "patient_foot_ankle_cumberland_q8_a4": "전혀",
  "patient_foot_ankle_cumberland_q8_a5": "발목으로 구른 적이 없다",
  "patient_foot_ankle_cumberland_q9": "발목이 구른 일반적인 사고 후 발목이 \"정상\"으로 돌아온다.",
  "patient_foot_ankle_cumberland_q9_a1": "거의 즉시",
  "patient_foot_ankle_cumberland_q9_a2": "하루 미만",
  "patient_foot_ankle_cumberland_q9_a3": "1-2 일",
  "patient_foot_ankle_cumberland_q9_a4": "2 일 이상",
  "patient_foot_ankle_cumberland_q9_a5": "발목으로 구른 적이 없다",
  "patient_foot_ankle_other_symptoms": "다른 증상",
  "patient_foot_ankle_other_symptoms_instruction1": "발/발목 증상에 가장 잘 맞는 답을 선택하십시오.",
  "patient_foot_ankle_other_symptoms_instruction2": "질문에 어떻게 답해야 할지 잘 모르겠다면 가능한 최선의 답을 제공해 주세요.",
  "patient_foot_ankle_other_symptoms_note": "주: 이 질문은 지난 일주일 동안의 발/발목 증상을 생각하면서 답해야 합니다.",
  "patient_foot_ankle_other_symptoms_instruction3": "다음을 가지고 있습니까?",
  "patient_foot_ankle_other_symptoms_instruction3_0": "전혀",
  "patient_foot_ankle_other_symptoms_instruction3_1": "드물게",
  "patient_foot_ankle_other_symptoms_instruction3_2": "가끔",
  "patient_foot_ankle_other_symptoms_instruction3_3": "자주",
  "patient_foot_ankle_other_symptoms_instruction3_4": "항상",
  "patient_foot_ankle_other_symptoms_q1": "발/발목이 부어오름 (오른쪽)",
  "patient_foot_ankle_other_symptoms_q1_a1": "전혀",
  "patient_foot_ankle_other_symptoms_q1_a2": "드물게",
  "patient_foot_ankle_other_symptoms_q1_a3": "가끔",
  "patient_foot_ankle_other_symptoms_q1_a4": "자주",
  "patient_foot_ankle_other_symptoms_q1_a5": "항상",
  "patient_foot_ankle_other_symptoms_q2": "발/발목이 움직일 때 삐걱거리는 소리, 딸깍거리는 소리 또는 기타 소음이 들린다",
  "patient_foot_ankle_other_symptoms_q2_a1": "전혀",
  "patient_foot_ankle_other_symptoms_q2_a2": "드물게",
  "patient_foot_ankle_other_symptoms_q2_a3": "가끔",
  "patient_foot_ankle_other_symptoms_q2_a4": "자주",
  "patient_foot_ankle_other_symptoms_q2_a5": "항상",
  "patient_foot_ankle_other_symptoms_q3": "움직일 때 발이 걸린다",
  "patient_foot_ankle_other_symptoms_q3_a1": "전혀",
  "patient_foot_ankle_other_symptoms_q3_a2": "드물게",
  "patient_foot_ankle_other_symptoms_q3_a3": "가끔",
  "patient_foot_ankle_other_symptoms_q3_a4": "자주",
  "patient_foot_ankle_other_symptoms_q3_a5": "항상",
  "patient_foot_ankle_other_symptoms_instruction4": "발/발목 뻣뻣함이 얼마나 심한가요?",
  "patient_foot_ankle_other_symptoms_instruction4_0": "없음",
  "patient_foot_ankle_other_symptoms_instruction4_1": "약간",
  "patient_foot_ankle_other_symptoms_instruction4_2": "보통",
  "patient_foot_ankle_other_symptoms_instruction4_3": "심각",
  "patient_foot_ankle_other_symptoms_instruction4_4": "극단적인",
  "patient_foot_ankle_other_symptoms_q4": "아침에 처음 깨어난 후",
  "patient_foot_ankle_other_symptoms_q4_a1": "없음",
  "patient_foot_ankle_other_symptoms_q4_a2": "약간",
  "patient_foot_ankle_other_symptoms_q4_a3": "보통",
  "patient_foot_ankle_other_symptoms_q4_a4": "심각",
  "patient_foot_ankle_other_symptoms_q4_a5": "극단적인",
  "patient_foot_ankle_other_symptoms_q5": "하루 중 늦은 시간에 앉거나 누워 있거나 휴식을 취한 후",
  "patient_foot_ankle_other_symptoms_q5_a1": "없음",
  "patient_foot_ankle_other_symptoms_q5_a2": "약간",
  "patient_foot_ankle_other_symptoms_q5_a3": "보통",
  "patient_foot_ankle_other_symptoms_q5_a4": "심각",
  "patient_foot_ankle_other_symptoms_q5_a5": "극단적인",
  "patient_foot_ankle_function": "기능",
  "patient_foot_ankle_function_q1": "발/발목으로 인해 일상 생활에서 어떤 제한이 있습니까?",
  "patient_foot_ankle_function_q1_a1": "제한도 없고 지원도 없다",
  "patient_foot_ankle_function_q1_a2": "일상 활동의 제한 없음, 레크리에이션 활동의 제한, 지원 없음",
  "patient_foot_ankle_function_q1_a3": "일상 및 레크리에이션 활동 제한, 지팡이 필요",
  "patient_foot_ankle_function_q1_a4": "일상 및 여가 활동의 심각한 제한. 보행기, 목발, 휠체어, 보조기 필요",
  "patient_foot_ankle_function_q2": "평소 어떤 신발을 신으시나요?",
  "patient_foot_ankle_function_q2_a1": "패셔너블한 일반 신발, 인서트 필요 없음",
  "patient_foot_ankle_function_q2_a2": "편안한 신발, 신발 인서트",
  "patient_foot_ankle_function_q2_a3": "개조된 신발 또는 보조기",
  "patient_foot_ankle_function_q3": "어떤 종류의 보행 표면이 걷는 데 어려움을 주나요?",
  "patient_foot_ankle_function_q3_a1": "어떤 표면에서도 어려움 없음",
  "patient_foot_ankle_function_q3_a2": "고르지 않은 지형, 계단, 경사, 사다리에 약간의 어려움",
  "patient_foot_ankle_function_q3_a3": "고르지 않은 지형, 계단, 경사, 사다리에서 심한 어려움",
  "patient_foot_ankle_function_instruction": "당신의 발/발목으로 인해 경험한 어려움의 정도를 나타내십시오.",
  "patient_foot_ankle_function_instruction_0": "어려움 없음",
  "patient_foot_ankle_function_instruction_1": "약간의 어려움",
  "patient_foot_ankle_function_instruction_2": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_instruction_3": "극도의 어려움",
  "patient_foot_ankle_function_instruction_4": "할 수 없다",
  "patient_foot_ankle_function_note": "주: 해당 활동이 발이나 발목이 아닌 다른 요인으로 인해 제한되는 경우, 해당 항목에 '해당 없음'을 입력하세요. 그렇지 않은 경우, 모든 질문에 지난 일주일 동안의 상태를 가장 잘 설명하는 한 가지 응답으로 답해 주세요.",
  "patient_foot_ankle_function_q4": "발/발목을 완전히 펴는 것",
  "patient_foot_ankle_function_q4_a1": "어려움 없음",
  "patient_foot_ankle_function_q4_a2": "약간의 어려움",
  "patient_foot_ankle_function_q4_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q4_a4": "극도의 어려움",
  "patient_foot_ankle_function_q4_a5": "할 수 없다",
  "patient_foot_ankle_function_q5": "발/발목을 완전히 굽히기",
  "patient_foot_ankle_function_q5_a1": "어려움 없음",
  "patient_foot_ankle_function_q5_a2": "약간의 어려움",
  "patient_foot_ankle_function_q5_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q5_a4": "극도의 어려움",
  "patient_foot_ankle_function_q5_a5": "할 수 없다",
  "patient_foot_ankle_function_q6": "계단 내려가기",
  "patient_foot_ankle_function_q6_a1": "어려움 없음",
  "patient_foot_ankle_function_q6_a2": "약간의 어려움",
  "patient_foot_ankle_function_q6_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q6_a4": "극도의 어려움",
  "patient_foot_ankle_function_q6_a5": "할 수 없다",
  "patient_foot_ankle_function_q7": "계단 오르기",
  "patient_foot_ankle_function_q7_a1": "어려움 없음",
  "patient_foot_ankle_function_q7_a2": "약간의 어려움",
  "patient_foot_ankle_function_q7_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q7_a4": "극도의 어려움",
  "patient_foot_ankle_function_q7_a5": "할 수 없다",
  "patient_foot_ankle_function_q8": "앉아서 일어나기",
  "patient_foot_ankle_function_q8_a1": "어려움 없음",
  "patient_foot_ankle_function_q8_a2": "약간의 어려움",
  "patient_foot_ankle_function_q8_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q8_a4": "극도의 어려움",
  "patient_foot_ankle_function_q8_a5": "할 수 없다",
  "patient_foot_ankle_function_q9": "서 있기",
  "patient_foot_ankle_function_q9_a1": "어려움 없음",
  "patient_foot_ankle_function_q9_a2": "약간의 어려움",
  "patient_foot_ankle_function_q9_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q9_a4": "극도의 어려움",
  "patient_foot_ankle_function_q9_a5": "할 수 없다",
  "patient_foot_ankle_function_q10": "바닥으로 숙이기/물건을 집기",
  "patient_foot_ankle_function_q10_a1": "어려움 없음",
  "patient_foot_ankle_function_q10_a2": "약간의 어려움",
  "patient_foot_ankle_function_q10_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q10_a4": "극도의 어려움",
  "patient_foot_ankle_function_q10_a5": "할 수 없다",
  "patient_foot_ankle_function_q11": "평면 위를 걷기",
  "patient_foot_ankle_function_q11_a1": "어려움 없음",
  "patient_foot_ankle_function_q11_a2": "약간의 어려움",
  "patient_foot_ankle_function_q11_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q11_a4": "극도의 어려움",
  "patient_foot_ankle_function_q11_a5": "할 수 없다",
  "patient_foot_ankle_function_q12": "걸을 수있는 최대 거리는 얼마입니까?",
  "patient_foot_ankle_function_q12_a1": "6 블록 이상",
  "patient_foot_ankle_function_q12_a2": "4-6 블록",
  "patient_foot_ankle_function_q12_a3": "1-3 블록",
  "patient_foot_ankle_function_q12_a4": "1 블록 미만",
  "patient_foot_ankle_function_q13": "신발 없이 평평한 땅을 걷는 것",
  "patient_foot_ankle_function_q13_a1": "어려움 없음",
  "patient_foot_ankle_function_q13_a2": "약간의 어려움",
  "patient_foot_ankle_function_q13_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q13_a4": "극도의 어려움",
  "patient_foot_ankle_function_q13_a5": "할 수 없다",
  "patient_foot_ankle_function_q14": "언덕을 오르는 것",
  "patient_foot_ankle_function_q14_a1": "어려움 없음",
  "patient_foot_ankle_function_q14_a2": "약간의 어려움",
  "patient_foot_ankle_function_q14_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q14_a4": "극도의 어려움",
  "patient_foot_ankle_function_q14_a5": "할 수 없다",
  "patient_foot_ankle_function_q15": "언덕을 내려가는 것",
  "patient_foot_ankle_function_q15_a1": "어려움 없음",
  "patient_foot_ankle_function_q15_a2": "약간의 어려움",
  "patient_foot_ankle_function_q15_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q15_a4": "극도의 어려움",
  "patient_foot_ankle_function_q15_a5": "할 수 없다",
  "patient_foot_ankle_function_q16": "고르지 않은 땅에서 걷기",
  "patient_foot_ankle_function_q16_a1": "어려움 없음",
  "patient_foot_ankle_function_q16_a2": "약간의 어려움",
  "patient_foot_ankle_function_q16_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q16_a4": "극도의 어려움",
  "patient_foot_ankle_function_q16_a5": "할 수 없다",
  "patient_foot_ankle_function_q17": "경사로를 오르내리기",
  "patient_foot_ankle_function_q17_a1": "어려움 없음",
  "patient_foot_ankle_function_q17_a2": "약간의 어려움",
  "patient_foot_ankle_function_q17_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q17_a4": "극도의 어려움",
  "patient_foot_ankle_function_q17_a5": "할 수 없다",
  "patient_foot_ankle_function_q18": "발끝까지 다가가기",
  "patient_foot_ankle_function_q18_a1": "어려움 없음",
  "patient_foot_ankle_function_q18_a2": "약간의 어려움",
  "patient_foot_ankle_function_q18_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q18_a4": "극도의 어려움",
  "patient_foot_ankle_function_q18_a5": "할 수 없다",
  "patient_foot_ankle_function_q19": "보행 초기",
  "patient_foot_ankle_function_q19_a1": "어려움 없음",
  "patient_foot_ankle_function_q19_a2": "약간의 어려움",
  "patient_foot_ankle_function_q19_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q19_a4": "극도의 어려움",
  "patient_foot_ankle_function_q19_a5": "할 수 없다",
  "patient_foot_ankle_function_q20": "5분 이내 걷기",
  "patient_foot_ankle_function_q20_a1": "어려움 없음",
  "patient_foot_ankle_function_q20_a2": "약간의 어려움",
  "patient_foot_ankle_function_q20_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q20_a4": "극도의 어려움",
  "patient_foot_ankle_function_q20_a5": "할 수 없다",
  "patient_foot_ankle_function_q21": "약 10분 도보",
  "patient_foot_ankle_function_q21_a1": "어려움 없음",
  "patient_foot_ankle_function_q21_a2": "약간의 어려움",
  "patient_foot_ankle_function_q21_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q21_a4": "극도의 어려움",
  "patient_foot_ankle_function_q21_a5": "할 수 없다",
  "patient_foot_ankle_function_q22": "15분 이상 걷기",
  "patient_foot_ankle_function_q22_a1": "어려움 없음",
  "patient_foot_ankle_function_q22_a2": "약간의 어려움",
  "patient_foot_ankle_function_q22_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q22_a4": "극도의 어려움",
  "patient_foot_ankle_function_q22_a5": "할 수 없다",
  "patient_foot_ankle_function_q23": "차량 탑승/하차",
  "patient_foot_ankle_function_q23_a1": "어려움 없음",
  "patient_foot_ankle_function_q23_a2": "약간의 어려움",
  "patient_foot_ankle_function_q23_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q23_a4": "극도의 어려움",
  "patient_foot_ankle_function_q23_a5": "할 수 없다",
  "patient_foot_ankle_function_q24": "쇼핑하기",
  "patient_foot_ankle_function_q24_a1": "어려움 없음",
  "patient_foot_ankle_function_q24_a2": "약간의 어려움",
  "patient_foot_ankle_function_q24_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q24_a4": "극도의 어려움",
  "patient_foot_ankle_function_q24_a5": "할 수 없다",
  "patient_foot_ankle_function_q25": "양말/스타킹 신기",
  "patient_foot_ankle_function_q25_a1": "어려움 없음",
  "patient_foot_ankle_function_q25_a2": "약간의 어려움",
  "patient_foot_ankle_function_q25_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q25_a4": "극도의 어려움",
  "patient_foot_ankle_function_q25_a5": "할 수 없다",
  "patient_foot_ankle_function_q26": "양말/스타킹 벗기",
  "patient_foot_ankle_function_q26_a1": "어려움 없음",
  "patient_foot_ankle_function_q26_a2": "약간의 어려움",
  "patient_foot_ankle_function_q26_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q26_a4": "극도의 어려움",
  "patient_foot_ankle_function_q26_a5": "할 수 없다",
  "patient_foot_ankle_function_q27": "침대에서 일어나기",
  "patient_foot_ankle_function_q27_a1": "어려움 없음",
  "patient_foot_ankle_function_q27_a2": "약간의 어려움",
  "patient_foot_ankle_function_q27_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q27_a4": "극도의 어려움",
  "patient_foot_ankle_function_q27_a5": "할 수 없다",
  "patient_foot_ankle_function_q28": "침대에 누워(뒤집기, 발/발목 자세 유지)",
  "patient_foot_ankle_function_q28_a1": "어려움 없음",
  "patient_foot_ankle_function_q28_a2": "약간의 어려움",
  "patient_foot_ankle_function_q28_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q28_a4": "극도의 어려움",
  "patient_foot_ankle_function_q28_a5": "할 수 없다",
  "patient_foot_ankle_function_q29": "잠자는",
  "patient_foot_ankle_function_q29_a1": "어려움 없음",
  "patient_foot_ankle_function_q29_a2": "약간의 어려움",
  "patient_foot_ankle_function_q29_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q29_a4": "극도의 어려움",
  "patient_foot_ankle_function_q29_a5": "할 수 없다",
  "patient_foot_ankle_function_q30": "침대에 오르고 내리기",
  "patient_foot_ankle_function_q30_a1": "어려움 없음",
  "patient_foot_ankle_function_q30_a2": "약간의 어려움",
  "patient_foot_ankle_function_q30_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q30_a4": "극도의 어려움",
  "patient_foot_ankle_function_q30_a5": "할 수 없다",
  "patient_foot_ankle_function_q31": "앉아 있기",
  "patient_foot_ankle_function_q31_a1": "어려움 없음",
  "patient_foot_ankle_function_q31_a2": "약간의 어려움",
  "patient_foot_ankle_function_q31_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q31_a4": "극도의 어려움",
  "patient_foot_ankle_function_q31_a5": "할 수 없다",
  "patient_foot_ankle_function_q32": "변기 타기 및 내리기",
  "patient_foot_ankle_function_q32_a1": "어려움 없음",
  "patient_foot_ankle_function_q32_a2": "약간의 어려움",
  "patient_foot_ankle_function_q32_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q32_a4": "극도의 어려움",
  "patient_foot_ankle_function_q32_a5": "할 수 없다",
  "patient_foot_ankle_function_q33": "가정 책임",
  "patient_foot_ankle_function_q33_a1": "어려움 없음",
  "patient_foot_ankle_function_q33_a2": "약간의 어려움",
  "patient_foot_ankle_function_q33_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q33_a4": "극도의 어려움",
  "patient_foot_ankle_function_q33_a5": "할 수 없다",
  "patient_foot_ankle_function_q34": "일상생활 활동",
  "patient_foot_ankle_function_q34_a1": "어려움 없음",
  "patient_foot_ankle_function_q34_a2": "약간의 어려움",
  "patient_foot_ankle_function_q34_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q34_a4": "극도의 어려움",
  "patient_foot_ankle_function_q34_a5": "할 수 없다",
  "patient_foot_ankle_function_q35": "개인 관리(몸 씻기, 옷 갈아입기 등)",
  "patient_foot_ankle_function_q35_a1": "어려움 없음",
  "patient_foot_ankle_function_q35_a2": "약간의 어려움",
  "patient_foot_ankle_function_q35_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q35_a4": "극도의 어려움",
  "patient_foot_ankle_function_q35_a5": "할 수 없다",
  "patient_foot_ankle_function_q36": "무거운 작업 (밀기/당기기, 등반, 운반, 무거운 상자 옮기기, 바닥 닦기 등)",
  "patient_foot_ankle_function_q36_a1": "어려움 없음",
  "patient_foot_ankle_function_q36_a2": "약간의 어려움",
  "patient_foot_ankle_function_q36_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q36_a4": "극도의 어려움",
  "patient_foot_ankle_function_q36_a5": "할 수 없다",
  "patient_foot_ankle_function_q37": "가벼운 집안일(요리, 청소 등)",
  "patient_foot_ankle_function_q37_a1": "어려움 없음",
  "patient_foot_ankle_function_q37_a2": "약간의 어려움",
  "patient_foot_ankle_function_q37_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q37_a4": "극도의 어려움",
  "patient_foot_ankle_function_q37_a5": "할 수 없다",
  "patient_foot_ankle_function_q38": "가벼운~중간 정도의 작업 (서 있기, 걷기)",
  "patient_foot_ankle_function_q38_a1": "어려움 없음",
  "patient_foot_ankle_function_q38_a2": "약간의 어려움",
  "patient_foot_ankle_function_q38_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q38_a4": "극도의 어려움",
  "patient_foot_ankle_function_q38_a5": "할 수 없다",
  "patient_foot_ankle_function_q39": "여가 활동",
  "patient_foot_ankle_function_q39_a1": "어려움 없음",
  "patient_foot_ankle_function_q39_a2": "약간의 어려움",
  "patient_foot_ankle_function_q39_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q39_a4": "극도의 어려움",
  "patient_foot_ankle_function_q39_a5": "할 수 없다",
  "patient_foot_ankle_function_q40": "스쾃(기마)자세 취하기",
  "patient_foot_ankle_function_q40_a1": "어려움 없음",
  "patient_foot_ankle_function_q40_a2": "약간의 어려움",
  "patient_foot_ankle_function_q40_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q40_a4": "극도의 어려움",
  "patient_foot_ankle_function_q40_a5": "할 수 없다",
  "patient_foot_ankle_function_q41": "발/발목을 중심으로 돌리기/피벗하기",
  "patient_foot_ankle_function_q41_a1": "어려움 없음",
  "patient_foot_ankle_function_q41_a2": "약간의 어려움",
  "patient_foot_ankle_function_q41_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q41_a4": "극도의 어려움",
  "patient_foot_ankle_function_q41_a5": "할 수 없다",
  "patient_foot_ankle_function_q42": "무릎 꿇기",
  "patient_foot_ankle_function_q42_a1": "어려움 없음",
  "patient_foot_ankle_function_q42_a2": "약간의 어려움",
  "patient_foot_ankle_function_q42_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q42_a4": "극도의 어려움",
  "patient_foot_ankle_function_q42_a5": "할 수 없다",
  "patient_foot_ankle_function_q43": "달리기",
  "patient_foot_ankle_function_q43_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_function_q43_a1": "어려움 없음",
  "patient_foot_ankle_function_q43_a2": "약간의 어려움",
  "patient_foot_ankle_function_q43_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q43_a4": "극도의 어려움",
  "patient_foot_ankle_function_q43_a5": "할 수 없다",
  "patient_foot_ankle_function_q44": "점프하기",
  "patient_foot_ankle_function_q44_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_function_q44_a1": "어려움 없음",
  "patient_foot_ankle_function_q44_a2": "약간의 어려움",
  "patient_foot_ankle_function_q44_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q44_a4": "극도의 어려움",
  "patient_foot_ankle_function_q44_a5": "할 수 없다",
  "patient_foot_ankle_function_q45": "착륙",
  "patient_foot_ankle_function_q45_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_function_q45_a1": "어려움 없음",
  "patient_foot_ankle_function_q45_a2": "약간의 어려움",
  "patient_foot_ankle_function_q45_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q45_a4": "극도의 어려움",
  "patient_foot_ankle_function_q45_a5": "할 수 없다",
  "patient_foot_ankle_function_q46": "빠르게 시작하고 멈추기",
  "patient_foot_ankle_function_q46_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_function_q46_a1": "어려움 없음",
  "patient_foot_ankle_function_q46_a2": "약간의 어려움",
  "patient_foot_ankle_function_q46_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q46_a4": "극도의 어려움",
  "patient_foot_ankle_function_q46_a5": "할 수 없다",
  "patient_foot_ankle_function_q47": "절단 / 측면 이동",
  "patient_foot_ankle_function_q47_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_function_q47_a1": "어려움 없음",
  "patient_foot_ankle_function_q47_a2": "약간의 어려움",
  "patient_foot_ankle_function_q47_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q47_a4": "극도의 어려움",
  "patient_foot_ankle_function_q47_a5": "할 수 없다",
  "patient_foot_ankle_function_q48": "저영향 운동",
  "patient_foot_ankle_function_q48_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_function_q48_a1": "어려움 없음",
  "patient_foot_ankle_function_q48_a2": "약간의 어려움",
  "patient_foot_ankle_function_q48_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q48_a4": "극도의 어려움",
  "patient_foot_ankle_function_q48_a5": "할 수 없다",
  "patient_foot_ankle_function_q49": "일반적인 기술로 활동을 수행할 수 있는 능력",
  "patient_foot_ankle_function_q49_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_function_q49_a1": "어려움 없음",
  "patient_foot_ankle_function_q49_a2": "약간의 어려움",
  "patient_foot_ankle_function_q49_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q49_a4": "극도의 어려움",
  "patient_foot_ankle_function_q49_a5": "할 수 없다",
  "patient_foot_ankle_function_q50": "원하는 스포츠에 원하는 만큼 오랫동안 참여할 수 있는 능력",
  "patient_foot_ankle_function_q50_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_function_q50_a1": "어려움 없음",
  "patient_foot_ankle_function_q50_a2": "약간의 어려움",
  "patient_foot_ankle_function_q50_a3": "어려움이 어느정도 있다",
  "patient_foot_ankle_function_q50_a4": "극도의 어려움",
  "patient_foot_ankle_function_q50_a5": "할 수 없다",
  "patient_foot_ankle_function_q51": "오늘 자신의 발목/발을 어떻게 백분율로 평가 하시겠습니까? (0 ~ 100%, 100% 정상)",
  "patient_foot_ankle_function_q51_a1": "정상",
  "patient_foot_ankle_function_q52": "정기적으로 참여할 수있는 최고 수준의 활동은 무엇입니까?",
  "patient_foot_ankle_function_q52_q1": "발 또는 발목 부상/문제 이전",
  "patient_foot_ankle_function_q52_q1_a1": "최고 수준의 스포츠",
  "patient_foot_ankle_function_q52_q1_a2": "일반 수준의 경쟁 스포츠",
  "patient_foot_ankle_function_q52_q1_a3": "레저 스포츠",
  "patient_foot_ankle_function_q52_q1_a4": "스포츠 활동 없음",
  "patient_foot_ankle_function_q52_q1_a5": "운동 없음, 일 없음",
  "patient_foot_ankle_function_q52_q1_a4_o7": "발목 문제로 인해 걷지 못하는, 장애인",
  "patient_foot_ankle_function_q52_q1_a1_title": "상위 수준 (국제 엘리트, 프로, 국가 대표팀 또는 1부 리그)",
  "patient_foot_ankle_function_q52_q1_a2_title": "일반 수준의 경쟁 스포츠",
  "patient_foot_ankle_function_q52_q1_a3_title": "레크리에이션",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1": "에어로빅, 피트니스",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2": "알파인 스키와 스노보드",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3": "미식 축구",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4": "배드민턴",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5": "야구",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6": "농구",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7": "볼링/컬링",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08": "복싱",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9": "크로스컨트리 러닝",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10": "사이클링",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11": "춤",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12": "다이빙",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13": "승마",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14": "펜싱",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15": "플로어볼",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16": "프리스타일 스노우보드",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17": "골프",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18": "체조",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19": "핸드볼",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20": "하키",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21": "아이스 하키",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22": "코르프볼",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23": "무술",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24": "현대 오크텀플론",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25": "모터스포츠, 기술 스포츠",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26": "산과 언덕을 오르는 것",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27": "산악자전거/비엠엑스",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28": "북유럽 스키",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29": "오리엔티어링",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30": "낙하산",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31": "파워 리프팅",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32": "리듬 체조",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33": "보트 경기, 카약",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34": "러그비",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35": "항해",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36": "스쿠버 다이빙",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37": "사격, 양궁",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38": "스케이팅, 인라인 스케이팅",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39": "축구",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40": "소프트볼",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41": "스쿼시",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42": "서핑, 윈드 서핑",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43": "탁구",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44": "테니스",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45": "육상: 필드 경기",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46": "육상: 트랙 이벤트",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47": "트라이애슬론",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48": "배구",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49": "수구와 수영",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50": "수상 스키",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51": "역도, 바디 빌딩",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52": "레슬링",
  "patient_foot_ankle_function_q52_q1_a4_o1": "무거운 육체적인 일",
  "patient_foot_ankle_function_q52_q1_a4_o2": "신체적인 일",
  "patient_foot_ankle_function_q52_q1_a4_o3": "특별한 직업: 발레 무용수, 전문 군인, 특수 구조대원, 스턴트맨",
  "patient_foot_ankle_function_q52_q1_a4_o4": "어떤 울퉁불퉁한 지면에서도 걷을 수 있습니다.",
  "patient_foot_ankle_function_q52_q1_a4_o5": "매일 활동은 제한되지 않습니다.",
  "patient_foot_ankle_function_q52_q1_a4_o6": "고른 지면을 걸을 수 있지만 일상적인 활동 제한",
  "patient_foot_ankle_function_q52_q2": "현재 상태",
  "patient_foot_ankle_function_q52_q2_a1": "최상위 레벨",
  "patient_foot_ankle_function_q52_q2_a2": "일반 수준의 경쟁 스포츠",
  "patient_foot_ankle_function_q52_q2_a3": "여가",
  "patient_foot_ankle_function_q52_q2_a4": "스포츠 활동이 없다",
  "patient_foot_ankle_function_q52_q2_a5": "운동 없이, 일 없이",
  "patient_foot_ankle_function_q52_q2_a4_o7": "발목 문제로 인해 걷지 못하는, 장애인",
  "patient_foot_ankle_function_q52_q2_a1_title": "상위 수준 (국제 엘리트, 프로, 국가 대표팀 또는 1부 리그)",
  "patient_foot_ankle_function_q52_q2_a2_title": "일반 수준의 경쟁 스포츠",
  "patient_foot_ankle_function_q52_q2_a3_title": "여가",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1": "에어로빅, 피트니스",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2": "알파인 스키와 스노보드",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3": "미식 축구",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4": "배드민턴",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5": "야구",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6": "농구",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7": "볼링/컬링",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8": "복싱",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9": "크로스컨트리 러닝",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10": "사이클링",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11": "춤",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12": "다이빙",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13": "승마",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14": "펜싱",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15": "플로어볼",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16": "프리스타일 스노우보드",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17": "골프",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18": "체조",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19": "핸드볼",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20": "하키",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21": "아이스 하키",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22": "코르프볼",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23": "무술",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24": "현대 오크텀플론",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25": "모터스포츠, 기술 스포츠",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26": "산과 언덕을 오르는 것",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27": "산악자전거/비엠엑스",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28": "북유럽 스키",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29": "오리엔티어링",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30": "낙하산",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31": "파워 리프팅",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32": "리듬 체조",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33": "보트 경기, 카약",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34": "러그비",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35": "항해",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36": "스쿠버 다이빙",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37": "사격, 양궁",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38": "스케이팅, 인라인 스케이팅",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39": "축구",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40": "소프트볼",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41": "스쿼시",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42": "서핑, 윈드 서핑",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43": "탁구",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44": "테니스",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45": "육상: 필드 경기",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46": "육상: 트랙 이벤트",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47": "트라이애슬론",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48": "배구",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49": "수구와 수영",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50": "수상 스키",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51": "역도, 바디 빌딩",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52": "레슬링",
  "patient_foot_ankle_function_q52_q2_a4_o1": "무거운 육체적인 일",
  "patient_foot_ankle_function_q52_q2_a4_o2": "신체적인 일",
  "patient_foot_ankle_function_q52_q2_a4_o3": "특별한 직업: 발레 무용수, 전문 군인, 특수 구조대원, 스턴트맨",
  "patient_foot_ankle_function_q52_q2_a4_o4": "어떤 울퉁불퉁한 지면에서도 걷을 수 있습니다.",
  "patient_foot_ankle_function_q52_q2_a4_o5": "매일 활동은 제한되지 않습니다.",
  "patient_foot_ankle_function_q52_q2_a4_o6": "평지에서도 걷을 수 있으며, 모든 활동이 제한됩니다.",
  "patient_foot_ankle_quality_of_life": "삶의 질",
  "patient_foot_ankle_quality_of_life_instructions": "다음은 발/발목 문제와 관련하여 느낄 수 있는 사회적, 정서적, 생활 습관적 우려에 대해 질문입니다. 발/발목과 관련하여 평소 어떤 기분을 느꼈는지 생각해 주세요.",
  "patient_foot_ankle_quality_of_life_q1": "당신은 얼마나 자주 발/발목 문제에 대해 인식하고 있나요?",
  "patient_foot_ankle_quality_of_life_q1_a1": "전혀",
  "patient_foot_ankle_quality_of_life_q1_a2": "매월",
  "patient_foot_ankle_quality_of_life_q1_a3": "매주",
  "patient_foot_ankle_quality_of_life_q1_a4": "매일",
  "patient_foot_ankle_quality_of_life_q1_a5": "끊임없이",
  "patient_foot_ankle_quality_of_life_q2": "당신은 발/발목에 잠재적으로 손상을 줄 수 있는 활동을 피하기 위해 생활 방식을 변경했습니까?",
  "patient_foot_ankle_quality_of_life_q2_a1": "전혀 없다",
  "patient_foot_ankle_quality_of_life_q2_a2": "약간",
  "patient_foot_ankle_quality_of_life_q2_a3": "보통",
  "patient_foot_ankle_quality_of_life_q2_a4": "심하게",
  "patient_foot_ankle_quality_of_life_q2_a5": "완전히",
  "patient_foot_ankle_quality_of_life_q3": "당신의 발/발목에 대한 자신감 부족으로 얼마나 괴로움을 느끼고 있나요?",
  "patient_foot_ankle_quality_of_life_q3_a1": "전혀 없다",
  "patient_foot_ankle_quality_of_life_q3_a2": "약간",
  "patient_foot_ankle_quality_of_life_q3_a3": "보통",
  "patient_foot_ankle_quality_of_life_q3_a4": "심하게",
  "patient_foot_ankle_quality_of_life_q3_a5": "극도로",
  "patient_foot_ankle_quality_of_life_q4": "일반적으로 발/발목에 얼마나 많은 어려움이 있나요?",
  "patient_foot_ankle_quality_of_life_q4_a1": "없음",
  "patient_foot_ankle_quality_of_life_q4_a2": "약간",
  "patient_foot_ankle_quality_of_life_q4_a3": "보통",
  "patient_foot_ankle_quality_of_life_q4_a4": "심각",
  "patient_foot_ankle_quality_of_life_q4_a5": "극단적인",
  "patient_foot_ankle_quality_of_life_q5": "오늘 얼만큼 불안/우울 하십니까?",
  "patient_foot_ankle_quality_of_life_q5_a1": "불안/우울하지 않다",
  "patient_foot_ankle_quality_of_life_q5_a2": "약간",
  "patient_foot_ankle_quality_of_life_q5_a3": "보통",
  "patient_foot_ankle_quality_of_life_q5_a4": "심하게",
  "patient_foot_ankle_quality_of_life_q5_a5": "극도로",
  "patient_foot_ankle_quality_of_life_q6": "오늘의 건강은 얼마나 좋은가요?/나쁜가요? (100은 최고의 건강, 0은 상상할 수있는 최악의 건강을 의미합니다)",
  "patient_foot_ankle_quality_of_life_q6_a1": "최악의 건강",
  "patient_foot_ankle_quality_of_life_q6_a2": "최고의 건강",
  "patient_foot_ankle_satisfaction": "만족도",
  "patient_foot_ankle_satisfaction_instruction": "발/발목 수술 또는 치료 결과에 얼마나 만족하십니까?",
  "patient_foot_ankle_satisfaction_instruction_a1": "매우 불만족한다",
  "patient_foot_ankle_satisfaction_instruction_a2": "어느정도 불만족한다",
  "patient_foot_ankle_satisfaction_instruction_a3": "어느정도 만족한다",
  "patient_foot_ankle_satisfaction_instruction_a4": "매우 만족한다",
  "patient_foot_ankle_satisfaction_q1": "증상 완화를 위해?",
  "patient_foot_ankle_satisfaction_q1_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_satisfaction_q1_a1": "매우 불만족한다",
  "patient_foot_ankle_satisfaction_q1_a2": "어느정도 불만족한다",
  "patient_foot_ankle_satisfaction_q1_a3": "어느정도 만족한다",
  "patient_foot_ankle_satisfaction_q1_a4": "매우 만족한다",
  "patient_foot_ankle_satisfaction_q2": "일상 활동 능력 향상을 위해?",
  "patient_foot_ankle_satisfaction_q2_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_satisfaction_q2_a1": "매우 불만족한다",
  "patient_foot_ankle_satisfaction_q2_a2": "어느정도 불만족한다",
  "patient_foot_ankle_satisfaction_q2_a3": "어느정도 만족한다",
  "patient_foot_ankle_satisfaction_q2_a4": "매우 만족한다",
  "patient_foot_ankle_satisfaction_q3": "발/발목 문제를 \"잊어 버린\"가요? (발/발목 문제에 대한 인지)",
  "patient_foot_ankle_satisfaction_q3_not_applicable": "해당 사항 없음",
  "patient_foot_ankle_satisfaction_q3_a1": "매우 불만족한다",
  "patient_foot_ankle_satisfaction_q3_a2": "어느정도 불만족한다",
  "patient_foot_ankle_satisfaction_q3_a3": "어느정도 만족한다",
  "patient_foot_ankle_satisfaction_q3_a4": "매우 만족한다",
  "patient_foot_ankle_complete": "설문 끝",
  "patient_foot_ankle_complete_text1": "시간을 내어 이 설문 조사를 작성해 주셔서 감사합니다.",
  "patient_foot_ankle_complete_text2": "제출을 클릭하여 완료하고 점수를 확인하십시오.",
  "patient_foot_ankle_complete_text3": "귀하의 답은 기밀로 취급되며 모든 보고서는 신원 식별되지 않은 정보만 사용합니다.",
  "patient_foot_ankle_results": "결과",
  "patient_foot_ankle_results_text1": "다음은 설문대로 계산되는 통증과 발/발목 기능 점수입니다.",
  "patient_foot_ankle_results_pain": "통증",
  "patient_foot_ankle_results_function": "기능",
  "patient_foot_ankle_results_overall_health_perception": "전반적인 건강 인식",
  "patient_foot_ankle_results_feeling_of_instability": "불안정한 느낌",
  "patient_foot_ankle_results_self_evaluation": "발/발목 점수 자체 평가",
  "patient_foot_ankle_results_text2": "곧 진료실로 호명되실 것입니다.",
  "patient_elbow_pain": "통증",
  "patient_elbow_pain_q1": "당신은 팔꿈치에 통증을 느끼십니까?",
  "patient_elbow_pain_q1_a1": "아니오",
  "patient_elbow_pain_q1_a2": "예",
  "patient_elbow_pain_q2": "팔꿈치 통증에 대한 가장 좋은 설명은 무엇입니까?",
  "patient_elbow_pain_q2_a1": "없음 또는 무시 가능",
  "patient_elbow_pain_q2_a2": "경증, 활동적이며, 약물/진통제를 사용하지 않거나 가끔 사용한다.",
  "patient_elbow_pain_q2_a3": "중간 정도, 활동 유무에 관계없이, 매일 진통제 사용",
  "patient_elbow_pain_q2_a4": "휴식 중이나 밤에 적당히",
  "patient_elbow_pain_q2_a5": "휴식시 심한 통증, 지속적인 약물 치료, 행동 장애",
  "patient_elbow_pain_instruction": "팔꿈치에 통증을 평가하십시오",
  "patient_elbow_pain_q3": "현재",
  "patient_elbow_pain_q3_a1": "아픔 없음",
  "patient_elbow_pain_q3_a2": "견딜 수 없는 최악의 통증",
  "patient_elbow_pain_q4": "지난 4주 동안의 일반적인 통증",
  "patient_elbow_pain_q4_a1": "아픔 없음",
  "patient_elbow_pain_q4_a2": "견딜 수 없는 최악의 통증",
  "patient_elbow_pain_q5": "지난 4주 동안 가장 최악일 때",
  "patient_elbow_pain_q5_a1": "아픔 없음",
  "patient_elbow_pain_q5_a2": "견딜 수 없는 최악의 통증",
  "patient_elbow_pain_q6": "휴식 중",
  "patient_elbow_pain_q6_a1": "아픔 없음",
  "patient_elbow_pain_q6_a2": "견딜 수 없는 최악의 통증",
  "patient_elbow_pain_q7": "무거운 물체 들기",
  "patient_elbow_pain_q7_a1": "아픔 없음",
  "patient_elbow_pain_q7_a2": "견딜 수 없는 최악의 통증",
  "patient_elbow_pain_q8": "반복된 팔꿈치 움직임을 수행할 때",
  "patient_elbow_pain_q8_a1": "아픔 없음",
  "patient_elbow_pain_q8_a2": "견딜 수 없는 최악의 통증",
  "patient_elbow_pain_q9": "밤에",
  "patient_elbow_pain_q9_a1": "아픔 없음",
  "patient_elbow_pain_q9_a2": "견딜 수 없는 최악의 통증",
  "patient_elbow_pain_q10": "밤에 침대에서 팔꿈치 통증으로 고통받은 적이 얼마나 자주 있었나요?",
  "patient_elbow_pain_q10_a1": "전혀 없다",
  "patient_elbow_pain_q10_a2": "한두밤",
  "patient_elbow_pain_q10_a3": "몇몇 밤들",
  "patient_elbow_pain_q10_a4": "대부분의 밤들",
  "patient_elbow_pain_q10_a5": "매일 밤",
  "patient_elbow_pain_q11": "팔꿈치 통증으로 인해 수면을 얼마나 자주 방해받았나요?",
  "patient_elbow_pain_q11_a1": "전혀 없다",
  "patient_elbow_pain_q11_a2": "가끔",
  "patient_elbow_pain_q11_a3": "어떤 때에는",
  "patient_elbow_pain_q11_a4": "대부분의 시간",
  "patient_elbow_pain_q11_a5": "항상",
  "patient_elbow_function": "기능",
  "patient_elbow_function_q1": "당신의 팔꿈치 기능에 대한 가장 적절한 설명은 무엇인가요?",
  "patient_elbow_function_q1_a1": "제한 없음",
  "patient_elbow_function_q1_a2": "약간의 제한, 일상 생활 활동 제한 없음",
  "patient_elbow_function_q1_a3": "4.5kg 이상의 물건을 들 수 없습니다.",
  "patient_elbow_function_q1_a4": "일상생활 활동에 대한 적당한 제한",
  "patient_elbow_function_q1_a5": "머리를 빗거나 머리를 만질 수 없습니다.",
  "patient_elbow_function_q1_a6": "스스로 밥을 먹을 수 없다.",
  "patient_elbow_function_q2": "할 수 있는 활동에 대해 가장 적절한 설명은 무엇인가요?",
  "patient_elbow_function_q2_a1": "팔꿈치의 무제한 사용",
  "patient_elbow_function_q2_a2": "여가 목적으로 한정적으로 사용",
  "patient_elbow_function_q2_a3": "집안일/작업 관련 활동으로 한정적으로 사용",
  "patient_elbow_function_q2_a4": "자신을 돌볼 수 있다",
  "patient_elbow_function_q2_a5": "사용할 수 없음",
  "patient_elbow_function_q3": "당신은 팔꿈치를 얼마나 오래 사용할 수 있나요?",
  "patient_elbow_function_q3_a1": "30분 동안 활동을 할 수 있다",
  "patient_elbow_function_q3_a2": "15분 동안 활동을 할 수 있다",
  "patient_elbow_function_q3_a3": "5분 동안 활동을 할 수 있다",
  "patient_elbow_function_q3_a4": "팔꿈치를 사용할 수 없다",
  "patient_elbow_function_instruction": "팔꿈치로 인해 지난 한 주 동안 다음 활동을 수행하기 어려웠던 정도를 나타내십시오.",
  "patient_elbow_function_instruction_0": "어려움 없음",
  "patient_elbow_function_instruction_1": "약간",
  "patient_elbow_function_instruction_2": "보통",
  "patient_elbow_function_instruction_3": "심각",
  "patient_elbow_function_instruction_4": "불가능",
  "patient_elbow_function_q4": "꽉 조여진 또는 새 뚜껑을 열기",
  "patient_elbow_function_q4_a1": "어려움 없음",
  "patient_elbow_function_q4_a2": "약간",
  "patient_elbow_function_q4_a3": "보통",
  "patient_elbow_function_q4_a4": "심각",
  "patient_elbow_function_q4_a5": "불가능",
  "patient_elbow_function_q5": "쇼핑백이나 서류가방을 들고 다니기",
  "patient_elbow_function_q5_a1": "어려움 없음",
  "patient_elbow_function_q5_a2": "약간",
  "patient_elbow_function_q5_a3": "보통",
  "patient_elbow_function_q5_a4": "심각",
  "patient_elbow_function_q5_a5": "불가능",
  "patient_elbow_function_q6": "쓰레기 버리기 등 집안 물건 들기",
  "patient_elbow_function_q6_a1": "어려움 없음",
  "patient_elbow_function_q6_a2": "약간",
  "patient_elbow_function_q6_a3": "보통",
  "patient_elbow_function_q6_a4": "심각",
  "patient_elbow_function_q6_a5": "불가능",
  "patient_elbow_function_q7": "등을 씻어주세요 (Deung-eul ssis-eojuseyo)",
  "patient_elbow_function_q7_a1": "어려움 없음",
  "patient_elbow_function_q7_a2": "약간",
  "patient_elbow_function_q7_a3": "보통",
  "patient_elbow_function_q7_a4": "심각",
  "patient_elbow_function_q7_a5": "불가능",
  "patient_elbow_function_q8": "화장실 관리",
  "patient_elbow_function_q8_a1": "어려움 없음",
  "patient_elbow_function_q8_a2": "약간",
  "patient_elbow_function_q8_a3": "보통",
  "patient_elbow_function_q8_a4": "심각",
  "patient_elbow_function_q8_a5": "불가능",
  "patient_elbow_function_q9": "몸 전체를 씻기",
  "patient_elbow_function_q9_a1": "어려움 없음",
  "patient_elbow_function_q9_a2": "약간",
  "patient_elbow_function_q9_a3": "보통",
  "patient_elbow_function_q9_a4": "심각",
  "patient_elbow_function_q9_a5": "불가능",
  "patient_elbow_function_q10": "개인 위생을 수행하기.",
  "patient_elbow_function_q10_a1": "어려움 없음",
  "patient_elbow_function_q10_a2": "약간",
  "patient_elbow_function_q10_a3": "보통",
  "patient_elbow_function_q10_a4": "심각",
  "patient_elbow_function_q10_a5": "불가능",
  "patient_elbow_function_q11": "음식을 자르기 위해 칼을 사용하기.",
  "patient_elbow_function_q11_a1": "어려움 없음",
  "patient_elbow_function_q11_a2": "약간",
  "patient_elbow_function_q11_a3": "보통",
  "patient_elbow_function_q11_a4": "심각",
  "patient_elbow_function_q11_a5": "불가능",
  "patient_elbow_function_q12": "식기를 이용하여 식사하기",
  "patient_elbow_function_q12_a1": "어려움 없음",
  "patient_elbow_function_q12_a2": "약간",
  "patient_elbow_function_q12_a3": "보통",
  "patient_elbow_function_q12_a4": "심각",
  "patient_elbow_function_q12_a5": "불가능",
  "patient_elbow_function_q13": "팔, 어깨 또는 손에 힘을 주거나 충격을 받는 레크리에이션 활동(예: 골프, 망치질, 테니스 등)",
  "patient_elbow_function_q13_a1": "어려움 없음",
  "patient_elbow_function_q13_a2": "약간",
  "patient_elbow_function_q13_a3": "보통",
  "patient_elbow_function_q13_a4": "심각",
  "patient_elbow_function_q13_a5": "불가능",
  "patient_elbow_function_q14": "팔, 어깨, 손의 통증으로 인한 수면 장애",
  "patient_elbow_function_q14_a1": "어려움 없음",
  "patient_elbow_function_q14_a2": "약간",
  "patient_elbow_function_q14_a3": "보통",
  "patient_elbow_function_q14_a4": "심각",
  "patient_elbow_function_q14_a5": "불가능",
  "patient_elbow_function_q15": "셔츠를 입기",
  "patient_elbow_function_q15_a1": "어려움 없음",
  "patient_elbow_function_q15_a2": "약간",
  "patient_elbow_function_q15_a3": "보통",
  "patient_elbow_function_q15_a4": "심각",
  "patient_elbow_function_q15_a5": "불가능",
  "patient_elbow_function_q16": "셔츠의 상단 버튼을 단단히 고정하기",
  "patient_elbow_function_q16_a1": "어려움 없음",
  "patient_elbow_function_q16_a2": "약간",
  "patient_elbow_function_q16_a3": "보통",
  "patient_elbow_function_q16_a4": "심각",
  "patient_elbow_function_q16_a5": "불가능",
  "patient_elbow_function_q17": "옷입기",
  "patient_elbow_function_q17_a1": "어려움 없음",
  "patient_elbow_function_q17_a2": "약간",
  "patient_elbow_function_q17_a3": "보통",
  "patient_elbow_function_q17_a4": "심각",
  "patient_elbow_function_q17_a5": "불가능",
  "patient_elbow_function_q18": "머리 빗기",
  "patient_elbow_function_q18_a1": "어려움 없음",
  "patient_elbow_function_q18_a2": "약간",
  "patient_elbow_function_q18_a3": "보통",
  "patient_elbow_function_q18_a4": "심각",
  "patient_elbow_function_q18_a5": "불가능",
  "patient_elbow_function_q19": "신발을 신기",
  "patient_elbow_function_q19_a1": "어려움 없음",
  "patient_elbow_function_q19_a2": "약간",
  "patient_elbow_function_q19_a3": "보통",
  "patient_elbow_function_q19_a4": "심각",
  "patient_elbow_function_q19_a5": "불가능",
  "patient_elbow_function_q20": "신발 끈을 묶기",
  "patient_elbow_function_q20_a1": "어려움 없음",
  "patient_elbow_function_q20_a2": "약간",
  "patient_elbow_function_q20_a3": "보통",
  "patient_elbow_function_q20_a4": "심각",
  "patient_elbow_function_q20_a5": "불가능",
  "patient_elbow_function_q21": "무거운 물건을 옮기기",
  "patient_elbow_function_q21_a1": "어려움 없음",
  "patient_elbow_function_q21_a2": "약간",
  "patient_elbow_function_q21_a3": "보통",
  "patient_elbow_function_q21_a4": "심각",
  "patient_elbow_function_q21_a5": "불가능",
  "patient_elbow_function_q22": "팔로 밀면서 의자에서 일어나기",
  "patient_elbow_function_q22_a1": "어려움 없음",
  "patient_elbow_function_q22_a2": "약간",
  "patient_elbow_function_q22_a3": "보통",
  "patient_elbow_function_q22_a4": "심각",
  "patient_elbow_function_q22_a5": "불가능",
  "patient_elbow_function_q23": "무거운 집안일(예: 벽 청소, 바닥 청소) 하기",
  "patient_elbow_function_q23_a1": "어려움 없음",
  "patient_elbow_function_q23_a2": "약간",
  "patient_elbow_function_q23_a3": "보통",
  "patient_elbow_function_q23_a4": "심각",
  "patient_elbow_function_q23_a5": "불가능",
  "patient_elbow_function_q24": "열쇠를 돌리기",
  "patient_elbow_function_q24_a1": "어려움 없음",
  "patient_elbow_function_q24_a2": "약간",
  "patient_elbow_function_q24_a3": "보통",
  "patient_elbow_function_q24_a4": "심각",
  "patient_elbow_function_q24_a5": "불가능",
  "patient_elbow_function_q25": "공 던지기",
  "patient_elbow_function_q25_a1": "어려움 없음",
  "patient_elbow_function_q25_a2": "약간",
  "patient_elbow_function_q25_a3": "보통",
  "patient_elbow_function_q25_a4": "심각",
  "patient_elbow_function_q25_a5": "불가능",
  "patient_elbow_function_q26": "팔, 어깨 또는 손의 통증으로 인해 평소 하던 일을 하기(가사가 주된 역할인 경우 가사도 포함됨)",
  "patient_elbow_function_q26_a1": "어려움 없음",
  "patient_elbow_function_q26_a2": "약간",
  "patient_elbow_function_q26_a3": "보통",
  "patient_elbow_function_q26_a4": "심각",
  "patient_elbow_function_q26_a5": "불가능",
  "patient_elbow_function_q27": "평소에 하는 일을 지정해 주세요",
  "patient_elbow_function_q27_placeholder": "직업을 지정하십시오",
  "patient_elbow_function_i_donot_work": "나는 일하지 않습니다",
  "patient_elbow_function_q28": "일하는 데 사용하는 평소 기술을 사용하기",
  "patient_elbow_function_q28_a1": "어려움 없음",
  "patient_elbow_function_q28_a2": "약간",
  "patient_elbow_function_q28_a3": "보통",
  "patient_elbow_function_q28_a4": "심각",
  "patient_elbow_function_q28_a5": "불가능",
  "patient_elbow_function_q29": "당신이 원하는 만큼 일을 하기",
  "patient_elbow_function_q29_a1": "어려움 없음",
  "patient_elbow_function_q29_a2": "약간",
  "patient_elbow_function_q29_a3": "보통",
  "patient_elbow_function_q29_a4": "심각",
  "patient_elbow_function_q29_a5": "불가능",
  "patient_elbow_function_q30": "일하는 데 평소와 같은 시간을 보내는 것",
  "patient_elbow_function_q30_a1": "어려움 없음",
  "patient_elbow_function_q30_a2": "약간",
  "patient_elbow_function_q30_a3": "보통",
  "patient_elbow_function_q30_a4": "심각",
  "patient_elbow_function_q30_a5": "불가능",
  "patient_elbow_function_q31": "팔, 어깨 또는 손 통증 때문에 악기를 연주하거나 스포츠를 하는 데 어려움이 있음",
  "patient_elbow_function_q31_a1": "어려움 없음",
  "patient_elbow_function_q31_a2": "약간",
  "patient_elbow_function_q31_a3": "보통",
  "patient_elbow_function_q31_a4": "심각",
  "patient_elbow_function_q31_a5": "불가능",
  "patient_elbow_function_q32": "운동 종목 또는 연주하는 악기를 지정하세요",
  "patient_elbow_function_q32_placeholder": "스포츠/악기를 지정하십시오",
  "patient_elbow_function_i_donot_play": "스포츠나 악기를 연주하지 않다",
  "patient_elbow_function_q33": "평소에 사용하는 악기나 스포츠 기술 사용",
  "patient_elbow_function_q33_a1": "어려움 없음",
  "patient_elbow_function_q33_a2": "약간",
  "patient_elbow_function_q33_a3": "보통",
  "patient_elbow_function_q33_a4": "심각",
  "patient_elbow_function_q33_a5": "불가능",
  "patient_elbow_function_q34": "당신이 원하는대로 악기를 연주하거나 스포츠를 하기",
  "patient_elbow_function_q34_a1": "어려움 없음",
  "patient_elbow_function_q34_a2": "약간",
  "patient_elbow_function_q34_a3": "보통",
  "patient_elbow_function_q34_a4": "심각",
  "patient_elbow_function_q34_a5": "불가능",
  "patient_elbow_function_q35": "기존에 하던 만큼의 시간을 악기나 스포츠에 투자하는 것",
  "patient_elbow_function_q35_a1": "어려움 없음",
  "patient_elbow_function_q35_a2": "약간",
  "patient_elbow_function_q35_a3": "보통",
  "patient_elbow_function_q35_a4": "심각",
  "patient_elbow_function_q35_a5": "불가능",
  "patient_elbow_function_q36": "지난 일주일 동안 팔, 어깨 또는 손 문제로 인해 가족, 친구, 이웃 또는 그룹과의 일상적인 사회적/여가적 활동에 어느 정도 지장을 받았습니까?",
  "patient_elbow_function_q36_a1": "전혀 없다",
  "patient_elbow_function_q36_a2": "약간",
  "patient_elbow_function_q36_a3": "보통",
  "patient_elbow_function_q36_a4": "꽤 많이",
  "patient_elbow_function_q36_a5": "극도로",
  "patient_elbow_function_q37": "지난 한 주 동안 팔, 어깨 또는 손 문제로 인해 일이나 기타 일상적인 활동에 제한이 있었나요?",
  "patient_elbow_function_q37_a1": "전혀 없다",
  "patient_elbow_function_q37_a2": "약간",
  "patient_elbow_function_q37_a3": "보통",
  "patient_elbow_function_q37_a4": "꽤 많이",
  "patient_elbow_function_q37_a5": "극도로",
  "patient_elbow_symptoms": "기타 증상",
  "patient_elbow_symptoms_instruction": "지난 일주일 동안 다음 증상의 심각성을 평가하십시오.",
  "patient_elbow_symptoms_instruction_0": "없음",
  "patient_elbow_symptoms_instruction_1": "약간",
  "patient_elbow_symptoms_instruction_2": "보통",
  "patient_elbow_symptoms_instruction_3": "심각",
  "patient_elbow_symptoms_instruction_4": "극단적인",
  "patient_elbow_symptoms_q1": "팔, 어깨 또는 손 통증",
  "patient_elbow_symptoms_q1_a1": "없음",
  "patient_elbow_symptoms_q1_a2": "약간",
  "patient_elbow_symptoms_q1_a3": "보통",
  "patient_elbow_symptoms_q1_a4": "심각",
  "patient_elbow_symptoms_q1_a5": "극단적인",
  "patient_elbow_symptoms_q2": "팔, 어깨 또는 손의 따끔거림(핀이나 바늘로 찌르는 듯한 통증)",
  "patient_elbow_symptoms_q2_a1": "없음",
  "patient_elbow_symptoms_q2_a2": "약간",
  "patient_elbow_symptoms_q2_a3": "보통",
  "patient_elbow_symptoms_q2_a4": "심각",
  "patient_elbow_symptoms_q2_a5": "극단적인",
  "patient_elbow_symptoms_q3": "오늘 당신의 팔꿈치를 정상의 백분율로 평가하면 어떻게 됩니까? (0-100%로 100%가 정상입니다)",
  "patient_elbow_quality_of_life": "삶의 질",
  "patient_elbow_quality_of_life_instruction": "다음은 팔꿈치 문제와 관련하여 느낄 수 있는 사회적, 정서적, 생활 습관적 우려에 대해 질문입니다. 팔꿈치와 관련하여 평소 어떤 느낌을 받았는지 생각해 보세요.",
  "patient_elbow_quality_of_life_q1": "지난 4주 동안 팔꿈치 문제가 \"삶을 통제하고 있다\"고 느낀 적이 있습니까?",
  "patient_elbow_quality_of_life_q1_a1": "전혀 없다",
  "patient_elbow_quality_of_life_q1_a2": "가끔",
  "patient_elbow_quality_of_life_q1_a3": "가끔",
  "patient_elbow_quality_of_life_q1_a4": "대부분의 날들",
  "patient_elbow_quality_of_life_q1_a5": "매일",
  "patient_elbow_quality_of_life_q2": "지난 4주 동안 팔꿈치에 얼마나 많은 신경이 쓰였나요?",
  "patient_elbow_quality_of_life_q2_a1": "전혀 없다",
  "patient_elbow_quality_of_life_q2_a2": "조금",
  "patient_elbow_quality_of_life_q2_a3": "어떤 때에는",
  "patient_elbow_quality_of_life_q2_a4": "대부분의 시간",
  "patient_elbow_quality_of_life_q2_a5": "항상",
  "patient_elbow_quality_of_life_instruction1": "귀하의 오늘 건강을 가장 잘 설명하는 항목에 대해 다음 중 하나를 선택해 주십시오.",
  "patient_elbow_quality_of_life_instruction1_0": "문제 없다",
  "patient_elbow_quality_of_life_instruction1_1": "약간",
  "patient_elbow_quality_of_life_instruction1_2": "보통",
  "patient_elbow_quality_of_life_instruction1_3": "심각",
  "patient_elbow_quality_of_life_instruction1_4": "극도로/불가능",
  "patient_elbow_quality_of_life_q3": "걸을 때 문제",
  "patient_elbow_quality_of_life_q3_a1": "문제 없다",
  "patient_elbow_quality_of_life_q3_a2": "약간",
  "patient_elbow_quality_of_life_q3_a3": "보통",
  "patient_elbow_quality_of_life_q3_a4": "심각",
  "patient_elbow_quality_of_life_q3_a5": "불가능",
  "patient_elbow_quality_of_life_q4": "스스로 목욕하기/물기 말리기",
  "patient_elbow_quality_of_life_q4_a1": "문제 없다",
  "patient_elbow_quality_of_life_q4_a2": "약간",
  "patient_elbow_quality_of_life_q4_a3": "보통",
  "patient_elbow_quality_of_life_q4_a4": "심각",
  "patient_elbow_quality_of_life_q4_a5": "불가능",
  "patient_elbow_quality_of_life_q5": "일반적인 활동을 수행하는 문제 (예 : 일, 공부, 가사, 가족 또는 여가 활동)",
  "patient_elbow_quality_of_life_q5_a1": "문제 없다",
  "patient_elbow_quality_of_life_q5_a2": "약간",
  "patient_elbow_quality_of_life_q5_a3": "보통",
  "patient_elbow_quality_of_life_q5_a4": "심각",
  "patient_elbow_quality_of_life_q5_a5": "불가능",
  "patient_elbow_quality_of_life_q6": "통증 또는 불편함",
  "patient_elbow_quality_of_life_q6_a1": "없음",
  "patient_elbow_quality_of_life_q6_a2": "약간",
  "patient_elbow_quality_of_life_q6_a3": "보통",
  "patient_elbow_quality_of_life_q6_a4": "심각",
  "patient_elbow_quality_of_life_q6_a5": "극단적인",
  "patient_elbow_quality_of_life_q7": "불안/우울",
  "patient_elbow_quality_of_life_q7_a1": "불안/우울하지 않다",
  "patient_elbow_quality_of_life_q7_a2": "약간 불안/우울",
  "patient_elbow_quality_of_life_q7_a3": "중간 정도의 불안/우울",
  "patient_elbow_quality_of_life_q7_a4": "심각하게 불안/우울",
  "patient_elbow_quality_of_life_q7_a5": "극도로 불안/우울",
  "patient_elbow_quality_of_life_q8": "오늘의 건강은 얼마나 좋은가요?/나쁜가요?",
  "patient_elbow_quality_of_life_q8_instruction": "100은 최고의 건강을 의미하며 0은 상상할 수있는 최악의 건강을 의미합니다.",
  "patient_elbow_quality_of_life_q8_a1": "최악",
  "patient_elbow_quality_of_life_q8_a2": "최고",
  "patient_elbow_satisfaction": "만족도",
  "patient_elbow_satisfaction_q1": "팔꿈치 치료에 만족하십니까?",
  "patient_elbow_satisfaction_na": "해당 사항 없음",
  "patient_elbow_satisfaction_q1_a1": "전혀 만족하지 않음",
  "patient_elbow_satisfaction_q1_a2": "매우 만족한다",
  "patient_elbow_complete": "설문 끝",
  "patient_elbow_complete_text1": "시간을 내어 이 설문 조사를 작성해 주셔서 감사합니다.",
  "patient_elbow_complete_text2": "제출을 클릭하여 완료하고 점수를 확인하십시오.",
  "patient_elbow_complete_text3": "귀하의 답은 기밀로 취급되며 모든 보고서는 신원 식별되지 않은 정보만 사용한다.",
  "patient_elbow_results": "결과",
  "patient_elbow_results_text1": "다음은 설문에서 계산되는 통증 및 팔꿈치 기능 점수입니다.",
  "patient_elbow_results_function": "기능",
  "patient_elbow_results_pain": "통증",
  "patient_elbow_results_health_perception": "전반적인 건강 인식",
  "patient_elbow_results_self_evaluation": "팔꿈치 점수 자체 평가",
  "patient_elbow_results_text4": "당신의 의사가 곧 도착할 것입니다.",
  "patient_hand_pain": "통증",
  "patient_hand_pain_q1": "지난 주 동안 손/손목의 통증을 설명해주세요.",
  "patient_hand_pain_q1_a1": "없음 또는 매우 경미함",
  "patient_hand_pain_q1_a2": "약간",
  "patient_hand_pain_q1_a3": "보통",
  "patient_hand_pain_q1_a4": "심각",
  "patient_hand_pain_q1_a5": "매우 심각/극도로",
  "patient_hand_pain_q2": "현재 손/손목의 통증은 얼마나 심한가요?",
  "patient_hand_pain_q2_a1": "아픔 없음",
  "patient_hand_pain_q2_a2": "최악의 통증",
  "patient_hand_pain_q3": "지난 주 동안 손/손목의 통증이 일상 생활(식사나 목욕과 같은)에 얼마나 자주 방해되었나요?",
  "patient_hand_pain_q3_a1": "전혀",
  "patient_hand_pain_q3_a2": "드물게",
  "patient_hand_pain_q3_a3": "가끔",
  "patient_hand_pain_q3_a4": "자주",
  "patient_hand_pain_q3_a5": "항상",
  "patient_hand_symptoms": "기타 증상",
  "patient_hand_symptoms_q1": "지난 일주일 동안 팔, 어깨 또는 손의 저림(핀이나 바늘로 찌르는 듯한 통증)이 얼마나 심했나요?",
  "patient_hand_symptoms_q1_a1": "없음",
  "patient_hand_symptoms_q1_a2": "약간",
  "patient_hand_symptoms_q1_a3": "보통",
  "patient_hand_symptoms_q1_a4": "심각",
  "patient_hand_symptoms_q1_a5": "극단적인",
  "patient_hand_symptoms_q2": "지난 주 동안 손에 느껴지는 감각은 어땠나요?",
  "patient_hand_symptoms_q2_a1": "매우 좋다",
  "patient_hand_symptoms_q2_a2": "좋다",
  "patient_hand_symptoms_q2_a3": "보통",
  "patient_hand_symptoms_q2_a4": "나쁘다",
  "patient_hand_symptoms_q2_a5": "매우 나쁘다",
  "patient_hand_symptoms_q3": "손 모양이 일상 생활에 방해가 되나요?",
  "patient_hand_symptoms_q3_a1": "강력히 동의하지 않음",
  "patient_hand_symptoms_q3_a2": "동의하지 않음",
  "patient_hand_symptoms_q3_a3": "중립",
  "patient_hand_symptoms_q3_a4": "동의함",
  "patient_hand_symptoms_q3_a5": "강력하게 동의함",
  "patient_hand_symptoms_q3_a6": "오늘 손/손목 상태를 정상 대비 백분율로 어떻게 평가하시겠습니까? (0-100%로 100%가 정상입니다)",
  "patient_hand_symptoms_q3_a6_normal": "정상",
  "patient_hand_function": "기능",
  "patient_hand_function_instruction1": "특정 작업을 수행하지 않는 경우, 해당 작업을 수행하는 데 얼마나 어려울지 추정해 주세요.",
  "patient_hand_function_instruction2": "질문에 어떻게 답해야 할지 잘 모르겠다면 최선을 다해 답해 주세요.",
  "patient_hand_function_q1": "지난 한 주 동안 전반적으로 손이 얼마나 잘 작동했나요?",
  "patient_hand_function_q1_a1": "매우 좋다",
  "patient_hand_function_q1_a2": "좋다",
  "patient_hand_function_q1_a3": "보통",
  "patient_hand_function_q1_a4": "나쁘다",
  "patient_hand_function_q1_a5": "매우 나쁘다",
  "patient_hand_function_q2": "손/손목 문제로 인해 일을 할 수 없었던 빈도는 어떻게 되나요?",
  "patient_hand_function_q2_a1": "전혀",
  "patient_hand_function_q2_a2": "드물게",
  "patient_hand_function_q2_a3": "가끔",
  "patient_hand_function_q2_a4": "자주",
  "patient_hand_function_q2_a5": "항상",
  "patient_hand_function_q3": "손/손목 문제로 인해 일을 할 때 얼마나 자주 더 오래 걸렸습니까?",
  "patient_hand_function_q3_a1": "전혀",
  "patient_hand_function_q3_a2": "드물게",
  "patient_hand_function_q3_a3": "가끔",
  "patient_hand_function_q3_a4": "자주",
  "patient_hand_function_q3_a5": "항상",
  "patient_hand_function_instruction": "손/손목의 문제로 인해 지난 한 주 동안 다음 활동을 수행하기 어려웠던 정도를 나타내십시오.",
  "patient_hand_function_instruction_0": "어려움 없음",
  "patient_hand_function_instruction_1": "약간 어려움",
  "patient_hand_function_instruction_2": "어려움이 어느정도 있다",
  "patient_hand_function_instruction_3": "심각한 어려움",
  "patient_hand_function_instruction_4": "극심한 어려움/불가능",
  "patient_hand_function_q4": "꽉 조여진 또는 새 뚜껑을 열기",
  "patient_hand_function_q4_a1": "어려움 없음",
  "patient_hand_function_q4_a2": "약간 어려움",
  "patient_hand_function_q4_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q4_a4": "심각한 어려움",
  "patient_hand_function_q4_a5": "극심한 어려움/불가능",
  "patient_hand_function_q5": "프라이팬을 잡기",
  "patient_hand_function_q5_a1": "어려움 없음",
  "patient_hand_function_q5_a2": "약간 어려움",
  "patient_hand_function_q5_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q5_a4": "심각한 어려움",
  "patient_hand_function_q5_a5": "극심한 어려움/불가능",
  "patient_hand_function_q6": "셔츠나 블라우스를 단추를 잠그기",
  "patient_hand_function_q6_a1": "어려움 없음",
  "patient_hand_function_q6_a2": "약간 어려움",
  "patient_hand_function_q6_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q6_a4": "심각한 어려움",
  "patient_hand_function_q6_a5": "극심한 어려움/불가능",
  "patient_hand_function_q7": "무거운 집안일(예: 벽 청소, 바닥 청소) 하기",
  "patient_hand_function_q7_a1": "어려움 없음",
  "patient_hand_function_q7_a2": "약간 어려움",
  "patient_hand_function_q7_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q7_a4": "심각한 어려움",
  "patient_hand_function_q7_a5": "극심한 어려움/불가능",
  "patient_hand_function_q8": "쇼핑백이나 서류가방을 들고 다니기",
  "patient_hand_function_q8_a1": "어려움 없음",
  "patient_hand_function_q8_a2": "약간 어려움",
  "patient_hand_function_q8_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q8_a4": "심각한 어려움",
  "patient_hand_function_q8_a5": "극심한 어려움/불가능",
  "patient_hand_function_q9": "등을 씻어주세요 (Deung-eul ssis-eojuseyo)",
  "patient_hand_function_q9_a1": "어려움 없음",
  "patient_hand_function_q9_a2": "약간 어려움",
  "patient_hand_function_q9_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q9_a4": "심각한 어려움",
  "patient_hand_function_q9_a5": "극심한 어려움/불가능",
  "patient_hand_function_q10": "음식을 자르기 위해 칼을 사용하기.",
  "patient_hand_function_q10_a1": "어려움 없음",
  "patient_hand_function_q10_a2": "약간 어려움",
  "patient_hand_function_q10_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q10_a4": "심각한 어려움",
  "patient_hand_function_q10_a5": "극심한 어려움/불가능",
  "patient_hand_function_q11": "팔, 어깨 또는 손에 힘을 주거나 충격을 받는 레크리에이션 활동(예: 골프, 망치질, 테니스 등)",
  "patient_hand_function_q11_a1": "어려움 없음",
  "patient_hand_function_q11_a2": "약간 어려움",
  "patient_hand_function_q11_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q11_a4": "심각한 어려움",
  "patient_hand_function_q11_a5": "극심한 어려움/불가능",
  "patient_hand_function_q12": "팔, 어깨, 손의 통증으로 인한 수면 장애",
  "patient_hand_function_q12_a1": "어려움 없음",
  "patient_hand_function_q12_a2": "약간 어려움",
  "patient_hand_function_q12_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q12_a4": "심각한 어려움",
  "patient_hand_function_q12_a5": "극심한 어려움/불가능",
  "patient_hand_function_q13": "팔, 어깨 또는 손의 통증으로 인해 평소 하던 일을 하기(가사가 주된 역할인 경우 가사도 포함됨)",
  "patient_hand_function_q13_a1": "어려움 없음",
  "patient_hand_function_q13_a2": "약간 어려움",
  "patient_hand_function_q13_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q13_a4": "심각한 어려움",
  "patient_hand_function_q13_a5": "극심한 어려움/불가능",
  "patient_hand_function_q14": "일반적인 작업을 명시해 주십시오",
  "patient_hand_function_q14_placeholder": "직업을 지정하십시오",
  "patient_hand_function_do_dot_work": "나는 일하지 않습니다",
  "patient_hand_function_q15": "일하는 데 사용하는 평소 기술을 사용하기",
  "patient_hand_function_q15_a1": "어려움 없음",
  "patient_hand_function_q15_a2": "약간 어려움",
  "patient_hand_function_q15_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q15_a4": "심각한 어려움",
  "patient_hand_function_q15_a5": "극심한 어려움/불가능",
  "patient_hand_function_q16": "당신이 원하는 만큼 일을 하기",
  "patient_hand_function_q16_a1": "어려움 없음",
  "patient_hand_function_q16_a2": "약간 어려움",
  "patient_hand_function_q16_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q16_a4": "심각한 어려움",
  "patient_hand_function_q16_a5": "극심한 어려움/불가능",
  "patient_hand_function_q17": "일하는 데 평소와 같은 시간을 보내는 것",
  "patient_hand_function_q17_a1": "어려움 없음",
  "patient_hand_function_q17_a2": "약간 어려움",
  "patient_hand_function_q17_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q17_a4": "심각한 어려움",
  "patient_hand_function_q17_a5": "극심한 어려움/불가능",
  "patient_hand_function_q18": "팔, 어깨 또는 손 통증 때문에 악기를 연주하거나 스포츠를 하는 것",
  "patient_hand_function_q18_a1": "어려움 없음",
  "patient_hand_function_q18_a2": "약간 어려움",
  "patient_hand_function_q18_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q18_a4": "심각한 어려움",
  "patient_hand_function_q18_a5": "극심한 어려움/불가능",
  "patient_hand_function_q19": "스포츠나 악기를 명시해 주십시오",
  "patient_hand_function_q19_placeholder": "스포츠/악기를 지정하십시오",
  "patient_hand_function_donot_play": "스포츠나 악기를 연주하지 않다",
  "patient_hand_function_20": "당신이 악기나 스포츠를 연주하는 데 사용하는 평소 기술",
  "patient_hand_function_q20_a1": "어려움 없음",
  "patient_hand_function_q20_a2": "약간 어려움",
  "patient_hand_function_q20_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q20_a4": "심각한 어려움",
  "patient_hand_function_q20_a5": "극심한 어려움/불가능",
  "patient_hand_function_q21": "당신이 원하는대로 악기를 연주하거나 스포츠를 하기",
  "patient_hand_function_q21_a1": "어려움 없음",
  "patient_hand_function_q21_a2": "약간 어려움",
  "patient_hand_function_q21_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q21_a4": "심각한 어려움",
  "patient_hand_function_q21_a5": "극심한 어려움/불가능",
  "patient_hand_function_q22": "기존에 하던 만큼의 시간을 악기나 스포츠에 투자하는 것",
  "patient_hand_function_q22_a1": "어려움 없음",
  "patient_hand_function_q22_a2": "약간 어려움",
  "patient_hand_function_q22_a3": "어려움이 어느정도 있다",
  "patient_hand_function_q22_a4": "심각한 어려움",
  "patient_hand_function_q22_a5": "극심한 어려움/불가능",
  "patient_hand_function_q23": "지난 일주일 동안 팔, 어깨 또는 손 문제로 인해 가족, 친구, 이웃 또는 그룹과의 일상적인 사회적/여가적 활동에 어느 정도 지장을 받았습니까?",
  "patient_hand_function_q23_a1": "전혀 없다",
  "patient_hand_function_q23_a2": "약간",
  "patient_hand_function_q23_a3": "보통",
  "patient_hand_function_q23_a4": "꽤 많이",
  "patient_hand_function_q23_a5": "극도로",
  "patient_hand_function_q24": "지난 한 주 동안 팔, 어깨 또는 손 문제로 인해 일이나 기타 일상적인 활동에 제한이 있었나요?",
  "patient_hand_function_q24_a1": "전혀 없다",
  "patient_hand_function_q24_a2": "약간",
  "patient_hand_function_q24_a3": "보통",
  "patient_hand_function_q24_a4": "꽤 많이",
  "patient_hand_function_q24_a5": "극도로",
  "patient_hand_questionnaire": "보스턴 손목 터널 증후군 설문",
  "patient_hand_questionnaire_symptom": "증상",
  "patient_hand_questionnaire_symptom_instruction": "증상에 가장 잘 맞는 답을 선택하십시오.",
  "patient_hand_questionnaire_symptom_instruction_0": "정상",
  "patient_hand_questionnaire_symptom_instruction_1": "약간",
  "patient_hand_questionnaire_symptom_instruction_2": "중간",
  "patient_hand_questionnaire_symptom_instruction_3": "심각",
  "patient_hand_questionnaire_symptom_instruction_4": "매우 심각합니다",
  "patient_hand_questionnaire_symptom_q1": "밤에 느끼는 손이나 손목 통증은 얼마나 심각한가요?",
  "patient_hand_questionnaire_symptom_q1_a1": "정상",
  "patient_hand_questionnaire_symptom_q1_a2": "약간",
  "patient_hand_questionnaire_symptom_q1_a3": "중간",
  "patient_hand_questionnaire_symptom_q1_a4": "심각",
  "patient_hand_questionnaire_symptom_q1_a5": "매우 심각합니다",
  "patient_hand_questionnaire_symptom_q2": "지난 2주 동안 일반적인 밤 동안 손이나 손목 통증으로 깨어난 빈도는 어떻게 되나요?",
  "patient_hand_questionnaire_symptom_q2_a1": "정상",
  "patient_hand_questionnaire_symptom_q2_a2": "한 번",
  "patient_hand_questionnaire_symptom_q2_a3": "2-3 번",
  "patient_hand_questionnaire_symptom_q2_a4": "4-5 번",
  "patient_hand_questionnaire_symptom_q2_a5": "5 번 이상",
  "patient_hand_questionnaire_symptom_q3": "일반적으로 낮에 손이나 손목에 통증이 느끼십니까?",
  "patient_hand_questionnaire_symptom_q3_a1": "아픔 없음",
  "patient_hand_questionnaire_symptom_q3_a2": "약간",
  "patient_hand_questionnaire_symptom_q3_a3": "중간",
  "patient_hand_questionnaire_symptom_q3_a4": "심각",
  "patient_hand_questionnaire_symptom_q3_a5": "매우 심각합니다",
  "patient_hand_questionnaire_symptom_q4": "주간에 손이나 손목 통증을 얼마나 자주 느끼십니까?",
  "patient_hand_questionnaire_symptom_q4_a1": "정상",
  "patient_hand_questionnaire_symptom_q4_a2": "매일 1-2 회",
  "patient_hand_questionnaire_symptom_q4_a3": "매일 3-5 회",
  "patient_hand_questionnaire_symptom_q4_a4": "매일 5회 이상",
  "patient_hand_questionnaire_symptom_q4_a5": "계속됨",
  "patient_hand_questionnaire_symptom_q5": "평균적으로 낮 동안 통증 발작은 얼마나 지속됩니까?",
  "patient_hand_questionnaire_symptom_q5_a1": "정상",
  "patient_hand_questionnaire_symptom_q5_a2": "<10 분",
  "patient_hand_questionnaire_symptom_q5_a3": "10-60 분 계속됨",
  "patient_hand_questionnaire_symptom_q5_a4": "> 60 분",
  "patient_hand_questionnaire_symptom_q5_a5": "계속됨",
  "patient_hand_questionnaire_symptom_q6": "손의 감각소실이 있나요?",
  "patient_hand_questionnaire_symptom_q6_a1": "정상",
  "patient_hand_questionnaire_symptom_q6_a2": "약간",
  "patient_hand_questionnaire_symptom_q6_a3": "중간",
  "patient_hand_questionnaire_symptom_q6_a4": "심각",
  "patient_hand_questionnaire_symptom_q6_a5": "매우 심각합니다",
  "patient_hand_questionnaire_symptom_q7": "손이나 손목 힘빠짐이 있나요?",
  "patient_hand_questionnaire_symptom_q7_a1": "정상",
  "patient_hand_questionnaire_symptom_q7_a2": "약간",
  "patient_hand_questionnaire_symptom_q7_a3": "중간",
  "patient_hand_questionnaire_symptom_q7_a4": "심각",
  "patient_hand_questionnaire_symptom_q7_a5": "매우 심각합니다",
  "patient_hand_questionnaire_symptom_q8": "손 저림이 있나요?",
  "patient_hand_questionnaire_symptom_q8_a1": "정상",
  "patient_hand_questionnaire_symptom_q8_a2": "약간",
  "patient_hand_questionnaire_symptom_q8_a3": "중간",
  "patient_hand_questionnaire_symptom_q8_a4": "심각",
  "patient_hand_questionnaire_symptom_q8_a5": "매우 심각합니다",
  "patient_hand_questionnaire_symptom_q9": "밤 동안 손저림증(감각 상실 또는 따끔거림)이 얼마나 심한가요?",
  "patient_hand_questionnaire_symptom_q9_a1": "정상",
  "patient_hand_questionnaire_symptom_q9_a2": "약간",
  "patient_hand_questionnaire_symptom_q9_a3": "중간",
  "patient_hand_questionnaire_symptom_q9_a4": "심각",
  "patient_hand_questionnaire_symptom_q9_a5": "매우 심각합니다",
  "patient_hand_questionnaire_symptom_q10": "지난 2주 동안 보통 밤에 손이 저리거나 따끔거려 잠에서 깨는 일이 얼마나 자주 있었나요?",
  "patient_hand_questionnaire_symptom_q10_a1": "정상",
  "patient_hand_questionnaire_symptom_q10_a2": "한 번",
  "patient_hand_questionnaire_symptom_q10_a3": "2-3 번",
  "patient_hand_questionnaire_symptom_q10_a4": "4-5 번",
  "patient_hand_questionnaire_symptom_q10_a5": "5 번 이상",
  "patient_hand_questionnaire_function": "기능",
  "patient_hand_questionnaire_function_instructions": "증상으로 다음 활동을 수행하는 데 어려움의 정도를 평가하십시오.",
  "patient_hand_questionnaire_function_instructions_0": "어려움 없음",
  "patient_hand_questionnaire_function_instructions_1": "조금 어려움",
  "patient_hand_questionnaire_function_instructions_2": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_instructions_3": "극심한 어려움",
  "patient_hand_questionnaire_function_instructions_4": "전혀 수행 할 수 없다",
  "patient_hand_questionnaire_function_q1": "열쇠나 펜과 같은 작은 물건을 잡고 사용하는 것이 어려움",
  "patient_hand_questionnaire_function_q1_a1": "어려움 없음",
  "patient_hand_questionnaire_function_q1_a2": "조금 어려움",
  "patient_hand_questionnaire_function_q1_a3": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_q1_a4": "극심한 어려움",
  "patient_hand_questionnaire_function_q1_a5": "전혀 수행 할 수 없다",
  "patient_hand_questionnaire_function_q2": "글쓰기",
  "patient_hand_questionnaire_function_q2_a1": "어려움 없음",
  "patient_hand_questionnaire_function_q2_a2": "조금 어려움",
  "patient_hand_questionnaire_function_q2_a3": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_q2_a4": "극심한 어려움",
  "patient_hand_questionnaire_function_q2_a5": "전혀 수행 할 수 없다",
  "patient_hand_questionnaire_function_q3": "옷의 단추를 잠그기",
  "patient_hand_questionnaire_function_q3_a1": "어려움 없음",
  "patient_hand_questionnaire_function_q3_a2": "조금 어려움",
  "patient_hand_questionnaire_function_q3_a3": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_q3_a4": "극심한 어려움",
  "patient_hand_questionnaire_function_q3_a5": "전혀 수행 할 수 없다",
  "patient_hand_questionnaire_function_q4": "책을 쥐고 독서하기",
  "patient_hand_questionnaire_function_q4_a1": "어려움 없음",
  "patient_hand_questionnaire_function_q4_a2": "조금 어려움",
  "patient_hand_questionnaire_function_q4_a3": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_q4_a4": "극심한 어려움",
  "patient_hand_questionnaire_function_q4_a5": "전혀 수행 할 수 없다",
  "patient_hand_questionnaire_function_q5": "전화기를 잡기",
  "patient_hand_questionnaire_function_q5_a1": "어려움 없음",
  "patient_hand_questionnaire_function_q5_a2": "조금 어려움",
  "patient_hand_questionnaire_function_q5_a3": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_q5_a4": "극심한 어려움",
  "patient_hand_questionnaire_function_q5_a5": "전혀 수행 할 수 없다",
  "patient_hand_questionnaire_function_q6": "병 뚜껑 열기",
  "patient_hand_questionnaire_function_q6_a1": "어려움 없음",
  "patient_hand_questionnaire_function_q6_a2": "조금 어려움",
  "patient_hand_questionnaire_function_q6_a3": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_q6_a4": "극심한 어려움",
  "patient_hand_questionnaire_function_q6_a5": "전혀 수행 할 수 없다",
  "patient_hand_questionnaire_function_q7": "집안일",
  "patient_hand_questionnaire_function_q7_a1": "가사",
  "patient_hand_questionnaire_function_q7_a2": "조금 어려움",
  "patient_hand_questionnaire_function_q7_a3": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_q7_a4": "극심한 어려움",
  "patient_hand_questionnaire_function_q7_a5": "전혀 수행 할 수 없다",
  "patient_hand_questionnaire_function_q8": "식료품 바구니를 들고 가기",
  "patient_hand_questionnaire_function_q8_a1": "어려움 없음",
  "patient_hand_questionnaire_function_q8_a2": "조금 어려움",
  "patient_hand_questionnaire_function_q8_a3": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_q8_a4": "극심한 어려움",
  "patient_hand_questionnaire_function_q8_a5": "전혀 수행 할 수 없다",
  "patient_hand_questionnaire_function_q9": "목욕과 옷 입기",
  "patient_hand_questionnaire_function_q9_a1": "어려움 없음",
  "patient_hand_questionnaire_function_q9_a2": "조금 어려움",
  "patient_hand_questionnaire_function_q9_a3": "어려움이 어느정도 있다",
  "patient_hand_questionnaire_function_q9_a4": "극심한 어려움",
  "patient_hand_questionnaire_function_q9_a5": "전혀 수행 할 수 없다",
  "patient_hand_uram": "손과 손목의 URAM 스케일표",
  "patient_hand_uram_canyou": "할 수 있나요...",
  "patient_hand_uram_q1": "손을 평평하게 유지하면서 플란넬로 몸을 씻기",
  "patient_hand_uram_q1_a1": "어려움없이",
  "patient_hand_uram_q1_a2": "어려움 거의 없다",
  "patient_hand_uram_q1_a3": "약간의 어려움이 있다",
  "patient_hand_uram_q1_a4": "많은 어려움",
  "patient_hand_uram_q1_a5": "거의 불가능",
  "patient_hand_uram_q1_a6": "불가능",
  "patient_hand_uram_q2": "얼굴을 씻기",
  "patient_hand_uram_q2_a1": "어려움없이",
  "patient_hand_uram_q2_a2": "어려움 거의 없다",
  "patient_hand_uram_q2_a3": "약간의 어려움이 있다",
  "patient_hand_uram_q2_a4": "많은 어려움",
  "patient_hand_uram_q2_a5": "거의 불가능",
  "patient_hand_uram_q2_a6": "불가능",
  "patient_hand_uram_q3": "한 손에 병을 쥐기",
  "patient_hand_uram_q3_a1": "어려움없이",
  "patient_hand_uram_q3_a2": "어려움 거의 없다",
  "patient_hand_uram_q3_a3": "약간의 어려움이 있다",
  "patient_hand_uram_q3_a4": "많은 어려움",
  "patient_hand_uram_q3_a5": "거의 불가능",
  "patient_hand_uram_q3_a6": "불가능",
  "patient_hand_uram_q4": "악수하기",
  "patient_hand_uram_q4_a1": "어려움없이",
  "patient_hand_uram_q4_a2": "어려움 거의 없다",
  "patient_hand_uram_q4_a3": "약간의 어려움이 있다",
  "patient_hand_uram_q4_a4": "많은 어려움",
  "patient_hand_uram_q4_a5": "거의 불가능",
  "patient_hand_uram_q4_a6": "불가능",
  "patient_hand_uram_q5": "누군가의 머리를 쓰다듬거나 애정표현하기",
  "patient_hand_uram_q5_a1": "어려움없이",
  "patient_hand_uram_q5_a2": "어려움 거의 없다",
  "patient_hand_uram_q5_a3": "약간의 어려움이 있다",
  "patient_hand_uram_q5_a4": "많은 어려움",
  "patient_hand_uram_q5_a5": "거의 불가능",
  "patient_hand_uram_q5_a6": "불가능",
  "patient_hand_uram_q6": "손뼉 치기",
  "patient_hand_uram_q6_a1": "어려움없이",
  "patient_hand_uram_q6_a2": "어려움 거의 없다",
  "patient_hand_uram_q6_a3": "약간의 어려움이 있다",
  "patient_hand_uram_q6_a4": "많은 어려움",
  "patient_hand_uram_q6_a5": "거의 불가능",
  "patient_hand_uram_q6_a6": "불가능",
  "patient_hand_uram_q7": "손가락을 펼치기",
  "patient_hand_uram_q7_a1": "어려움없이",
  "patient_hand_uram_q7_a2": "어려움 거의 없다",
  "patient_hand_uram_q7_a3": "약간의 어려움이 있다",
  "patient_hand_uram_q7_a4": "많은 어려움",
  "patient_hand_uram_q7_a5": "거의 불가능",
  "patient_hand_uram_q7_a6": "불가능",
  "patient_hand_uram_q8": "손에 기대기",
  "patient_hand_uram_q8_a1": "어려움없이",
  "patient_hand_uram_q8_a2": "어려움 거의 없다",
  "patient_hand_uram_q8_a3": "약간의 어려움이 있다",
  "patient_hand_uram_q8_a4": "많은 어려움",
  "patient_hand_uram_q8_a5": "거의 불가능",
  "patient_hand_uram_q8_a6": "불가능",
  "patient_hand_uram_q9": "엄지손가락과 검지로 작은 물건을 집기",
  "patient_hand_uram_q9_a1": "어려움없이",
  "patient_hand_uram_q9_a2": "어려움 거의 없다",
  "patient_hand_uram_q9_a3": "약간의 어려움이 있다",
  "patient_hand_uram_q9_a4": "많은 어려움",
  "patient_hand_uram_q9_a5": "거의 불가능",
  "patient_hand_uram_q9_a6": "불가능",
  "patient_hand_quality_of_life": "삶의 질",
  "patient_hand_quality_of_life_instruction": "귀하의 오늘 건강을 가장 잘 설명하는 항목에 대해 다음 중 하나를 선택해 주십시오.",
  "patient_hand_quality_of_life_instruction_0": "문제 없다",
  "patient_hand_quality_of_life_instruction_1": "약간",
  "patient_hand_quality_of_life_instruction_2": "보통",
  "patient_hand_quality_of_life_instruction_3": "심각",
  "patient_hand_quality_of_life_instruction_4": "극도로/불가능",
  "patient_hand_quality_of_life_q1": "걸을 때 문제",
  "patient_hand_quality_of_life_q1_a1": "문제 없다",
  "patient_hand_quality_of_life_q1_a2": "약간",
  "patient_hand_quality_of_life_q1_a3": "보통",
  "patient_hand_quality_of_life_q1_a4": "심각",
  "patient_hand_quality_of_life_q1_a5": "불가능",
  "patient_hand_quality_of_life_q2": "스스로 목욕하기/물기 말리기",
  "patient_hand_quality_of_life_q2_a1": "문제 없다",
  "patient_hand_quality_of_life_q2_a2": "약간",
  "patient_hand_quality_of_life_q2_a3": "보통",
  "patient_hand_quality_of_life_q2_a4": "심각",
  "patient_hand_quality_of_life_q2_a5": "불가능",
  "patient_hand_quality_of_life_q3": "일반적인 활동을 수행하는 문제 (예 : 일, 공부, 가사, 가족 또는 여가 활동)",
  "patient_hand_quality_of_life_q3_a1": "문제 없다",
  "patient_hand_quality_of_life_q3_a2": "약간",
  "patient_hand_quality_of_life_q3_a3": "보통",
  "patient_hand_quality_of_life_q3_a4": "심각",
  "patient_hand_quality_of_life_q3_a5": "불가능",
  "patient_hand_quality_of_life_q4": "통증 또는 불편함",
  "patient_hand_quality_of_life_q4_a1": "없음",
  "patient_hand_quality_of_life_q4_a2": "약간",
  "patient_hand_quality_of_life_q4_a3": "보통",
  "patient_hand_quality_of_life_q4_a4": "심각",
  "patient_hand_quality_of_life_q4_a5": "극단적인",
  "patient_hand_quality_of_life_q5": "불안/우울",
  "patient_hand_quality_of_life_q5_a1": "불안/우울하지 않다",
  "patient_hand_quality_of_life_q5_a2": "약간 불안/우울",
  "patient_hand_quality_of_life_q5_a3": "중간 정도의 불안/우울",
  "patient_hand_quality_of_life_q5_a4": "심각하게 불안/우울",
  "patient_hand_quality_of_life_q5_a5": "극도로 불안/우울",
  "patient_hand_quality_of_life_q6": "오늘 건강은 얼마나 좋은가요, 나쁜가요? (100은 최고의 건강 상태, 0은 최악의 건강 상태를 의미합니다.)",
  "patient_hand_quality_of_life_q6_a1": "최악",
  "patient_hand_quality_of_life_q6_a2": "최고",
  "patient_hand_satisfaction": "만족도",
  "patient_hand_satisfaction_instrucion": "다음 항목에 얼마나 만족하시는지 명시해 주세요:",
  "patient_hand_satisfaction_instrucion_0": "매우 불만족한다",
  "patient_hand_satisfaction_instrucion_1": "불만족한다",
  "patient_hand_satisfaction_instrucion_2": "만족하지도, 불만족하지도 않음",
  "patient_hand_satisfaction_instrucion_3": "어느정도 만족한다",
  "patient_hand_satisfaction_instrucion_4": "매우 만족한다",
  "patient_hand_satisfaction_q1": "손가락의 움직임",
  "patient_hand_satisfaction_q1_na": "해당 사항 없음",
  "patient_hand_satisfaction_q1_a1": "매우 불만족한다",
  "patient_hand_satisfaction_q1_a2": "불만족한다",
  "patient_hand_satisfaction_q1_a3": "만족하지도, 불만족하지도 않음",
  "patient_hand_satisfaction_q1_a4": "어느정도 만족한다",
  "patient_hand_satisfaction_q1_a5": "매우 만족한다",
  "patient_hand_satisfaction_q2": "손목의 움직임",
  "patient_hand_satisfaction_q2_na": "해당 사항 없음",
  "patient_hand_satisfaction_q2_a1": "매우 불만족한다",
  "patient_hand_satisfaction_q2_a2": "불만족한다",
  "patient_hand_satisfaction_q2_a3": "만족하지도, 불만족하지도 않음",
  "patient_hand_satisfaction_q2_a4": "어느정도 만족한다",
  "patient_hand_satisfaction_q2_a5": "매우 만족한다",
  "patient_hand_satisfaction_q3": "손의 모양 (오른쪽)",
  "patient_hand_satisfaction_q3_na": "해당 사항 없음",
  "patient_hand_satisfaction_q3_a1": "매우 불만족한다",
  "patient_hand_satisfaction_q3_a2": "불만족한다",
  "patient_hand_satisfaction_q3_a3": "만족하지도, 불만족하지도 않음",
  "patient_hand_satisfaction_q3_a4": "어느정도 만족한다",
  "patient_hand_satisfaction_q3_a5": "매우 만족한다",
  "patient_hand_satisfaction_q4": "손/손목 치료",
  "patient_hand_satisfaction_q4_na": "해당 사항 없음",
  "patient_hand_satisfaction_q4_a1": "매우 불만족한다",
  "patient_hand_satisfaction_q4_a2": "불만족한다",
  "patient_hand_satisfaction_q4_a3": "만족하지도, 불만족하지도 않음",
  "patient_hand_satisfaction_q4_a4": "어느정도 만족한다",
  "patient_hand_satisfaction_q4_a5": "매우 만족한다",
  "patient_hand_complete": "설문 끝",
  "patient_hand_complete_text1": "시간을 내어 이 설문 조사를 작성해 주셔서 감사합니다.",
  "patient_hand_complete_text2": "제출을 클릭하여 완료하고 점수를 확인하십시오.",
  "patient_hand_complete_text3": "귀하의 답은 기밀로 취급되며 모든 보고서는 신원 식별되지 않은 정보만 사용한다.",
  "patient_hand_result": "결과",
  "patient_hand_result_text1": "아래는 설문대로 계산된 통증 및 손과 손목 기능 점수입니다.",
  "patient_hand_result_function": "기능",
  "patient_hand_result_pain": "통증",
  "patient_hand_result_health_perception": "전반적인 건강 인식",
  "patient_hand_result_self_evaluation": "손 및 손목 점수 자체 평가",
  "patient_hand_result_text2": "당신의 의사가 곧 도착할 것입니다.",
  "patient_general_history": "현재 불편/부상/질병의 역사",
  "patient_general_history_q1": "주요 불만 사항 (본인의 말로 불만을 설명하기)",
  "patient_general_history_q2": "문제가 시작된 날짜",
  "patient_general_history_q3": "이미지 검사를 받으셨나요?",
  "patient_general_history_q3_a1": "없음",
  "patient_general_history_q3_a2": "엑스선",
  "patient_general_history_q3_a3": "초음파",
  "patient_general_history_q3_a4": "MRI",
  "patient_general_history_q3_a5": "MRI 관절 조영술(관절에 염료를 주입한 MRI)",
  "patient_general_history_q3_a6": "CT",
  "patient_general_history_q3_a7": "CT 관절 조영술(관절에 염료를 주입한 CT)",
  "patient_general_history_q4": "위에 나열되지 않은 귀하의 건강 상태에 대한 추가 조사가 있었나요?",
  "patient_general_history_q4_placeholder": "다른 조사",
  "patient_general_past_history": "과거 병력 / 현재 진행 중인 의료 문제",
  "patient_general_past_history_q1": "알레르기가 있으신가요? (약물 또는 음식)",
  "patient_general_past_history_q1_a1": "아니오",
  "patient_general_past_history_q1_a2": "예",
  "patient_general_past_history_placeholder": "알레르기 및 반응 유형을 지정하십시오.",
  "patient_general_past_history_q2": "라텍스에 알레르기가 있으신가요?",
  "patient_general_past_history_q2_a1": "아니오",
  "patient_general_past_history_q2_a2": "예",
  "patient_general_past_history_q3": "고혈압이 있으신가요? 혹은 고혈압 치료를 받고 계신가요?",
  "patient_general_past_history_q3_a1": "아니오",
  "patient_general_past_history_q3_a2": "예",
  "patient_general_past_history_q3_a2_placeholder": "고혈압 약물을 지정하십시오",
  "patient_general_past_history_q4": "현재 심장 질환/질병이 있거나 과거에 있었습니까?",
  "patient_general_past_history_q4_a1": "아니오",
  "patient_general_past_history_q4_a2": "예",
  "patient_general_past_history_q4_a2_o1": "건강문제를 선택하십시오",
  "patient_general_past_history_q4_a2_o2": "협심증",
  "patient_general_past_history_q4_a2_o3": "심장마비",
  "patient_general_past_history_q4_a2_o4": "심부전 (폐의 유체)",
  "patient_general_past_history_q4_a2_o5": "중얼 거림 또는 심장 판막 문제",
  "patient_general_past_history_q4_a2_o6": "촉진/불규칙한 심장 박동",
  "patient_general_past_history_q4_a2_o7": "이식형 장치(ICD, 심박조율기)",
  "patient_general_past_history_q4_a2_o8": "심장 수술",
  "patient_general_past_history_q4_a2_o9": "경동맥 수술",
  "patient_general_past_history_q4_a2_o10": "다른 심장 문제",
  "patient_general_past_history_q4_a2_o8_q1_placeholder": "심장 수술을 명시하십시오",
  "patient_general_past_history_q4_a2_o10_q1_placeholder": "다른 심장 상태를 명시합니다",
  "patient_general_past_history_q5": "당신은 심장병 전문의를 가지고 있나요?",
  "patient_general_past_history_q5_a1": "아니오",
  "patient_general_past_history_q5_a2": "예",
  "patient_general_past_history_q5_a2_placeholder": "이름 및 사례",
  "patient_general_past_history_q6": "다리의 혈액순환에 문제가 있나요 (말초혈관질환)?",
  "patient_general_past_history_q6_a1": "아니오",
  "patient_general_past_history_q6_a2": "예",
  "patient_general_past_history_q6_a2_placeholder": "설명",
  "patient_general_past_history_q7": "지난 한 달 동안 혈액 희석제를 복용한 적이 있으신가요?",
  "patient_general_past_history_q7_a1": "아니오",
  "patient_general_past_history_q7_a2": "예",
  "patient_general_past_history_q7_a2_q1": "이게 뭐야?",
  "patient_general_past_history_q7_a2_q1_a1": "아스피린",
  "patient_general_past_history_q7_a2_q1_a2": "클로피도그렐/플라빅스",
  "patient_general_past_history_q7_a2_q1_a3": "리바록사반",
  "patient_general_past_history_q7_a2_q1_a4": "엘리퀴스",
  "patient_general_past_history_q7_a2_q1_a5": "기타",
  "patient_general_past_history_q7_a2_q1_a5_placeholder": "혈액 희석제를 지정하십시오",
  "patient_general_past_history_q8": "폐나 가슴에 문제가 있으신가요?",
  "patient_general_past_history_q8_a1": "아니오",
  "patient_general_past_history_q8_a2": "예",
  "patient_general_past_history_q8_a2_o1": "가래를 동반한 기침?",
  "patient_general_past_history_q8_a2_o2": "호흡에 어려움이 있으신가요?",
  "patient_general_past_history_q8_a2_o3": "기타",
  "patient_general_past_history_q8_a2_o2_o1": "천식",
  "patient_general_past_history_q8_a2_o2_o2": "폐고혈압 (폐동맥성 고혈압)",
  "patient_general_past_history_q8_a2_o2_o3": "폐 섬유증",
  "patient_general_past_history_q8_a2_o2_o4": "사르코이도시스",
  "patient_general_past_history_q8_a2_o2_o5": "COPD 또는 폐기종",
  "patient_general_past_history_q8_a2_o2_o6": "낭성 섬유증",
  "patient_general_past_history_q8_a2_o2_o7": "결핵 (TB)",
  "patient_general_past_history_q8_a2_o2_o8": "기타",
  "patient_general_past_history_q8_a2_o3_placehold": "지정하십시오",
  "patient_general_past_history_q8_a2_o2_o8_placeholder": "지정하십시오",
  "patient_general_past_history_q9": "지난 일년 동안 스테로이드(프레드니손 또는 코티손)을 복용한 적이 있으신가요?",
  "patient_general_past_history_q9_a1": "아니오",
  "patient_general_past_history_q9_a2": "예",
  "patient_general_past_history_q10": "집에서 산소를 사용하시나요?",
  "patient_general_past_history_q10_a1": "아니오",
  "patient_general_past_history_q10_a2": "예",
  "patient_general_past_history_q11": "호흡기 전문의가 있으신가요?",
  "patient_general_past_history_q11_a1": "아니오",
  "patient_general_past_history_q11_a2": "예",
  "patient_general_past_history_q11_a2_placeholder": "이름 및 사례",
  "patient_general_past_history_q12": "과도한 코골이나 수면무호흡증이 있으신가요?",
  "patient_general_past_history_q12_a1": "아니오",
  "patient_general_past_history_q12_a2": "예",
  "patient_general_past_history_q12_a2_q1": "수면무호흡증을 CPAP 또는 BiPAP로 치료하고 있나요?",
  "patient_general_past_history_q12_a2_q1_a1": "아니오",
  "patient_general_past_history_q12_a2_q1_a2": "예",
  "patient_general_past_history_q13": "간 문제가 있었던 적이 있나요?",
  "patient_general_past_history_q13_a1": "아니오",
  "patient_general_past_history_q13_a2": "예",
  "patient_general_past_history_q13_a2_o1": "선택된",
  "patient_general_past_history_q13_a2_o2": "경화증",
  "patient_general_past_history_q13_a2_o3": "A형, B형 또는 C형 간염",
  "patient_general_past_history_q13_a2_o4": "황달",
  "patient_general_past_history_q13_a2_o5": "기타",
  "patient_general_past_history_q13_a2_o5_placeholder": "지정하십시오",
  "patient_general_past_history_q14": "당신은 이전에 신전 질환을 겪어 본 적이 있나요?",
  "patient_general_past_history_q14_a1": "아니오",
  "patient_general_past_history_q14_a2": "예",
  "patient_general_past_history_q15": "당신은 이전에 신전 질환을 겪어 본 적이 있나요?",
  "patient_general_past_history_q15_a1": "아니오",
  "patient_general_past_history_q15_a2": "예",
  "patient_general_past_history_q15_a2_q1": "당신은 투석 환자입니까? 복막 투석이세요? 혈액 투석이세요?",
  "patient_general_past_history_q15_a2_q1_a1": "아니오",
  "patient_general_past_history_q15_a2_q1_a2": "예",
  "patient_general_past_history_q15_a2_placeholder": "신장 질환을 지정하십시오",
  "patient_general_past_history_q15_a2_q1_a2_placeholder": "투석 유형 및 일정을 지정하십시오",
  "patient_general_past_history_q16": "당뇨병이 있으신가요?",
  "patient_general_past_history_q16_a1": "아니오",
  "patient_general_past_history_q16_a2": "예",
  "patient_general_past_history_q16_a2_q1": "당뇨병은 어떻게 관리하고 계세요? 식이/구강 약물 또는 인슐린을 사용하시나요?",
  "patient_general_past_history_q16_a2_q1_placeholder": "지정하십시오",
  "patient_general_past_history_q17": "당신은 갑상선 문제가 있나요?",
  "patient_general_past_history_q17_a1": "아니오",
  "patient_general_past_history_q17_a2": "예",
  "patient_general_past_history_q18": "당신은 이전에 간질, 발작 또는 경련을 겪어 본 적이 있습니까?",
  "patient_general_past_history_q18_a1": "아니오",
  "patient_general_past_history_q18_a2": "예",
  "patient_general_past_history_q18_a2_q1": "마지막 발작은 언제였나요?",
  "patient_general_past_history_q18_a2_q1_placeholder": "지정하십시오",
  "patient_general_past_history_q19": "신경이나 근육에 문제가 있으셨나요?",
  "patient_general_past_history_q19_a1": "아니오",
  "patient_general_past_history_q19_a2": "예",
  "patient_general_past_history_q19_a2_o1": "발작",
  "patient_general_past_history_q19_a2_o1_placeholder": "언제 ?",
  "patient_general_past_history_q19_a2_o2": "일과성 허혈 발작(TIA) 또는 뇌졸중",
  "patient_general_past_history_q19_a2_o2_placeholder": "언제 ?",
  "patient_general_past_history_q19_a2_o3": "얼굴, 다리 또는 팔 약화",
  "patient_general_past_history_q19_a2_o4": "신경학적 장애(예: 다발성 경화증, 루게릭병, 알츠하이머병)",
  "patient_general_past_history_q19_a2_o4_placeholder": "지정하십시오",
  "patient_general_past_history_q19_a2_o5": "근육 장애(예: 중증 근무력증, 근이영양증)",
  "patient_general_past_history_q19_a2_o5_placeholder": "지정하십시오",
  "patient_general_past_history_q19_a2_o6": "청각, 시력 또는 기억력에 문제가 있는 경우",
  "patient_general_past_history_q19_a2_o7": "만성 통증",
  "patient_general_past_history_q19_a2_o8": "기타",
  "patient_general_past_history_q19_a2_o8_placeholder": "지정하십시오",
  "patient_general_past_history_q20": "관절염이 있습니까?",
  "patient_general_past_history_q20_a1": "아니오",
  "patient_general_past_history_q20_a2": "예",
  "patient_general_past_history_q20_a2_o1": "골관절염",
  "patient_general_past_history_q20_a2_o2": "류마티스 관절염",
  "patient_general_past_history_q20_a2_o3": "기타",
  "patient_general_past_history_q20_a2_o3_placeholder": "지정하십시오",
  "patient_general_past_history_q21": "정신 질환으로 치료를 받은 적이 있나요?",
  "patient_general_past_history_q21_a1": "아니오",
  "patient_general_past_history_q21_a2": "예",
  "patient_general_past_history_q21_a2_o1": "심한 불안증",
  "patient_general_past_history_q21_a2_o2": "우울",
  "patient_general_past_history_q21_a2_o3": "기타",
  "patient_general_past_history_q21_a2_o3_placeholder": "지정하십시오",
  "patient_general_past_history_q22": "출혈 장애 진단을 받은 적이 있으신가요?",
  "patient_general_past_history_q22_a1": "아니오",
  "patient_general_past_history_q22_a2": "예",
  "patient_general_past_history_q22_a2_o1": "백혈병 또는 림프종",
  "patient_general_past_history_q22_a2_o2": "혈우병",
  "patient_general_past_history_q22_a2_o3": "혈전",
  "patient_general_past_history_q22_a2_o4": "빈혈증",
  "patient_general_past_history_q22_a2_o5": "겸상 적혈구 질환",
  "patient_general_past_history_q22_a2_o6": "기타",
  "patient_general_past_history_q22_a2_o6_placeholder": "지정하십시오",
  "patient_general_past_history_q23": "당신은 빈혈이 있나요?",
  "patient_general_past_history_q23_a1": "아니오",
  "patient_general_past_history_q23_a2": "예",
  "patient_general_past_history_q24": "다리나 폐에서 혈전이 생긴 적이 있으신가요?",
  "patient_general_past_history_q24_a1": "아니오",
  "patient_general_past_history_q24_a2": "예",
  "patient_general_past_history_q25": "당신이나 가족 중 누구든 다음과 같은 심각한 문제를 겪었습니까?",
  "patient_general_past_history_q25_a1": "코피",
  "patient_general_past_history_q25_a1_o1": "아니오",
  "patient_general_past_history_q25_a1_o2": "예",
  "patient_general_past_history_q25_a2": "발치로 인한 출혈",
  "patient_general_past_history_q25_a2_o1": "아니오",
  "patient_general_past_history_q25_a2_o2": "예",
  "patient_general_past_history_q25_a3": "수술 후 출혈",
  "patient_general_past_history_q25_a3_o1": "아니오",
  "patient_general_past_history_q25_a3_o2": "예",
  "patient_general_past_history_q26": "암 진단을 받은 적이 있나요?",
  "patient_general_past_history_q26_a1": "아니오",
  "patient_general_past_history_q26_a2": "예",
  "patient_general_past_history_q26_a2_placeholder1": "암 유형을 지정하십시오",
  "patient_general_past_history_q26_a2_placeholder2": "언제?",
  "patient_general_past_history_q27": "화학요법이나 방사선요법으로 치료 받은 적이 있으신가요?",
  "patient_general_past_history_q27_a1": "아니오",
  "patient_general_past_history_q27_a2": "예",
  "patient_general_past_history_q27_a2_placeholder1": "치료의 이름",
  "patient_general_past_history_q27_a2_placeholder2": "최근 치료 날짜",
  "patient_general_past_history_q28": "본인이나 친척이 마취에 문제가 있었나요?",
  "patient_general_past_history_q28_a1": "아니오",
  "patient_general_past_history_q28_a2": "예",
  "patient_general_past_history_q28_a2_q1": "어떤 문제들이 있나요?",
  "patient_general_past_history_q28_a2_q1_placeholder": "선택",
  "patient_general_past_history_q28_a2_q1_o1": "수술 후 심한 메스꺼움 또는 구토",
  "patient_general_past_history_q28_a2_q1_o2": "악성 고열(혈족인 가족 또는 본인)",
  "patient_general_past_history_q28_a2_q1_o3": "마취 호흡 튜브의 삽입에 대한 호흡 문제 또는 어려움?",
  "patient_general_past_history_q28_a2_q1_o4": "호흡 튜브 배치 문제",
  "patient_general_past_history_q28_a2_q1_o5": "기타",
  "patient_general_past_history_q28_a2_q1_o5_placeholder": "지정하십시오",
  "patient_general_past_history_q29": "다음 중 어떤 것을 가지고 있습니까?",
  "patient_general_past_history_q29_q1": "깨진 이빨이나 느슨한 이빨, 치과용 의치, 부분치과",
  "patient_general_past_history_q29_q1_a1": "아니오",
  "patient_general_past_history_q29_q1_a2": "예",
  "patient_general_past_history_q29_q2": "목을 움직이는 데 문제가 있나요?",
  "patient_general_past_history_q29_q2_a1": "아니오",
  "patient_general_past_history_q29_q2_a2": "예",
  "patient_general_past_history_q29_q3": "입을 벌리는 문제",
  "patient_general_past_history_q29_q3_a1": "아니오",
  "patient_general_past_history_q29_q3_a2": "예",
  "patient_general_past_history_q30": "임신하셨을 가능성이 있나요?",
  "patient_general_past_history_q30_a1": "아니오",
  "patient_general_past_history_q30_a2": "예",
  "patient_general_past_history_q30_not_applicable": "해당 사항 없음",
  "patient_general_past_history_q30_a2_q1": "마지막 월경 기간",
  "patient_general_past_history_q30_a2_q1_placeholder": "마지막 월경 기간",
  "patient_general_past_history_q31": "의료 기록 : 위에 언급되지 않은 추가적인 의료 질환을 기재해주세요.",
  "patient_general_past_history_q31_q1": "모든 약품(일반의약품, 흡입기, 패치, 점안제, 비타민, 미네랄, 보충제, 허브 포함)을 기재하세요.",
  "patient_general_past_history_q31_q2": "약물 이름, 복용량, 복용 횟수 및 복용 이유를 명시하세요.",
  "patient_general_past_history_q31_q2_placeholder": "약물 이름, 복용량, 복용 횟수 및 복용 이유",
  "patient_general_past_history_q31_q3": "용량",
  "patient_general_past_history_q31_q4": "자주",
  "patient_general_past_history_q31_q5": "약을 먹는 이유",
  "patient_general_past_history_q31_q6": "수술 이력",
  "patient_general_past_history_q31_q6_placeholder": "수술 이름과 수술 날짜를 입력해 주세요.",
  "patient_general_social_history": "사회적 역사",
  "patient_general_social_history_q1": "혼자 거주 중입니까?",
  "patient_general_social_history_q1_a1": "아니오",
  "patient_general_social_history_q1_a2": "예",
  "patient_general_social_history_q1_a1_q1": "누구와 함께 살고 있나요?",
  "patient_general_social_history_q1_a1_q1_placeholder": "누구와 함께 살고 있나요?",
  "patient_general_social_history_q2": "담배를 피우시나요?",
  "patient_general_social_history_q2_a1": "아니오",
  "patient_general_social_history_q2_a2": "예",
  "patient_general_social_history_q2_a2_q1": "하루에 몇 개의 담배를 피우십니까?",
  "patient_general_social_history_q2_a2_q1_placeholder": "담배 수",
  "patient_general_social_history_q2_a2_q2": "몇 년 동안?",
  "patient_general_social_history_q2_a2_q2_placeholder": "년",
  "patient_general_social_history_q3": "과거에 담배를 피웠습니까?",
  "patient_general_social_history_q3_a1": "아니오",
  "patient_general_social_history_q3_a2": "예",
  "patient_general_social_history_q3_a2_q1": "담배를 피우는 것을 언제 그만두셨나요?",
  "patient_general_social_history_q3_a2_q1_placeholder": "담배를 피우는 것을 언제 그만두셨나요?",
  "patient_general_social_history_q3_a2_q2_placeholder": "년",
  "patient_general_social_history_q4": "술을 드시나요?",
  "patient_general_social_history_q4_a1": "아니오",
  "patient_general_social_history_q4_a2": "예",
  "patient_general_social_history_q4_a2_q1_placeholder": "주당 표준 음주 횟수",
  "patient_general_social_history_q5": "처방되지 않은 기호용 또는 길거리(불법) 약물, 마리화나 또는 오피오이드를 사용하거나 소지한 적이 있습니까?",
  "patient_general_social_history_q5_a1": "아니오",
  "patient_general_social_history_q5_a2": "예",
  "patient_general_social_history_q5_a2_placeholder": "약물을 지정하십시오.",
  "patient_general_complete": "설문 끝",
  "patient_general_complete_text1": "시간을 내어 이 설문 조사를 작성해 주셔서 감사합니다.",
  "patient_general_complete_text2": "제출을 클릭하여 완료하고 점수를 확인하십시오.",
  "patient_general_complete_text3": "귀하의 답은 기밀로 취급되며 모든 보고서는 신원 식별되지 않은 정보만 사용한다.",
  "AppVersion": "앱 버전",
  "Complete_personal_info": "완전한 개인 정보",
  "To_complete_the_sign_up": "가입을 완료하려면 아래에 정보를 입력해 주세요.",
  "Select_Existing_Profile": "기존 프로필을 선택하기",
  "Get_In": "기입",
  "Create_new_patient": "새로운 환자를 생성",
  "myProfile": "나의 프로필",
  "First_Name": "이름",
  "Last_Name": "성",
  "DOB": "생년월일",
  "Sex": "성별",
  "Address": "주소",
  "Postcode": "우편번호",
  "Suburb": "구역*",
  "Country_Code": "국가번호",
  "Mobile_Phone": "핸드폰번호",
  "Home_Phone": "자택번호",
  "Email": "이메일",
  "Emergency_Contact": "긴급 연락처",
  "Phone": "전화",
  "Relationship": "관계",
  "Regular_GP_Name": "담당 GP 의사 이름*",
  "Practice_Name": "의료 장소 이름*",
  "Medicare_Card_Number": "메디케어 카드 번호",
  "Ref": "참조",
  "Message": "메시지",
  "Expiry": "기한",
  "Private_Health_Fund": "개인 건강 기금",
  "Membership_Number": "멤버십 번호",
  "Claim_Type": "클레임 유형",
  "Work_Cover": "직업 보험 보장 범위",
  "Employer": "고용인",
  "Third_Party_Claim_Number": "3자 청구 번호",
  "Third_Party_Name": "3자 이름",
  "DVA_Concession_Card": "DVA 할인 카드",
  "Number": "번호",
  "Defence_Personnel": "국방 요원",
  "I_agree": "내 개인정보를 제공하는 것에 동의하며 파일을 읽었습니다",
  "Privacy_Policy": "개인정보정책",
  "visitClinic": "예약하기",
  "selectdocOrg": "의사와 병원 고르기",
  "doctorOrganization": "의사 조직 \"",
  "Select_Treatment_Category": "치료 카테고리 고르기",
  "Do_you_want_to_submit_patient_form_or_not": "환자 양식을 제출하시겠습니까?",
  "areyousurewanttocontinueyourform": "이전에 작업하던 양식이 있습니다. 계속하시겠습니까?",
  "myVisit": "이전 방문",
  "View_score": "점수 보기",
  "upcoming_visits": "다가오는",
  "No_data_found": "데이터가 없습니다",
  "Doctor_Name": "의사 이름",
  "Appointment_Date_Time": "예약 일시",
  "Clinic_Name": "의원 이름",
  "Clinic_Address": "의원 주소",
  "akmed": "아무나 메디컬",
  "Type": "타이핑",
  "Date_Time": "일시",
  "Action": "액션",
  "View": "보기",
  "No_Messages": "메시지 없음",
  "settings": "환경설정",
  "Language": "언어",
  "language": "언어 및 지역",
  "timezone": "시간대",
  "Timeformat": "시간 유형",
  "List_of_my_profile": "나의 프로필 목록",
  "next_button": "다음",
  "back_button": "뒤로",
  "Continue": "지속",
  "Close": "닫기",
  "submit": "제출",
  "Sign_out": "프로필로 돌아가기",
  "Results": "결과",
  "update": "서류 업데이트 성공",
  "Personal_Data": "개인 정보",
  "Date_of_Birth": "생년월일",
  "Hip_arthritis": "고관절 관절염?",
  "Area_of_foot_ankle": "족부/발목 수상부위?",
  "Diagnosis": "진단?",
  "Evaluated_Shoulder": "검진한 어깨",
  "Dominant_Hand": "우세수",
  "Age": "나이",
  "No": "아니오",
  "Yes": "예",
  "visitNo": "방문No",
  "visitYes": "방문Yes",
  "shoulderPainR": "어깨통증R",
  "Is_it_initial_visit": "Is this your initial visit with",
  "for_the_problem": "이 문제에 대해?*",
  "Diagnosis_Procedure": "진단 / 절차",
  "Procedure": "절차",
  "Evaluated_sdfs": "평가됨 *.",
  "Please_Select_previous_visits": "이전 방문을 선택해주세요",
  "Follow_Up": "추시",
  "Assessment_Date": "평가일",
  "Do_you_have_Dupuytren_disease": "듀피트렌 병이 있습니까?",
  "Do_you_have_carpal_tunnel_syndrome": "손목터널증후군이 있습니까?",
  "Is_your_unaffected_shoulder_pain": "건강한 반대쪽 어깨는 통증이 없고 정상적으로 사용 가능합니까?",
  "Is_your_unaffected_hip_pain": "영향을 받지 않은 고관절에 통증이 없고 정상적인 기능이 가능합니까?",
  "Is_your_unaffected_knee_pain": "영향을 받지 않은 무릎에 통증이 없고 정상적인 기능이 가능합니까?",
  "Is_your_unaffected_elbow_pain": "영향을 받지 않은 팔꿈치에 통증이 없고 정상적인 기능이 가능합니까?",
  "Is_your_unaffected_foot_and_ankle_pain": "영향을 받지 않은 발과 발목에 통증이 없고 정상적인 기능이 가능한가요?",
  "Is_your_unaffected_hand_pain": "영향을 받지 않은 손과 손목에 통증이 없고 정상적인 기능이 가능한가요?",
  "Patient_Consent": "아쿠나 환자 정보 및 사전 동의 양식",
  "Patient_Last_Name": "환자 성",
  "Patient_First_Name": "환자 이름",
  "Patient_Date_of_Birth": "환자 생년월일",
  "information": "정보",
  "I_am_informedDDD": "당신의 정형 외과 치료의 평가, 계획 및 결과 평가를 위해 의사님,",
  "Clinician": "(이하 \"임상의\")는 Akunah Medical Technology Pty Ltd 및 그 자회사(\"Akunah\")가 제공하는 평가, 결과 데이터 수집 및 분석 및/또는 수술 전 계획 서비스 및 소프트웨어를 사용합니다.",
  "For_that": "그 목적을 위해, 의사는 당신의 개인 데이터를 Akunah로 전송할 것입니다. 이에는 당신의 몸의 컴퓨터 단층 촬영(CT) 스캔과 다른 의료 데이터가 포함됩니다.",
  "Akunah_softawre": "Akunah의 소프트웨어 및 서비스에는 이미지 평가, 의료 데이터 평가, 주요 해부학적 측정과 함께 개인화된 해부학 3D 모델 생성, 수술 계획 및 수술 지원이 포함될 수 있으며, 이는 임상의와 공유됩니다.",
  "the_clinic": "의사는 귀하의 정형 외과 치료를 위해 평가 및 수술 전 계획을 사용할 것입니다.",
  "oportunnity": "또한, Akunah의 환자 포털을 통해 개인 데이터를 제공하고 Akunah의 모델과 계획에 접근할 수 있는 기회도 있습니다.",
  "akunah_approch": "개인 정보 보호 및 보안에 대한 Akunah의 접근 방식",
  "akunah_approch1": "Akunah는 임상의를 지원하기 위해 귀하로부터 수집하는 데이터의 개인정보 보호 및 보안을 가장 중요하게 생각합니다.",
  "akunah_approch2": "Akunah는 전 세계에서 제정된 가장 강력한 개인정보 보호법인 EU 일반 데이터 보호 규정 EU/2016/679를 포함한 해당 유럽 데이터 보호법을 자발적으로 준수합니다. 이러한 법률은 호주, 미국 및 기타 국가에서 제정된 개인정보 보호법을 초과합니다.",
  "akunah_approch3": "Akunah의 \"고객 개인의 개인 정보 처리를 위한 개인정보 처리방침\"은 Akunah가 해당 유럽 데이터 보호법(",
  "akunah_approch4": "개인 정보 보호 코드",
  "akunah_approch5": "아쿠나의 '데이터 처리 계약'은 클리니션들과의 협정을 설명하며 개인 정보를 보호하고 안전하게 유지하는 방법을 제시합니다.",
  "akunah_approch6": "데이터 처리 동의서",
  "akunah_approch7": "해당 정책은 Akunah 웹사이트에서 확인하실 수 있습니다.",
  "terms_": "https://akunah.com/termsofservices 를 한국어로 번역하면 다음과 같습니다.",
  "akunah_approch8": "개인정보 보호 규정, 데이터 처리 계약서 및 이 문서 간에 일관성에 어떠한 모순이 있을 경우, 첫 번째 언급된 문서의 조항이 이 문서와의 모순 부분에 한하여 적용됩니다.",
  "Data_Collection_and_Processing": "데이터 수집 및 처리",
  "I_am_informed": "아쿠나는 귀하의 연락처 정보, 인증, 신분증명, 직업 및 인구통계와 관련된 정보를 포함하여 귀하의 데이터를 수집할 수 있습니다. 아쿠나는 또한 귀하의 건강과 관련된 의료 데이터도 수집할 수 있습니다.",
  "Assessment_and_evaluation": "• 제안된 치료법 및 결과와 관련된 평가 및 평가 데이터, 신체적 특성 및 기타 정보;",
  "Previous_surgery": "• 이전 수술, 진단, 방사선 스캔, 3D 모델, 해부학, 해부학적 측정; 그리고",
  "Health_and_medical_history": "• 건강 및 의료 기록, 증상, 약물 및 이전 진단 및 치료 내역을 포함합니다.",
  "I_have_been_assured": "Akunah는 환자로부터 직접 수집하든, 임상의가 공유하든, Akunah 제품을 통해 수집하든 항상 합법적이고 공정한 방식으로 정보를 수집합니다. Akunah는 필요한 경우 평가 및 수술 전 계획 서비스를 제공할 수 있도록 아래에 명시된 대로 귀하의 동의에 따라 이러한 데이터를 처리합니다.",
  "My_data": "또한, Akunah는 모든 관련 법률과 규정을 준수하기 위해 귀하의 개인 데이터를 사용할 수 있습니다.",
  "your_data": "제안된 서비스를 제공하기 위해 필요하거나 법률에 따라 제3자에게 데이터가 공개될 수도 있습니다.",
  "Data_Access_and_Storage": "데이터 접근, 저장 및 보안",
  "Personal_data_provided": "당신과 당신의 치료사가 제공한 데이터는 Akunah의 직원들과 제3자들에게만 접근이 가능하며, 당신의 치료나 회복에 기여하는 사람들 (예: 마취과 의사, 물리치료사)과 데이터에 접근해야 하는 사람들에게만 공개됩니다.",
  "data1": "아쿠나 소프트웨어를 통해 입력된 데이터는 승인된 호스팅 제공업체에 의해 호주 연방에서 안전하게 호스팅됩니다. 이는 아쿠나 소프트웨어를 통해 처리되는 데이터의 성격을 반영한 적절한 기밀성과 보안 수준을 보장합니다.",
  "data2": "귀하가 오스트레일리아 연방 외부에 거주하거나 Akunah 소프트웨어를 통해 입력한 데이터가 유럽연합 또는 미국을 포함하여 오스트레일리아 연방 외부에 위치한 Akunah 자회사가 액세스할 수 있는 경우, 해당 국가에서 귀하의 데이터가 사용 및 보호되는 방식에 다른 개인정보 보호 표준이 적용될 수 있습니다. 이 경우 귀하의 개인 데이터는 해당 국가에 저장됩니다.",
  "data3": "Akunah는 모든 관련 법적 요건에 따라 적절한 안전장치를 마련하여 데이터를 기밀로 유지하고 모든 제3자와 적절한 수준의 데이터 보호를 보장합니다. 당사는 액세스 권한이 있는 모든 사람에게 Akunah가 채택한 것과 동등한 수준의 데이터 보호를 보장하는 표준을 채택할 것을 요구합니다.",
  "data4": "귀하의 임상의는 법적 요건에 따라 귀하의 데이터를 수집, 액세스, 분석 및 공유할 책임을 공유합니다.",
  "p_data1": "귀하의 개인 데이터는 Akunah에서 다양한 기간 동안 보관합니다. 보존 기간은 다음 기준에 따라 결정됩니다: (i) Akunah 또는 임상의가 귀하의 개인 데이터를 사용하는 목적(해당 목적에 필요한 기간 동안만 데이터를 보관해야 함); 그리고 (ii) Akunah가 귀하의 개인 데이터를 보관해야 하는 최소 기간을 설정할 수 있는 관련 법률 또는 규정.",
  "Deidentified_Data": "비식별화된 데이터",
  "d_txt1": "Akunah는 귀하의 데이터를 신원 비식별 처리한 다음 기록 목적, 연구 개발, 데이터 분석, 소프트웨어/기기 개발 및 Akunah의 제품 및 서비스 개선을 위해 이러한 비식별 처리된 데이터(일반적으로 다른 환자의 비식별 처리된 데이터와 집계)를 사용할 수 있습니다. 이를 통해 Akunah는 귀하와 같은 환자를 위해 제품과 서비스를 지속적으로 개선할 수 있습니다.",
  "Patient_Rights": "당신의 권리",
  "I_understand_that": "귀하는 Akunah에 귀하의 개인 데이터를 제공하는 것에 대해 거부할 수 있습니다. 이 경우 담당 임상의가 Akunah의 소프트웨어 및 서비스를 사용하지 않고는 의료 서비스 또는 치료를 제공하지 못할 수 있으므로 담당 임상의와 상의하시기 바랍니다.",
  "I_understand_that_1txt": "귀하는 언제든지 어떠한 방법으로든 동의를 철회할 권리가 있으며, 해당되는 경우 다음과 같은 권리를 갖습니다: (i) 귀하의 개인 데이터 처리에 관한 정보를 얻고 해당 개인 데이터에 액세스할 권리, (ii) 정보가 부정확한 경우 수정을 요청할 권리, (iii) 특정 개인 데이터 처리에 대한 반대 및 제공한 동의의 철회 요청을 포함하여 귀하의 개인 데이터 처리 제한을 요청할 권리; (iv) 귀하의 개인 데이터 삭제를 요청할 권리, (v) 귀하의 사망 후 데이터 처리와 관련된 지침을 정의할 권리, (vi) 위에서 언급한 조건에 따라 처리된 귀하의 개인 데이터 사본을 구조화되고 일반적으로 사용되며 기계가 읽을 수 있는 형식으로 받을 권리. 이러한 권리는 임상의에게 직접 요청하여 행사할 수 있습니다.",
  "I_understand_that_2txt": "당신의 권리는 적용 가능한 예외와 법적 제한에 따라 제한될 수 있습니다.",
  "I_understand_that_3txt": "철회 후에는 제안된 절차 이전에 해당 권리를 행사한다면, 당신의 치료사는 의료 서비스나 치료를 제공할 수 없을 수도 있습니다.",
  "I_understand_that_4txt": "또한 귀하는 거주 국가의 감독 기관에 개인 데이터 처리에 대한 불만을 제기할 자유도 있습니다.",
  "I_understand_that_5txt": "비식별처리된 데이터와 관련하여 신원 비식별처리된 데이터는 개인 데이터와 동일한 법적 제한이 적용되지 않습니다. 비식별 처리된 데이터에 대한 액세스 및 사용에 대한 동의를 철회하거나 데이터 삭제를 요청할 수 없으며, Akunah는 귀하의 데이터를 찾을 수 없으므로 주의하시기 바랍니다. 비식별처리 후에는 Akunah는 데이터를 다시 추적할 수 없습니다.",
  "Contact": "동의서",
  "I_am_able_to_contact": "위의 정보 섹션을 읽고 이해했으며, 또는 나에게 이해할 수 있는 언어로 읽어준 사람이 있습니다.",
  "or_by_post": "질문을 할 수 있는 기회가 있었고, 받은 답변에 만족합니다. 제 정보가 비밀로 유지될 것을 이해합니다.",
  "or_by_post2": "의사에게 허락을 줍니다.",
  "or_by_post2_1": "나는 (의사)가 나의 개인 데이터를 아쿠나와 공유하도록 허락합니다.",
  "or_by_post3": "저는 위의 정보 섹션에 설명된 대로 Akunah가 제 개인 및 비식별화된 데이터를 수집, 저장, 사용 및 공유할 수 있는 권한을 부여합니다.",
  "or_by_post4": "언제든지 동의를 철회할 수 있는 자유를 가지고 있다는 것을 이해합니다.",
  "or_by_post5": "나는 이 문서의 서명된 사본을 보관할 것을 이해합니다.",
  "or_by_post6": "나는 이메일을 통해 내 데이터 처리에 관해 Akunah에게 연락할 수 있습니다.",
  "info_akunah": "info@akunah.com.",
  "or_by_post7": "또는 우편으로 호주 퀸즐랜드 그린슬로프스 뉴데게이트 스트리트 121번지 9층 니콜슨 스트리트 전문 센터 307호로 보내십시오.",
  "Clear": "클리어",
  "Date": "날짜",
  "Signed_by_Mouse": "마우스에 의해 서명됨",
  "Name_if_consent_on_behalf_of_patient": "이름 (환자 대신 동의 시)",
  "Relationship_to_patient_if_applicable": "해당되는 경우 환자와의 관계",
  "Choose_Sign_Type": "서명 방법 선택*",
  "Draw": "그리기",
  "Signed_by_Keyboard": "키보드로 서명됨 *",
  "Agreement": "동의",
  "agree_that_I_have_read": "본인은 본 환자동의서를 읽고 이해했으며, 위에 설명된 목적으로 개인 데이터를 처리하는 데 명시적으로 동의합니다.",
  "NOTE": "주의사항",
  "If_the_patient": "환자가 18 세 미만인 경우 부모 또는 법적 보호자가 서명해야합니다.",
  "If_the_patient_is_deceased": "환자가 사망 한 경우, 이것은 다음 친척이 서명해야합니다.",
  "patient_signature": "환자 서명",
  "profile_updated": "프로필 업데이트됨",
  "profile_not_updated": "프로필 업데이트되지 않음",
  "video": "동영상",
  "radiology_form": "방사선 서류",
  "doc_document": "문서",
  "pathology_form": "병리 서류",
  "text": "텍스트",
  "Document_Name": "문서 이름",
  "email_sent_successfully": "삭제 요청이 성공적으로 전송됩니다.",
  "messages": "메시지",
  "adult_knee": "성인 무릎",
  "paediatric_knee": "소아 무릎",
  "document": "내 문서",
  "Check_in": "체크인",
  "info_icon": "환자 설문지를 작성하십시오",
  "reminder": "주의",
  "Search_with_minimum_three_characters": "최소 3 자로 검색하십시오",
  "Select": "선택",
  "delete_account": "계정 삭제",
  "DD_MM_YYYY": "DD / mm / yyyy",
  "Female": "여성",
  "Male": "남성",
  "want_to_disclose": "공개하고 싶지 않습니다",
  "enter_address": "주소를 입력하십시오",
  "logout": "로그 아웃",
  "full_name": "전체 이름",
  "mmyy": "mm / yy",
  "health_found": "건강 기금",
  "Not_Applicable": "해당 사항 없음",
  "not_available": "사용 불가",
  "available": "사용 가능",
  "shared": "공유됨",
  "private": "사적인",
  "add_document": "문서 추가",
  "drap_and_drop": "여기로 파일을 끌어다 놓으세요.",
  "or_browser_files": "또는 컴퓨터에서 파일을 찾아보십시오",
  "browse_files": "파일을 찾아보기",
  "download": "다운로드",
  "Oops_error": "죄송합니다! 요청한 데이터는 찾을 수 없었습니다.",
  "go_to_home": "집으로 돌아가기",
  "This_field_is_required": "이 필드는 필수입니다.",
  "PendingForm": "보류 중인 양식",
  "NoFollowUpRecords": "추적 기록 없음",
  "Right": "오른쪽",
  "Both": "둘 다",
  "Left": "왼쪽",
  "shoulder": "어깨",
  "hip_form": "힙",
  "Foot_And_Ankle": "발과 발목",
  "Hand_And_Wrist": "손과 손목",
  "General": "일반적인",
  "adolescent_knee": "청소년 무릎",
  "Elbow": "팔꿈치",
  "title": "제목",
  "description": "설명",
  "enter_your_description_here": "여기에 설명을 입력하십시오",
  "enter_your_title_here": "여기에 제목을 입력하십시오",
  "progress": "보류 중",
  "done": "완성",
  "reset": "초기화",
  "send": "보내다",
  "Edit": "편집하다",
  "delete": "삭제",
  "Are_you_sure_want_to_done_the_remainder": "이 메모를 작성 하시겠습니까?",
  "Confirm": "확인하다",
  "Update": "업데이트",
  "success": "성공",
  "error": "오류",
  "Are_you_sure_you_want_to_delete": "삭제 하시겠습니까?",
  "cancel": "취소",
  "Hour_Time": "24시간제",
  "Knee": "무릎",
  "no": "아니오",
  "yes": "예",
  "Year": "0 년, 0 개월, 0 일",
  "Bilateral": "양손잡이",
  "Left_Arm": "왼쪽 어깨",
  "Right_Arm": "오른쪽 어깨",
  "out_of": "/",
  "Right_Elbow": "오른쪽 팔꿈치",
  "Left_Elbow": "왼쪽 팔꿈치",
  "right_foot_ankle": "오른발/발목",
  "left_foot_ankle": "왼쪽 발/발목",
  "Right_hand_and_wrist": "오른손과 손목",
  "Left_hand_and_wrist": "왼손과 손목",
  "Hip_Function_R": "오른쪽 힙",
  "Hip_Function_L": "왼쪽 힙",
  "Right_Knee": "우측 무릎",
  "Left_Knee": "좌측 무릎",
  "Right_Pedi_Knee": "우측 무릎",
  "Left_Pedi_Knee": "좌측 무릎",
  "Scoring_Self_Evaluation": "자체 평가 점수",
  "presciption_form": "처방",
  "insurance_form": "보험",
  "letter_form": "편지",
  "refferal_form": "추천",
  "medical_form": "의료",
  "are_you_sure_delete_account": "계정을 삭제 하시겠습니까?",
  "Adult_Knee_Pain": "성인 무릎 통증",
  "Knee_pain_13_18_year": "무릎 통증",
  "day_pdata": "일",
  "days_pdata": "일",
  "month_pdata": "월",
  "months_pdata": "월",
  "year_pdata": "년",
  "years_pdata": "년",
  "Name": "이름",
  "akunah_id": "Akunah ID",
  "mobile": "전화",
  "email": "이메일",
  "upload_a_profile": "이미지를 업로드",
  "No_special_characters_are_allowed": "특수 문자는 허용되지 않습니다",
  "numbers_are_not_allowed": "숫자는 허용되지 않습니다",
  "maximum_50_character_are_allowed": "최대 50자가 허용",
  "invalid_character": "잘못된 문자",
  "fields_only_contain_10_character": "필드에 10자만 입력 가능",
  "maximum_10_Numbers_are_allowed": "최대 10 개의 숫자가 허용됩니다",
  "Only_I": "숫자 만 허용됩니다",
  "invalid_home_phone_number": "잘못된 주택 전화 번호",
  "special_characters_are_not_allowed": "특수 문자는 허용되지 않습니다",
  "maximum_digit_are_allowed": "최대 1 자리가 허용됩니다",
  "invalid_date": "유효하지 않은 날짜",
  "maximum_10_digits_are_allowed": "최대 10 자리가 허용됩니다",
  "switch_profile": "프로필 전환",
  "loading": "로딩",
  "error_max_profile_limit": "하나의 이메일 주소로 허용되는 최대 계정 수에 도달했습니다.",
  "error_upcoming_appointments": "예정된 약속을 찾을 수 없습니다.",
  "error_past_visits": "과거 방문 기록이 없습니다.",
  "error_documents": "문서를 찾을 수 없습니다.",
  "country_hawaii": "(UTC-10:00) Hawaii Time",
  "country_tahiti": "(UTC-10:00) Tahiti",
  "country_pitcairn": "(UTC-08:00) Pitcairn",
  "country_niue": "(UTC-11:00) Niue",
  "country_mountain": "(UTC-07:00) Mountain Time",
  "country_arizona": "(UTC-07:00) Mountain Time - Arizona",
  "country_whitehorse": "(UTC-08:00) Pacific Time - Whitehorse",
  "country_chihuahua": "(UTC-07:00) Mountain Time - Chihuahua, Mazatlan",
  "country_edmonton": "(UTC-07:00) Mountain Time - Edmonton",
  "country_gambier": "(UTC-09:00) Gambier",
  "country_yellowknife": "(UTC-07:00) Mountain Time - Yellowknife",
  "country_dawson": "(UTC-07:00) Mountain Time - Dawson Creek",
  "country_belize": "(UTC-06:00) Belize",
  "country_hermosillo": "(UTC-07:00) Mountain Time - Hermosillo",
  "country_mexico": "(UTC-06:00) Central Time - Mexico City",
  "country_regina": "(UTC-06:00) Central Time - Regina",
  "country_tegucigalpa": "(UTC-06:00) Central Time - Tegucigalpa",
  "country_centraltime": "(UTC-06:00) Central Time",
  "country_rarotonga": "(UTC-10:00) Rarotonga",
  "country_pago": "(UTC-11:00) Pago Pago",
  "country_pacific": "(UTC-08:00) Pacific Time",
  "country_alaska": "(UTC-09:00) Alaska Time",
  "country_vancouver": "(UTC-08:00) Pacific Time - Vancouver",
  "country_tijuana": "(UTC-08:00) Pacific Time - Tijuana",
  "country_salvador": "(UTC-06:00) El Salvador",
  "country_costa": "(UTC-06:00) Costa Rica",
  "country_winnipeg": "(UTC-06:00) Central Time - Winnipeg",
  "country_galapagos": "(UTC-06:00) Galapagos",
  "country_managua": "(UTC-06:00) Managua",
  "country_america_cancun": "(UTC-05:00) America Cancun",
  "country_guatemala": "(UTC-06:00) Guatemala",
  "country_bogota": "(UTC-05:00) Bogota",
  "country_easter_island": "(UTC-05:00) Easter Island",
  "country_iqaluit": "(UTC-05:00) Eastern Time - Iqaluit",
  "country_toronto": "(UTC-05:00) Eastern Time - Toronto",
  "country_eastern_time": "(UTC-05:00) Eastern Time",
  "country_havana": "(UTC-05:00) Havana",
  "country_lima": "(UTC-05:00) Lima",
  "country_jamaica": "(UTC-05:00) Jamaica",
  "country_panama": "(UTC-05:00) Panama",
  "country_port_au_prince": "(UTC-05:00) Port-au-Prince",
  "country_nassau": "(UTC-05:00) Nassau",
  "country_rio_branco": "(UTC-05:00) Rio Branco",
  "country_barbados": "(UTC-04:00) Barbados",
  "country_halifax": "(UTC-04:00) Atlantic Time - Halifax",
  "country_bermuda": "(UTC-04:00) Bermuda",
  "country_boa_vista": "(UTC-04:00) Boa Vista",
  "country_curacao": "(UTC-04:00) Curacao",
  "country_marquesas": "(UTC-09:30) Marquesas",
  "country_caracas": "(UTC-04:00) Caracas",
  "country_grand_turk": "(UTC-04:00) Grand Turk",
  "country_la_paz": "(UTC-04:00) La Paz",
  "timezone_guyana": "(UTC-04:00) Guyana",
  "timezone_port_of_spain": "(UTC-04:00) Port of Spain",
  "timezone_manaus": "(UTC-04:00) Manaus",
  "timezone_martinique": "(UTC-04:00) Martinique",
  "timezone_porto_velho": "(UTC-04:00) Porto Velho",
  "timezone_santo_domingo": "(UTC-04:00) Santo Domingo",
  "timezone_puerto_rico": "(UTC-04:00) Puerto Rico",
  "timezone_araguaina": "(UTC-03:00) Araguaina",
  "timezone_belem": "(UTC-03:00) Belem",
  "timezone_buenos_aires": "(UTC-03:00) Buenos Aires",
  "timezone_newfound_time": "(UTC-03:30) Newfoundland Time - St. Johns",
  "timezone_asuncion": "(UTC-03:00) Asuncion",
  "timezone_campo": "(UTC-03:00) Campo Grande",
  "timezone_cayenne": "(UTC-03:00) Cayenne",
  "timezone_cuiaba": "(UTC-03:00) Cuiaba",
  "timezone_maceio": "(UTC-03:00) Maceio",
  "timezone_godthab": "(UTC-03:00) Godthab",
  "timezone_fortaleza": "(UTC-03:00) Fortaleza",
  "timezone_miquelon": "(UTC-03:00) Miquelon",
  "timezone_palmer": "(UTC-03:00) Palmer",
  "timezone_montevideo": "(UTC-03:00) Montevideo",
  "timezone_recife": "(UTC-03:00) Recife",
  "timezone_rothera": "(UTC-03:00) Rothera",
  "timezone_paramaribo": "(UTC-03:00) Paramaribo",
  "timezone_punta_arenas": "(UTC-03:00) Punta Arenas",
  "timezone_santig": "(UTC-03:00) Santiag",
  "timezone_salvador": "(UTC-03:00) Salvador",
  "timezone_stanley": "(UTC-03:00) Stanley",
  "timezone_south_georgia": "(UTC-02:00) South Georgia",
  "timezone_noronha": "(UTC-02:00) Noronha",
  "timezone_sao_pulo": "(UTC-02:00) Sao Paulo",
  "timezone_azores": "(UTC-01:00) Azores",
  "timezone_abidjan": "(UTC+00:00) Abidjan",
  "timezone_cape_verde": "(UTC-01:00) Cape Verde",
  "timezone_scoresbysund": "(UTC-01:00) Scoresbysund",
  "timezone_bissau": "(UTC+00:00) Bissau",
  "timezone_accra": "(UTC+00:00) Accra",
  "timezone_danmarkshavn": "(UTC+00:00) Danmarkshavn",
  "timezone_casablanca": "(UTC+00:00) Casablanca",
  "timezone_dublin": "(UTC+00:00) Dublin",
  "timezone_canary_islands": "(UTC+00:00) Canary Islands",
  "timezone_el_aaiun": "(UTC+00:00) El Aaiun",
  "timezone_thule": "(UTC-04:00) Thule",
  "timezone_gmt": "(UTC+00:00) GMT (no daylight saving)",
  "timezone_lisbon": "(UTC+00:00) Lisbon",
  "timezone_faeroe": "(UTC+00:00) Faeroe",
  "timezone_reykjavik": "(UTC+00:00) Reykjavik",
  "timezone_monrovia": "(UTC+00:00) Monrovia",
  "timezone_algiers": "(UTC+01:00) Algiers",
  "timezone_london": "(UTC+00:00) London",
  "timezone_amsterdam": "(UTC+01:00) Amsterdam",
  "timezone_andorra": "(UTC+01:00) Andorra",
  "timezone_berlin": "(UTC+01:00) Berlin",
  "timezone_budapest": "(UTC+01:00) Budapest",
  "timezone_ceuta": "(UTC+01:00) Ceuta",
  "timezone_brussels": "(UTC+01:00) Brussels",
  "timezone_copenhagen": "(UTC+01:00) Copenhagen",
  "timezone_gibrltar": "(UTC+01:00) Gibraltar",
  "timezone_prague": "(UTC+01:00) Central European Time - Prague",
  "timezone_lagos": "(UTC+01:00) Lagos",
  "timezone_luxembourg": "(UTC+01:00) Luxembourg",
  "timezone_madrid": "(UTC+01:00) Madrid",
  "timezone_malta": "(UTC+01:00) Malta",
  "timezone_ndjamena": "(UTC+01:00) Ndjamena",
  "timezone_paris": "(UTC+01:00) Paris",
  "timezone_oslo": "(UTC+01:00) Oslo",
  "timezone_monaco": "(UTC+01:00) Monaco",
  "timezone_tirane": "(UTC+01:00) Tirane",
  "timezone_rome": "(UTC+01:00) Rome",
  "timezone_vienna": "(UTC+01:00) Vienna",
  "timezone_tunis": "(UTC+01:00) Tunis",
  "timezone_warsaw": "(UTC+01:00) Warsaw",
  "timezone_amman": "(UTC+02:00) Amman",
  "timezone_zurich": "(UTC+01:00) Zurich",
  "timezone_athens": "(UTC+02:00) Athens",
  "timezone_burcharest": "(UTC+02:00) Bucharest",
  "timezone_chisinau": "(UTC+02:00) Chisinau",
  "timezone_stockholm": "(UTC+01:00) Stockholm",
  "timezone_cairo": "(UTC+02:00) Cairo",
  "timezone_gaza": "(UTC+02:00) Gaza",
  "timezone_jerusalem": "(UTC+02:00) Jerusalem",
  "timezone_johannesburg": "(UTC+02:00) Johannesburg",
  "timezone_helsinki": "(UTC+02:00) Helsinki",
  "timezone_khartoum": "(UTC+02:00) Khartoum",
  "timezone_belgrade": "(UTC+01:00) Central European Time - Belgrade",
  "timezone_damascus": "(UTC+02:00) Damascus",
  "timezone_maputo": "(UTC+02:00) Maputo",
  "timezone_kaliningrad": "(UTC+02:00) Moscow-01 - Kaliningrad",
  "timezone_riga": "(UTC+02:00) Riga",
  "timezone_kiev": "(UTC+02:00) Kiev",
  "timezone_nicosia": "(UTC+02:00) Nicosia",
  "timezone_tallinn": "(UTC+02:00) Tallinn",
  "timezone_tripoli": "(UTC+02:00) Tripoli",
  "timezone_sofia": "(UTC+02:00) Sofia",
  "timezone_vilnius": "(UTC+02:00) Vilnius",
  "timezone_istanbul": "(UTC+03:00) Istanbul",
  "timezone_baghdad": "(UTC+03:00) Baghdad",
  "timezone_minsk": "(UTC+03:00) Minsk",
  "timezone_windhoek": "(UTC+02:00) Windhoek",
  "timezone_nairobi": "(UTC+03:00) Nairobi",
  "timezone_moscow": "(UTC+03:00) Moscow+00 - Moscow",
  "timezone_qatar": "(UTC+03:00) Qatar",
  "timezone_beirut": "(UTC+02:00) Beirut",
  "timezone_riyadh": "(UTC+03:00) Riyadh",
  "timezone_syowa": "(UTC+03:00) Syowa",
  "timezone_tehran": "(UTC+03:30) Tehran",
  "timezone_baku": "(UTC+04:00) Baku",
  "timezone_dubai": "(UTC+04:00) Dubai",
  "timezone_mahe": "(UTC+04:00) Mahe",
  "timezone_mauritius": "(UTC+04:00) Mauritius",
  "timezone_tbilisi": "(UTC+04:00) Tbilisi",
  "timezone_samara": "(UTC+04:00) Moscow+01 - Samara",
  "timezone_reunion": "(UTC+04:00) Reunion",
  "timezone_yerevan": "(UTC+04:00) Yerevan",
  "timezone_kabul": "(UTC+04:30) Kabul",
  "timezone_aqtau": "(UTC+05:00) Aqtau",
  "timezone_aqtobe": "(UTC+05:00) Aqtobe",
  "timezone_ashgabat": "(UTC+05:00) Ashgabat",
  "timezone_dushanbe": "(UTC+05:00) Dushanbe",
  "timezone_maldives": "(UTC+05:00) Maldives",
  "timezone_yekaterinburg": "(UTC+05:00) Moscow+02 - Yekaterinburg",
  "timezone_kerguelen": "(UTC+05:00) Kerguelen",
  "timezone_mawson": "(UTC+05:00) Mawson",
  "timezone_tashkent": "(UTC+05:00) Tashkent",
  "timezone_colombo": "(UTC+05:30) Colombo",
  "timezone_kathmandu": "(UTC+05:45) Kathmandu",
  "timezone_omsk": "(UTC+06:00) Moscow+03 - Omsk",
  "timezone_india_standard_time": "(UTC+05:30) India Standard Time",
  "timezone_chagos": "(UTC+06:00) Chagos",
  "timezone_dhaka": "(UTC+06:00) Dhaka",
  "timezone_bishkek": "(UTC+06:00) Bishkek",
  "timezone_cocos": "(UTC+06:30) Cocos",
  "timezone_thimphu": "(UTC+06:00) Thimphu",
  "timezone_almaty": "(UTC+06:00) Almaty",
  "timezone_vostok": "(UTC+06:00) Vostok",
  "timezone_rangoon": "(UTC+06:30) Rangoon",
  "timezone_christmas": "(UTC+07:00) Christmas",
  "timezone_davis": "(UTC+07:00) Davis",
  "timezone_karachi": "(UTC+05:00) Karachi",
  "timezone_hanoi": "(UTC+07:00) Hanoi",
  "timezone_beijing": "(UTC+08:00) China Time - Beijing",
  "timezone_hongkong": "(UTC+08:00) Hong Kong",
  "timezone_hovd": "(UTC+07:00) Hovd",
  "timezone_jakarta": "(UTC+07:00) Jakarta",
  "timezone_kuala_lumpur": "(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok": "(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk": "(UTC+07:00) Moscow+04 - Krasnoyarsk",
  "timezone_brunei": "(UTC+08:00) Brunei",
  "timezone_macau": "(UTC+08:00) Macau",
  "timezone_makassar": "(UTC+08:00) Makassar",
  "timezone_choibalsan": "(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar": "(UTC+08:00) Ulaanbaatar",
  "timezone_manila": "(UTC+08:00) Manila",
  "timezone_pyongyang": "(UTC+08:30) Pyongyang",
  "timezone_singapore": "(UTC+08:00) Singapore",
  "timezone_irkutsk": "(UTC+08:00) Moscow+05 - Irkutsk",
  "timezone_taipei": "(UTC+08:00) Taipei",
  "timezone_jayapura": "(UTC+09:00) Jayapura",
  "timezone_dili": "(UTC+09:00) Dili",
  "timezone_perth": "(UTC+08:00) Western Time - Perth",
  "timezone_yakutsk": "(UTC+09:00) Moscow+06 - Yakutsk",
  "timezone_palau": "(UTC+09:00) Palau",
  "timezone_seoul": "(UTC+09:00) Seoul",
  "timezone_tokyo": "(UTC+09:00) Tokyo",
  "timezone_darwin": "(UTC+09:30) Central Time - Darwin",
  "timezone_dumont": "(UTC+10:00) Dumont D'Urville",
  "timezone_brisbane": "(UTC+10:00) Eastern Time - Brisbane",
  "timezone_guam": "(UTC+10:00) Guam",
  "timezone_vladivostok": "(UTC+10:00) Moscow+07 - Vladivostok",
  "timezone_port_moresby": "(UTC+10:00) Port Moresby",
  "timezone_truk": "(UTC+10:00) Truk",
  "timezone_adelaide": "(UTC+10:30) Central Time - Adelaide",
  "timezone_casey": "(UTC+11:00) Eastern Time - Hobart",
  "timezone_melbourne_sydney": "(UTC+11:00) Eastern Time - Melbourne, Sydney",
  "timezone_kosrae": "(UTC+11:00) Kosrae",
  "timezone_efate": "(UTC+11:00) Efate",
  "timezone_guadalcanal": "(UTC+11:00) Guadalcanal",
  "timezone_ponape": "(UTC+11:00) Ponape",
  "timezone_norfolk": "(UTC+11:00) Norfolk",
  "timezone_magadan": "(UTC+11:00) Moscow+08 - Magadan",
  "timezone_noumea": "(UTC+11:00) Noumea",
  "timezone_majuro": "(UTC+12:00) Majuro",
  "timezone_funafuti": "(UTC+12:00) Funafuti",
  "timezone_nauru": "(UTC+12:00) Nauru",
  "timezone_tarawa": "(UTC+12:00) Tarawa",
  "timezone_kwajalein": "(UTC+12:00) Kwajalein",
  "timezone_enderbury": "(UTC+13:00) Enderbury",
  "timezone_wake": "(UTC+12:00) Wake",
  "timezone_fakaofo": "(UTC+13:00) Fakaofo",
  "timezone_wallis": "(UTC+12:00) Wallis",
  "timezone_fiji": "(UTC+13:00) Fiji",
  "timezone_apia": "(UTC+14:00) Apia",
  "timezone_kiritimati": "(UTC+14:00) Kiritimati",
  "timezone_tongatapu": "(UTC+13:00) Tongatapu",
  "timezone_auckland": "(UTC+13:00) Auckland",
  "dateFormet": "날짜 형식",
  "Org_success": "조직 가입 요청이 성공적으로 전송되었습니다.",
  "Org_err": "이미 조직에 가입한 상태입니다.",
  "Request_Organization": "조직 요청",
  "organization_requested": "조직 요청되었습니다",
  "join": "가입하다",
  "your_email": "email@address.com",
  "medical_summary": "의료 개요는 아직 완료되지 않았습니다.",
  "medical_summary_msg": "내 의료 개요",
  "medical_success": "의료 개요는 정상적으로 제출되었습니다",
  "patient_form_popup": "이전의 설문지를 계속 작성하시겠습니까?",
  "Please_confirm_that_your_details_are_correct": "고객 정보가 정확한지 확인해 주십시오.",
  "Welcome_to_Akunah_your_Health_Passport": "당신의 건강 여권, 아쿠나에 오신 것을 환영합니다.",
  "Your_helth_is_in_your_hands": "건강은 당신의 손에 달려있다.",
  "Lets_begin_your_journey": "여정을 시작하세요.",
  "Please_complete_your_profile": "프로필을 작성해 주세요.",
  "Profile_complete": "프로필이 완성되었습니다!",
  "Would_you_like_to_add_another_profile_for_a_family_member": "가족 구성원을 위해 다른 프로필을 추가하시겠습니까?",
  "Alert_detected": "알림: 다중 로그인이 감지됨",
  "To_safeguard_your_data": "데이터 프라이버시 및 보안을 보호하기 위해 웹페이지의 시간이 초과되었습니다.",
  "Kindly_close_all_other_Akunah": "계속하려면 다른 모든 Akunah 탭을 닫고 '홈으로 돌아가기'를 누르세요.",
  "Akunah_Team_is_dedicated": "Akunah 팀은 데이터 보호를 위해 최선을 다합니다.",
  "Last_Created": "최신 생성",
  "First_Created": "처음 생성",
  "Alphabetically": "알파벳순(A-Z)",
  "Alphabetically1": "알파벳순(Z-A)",
  "Your_form_submission_was_successful_Clinician": "폼 제출에 성공했습니다. 이제 자동으로 임상의사 앱으로 리디렉션됩니다.",
  "Your_form_submission_was_successful_Patient": "폼 제출이 완료되었습니다. 이제 환자 앱으로 자동 리디렉션됩니다.",
  "If_this_doesnt_happen_within": "5초 이내에 이동되지 않으면",
  "click_here": "여기를 클릭",
  "Save": "저장",
  "Proceed_to_my_dashboard": "내 대시 보드로 이동",
  "myJourney": "나의 역정",
  "Home": "홈",
  "No_journey": "역정을 찾을 수 없습니다",
  "Share_results_with_your_doctor": "의사와 결과를 공유",
  "If_you_would_like_to_share_your_results_with": "의사와 결과를 공유하려면 아래의 \"즉시 공유\"를 선택하십시오",
  "Link_PROMs": "환자의 환자중심 건강결과 측정과 연결하기",
  "Welcome_to_akunah": "아쿠나에 오신 것을 환영합니다",
  "Share_to_your_Doctor_using_one_of_the_3_options_below": "다음 세 가지 옵션을 사용하여 의사와 공유한다",
  "Doctor_Code": "의사 코드",
  "Enter_the_code_that_has_been_provided_to_you_by_your": "이메일 또는 클리닉에 의사가 제공 한 코드를 입력하십시오. 코드가 없는 경우 최근의 방문 기록을 확인하거나 의사를 검색하십시오.",
  "Recent_Visits": "최근의 방문",
  "Select_your_recent_doctor_or": "최근의 의사 또는 의료기구를",
  "organisation_here": "여기서 선택하십시오",
  "Search": "검색",
  "Search_for_your_doctor_or": "의사를 검색하거나",
  "Please_confirm_that_the_following_details": "다음 세부 정보가 올바른지 확인하십시오",
  "Organisation": "기구",
  "PROMs_Specialty_Date": "환자중심 건강결과 측정 전문과목 및 날짜",
  "Get_started_by_completing_your": "환자의 환자중심 건강결과 측정 설문지부터 시작하십시오",
  "and_exploring_your_progress": "당신의 진행 상황을 탐구합니다",
  "Fill_up_your_PROMs_questionnaire": "환자의 환자중심 건강결과 측정 설문지를 작성하십시오",
  "Hip": "힙",
  "My_Progress": "내 진보",
  "Completed": "완성되었다",
  "Expired": "기한 만료됨",
  "Incomplete": "미완성",
  "Specialty": "전문 분야",
  "Doctor": "의사",
  "Status": "상태",
  "View_form": "양식을 보기",
  "Share_to_another_doctor": "다른 의사들과 공유",
  "Edit_form": "양식을 편집",
  "Answer_the_form": "양식을 답하기",
  "Complete_the_form": "양식을 작성",
  "Delete_the_form": "양식을 삭제",
  "Share_to_doctor": "의사와 공유하기",
  "Your_account_has_been_created": "계정이 작성되었습니다",
  "Please_click_below_to_proceed_to_your_dashboard": "대시 보드로 진행하려면 아래 링크를 클릭하십시오.",
  "If_you_would_like_to_add_another_profile_for_a_family": "가족에게 개인 파일을 추가하려면 제발",
  "Share": "공유",
  "Hip_Scoring_Self_Evaluation_Questionnaire": "고관절 점수 자기 평가 설문지",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire": "손과 손목 점수 자기 평가 설문지",
  "Right_Hand_nd_Wrist": "오른손과 손목",
  "Left_Hand_nd_Wrist": "왼손과 손목",
  "DOB_1": "생일",
  "Sex_1": "성별",
  "Address_1": "주소",
  "Please_Choose_PROMs_to_link": "연결될 환자중심 건강결과 측정을 선택하십시오",
  "Please_select_atleast_one_form_or_close": "한 개 이상의 환자중심 건강결과 측정을 선택하거나 닫으십시오",
  "cant_find_appropriate_PROMs_to_link": "연결할 적절한 환자중심 건강결과 측정을 찾을 수 없습니까?",
  "Request_New_PROMs": "새로운 환환자중심 건강결과 측정을 요청",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit": "알림 : 최근 방문에는 미완성 된 설문지가 있습니다",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted": "\"아니오\"를 선택하면 새로운 설문지가 작성되고 미완성 된 설문지가 삭제됩니다.",
  "Would_you_like_to_continue_this_form": "계속 하시겠습니까?",
  "Note": "주의:",
  "Expiring_in": "기한 만료까지:",
  "Date_Created": "생성 날짜",
  "Days": "일",
  "Shared": "공유됐다",
  "Pending": "보류중",
  "Initial": "초기",
  "Recent": "최근",
  "Pain": "통증",
  "Function": "기능",
  "Share_Now": "즉시 공유",
  "Share_Later": "나중에 공유",
  "Updated": "업데이트됨",
  "Display_More": "더 표시",
  "Address_Now": "바로 처리",
  "Address_Later": "나중에 처리",
  "You_have_pending_questionnaires": "보류중의 설문지가 있습니다",
  "Notifications": "알림",
  "Form_shared_successfully": "양식이 성공적으로 공유되었습니다",
  "You_have_already_shared_with_same_doctor_and_organization": "이미 같은 의사나 같은 기구와 공유했습니다",
  "No_recent_notifications": "최근 알림이 없습니다",
  "Have_a_good_day": "좋은 하루 보내세요",
  "Patient_scores": "환자 점수",
  "Display less": "덜 표시",
  "myJourney_Shoulder": "어깨",
  "myJourney_Shoulder_txt": "어깨를 선택하여 손과 손목 역정을 볼 수 있습니다",
  "myJourney_Elbow": "팔꿈치",
  "myJourney_Elbow_txt": "팔꿈치를 선택하여 손과 손목 역정을 볼 수 있습니다",
  "myJourney_Hand_Wrist": "손과 손목",
  "myJourney_Hand_Wrist_txt": "손과 손목을 선택하여 손과 손목 역정을 볼 수 있습니다",
  "myJourney_Hip": "엉덩이",
  "myJourney_Hip_txt": "엉덩이를 선택하여 엉덩이 역정을 볼 수 있습니다",
  "myJourney_knee": "무릎",
  "myJourney_knee_txt": "무릎을 선택하여 무릎을 볼 수 있습니다",
  "myJourney_Foot_Ankle": "발과 발목",
  "myJourney_Foot_Ankle_txt": "발과 발목을 선택하여 발과 발목 역정을 볼 수 있습니다",
  "data_sort": "종류",
  "Visit_Clinic": "클리닉을 방문",
  "My_Visits": "내 방문",
  "Patient_Scoring_Forms": "환자 스코어링 양식",
  "Other_findings": "다른 발견",
  "Organization": "기구",
  "more": "더",
  "mediacal_summary": "의료 요약",
  "my_consent": "내 동의",
  "Are_you_sure_you_want_to_reject": "이 추천을 차단 하시겠습니까?",
  "reject": "거부",
  "i_accept_consent": "나는 이것에 동의한다",
  "grant": "부여",
  "no_conscent_found": "동의가 없습니다",
  "rejected": "거부됨",
  "No_Time_Lines": "타임 라인 없음",
  "Hide_Score": "점수 숨기기",
  "Show_Score": "점수 표시하기",
  "patient_sex": "성별",
  "Dominant_hand_1": "우세수",
  "patient_Initial_Assessment": "초기 평가",
  "patient_Treatment_Category": "치료 카테고리",
  "View_all": "모두 보기",
  "Display_Less": "덜 표시",
  "patient_general_past_Medical_history": "의료 기록",
  "patient_general_past_Medication_name": "약물 목록",
  "Evaluated_Knee": "평가 된 무릎",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function": "영향을 받지 않은 손과 손목에 통증이 없고 정상적인 기능이 가능한가요?",
  "Evaluated_Hip": "평가 된 고관절 ",
  "Evaluated_Foot_Ankle": "평가 된 발과 발목",
  "patient_Evaluated_Elbow": "평가 된 팔꿈치",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function": "영향을 받지 않은 발과 발목에 통증이 없고 정상적인 기능이 가능한가요?",
  "Evaluated_Hand_Wrist": "평가 된 손과 손목",
  "patient_shoulder_feeling_instability_q2": "어깨가 얼마나 불안정한지 표시해주세요.",
  "patient_shoulder_feeling_instability_q3": "어깨가 얼마나 불안정한지 표시해주세요.",
  "patient_Hand_BCTQ": "손과 손목 BCTQ",
  "Paediatric_Knee_Satisfaction": "소아 무릎 만족도",
  "patient_foot_ankle_symptoms": "발과 발목의 다른 증상",
  "Right_Foot_and_Ankle": "오른 발 및 오른 발목",
  "Left_Foot_and_Ankle": "왼 발과 왼 발목",
  "summary": "요약",
  "Shoulder_Treatment_Form": "어깨 치료 양식",
  "Adolescent_knee_Treatment_Form": "청소년 무릎 치료 양식",
  "Adult_Knee_Treatment_Form": "성인 무릎 치료 양식",
  "Paediatric_Knee_Treatment_Form": "소아 무릎 치료 양식",
  "Hip_Treatment_Form": "고관절 치료 양식",
  "Foot_Ankle_Treatment_Form": "발과 발목 치료 양식",
  "Elbow_Treatment_Form": "팔꿈치 치료 양식",
  "Hand_Wrist_Treatment_Form": "손 및 손목 치료 양식",
  "Upload_complete": "업로드 완료",
  "Select_Date_Format": "날짜 형식을 선택",
  "timezone_casey1": "(UTC+11:00) Casey",
  "timezone_Kamchatskiy": "(UTC+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
  "timezone_Guayaquil": "(UTC-05:00) Guayaquil",
  "requested": "요청",
  "Display_name": "이름 표시",
  "Are_you_sure_you_want_to_delete_Consent": "이 동의서를 거부하시겠습니까?",
  "form": "양식",
  "Requested_by": "요청한 사람",
  "requested_hip_form": "엉덩이 양식 요청",
  "requested_shoulder_form": "어깨 양식 요청",
  "requested_knee_form": "무릎 양식 요청",
  "requested_foot_form": "발 및 발목 양식 요청",
  "requested_hand_form": "손 및 손목 양식 요청",
  "requested_elbow_form": "팔꿈치 양식 요청",
  "requested_General_form": "일반 양식 요청",
  "requested_Paediatric_form": "requested a Paediatric Knee form",
  "requested_Adolescent_form": "requested a Adolescent Knee form",
  "Expiring_in_Tomorrow": "Expiring tomorrow",
  "PROMs_questionnaire": "Dr. [doctor’s full name who requested] has requested you to fill out [specialty] PROMs questionnaire",
  "new_message": "You have a new message from [full name of message sender]",
  "new_appointment": "You have a new appointment with Dr. [doctor’s full name]",
  "upcoming_appointment": "Reminder: upcoming appointment with Dr. [full name] on [date]",
  "pending_message": "Reminder: you have a pending [specialty] PROMs questionnaire",
  "document_message": "Dr. [full name of who shared] shared a document with you",
  "requested_message": "Dr. [full name of who requested] has requested for a consent",
  "patient_added_message": "You have been added as a patient of Dr [full name] in [organisation]",
  "cancelled_appointment_message": "Your appointment with Dr [full name] on [date of appointment] has been cancelled.",
  "Mark_all_read": "Mark all as read",
  "Delete_All": "Delete All",
  "No_Notification": "No notifications found",
  "You_have_not": "You have not started your journey yet. Please fill out a PROMs questionnaire to begin your journey.",
  "pass_verification1": "Password should be at least 8 characters long *",
  "pass_verification2": "Password must contain at least 1 upper case character*",
  "pass_verification3": "Password must contain at least 1 lower case character*",
  "pass_verification4": "Password must contain at least 1 digit *",
  "loginTitle": "Akunah:Sign In ::",
  "Create_patient_mobile": "Create new profile",
  "Select_Profile_mobile": "Select your profile",
  "Profile_Created_mobile": "Profile Created",
  "Your_account_has_been_created_mobile": "Your account has been created.",
  "Please_tap_below_to_sign_in_to_your_workspace_mobile": "Please tap below to sign in to your workspace.",
  "Start_Journey_mobile": "Start Journey",
  "If_add_another_profile_mobile": "If you would like to add another profile for a family member, please",
  "Create_Profile_mobile": "Create Profile",
  "change_Profile_Pic_mobile": "Change profile photo",
  "MobileImp_mobile": "Mobile*",
  "Gender_mobile": "Gender",
  "Country_1_mobile": "Country",
  "Med_1_mobile": "Med",
  "Appoinments_mobile": "Appointments",
  "View_Profile_mobile": "View Profile",
  "Sign_out_mobile": "Sign out",
  "My_Appointments_mobile": "My Appointments",
  "Appoinments_Past_mobile": "Past",
  "No_appointments_mobile": "No appointments",
  "When_appointment_available_mobile": "When your next appointment is available, it will be displayed here",
  "Available_soon_mobile": "Available soon!",
  "please_akunah_desktop_mobile": "To access it now, please login tome.akunah.comon your desktop.",
  "All_Notes_mobile": "All Notes",
  "AddButton_mobile": "AddButton",
  "Start_your_note_mobile": "Start your note...",
  "Done_mobile": "Done",
  "Note_Title_mobile": "Note Title",
  "New_Consents_mobile": "New Consents",
  "Signed_Consents_mobile": "Signed Consents",
  "Consent_mobile": "Consent",
  "Signed_Type_mobile": "Signed by Type",
  "Submit_Consent_mobile": "Submit Consent",
  "Reject_Consent_mobile": "Reject Consent",
  "Type_name_mobile": "Type your name",
  "Signature_mobile": "Signature",
  "No_consents_mobile": "No consents",
  "Any_display_ready_mobile": "Any new or signed consents will display here when ready",
  "Clinician_Documents_mobile": "Clinician Documents",
  "Uploaded_Documents_mobile": "Uploaded Documents",
  "Upload_Document_mobile": "Upload Document",
  "Upload_file_mobile": "Upload file",
  "Upload_photo_mobile": "Upload photo",
  "Take_photo_mobile": "Take photo",
  "Uploading_1_mobile": "Uploading",
  "New_1_mobile": "New",
  "Share_document_mobile": "Share this document",
  "Enter_email_mobile": "Enter email",
  "Add_message_like_mobile": "Add a message, if you’d like.",
  "Are_delete_account_mobile": "Are you sure you want to delete your account?",
  "No_display_mobile": "No documents to display",
  "All_clinician_documents_mobile": "All clinician documents will display here",
  "New_Messages_mobile": "New Messages",
  "Read_Messages_mobile": "Read Messages",
  "Any_new_unread_messages_ready_mobile": "Any new or unread messages will be displayed here when ready",
  "Shoulder_Journey_mobile": "Shoulder Journey",
  "Hip_Journey_mobile": "Hip Journey",
  "Foot_Ankle_Journey_mobile": "Foot and Ankle Journey",
  "Elbow_Journey_mobile": "Elbow Journey",
  "Knee_Journey_mobile": "Knee Journey",
  "Hand_Wrists_Journey_mobile": "Hand and Wrist Journey",
  "New_Form_mobile": "New Form",
  "Start_new_form_mobile": "Start new form",
  "Requested_Forms_mobile": "Requested Forms",
  "Incomplete_Forms_mobile": "Incomplete Forms",
  "Completed_Forms_mobile": "Completed Forms",
  "No_Available_mobile": "No Forms Available",
  "Start_new_journey_mobile": "Start a new form to begin your journey.",
  "View_Results_mobile": "View Results",
  "Complete_Form_mobile": "Complete Form",
  "Delete_Form_mobile": "Delete Form",
  "Edit_Details_mobile": "Edit Details",
  "Personal_Details_mobile": "Personal Details",
  "DOB_1_mobile": "DOB",
  "Unknown_Gender_mobile": "Unknown Gender",
  "Mobile_mobile": "Mobile",
  "Next_kin’s_name_mobile": "Next of kin’s name",
  "Next_kin’s_relationship_mobile": "Next of kin’s relationship",
  "GP_Details_mobile": "GP Details",
  "GP_Name_mobile": "GP Name",
  "Practice_mobile": "Practice",
  "Edit_Profile_mobile": "Edit Profile",
  "Full_Name_mobile": "Full Name",
  "Country_Code_mobile": "Country Code",
  "GP_Full_Name_mobile": "GP Full Name",
  "Doctor’s_full_name_mobile": "Doctor’s full name",
  "All_mobile": "All",
  "No_notifications_mobile": "No recent notifications",
  "Any_new_available_mobile": "Any new or unread notifications will be displayed here when available",
  "Account_mobile": "Account",
  "Manage_My_Account_mobile": "Manage My Account",
  "Change_Password_mobile": "Change Password",
  "Privacy_mobile": "Privacy",
  "Time_mobile": "Time",
  "Date_Format_mobile": "Date & Format",
  "Support_mobile": "Support",
  "All_Notifications_mobile": "All Notifications",
  "Receive_notifications_mobile": "Receive all notifications",
  "Journey_Updates_mobile": "Journey Updates",
  "Receive_appointment_notifications_mobile": "Receive all appointment notifications",
  "Start_Form_mobile": "Start Form",
  "Your_journey_starts_here_mobile": "Your journey starts here",
  "To_recovery_questions_mobile": "To get started with your journey to recovery, we just ask you to please best answer the following questions.",
  "Please_estimate_questions_mobile": "Please estimate 12-15 minutes to answer these questions.",
  "Shoulder_Form_mobile": "Shoulder Form",
  "Save_and_Continue_mobile": "Save & Continue",
  "How_years_smoke_mobile": "How many years did you smoke?",
  "patient_shoulder_pain_inner_value_mobile": "(0 means no pain, 15 means intolerable pain)",
  "Scoring_Key_mobile": "Scoring Key",
  "patient_shoulder_wosi_1_mobile": "Western Ontario Shoulder Instability Index (WOSI)",
  "patient_shoulder_wosi_sectionA_phy_mobile": "PHYSICAL SYMPTOMS",
  "patient_shoulder_wosi_sectionB_sp_mobile": "SPORTS/RECREATION/WORK",
  "patient_shoulder_wosi_sectionC_life_mobile": "LIFESTYLE",
  "patient_shoulder_wosi_sectionD_emo_mobile": "EMOTIONS",
  "Specific_AC_Score_mobile": "Specific AC Score (SACS)",
  "patient_shoulder_sacs_sectionA_pain_mobile": "Pain",
  "patient_shoulder_sacs_sectionB_function_mobile": "FUNCTION",
  "patient_shoulder_sacs_sectionC_Qua_mobile": "Quality of life and satisfaction",
  "Finish_mobile": "Finish",
  "Share_Results_mobile": "Share Results",
  "Share_Doctor_mobile": "Share to your Doctor",
  "Choose_share_results_doctor_mobile": "Choose one of the 3 options below to share your results with your doctor",
  "Share_your_Clinician_mobile": "Share with your Clinician",
  "Select_recent_doctor_organisation_mobile": "Select your recent doctor or organisation here",
  "Clinician_Search_mobile": "Clinician Search",
  "Search_doctor_organisation_mobile": "Search for your doctor or organisation here",
  "Clinician_Code_mobile": "Clinician Code",
  "Enter_doctor_code_search_mobile": "Enter the code that has been provided to you by your doctor in your email or in the clinic. If the code is not available, please check you recent visits or use the search.",
  "Skip_sharing_now_mobile": "Skip sharing for now",
  "Code_please_try_again_mobile": "Code incorrect, please try again.",
  "Confirm_details_mobile": "Confirm details",
  "PROMs_Speciality_Date_mobile": "PROMs Speciality & Date",
  "Results_Shared_mobile": "Results Shared!",
  "Your_successfully_doctor_mobile": "Your results has been successfully shared with your doctor.",
  "Please_results_mobile": "Please tap below to view your results.",
  "If_another_doctor_mobile": "If you would like to share with another doctor",
  "please_mobile": "please",
  "click_here_mobile": "click here",
  "Pain_Score_mobile": "Pain Score",
  "Recent_Scores_mobile": "Recent Scores",
  "Right_Arm_mobile": "Right Arm",
  "SACS_Score_mobile": "SACS",
  "complete_mobile": "Complete",
  "Add_Note_mobile": "Add Note",
  "Pending_Forms_mobile": "Pending Forms",
  "Your_pending__will_display_mobile": "Your pending forms will be displayed here",
  "Your_Incomplete_display_mobile": "Your Incomplete Forms will be displayed here",
  "Your_complete_display_mobile": "Your complete forms will be displayed here",
  "PROMs_Shoulder_request_mobile": "PROMs Shoulder request",
  "New_message_mobile": "New message",
  "New_appointment_mobile": "New appointment",
  "New_Consent_request_mobile": "New Consent request",
  "New_shared_document_mobile": "New shared document",
  "New_Organisation_Clinician_mobile": "New Organisation/Clinician",
  "This_field_cannot_empty_mobile": "This field cannot be empty.",
  "List_work_activities_mobile": "List work activities ...",
  "List_hobby_activities_mobile": "List hobby and sport activities ...",
  "patient_shoulder_wosi_sectionA_mobile": "SECTION A:",
  "patient_shoulder_wosi_sectionB_mobile": "SECTION B:",
  "patient_shoulder_wosi_sectionC_mobile": "Section C:",
  "patient_shoulder_wosi_sectionD_mobile": "Section D:",
  "File_size_should_than_10MB_mobile": "File size should not more than 10MB",
  "Share_this_document_mobile": "Share this document",
  "sac_explaination": "Explanation",
  "patient_shoulder_sacs_explanation_mobile": "For each question, you will be asked to rate either your level of pain or difficulty performing different tasks. The rating scale is designed so that 0 reflects no pain or difficulty while 10 reflects the most severe amount of pain or difficulty.",
  "patient_shoulder_sacs_a3_note_mobile": "(e.g. Laptop or hand bag strap).",
  "patient_shoulder_sacs_b2_note_mobile": "(e.g. Resting your arm in a pocket or using a sling).",
  "patient_shoulder_sacs_b3_note_mobile": "(e.g. Push up, cycling, yoga).",
  "patient_shoulder_sacs_b4_note_mobile": "(e.g. Bag of shopping).",
  "patient_shoulder_sacs_b5_note_mobile": "(e.g. To reach across and touch the unaffected shoulder).",
  "patient_shoulder_sacs_b6_note_mobile": "(e.g. Greater than five kilograms).",
  "patient_shoulder_sacs_b7_note_mobile": "(e.g. Holding the steering wheel whilst driving).",
  "patient_shoulder_sacs_c1_note_mobile": "(Including the presence of a lump).",
  "Function_Score_mobile": "Function Score",
  "Instability_Score_mobile": "Instability Score",
  "patient_shoulder_pain_q2_mobile": "On a scale of 0-15, what is the highest level of pain that you have experienced in your shoulder during ordinary activities within the last 24 hours?",
  "patient_shoulder_pain_q6_mobile": "Do you take pain medication",
  "patient_shoulder_pain_q6_mobile_sub": "(eg paracetamol etc)?",
  "patient_shoulder_pain_q6_a2_q1_mobile": "Do you take strong pain medication",
  "patient_shoulder_pain_q6_a2_q1_mobile_sub": "(eg codeine, tramadol, morphine etc)?",
  "patient_shoulder_feeling_instability_q1_b1_mobile_sub": "(as if it is going to dislocate)?",
  "patient_shoulder_feeling_instability_q1_mobile": "Does your shoulder feel unstable",
  "patient_shoulder_wosi_instruction_mobile": "INSTRUCTIONS",
  "Next_of_kins_number_mobile": "Next of kin’s number",
  "Next_of_Kin_mobile": "Next of Kin",
  "Doctor_Name_mobile": "Doctor's name",
  "Doctors_practice_mobile": "Doctor’s practice",
  "Doctors_practice_address_mobile": "Doctor’s practice address",
  "Language_mobile": "Languages",
  "No_Internet_Connection_mobile": "No Internet Connection",
  "Please_type_your_name_mobile": "Please type your name",
  "Choose_Sign_Type_mobile": "Choose Sign Type",
  "Signed_by_Draw_mobile": "Signed by Draw",
  "select_image_mobile": "select image",
  "Consent_rejected_mobile": "Consent rejected",
  "Consent_submitted_mobile": "Consent submitted",
  "Today_mobile": "Today",
  "New_mobile": "New",
  "No_recent_scores_display_mobile": "No recent scores to display",
  "All_recent_scores_display_here_mobile": "All recent scores will display here",
  "Shared_Documents_mobile": "Shared Documents"
}